import {
  saveAuthCompanyToCookie,
  saveAuthUuidToCookie,
  saveAuthTokenToCookie,
  saveAuthGradeToCookie,
  saveAuthIdToCookie,
  saveAuthNameToCookie,
  saveHistoryCompanyToCookie,
  saveHistoryUuidToCookie,
  deleteCookie,
  getAuthIdFromCookie,
  saveTriralCookie,
} from "@/utils/cookies";
// import { getApprovalCountByStatus } from "@/api/approval";
import {
  getOrder,
  getSelectOrder,
  getSelectIdOrder,
  putOrder,
  postOrder,
  purchaseCacListFind,
  mainListFind,
  orderListFind,
  productionListFind,
  releaseListFind,
  orderBakNumFind,
  repeatNum,
  excelOrderListFind,
  orderBuyerFind,
  getSelectOrderList,
  searchDocGrop,
  totalCount,
  calculateSave,
  calculateEditSave,
  orderPageSave,
  mainChartDataFind,
} from "@/api/order";
import { getItemByCompany, getItemByCompanybyStatus } from "@/api/item";
import {
  getSelectClientList,
  getClientByCompany,
  getClientByCompanyStatus,
} from "@/api/client";
import { getUserByCompany, getSelectUserList } from "@/api/user";
import { getCompanyOneById } from "@/api/company";
import {
  claimIdFind,
  targetClaimFind,
  claimListFind,
  claimTypeIdFind,
  endClaimListFind,
  getClaim,
  postClaim,
  putClaim,
  notParseClaimFInd,
  getClaimListStatus,
  saveClaim,
  claimMultiSave,
  claimSearch,
  claimTotalCount,
} from "@/api/claim";
import {
  getaApprovalRouteComId,
  getApprovalByStatus,
  putApproval,
  postApproval,
  getApprovalCountByStatus,
  getRequestApproval,
  getRequestFormData,
  getRequesApprovalTypeList,
  getApprovalHistory,
} from "@/api/approval";
import {
  getReceivableByClient,
  postReceivable,
  putReceivable,
  receivableSearch,
  receivableCount,
} from "@/api/receivable";
import {
  getClothestypeByCompany,
  getClothestypeByCompanybyStatus,
  putClothestype,
  postClothestype,
} from "@/api/clothestype";
import { putClient, postClient } from "@/api/client";
import { putItem, postItem } from "@/api/item";
// import { } from "@/api/approval";
import store from ".";
import exceljs from "exceljs";
import itemHeader from "@/assets/data/excelheader/item";
import clientHeader from "@/assets/data/excelheader/client";
import ctypeHeader from "@/assets/data/excelheader/ctype";
import { getReceivable } from "@/api/receivable";
import {
  postReceivableAmount,
  putReceivableAmount,
  deleteReceivableAmount,
  saveReceivableAmount,
} from "@/api/receiveAmount";
import {
  paySave,
  refundPay,
  paymentCheck,
  getPaymentList,
  getAdminPaymentList,
  getMembershipInfo,
} from "@/api/pay";
import { getfileOneById, downloadFile } from "@/api/file";
import { getFilterData } from "@/api/common/common";
import router from "@/router";
import { register, modifyPw, adminCheck, verificationCode } from "@/api/auth";
import OrderStatus from "@/assets/data/status/OrderStatus";
// import item from "@/assets/data/excelheader/item";
import dayjs from "dayjs";
import { certifiedEmail } from "@/api/print";
// import claim from "@/assets/table/approval/claim";
import ClienTypeAcronyms from "@/assets/data/kind/ClienTypeAcronyms";
import _, { before, conforms, find, first, head } from "lodash";
//필터링 이동을 위한 헤더 파일 참조
import claimHeader from "@/assets/table/claim/claimHeader";
import endClaimHeader from "@/assets/table/claim/claimEndHeader";
import purCacHeader from "@/assets/table/purchase/purchaseCalculateHeaders";
import salesCacHeader from "@/assets/table/sales/headersCalculateSales";
import purDedHeader from "@/assets/table/purchase/purchaseDeadlineHeaders";
import salesDedHeader from "@/assets/table/sales/headersDeadlineSales";
import mainHeader from "@/assets/table/main/homeHeaders";
import purductionHeader from "@/assets/table/production/productionOrderHeaders";
import orderHeader from "@/assets/table/order/orderListHeader";
import purVatHeader from "@/assets/table/receivable/receivableHeaders";
import saleVatHeader from "@/assets/table/receivable/receivableSalesHeaders";
import stockHeader from "@/assets/table/stock/stock";
import commitName from "@/components/pages/common/ApiActionName.js";

import {
  postStock,
  putStock,
  getStockForRelease,
  getStockList,
  getStockForId,
  deleteStock,
  // getStockForItem,
  getUseStockClientList,
  getStockTableList,
  getSetStockList,
  saveStockId,
  stockSearch,
  stockCount,
  saveStock,
  saveSelectStock,
} from "@/api/stock.js";
import {
  getUseStockList,
  postUseStock,
  putUseStock,
  deleteUseStock,
  getIdStockList,
  saveUseStockLength,
} from "@/api/useStock.js";
import {
  productionPageSave,
  productionPageDelete,
  productionPageSearchApproval,
  productionPageNewDocDelete,
} from "@/api/production";
import getters from "./getters";

// import { NativeEventSource, EventSourcePolyfill } from "event-source-polyfill";
// import socket from "vue-socket.io";
const filterHeader = {
  main: mainHeader,
  order: orderHeader,
  production: purductionHeader,
  purchasecalculate: purCacHeader,
  purchasedeadline: purDedHeader,
  salescalculate: salesCacHeader,
  salesdeadline: salesDedHeader,
  purchaseVat: purVatHeader,
  salesVat: saleVatHeader,
  claim: claimHeader,
  endClaim: endClaimHeader,
  stock: stockHeader,
};
const totalList = {
  main: "setMainOriginTotalList",
  order: "setOrderOriginTotalList",
  production: "setProductionOriginTotalList",
  purchasecalculate: "setPurchaseCacOriginTotalList",
  purchasedeadline: "setPurchaseDedOriginTotalList",
  salescalculate: "setSalesCacOriginTotalList",
  salesdeadline: "setSalesDedOriginTotalList",
  purchaseVat: "setVatOriginTotalList",
  salesVat: "setSalesVatOriginTotalList",
  claim: "setClaimOriginTotalList",
  endClaim: "setEndClaimOriginTotalList",
  stock: "setStockOriginTotalList",
};
const originFilter = {
  main: "setMainOriginFilter",
  order: "setOrderOriginFilter",
  production: "setProductionOriginFilter",
  purchasecalculate: "setPurchaseCacOriginFilter",
  purchasedeadline: "setPurchaseDedcOriginFilter",
  salescalculate: "setSalesCaccOriginFilter",
  salesdeadline: "setSalesDedOriginFilter",
  purchaseVat: "setVatOriginFilter",
  salesVat: "setSalesVatOriginFilter",
  claim: "setClaimOriginFilter",
  endClaim: "setEndClaimOriginFilter",
  stock: "setStockOriginFilter",
};
const filteredOriginFilter = {
  main: "mainFilteredOrginList",
  order: "setOrderFilteredOrginList",
  production: "setProductionFilteredOrginList",
  purchasecalculate: "setPurchaseCacFilteredOrginList",
  purchasedeadline: "setPurchaseDedFilteredOrginList",
  salescalculate: "setSalesCacFilteredOrginList",
  salesdeadline: "setSalesDedFilteredOrginList",
  purchaseVat: "setVatFilteredOrginList",
  salesVat: "setSalesVatFilteredOrginList",
  claim: "setClaimFilteredOrginList",
  endClaim: "setEndClaimFilteredOrginList",
  stock: "setStockFilteredOrginList",
};

const orderList = {
  main: "setMainOrderList",
  order: "setOrderPageList",
  production: "setProductionOrderList",
  purchasecalculate: "setPurchCalculList",
  purchasedeadline: "setPurchSalesCalculList",
  salescalculate: "setSalesCacList",
  salesdeadline: "setSalesEndList",
  purchaseVat: "setVatList",
  salesVat: "setVatSalesList",
  claim: "setClaimList",
  endClaim: "setEndClaimList",
  stock: "setStockTableList",
};
const pageOrderList = {
  main: "mainOrderList",
  order: "orderPageList",
  production: "productionOrderList",
  purchasecalculate: "purchCalculList",
  purchasedeadline: "purchSalesCalculList",
  salescalculate: "salesCacList",
  salesdeadline: "salesEndList",
  purchaseVat: "vatList",
  salesVat: "vatSalesList",
  claim: "claimList",
  endClaim: "endClaimList",
  stock: "stockTableList",
};

const orginList = {
  main: "setMainOriginList",
  order: "setOrderPageOrigin",
  production: "setProductionOriginList",
  purchasecalculate: "setPurchCalculOrigin",
  purchasedeadline: "setPurchSalesCalculOrigin",
  salescalculate: "setSalesCacOrigin",
  salesdeadline: "setSalesEndOrigin",
  purchaseVat: "setOriginVatList",
  salesVat: "setSalesOriginVatList",
  claim: "setClaimOriginalList",
  endClaim: "setEndClaimOriginalList",
  stock: "setStockTableOriginList",
};

const filterList = {
  main: "setMainFilterList",
  order: "setOrderPageFilter",
  production: "setProductionFilterList",
  purchasecalculate: "setPurchCalculFilter",
  purchasedeadline: "setPurchSalesCalculFilter",
  salescalculate: "setSalesCacFilter",
  salesdeadline: "setSalesEndFilter",
  purchaseVat: "setVatFilterList",
  salesVat: "setSalesVatFilterList",
  claim: "setClaimFilterList",
  endClaim: "setEndClaimFilterList",
  stock: "setStockTableFilterList",
};
const headerFilterList = {
  main: "mainFilterList",
  order: "orderPageFilter",
  production: "productionFilterList",
  purchasecalculate: "purchCalculFilter",
  purchasedeadline: "purchSalesCalculFilter",
  salescalculate: "salesCacFilter",
  salesdeadline: "salesEndFilter",
  purchaseVat: "vatFilterList",
  salesVat: "salesVatFilterList",
  claim: "claimFilterList",
  endClaim: "endClaimFilterList",
  stock: "stockTableFilterList",
};
const filteringFindList = {
  main: mainListFind,
  order: orderListFind,
  production: productionListFind,
  purchasecalculate: releaseListFind,
  purchasedeadline: releaseListFind,
  salescalculate: releaseListFind,
  salesdeadline: releaseListFind,
  purchaseVat: getReceivable,
  salesVat: getReceivable,
  claim: claimListFind,
  endClaim: endClaimListFind,
  stock: getStockTableList,
};
const copyData = (data) => {
  return data ? JSON.parse(JSON.stringify(data)) : "";
};

// 동기처리를 위한 함수
const compose = (...functions) => {
  return (initialValue) => {
    functions.reduce(async (val, fn) => {
      const prevVal = await val.then();
      const result = await fn(prevVal);

      return Promise.resolve(result);
    }, Promise.resolve(initialValue));
  };
};
const monthFormat = (data) => {
  const temp = new Date(data);
  const month = 1 + temp.getMonth();
  const day = temp.getDate();
  return `${month >= 10 ? month : "0" + month}-${day >= 10 ? day : "0" + day}`;
};
function dateFormat(date) {
  if (!date) {
    date = this.form.date_register;
  }
  const temp = new Date(date);
  const year = temp.getFullYear();
  const month = 1 + temp.getMonth();
  const day = temp.getDate();
  return `${year}-${month >= 10 ? month : "0" + month}-${
    day >= 10 ? day : "0" + day
  }`;
}
function praseSaveDataComma(form) {
  Object.keys(form).map((key) => {
    if (
      key !== "arise_detail" &&
      key !== "pass_detail" &&
      typeof form[key] === "string"
    ) {
      form[key] = form[key].replace(/,/g, "");
    }
  });
  return form;
}
function getMask(value) {
  if (!value) {
    return "";
  }

  value = value.replace(/[^0-9]/g, "");

  let result = [];
  let restNumber = "";

  // 지역번호와 나머지 번호로 나누기
  if (value.startsWith("02")) {
    // 서울 02 지역번호
    result.push(value.substr(0, 2));
    restNumber = value.substring(2);
  } else if (value.startsWith("1")) {
    // 지역 번호가 없는 경우
    // 1xxx-yyyy
    restNumber = value;
  } else {
    // 나머지 3자리 지역번호
    // 0xx-yyyy-zzzz
    result.push(value.substr(0, 3));
    restNumber = value.substring(3);
  }

  if (restNumber.length === 7) {
    // 7자리만 남았을 때는 xxx-yyyy
    result.push(restNumber.substring(0, 3));
    result.push(restNumber.substring(3));
  } else {
    result.push(restNumber.substring(0, 4));
    result.push(restNumber.substring(4));
  }

  return result.filter((val) => val).join("-");
}
function businessNumMask(str) {
  str = str.replace(/[^0-9]/g, "");
  return str.length < 4
    ? str
    : str.length < 6
    ? `${str.substr(0, 3)}-${str.substr(3)}`
    : str.length < 11
    ? `${str.substr(0, 3)}-${str.substr(3, 2)}-${str.substr(5)}`
    : str;
}

//가공의뢰서 parse
const manufactureParse = (data, color) => {
  const manufacture = {
    company: data.company,
    id: data.id ? data.id : "", // 고유 값
    liaison: data.liaison._id,
    kind: data.kind, // 7 : 가공의뢰서
    date_register: data.date_register, // 작성 날짜
    date_modify: new Date(Date.now()), // 저장 날짜
    date_payment: data.date_payment, // 납기 날짜
    unit_price: parsingNum(data.unit_price), // 단가
    eschamge: parsingNum(data.eschamge), // 환율
    width: data.width, // 중량
    full_width: data.full_width, // 전체 폭
    post_processing: data.post_processing, // 후가공
    currency_sign: data.currency_sign, // 금액 단위
    unit_sign: data.unit_sign, // 수량 단위
    item: data.item._id, // 아이템
    inspection: data.inspection ? data.inspection._id : "", // 검사소
    place_manufacture: data.place_manufacture._id, // 가공처
    quantity_by_color: parsingNum(data.quantity_by_color), //COLOR 별 SAMPLE
    confirmation: data.confirmation, // 확인사항
    precautions: data.precautions, // 주의사항
    sucharge_price: parsingNum(data.sucharge_price), // surcharge(제직, 건당)
    style_list: data.style_list,
    shrigkage: data.shrigkage,
    color_list: manufaColorListParse(color),
    isOrder: data.isOrder,
    referenceDoc: data.referenceDoc ? data.referenceDoc.id : "",
  };
  if (data.surcharge_unit)
    manufacture.surcharge_unit = {
      price: parsingNum(data.surcharge_unit), // surcharge 금액
      is_used: false, // 사용 여부
    }; // surcharge(제직, 단위 당)

  return manufacture;
};

const manufaColorListParse = (dialogColor) => {
  const color_list = [];
  dialogColor.forEach((x, i) => {
    if (x.name && x.quantity) {
      const color = {
        name: x.name,
        quantity: x.quantity,
        indexColorName: `${i + 1}_${x.name}`,
      };
      if (x.quantity_shrigkage)
        color.quantity_shrigkage = +x.quantity_shrigkage;
      if (x.quantity_sample) color.quantity_sample = +x.quantity_sample;
      if (x.color_surcharge_unit)
        color.color_surcharge_unit = x.color_surcharge_unit;
      if (x.color_surcharge_price)
        color.color_surcharge_price = {
          price: x.color_surcharge_price,
          is_used: false,
        };

      color_list.push(color);
    }
  });
  return color_list;
};

const colorEditedItemSet = (colorList) => {
  const formColor = JSON.parse(JSON.stringify(colorList));
  let productionColor = [];
  if (formColor.length < 13)
    for (let i = 0; i < 13; i++) {
      productionColor.push(formColor[i] !== undefined ? formColor[i] : {});
      // if (formColor[i] && formColor[i].surcharge_price)
      //   productionColor[i].color_surcharge_price = formColor[i].surcharge_price;
    }
  else
    productionColor = formColor.map((color) => {
      const newColor = {
        name: color.name,
        quantity: color.quantity,
      };
      // if (color && color.surcharge_price)
      //   newColor.color_surcharge_price = color.surcharge_price;
      return newColor;
    });

  return {
    productionColor,
    total_quantity: productionColor
      .filter((x) => x.quantity || x.quantity_sample)
      .reduce(
        (r, c) =>
          r +
          (c.quantity_sample
            ? +c.quantity
              ? +c.quantity + +c.quantity_sample
              : +c.quantity_sample
            : +c.quantity),
        0,
      ),
  };
};

// 선매입 잔여 수량 단위 변환 리턴
const unitChange = (unit, data) => {
  return (data * (unit === "y" ? 1.094 : 0.914)).toFixed(0);
};

// 출고의뢰서 parse
const releaseParse = (data, saveColorList) => {
  const release = {
    company: data.company, // ref company.id
    id: data.id ? data.id : "", // 고유 값
    order_kind: 1,
    liaison: data.liaison._id, // 담당자
    kind: data.kind, // 검사서 종류
    unit_sign: data.unit_sign, // 수량 단위
    date_register: data.date_register, // 작성 날짜
    date_modify: new Date(Date.now()), // 저장 날짜
    date_delivery: data.date_delivery,
    total_quantity: saveColorList.total_quantity,
    order: data.order,
    place_receive_memo: data.place_receive_memo, //입고지 메모
    remark: data.remark,
    manufacture:
      data.requestDoc.kind === 7
        ? data.requestDoc.id
        : data.requestDoc.manufacture, // 가공의뢰서
    precautions: data.precautions, // 주의사항
    item: data.item._id, // 아이템
    place_forward: data.place_forward._id, // 출고처
    color_list: saveColorList.colorList,
  };

  if (data.place_receive) release.place_receive = data.place_receive._id;
  if (saveColorList.purchase.color_list) {
    release.purchase = saveColorList.purchase;
    release.purchase.check_status =
      data._id &&
      saveColorList.purchase.check_status &&
      saveColorList.purchase.check_status !== 1
        ? 2
        : 1;
  }
  if (saveColorList.sales.color_list) release.sales = saveColorList.sales;
  if (data.place_sales) release.place_sales = data.place_sales._id;
  if (data.shrigkage) release.shrigkage = data.shrigkage;

  if (data.salesBtn) {
    release.sales_unit_price = parsingNum(data.sales_unit_price);
    release.currency_sign = data.currency_sign;
    release.sales.check_status =
      data._id && data.sales.check_status && data.sales.check_status !== 1
        ? 2
        : 1;
    if (data.currency_sign !== "₩")
      release.sales.eschamge = parsingNum(data.eschamge);
    else {
      release.sales.eschamge = "";
      release.sales.eschamge_price = "";
    }
  }

  if (data.requestDoc.kind === 11) release.appearance = data.requestDoc.id;
  if (data.requestDoc.kind === 12) release.release = data.requestDoc.id;
  if (data.requestDoc.kind === 17) release.stock = "stock";

  return release;
};

const progressLogMake = (data, kind) => {
  return kind === 7 || kind === 18
    ? `${data.place_manufacture.name}(~${data.date_payment})`
    : kind === 11
    ? `${data.inspection.name}`
    : kind === 9 || kind === 15
    ? `${data.place_forward.name} -> ${
        data.place_sales ? data.place_sales.name : "미지정"
      }`
    : kind === 17
    ? "자사 매입"
    : `${data.place_forward.name} -> ${
        data.place_receive ? data.place_receive.name : "미지정"
      }`;
};

const numFromat = (num) => {
  return 10 > num ? `00${num}` : 100 > num ? `0${num}` : `${num}`;
};

const productionIdMake = (kind, order, list) => {
  return `${order}_${kind}_${numFromat(list.length + 1)}`;
};

const releaseIdMake = (release, order) => {
  let num = 0;
  let id = "";

  do {
    num = num + 1;
    id = `${order}_release_${numFromat(num)}`;
  } while (release.find((x) => x.id === id));

  return id;
};

const monthDateFormat = (date) => {
  const temp = new Date(date);
  const month = 1 + temp.getMonth();
  return month;
};

const parsingNum = (str) => {
  const num = +uncomma(str);
  return str && isNaN(num) ? "" : num;
};

const usingComma = (str) => {
  return str ? comma(uncomma(str)) : "";
};

const decimalCacul = (num) => {
  if (!num) return 0;
  return parseFloat(+num.toFixed(2));
};

const comma = (str) => {
  str = "" + str;
  var regx = new RegExp(/(-?\d+)(\d{3})/);
  var bExists = str.indexOf(".", 0); //0번째부터 .을 찾는다.
  var strArr = str.split(".");
  while (regx.test(strArr[0])) {
    //문자열에 정규식 특수문자가 포함되어 있는지 체크
    //정수 부분에만 콤마 달기
    strArr[0] = strArr[0].replace(regx, "$1,$2"); //콤마추가하기
  }
  if (bExists > -1) {
    //. 소수점 문자열이 발견되지 않을 경우 -1 반환
    str = strArr[0] + "." + strArr[1];
  } else {
    //정수만 있을경우 //소수점 문자열 존재하면 양수 반환
    str = strArr[0];
  }
  return str;
};

const uncomma = (str) => {
  str = "" + str;
  str = str.replace(/,/gi, ""); // 콤마 제거
  str = str.replace(/(^\s*)|(\s*$)/g, ""); // trim()공백,문자열 제거

  return str;
};

/**return 받은 list 를 기존 필터링 데이터와 비교하여 반환 */
const setExistingFilter = (resultData) => {
  const result = resultData.data;
  // const header = filterHeader[router.app._route.name].filter((x) => x.checked);
  const header = filterHeader[resultData.kind].filter((x) => x.checked);
  result.originList = JSON.parse(JSON.stringify(result.orderList));

  if (header.length) {
    result.orderList = filterling({
      header,
      orderList: result.orderList,
      oldData: JSON.parse(JSON.stringify(result.oldData)),
    });

    result.header = setFilter(result.orderList, result.header, result.oldData);
  }
  return result;
};

// 필터링 한 orderList 반환
const filterling = (data) => {
  let orderList = JSON.parse(JSON.stringify(data.orderList));
  data.header.forEach((x) => {
    const list = [];
    const findList = JSON.parse(JSON.stringify(orderList));

    if (data.oldData[x.value]) {
      data.oldData[x.value].forEach((y) => {
        if (y.checked) {
          findList.forEach((z) => {
            if (z[x.value] === y.name) {
              list.push(z);
            }
          });
        }
      });

      orderList = list;
    }
  });

  return orderList;
};

// filtserList 반환
const setFilter = (orderList, newHeader, oldHeader) => {
  // console.log(oldHeader);
  filterHeader[router.app._route.name].forEach((x) => {
    if (x.first) firstHeader(newHeader[x.value], oldHeader[x.value]);
    else if (x.checked)
      newHeader[x.value] = checkedHeaderSet(
        newHeader[x.value],
        oldHeader[x.value],
      );
    else if (newHeader[x.value])
      newHeader[x.value] = headerSet(orderList, newHeader[x.value], x.value);

    router.app._route.name === "claim"
      ? newHeader[x.value]
        ? claimSortHeader(newHeader[x.value], x.value)
        : ""
      : newHeader[x.value]
      ? sortHeader(newHeader[x.value], x.value)
      : "";
  });

  return newHeader;
};

// first true 인 filter 반환
const firstHeader = (newHeader, oldHeader) => {
  if (!newHeader) return;
  newHeader = newHeader.map((x) => {
    x.checked = false;
    return x;
  });

  oldHeader
    .filter((x) => x.checked)
    .forEach((x) => {
      const index = newHeader.findIndex((y) => y.name === x.name);

      if (newHeader[index]) {
        newHeader[index].checked = true;
      }
    });
};

// checked true 인 filter 반환
const checkedHeaderSet = (newHeader, oldHeader) => {
  const header = [];
  if (oldHeader)
    oldHeader.forEach((x) => {
      const filter = newHeader.find((y) => y.name === x.name);
      if (filter) {
        filter.checked = x.checked;
        header.push(filter);
      }
    });
  return header;
};

// 미사용 중인 filter 반환
const headerSet = (orderList, newHeader, type) => {
  const filter = [];

  newHeader.forEach((x) => {
    if (orderList.find((y) => y[type] === x.name)) filter.push(x);
  });
  return filter;
};
const filterParsing = (list) => {
  const headers = {};

  if (list.length) {
    // 헤더 생성

    Object.keys(list[0]).forEach((x) => {
      headers[x] = [];
    });
    // 헤더 데이터 push
    list.forEach((x) => {
      Object.keys(headers).forEach((y) => {
        headers[y].push(x[y]);
      });
    });
    // 헤더 데이터 중복 제거
    Object.keys(headers).forEach((x) => {
      headers[x] = [...new Set(headers[x])].map(
        (y) => (y = { name: y, checked: true }),
      );
      sortHeader(headers[x], x);
    });
  }
  return headers;
};

// 필터링 sort
const sortHeader = (source, header) => {
  // date 예외처리 header 이름
  const dateHeaders = [
    "mainDay",
    "day",
    "month",
    "sale_month",
    "pur_month",
    "date",
    "year",
    "salesMonth",
    "purchaseMonth",
  ];

  // sort 규칙
  const getLevel = (s) => {
    const index = [
      /[(비어있음)]/,
      /ss/,
      /fw/,
      /\(([^)]+)\)/,
      /[0-9]/,
      /[a-zA-Z]/,
      /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/,
    ].findIndex((pattern) => pattern.test(s));

    return index;
  };
  source.sort((a, b) => {
    //order sort
    if (header === "order" && b.name && a.name)
      return b.name.localeCompare(a.name);

    if (header !== "order" && header !== "season") {
      if (a.name === "(비어있음)") return -1;
      if (b.name === "(비어있음)") return 1;
    }
    // date sort
    else if (dateHeaders.includes(header)) {
      const [aMonth, aDay] = a.name ? a.name.split("-") : "";
      const [bMonth, bDay] = b.name ? b.name.split("-") : "";
      const aParseDate =
        aMonth && aDay ? new Date(2023, aMonth - 1, aDay) : null;
      const bParseDate =
        bMonth && bDay ? new Date(2023, bMonth - 1, bDay) : null;
      return bParseDate && aParseDate
        ? bParseDate - aParseDate
        : b.name.localeCompare(a.name);
    }
    // 문자열 sort
    else if (typeof a.name === "string" && typeof b.name === "string") {
      const aLevel = getLevel(a.name.charAt(0));
      const bLevel = getLevel(b.name.charAt(0));

      if (header !== "season" && aLevel === bLevel) {
        if (aLevel === 1) {
          // 괄호 안에 있는 문자열 가나다 순으로 정렬
          const aValue = a.name.match(/\(([^\)]+)\)/)[1]; //eslint-disable-line
          const bValue = b.name.match(/\(([^\)]+)\)/)[1]; //eslint-disable-line
          return aValue.localeCompare(bValue, "ko-KR");
        } else {
          return a.name.localeCompare(b.name);
        }
      }

      return bLevel - aLevel; // 오름 차순 정렬
    }
    // 숫자형 sort
    return a.name - b.name;
  });
  // .sort((a, b) => b.checked - a.checked);

  return source;
};
const claimSortHeader = (source, header) => {
  // date 예외처리 header 이름
  const dateHeaders = [
    "day",
    "arise_month",
    "pass_month",
    "month",
    "sale_month",
    "pur_month",
    "date",
    "year",
  ];
  // sort 규칙
  const getLevel = (s) => {
    const index = [
      /[(비어있음)]/,
      /\(([^)]+)\)/,
      /[0-9]/,
      /[a-zA-Z]/,
      /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/,
    ].findIndex((pattern) => pattern.test(s));
    return index;
  };
  source.sort((a, b) => {
    //order sort
    if (header === "order") return b.name.localeCompare(a.name);
    if (header !== "order") {
      if (a.name === "") return -1;
      if (b.name === "") return 1;
    }
    // date sort
    else if (dateHeaders.includes(header)) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    }
    // 문자열 sort
    else if (typeof a.name === "string" && typeof b.name === "string") {
      const aLevel = getLevel(a.name.charAt(0));
      const bLevel = getLevel(b.name.charAt(0));

      if (aLevel === bLevel) {
        if (aLevel === 1) {
          // 괄호 안에 있는 문자열 가나다 순으로 정렬
          const aValue = a.name.match(/\(([^\)]+)\)/)[1]; //eslint-disable-line
          const bValue = b.name.match(/\(([^\)]+)\)/)[1]; //eslint-disable-line
          return aValue.localeCompare(bValue, "ko-KR");
        } else {
          return a.name.localeCompare(b.name);
        }
      }

      return bLevel - aLevel; // 오름 차순 정렬
    }
    // 숫자형 sort
    return a.name - b.name;
  });

  return source;
};

const presetProgress = (progress, index) => {
  return {
    id: progress.id,
    progress: `${index + 1}_${progress.log} | (${
      progress.kind === 7
        ? "가공"
        : progress.kind === 8
        ? "매입 출고"
        : progress.kind === 9
        ? "매출 출고"
        : progress.kind === 10
        ? "검사 의뢰"
        : progress.kind === 12
        ? "선매입"
        : progress.kind === 15
        ? "매입/매출 출고"
        : progress.kind === 16
        ? "원단 이동"
        : progress.kind === 17
        ? "재고 매입"
        : ""
    })`,
  };
};

const setEmptyValue = (count, list) => {
  const num = count - list.length;
  for (let i = 0; i < num; i++) {
    list.push({ isSuccess: false });
  }
  return list;
};

const setColorQuantityComma = (list) => {
  return copyData(list).map((x) => {
    x.quantity = usingComma(x.quantity);
    return x;
  });
};
const filteredListPasing = (headerList, list, applyList) => {
  const filteredList = list.filter((data) => {
    let checkValues = applyList[router.app._route.name].map((apply) => {
      return apply.filtering.some((filterData) => {
        if (data[apply.target]) {
          return filterData === data[apply.target];
        }
      });
    });

    return checkValues.every((checkValue) => checkValue);
  });

  let filterHeader = [];

  for (let [key, value] of Object.entries(headerList)) {
    if (
      applyList[router.app._route.name].every((header) => header.target !== key)
    ) {
      filterHeader = filteredList.map((data) => {
        return { checked: true, name: data[key] };
      });
      headerList[key] = _.uniqBy(filterHeader, "name");
    } else {
      if (
        applyList[router.app._route.name].some(
          (header) => header.target === key && header.first,
        )
      ) {
        value.forEach((data) => {
          data.checked = filteredList.some(
            (filterData) => data.name === filterData[key],
          )
            ? true
            : false;
        });
      } else {
        filterHeader = filteredList.map((data) => {
          return { checked: true, name: data[key] };
        });
        headerList[key] = _.uniqBy(filterHeader, "name");
      }
    }
  }

  return headerList;
};

const stockSave = async (stock) => {
  try {
    const result = stock._id ? await putStock(stock) : await postStock(stock);

    if (result.status !== 200) throw `error: ${result.status}`;
  } catch (e) {
    console.log(e);
  }
};

const stockDelete = async (stock) => {
  try {
    const result = await deleteStock(stock.company, stock.id);

    if (result.status !== 200) throw `error: ${result.status}`;
  } catch (e) {
    console.log(e);
  }
};

const getStock = async (company, id) => {
  try {
    const result = await getStockForId(company, id);

    if (result.status !== 200) throw `error: ${result.status}`;

    return result.data[0];
  } catch (e) {
    console.log(e);
  }
};

export default {
  SET_DATA_FORM_BACKUP({ commit }, data) {
    commit("setFormBackup", data);
  },
  // login
  LOGIN({ commit }, user) {
    commit("setAuth", user);
    deleteCookie("til_auth_company");
    deleteCookie("til_auth_id");
    deleteCookie("til_auth_uuid");
    deleteCookie("til_auth_token");
    deleteCookie("til_auth_name");
    deleteCookie("til_auth_grade");
    deleteCookie("til_isExpired");

    // if (
    //   location.hostname === "localhost" ||
    //   location.hostname === "127.0.0.1"
    // ) {
    //   saveAuthCompanyToCookie(this.state.auth_company);

    saveAuthIdToCookie(this.state.auth_uuid);
    saveTriralCookie(this.state.isExpired);

    //   saveAuthTokenToCookie(this.state.auth_token);
    // }
    // history
    commit("setHistory", user);
    // commit("setAuthByHistory", {
    //   company: this.state.auth_company,
    //   uuid: this.state.auth_uuid,
    // });
    saveHistoryCompanyToCookie(this.state.history_company);
    saveHistoryUuidToCookie(this.state.history_uuid);
  },

  LOGIN_CHECK({ commit }, user) {
    commit("setAuthCheck", user);
    this.dispatch("SET_ROUTE_ALERT_ALL_BY_GRADE");
  },

  // logout
  LOGOUT({ commit }) {
    commit("clearAuth");
  },

  async SET_ROUTE_ALERT({ commit }, data) {
    commit("setRouteAlert", data);
  },
  async SET_ROUTE_ALERT_ALL_BY_GRADE({ commit }) {
    commit("setRouteAlertAll", this.state.auth_grade < 2 ? false : true);
  },
  // history
  REMOVE_HISTORY({ commit }, index) {
    commit("removeHistory", index);
    saveHistoryCompanyToCookie(this.state.history_company);
    saveHistoryUuidToCookie(this.state.history_uuid);
  },
  // login by history
  SET_LOGIN_BY_HISTORY({ commit }, index) {
    commit("setAuthByHistory", {
      company: this.state.history_company[index],
      uuid: this.state.history_uuid[index],
    });
  },
  CLEAR_LOGIN_BY_HISTORY({ commit }) {
    commit("clearAuthByHistory");
  },
  // v-progress-circular loading
  SET_API_LOADING({ commit }, data) {
    commit("setAPILoading", data);
  },

  // list setup
  async SET_ORDERLIST_DATA({ commit }) {
    try {
      const result = await getOrder(this.state.auth_company, {}, []);

      commit("setOrderList", result.data);
    } catch (e) {
      alert(e);
    }
  },
  async SET_EXCEL_ORDERLIST({ commit }) {
    try {
      const result = await excelOrderListFind(
        this.state.auth_company,
        this.state.auth_grade,
      );

      commit("setExcelOrderList", result.data);
    } catch (e) {
      alert(e);
    }
  },
  async SET_EXCEL_MAIN_ORDERLIST({ commit }) {
    try {
      const result = await mainListFind(
        this.state.auth_company,
        this.state.auth_grade,
      );

      commit("setExcelMainOrderList", result.data.orderList);
    } catch (e) {
      alert(e);
    }
  },
  async SET_EXCEL_PURCHASE({ commit }) {
    try {
      const result = await releaseListFind(
        this.state.auth_company,
        "purchase",
        "deadLine",
      );
      commit("setExcelPurchaseList", result.data.releaseList);
    } catch (e) {
      alert(e);
    }
  },
  async SET_EXCEL_SALES({ commit }) {
    try {
      const result = await releaseListFind(
        this.state.auth_company,
        "sales",
        "deadLine",
      );

      commit("setExcelSalesList", result.data.releaseList);
    } catch (e) {
      alert(e);
    }
  },
  async SET_EXCEL_CLAIM({ commit }, type) {
    try {
      const result =
        type === "claim"
          ? await claimListFind(this.state.auth_company)
          : await endClaimListFind(this.state.auth_company);

      commit("setExcelClaimList", result.data.claimList);
    } catch (e) {
      alert(e);
    }
  },
  async SET_EXCEL_PURCHASECAC({ commit }) {
    try {
      const result = await releaseListFind(
        this.state.auth_company,
        "purchase",
        "calculate",
      );
      commit("setExcelPurchaseList", result.data.releaseList);
    } catch (e) {
      alert(e);
    }
  },
  async SET_EXCEL_SALESCAC({ commit }) {
    try {
      const result = await releaseListFind(
        this.state.auth_company,
        "sales",
        "calculate",
      );

      commit("setExcelSalesList", result.data.releaseList);
    } catch (e) {
      alert(e);
    }
  },
  async SET_EXCEL_PUR_VAT({ commit }) {
    try {
      const result = await getReceivable(this.state.auth_company, {
        company: this.state.auth_company,

        filter: [],

        vatType: "common",
        kind: "purchase",
      });

      commit("setExcelPurVatList", result.data.receivableList);
    } catch (e) {
      alert(e);
    }
  },
  async SET_EXCEL_SALES_VAT({ commit }) {
    try {
      const result = await getReceivable(this.state.auth_company, {
        company: this.state.auth_company,

        filter: [],

        vatType: "common",
        kind: "sales",
      });

      commit("setExcelSalesVatList", result.data.receivableList);
    } catch (e) {
      alert(e);
    }
  },
  async SET_ITEMLIST({ commit }) {
    try {
      const itemList = await getItemByCompany(this.state.auth_company);
      if (itemList.status !== 200) throw `error : ${itemList.status}`;

      commit("setItemList", itemList.data);
    } catch (e) {
      alert(e);
    }
  },

  async SET_USE_ITEMLIST({ commit }) {
    try {
      const itemList = await getItemByCompanybyStatus(
        this.state.auth_company,
        "40",
      );
      if (itemList.status !== 200) throw `error : ${itemList.status}`;

      commit("setUseItemList", itemList.data);
    } catch (e) {
      alert(e);
    }
  },
  async SET_USERLIST({ commit }) {
    try {
      const userList = await getUserByCompany(this.state.auth_company);
      if (userList.status !== 200) throw `error : ${userList.status}`;

      commit("setUserList", userList.data);
      commit(
        "setUserAlert",
        userList.data.filter((x) => x.signStatus === "20").length,
      );

      return true;
    } catch (e) {
      alert(e);
    }
  },

  async SET_CTYPELIST({ commit }) {
    try {
      const ctypeList = await getClothestypeByCompany(this.state.auth_company);
      if (ctypeList.status !== 200) throw `error : ${ctypeList.status}`;
      ctypeList.data = ctypeList.data.sort((a, b) =>
        typeof a === "number"
          ? a.name - b.anme
          : "" + a.name.localeCompare("" + b.name),
      );
      commit("setCtypeList", ctypeList.data);

      return true;
    } catch (e) {
      alert(e);
    }
  },

  async SET_USE_CTYPELIST({ commit }) {
    try {
      const ctypeList = await getClothestypeByCompanybyStatus(
        this.state.auth_company,
        "40",
      );
      if (ctypeList.status !== 200) throw `error : ${ctypeList.status}`;

      commit("setUseCtypeList", ctypeList.data);

      return true;
    } catch (e) {
      alert(e);
    }
  },

  async SET_CLIENTLIST({ commit }) {
    try {
      const clientList = await getClientByCompany(this.state.auth_company);
      if (clientList.status !== 200) throw `error : ${clientList.status}`;

      clientList.data.forEach((x) => {
        x.checked = false;
      });

      commit("setClientList", clientList.data);
    } catch (e) {
      alert(e);
    }
  },

  //status 99가 아닌 클라이언트만 가져오는 리스트(목록)
  async SET_USE_CLIENTLIST({ commit }) {
    try {
      const clientList = await getClientByCompanyStatus(
        this.state.auth_company,
        "40",
      );

      if (clientList.status !== 200) throw `error : ${clientList.status}`;

      // clientList.data.forEach((x) => {
      //   x.sensibleName = x.bill_client
      //     ? `${x.name} - ${x.bill_client.name}`
      //     : x.name;
      // });

      commit("setUseClientList", clientList.data);
    } catch (e) {
      alert(e);
    }
  },
  async SET_PURCHASE_CAC_LIST({ commit }) {
    try {
      const result = await purchaseCacListFind(this.state.auth_company);
      if (result.status !== 200) throw `error : ${result.status}`;
      commit("setPurchaseCacList", result.data.releaseList);
      commit("setOriginalList", result.data.releaseList);
      commit("setFilterList", result.data.header);
    } catch (e) {
      alert(e);
    }
  },
  async SET_CLAIMLIST({ commit }, data) {
    try {
      const result = await claimListFind({
        company: this.state.auth_company,
        grade: this.state.auth_grade,
      });

      if (result.status !== 200) throw `error : ${result.status}`;

      if (result?.data?.orderList) {
        if (!result.data.header) result.data.header = {};
        commit(
          "setOriginFilter",
          JSON.parse(JSON.stringify(result.data.header)),
        );

        const list = setExistingFilter({
          kind: "claim",
          data: {
            oldData: this.state.claimFilterList,
            orderList: result.data.orderList,
            header: result.data.header,
          },
        });

        commit("setClaimList", list.orderList);
        commit("setClaimOriginalList", list.originList);
        commit("setClaimFilterList", list.header);
      }
    } catch (e) {
      alert(e);
    }
  },
  async SET_END_CLAIMLIST({ commit }, data) {
    try {
      const result = await endClaimListFind({
        company: this.state.auth_company,
        grade: this.state.auth_grade,
      });

      if (result.status !== 200) throw `error : ${result.status}`;

      if (result?.data?.orderList) {
        if (!result.data.header) result.data.header = {};
        commit(
          "setOriginFilter",
          JSON.parse(JSON.stringify(result.data.header)),
        );

        const list = setExistingFilter({
          kind: "endClaim",
          data: {
            oldData: this.state.endClaimFilterList,
            orderList: result.data.orderList,
            header: result.data.header,
          },
        });

        commit("setEndClaimList", list.orderList);
        commit("setEndClaimOriginalList", list.originList);
        commit("setEndClaimFilterList", list.header);
      }
    } catch (e) {
      console.log(e);
      alert(e);
    }
  },
  async SELECT_CLAIMLIST({ commit }, status) {
    try {
      const result = await getClaim(this.state.auth_company);

      commit("setSelectClaimList", result.data);
    } catch (e) {
      alert(e);
    }
  },

  // 생산 페이지 (production page) ////////////////////////////////////////////////////////////////////////

  async GET_COMPANY({ commit }) {
    try {
      const company = await getCompanyOneById(this.state.auth_company);
      if (company.status !== 200) throw `error : ${company.status}`;
      const editCompany = { ...company.data[0] };
      if (!editCompany.logoType) editCompany.logoType = 1;
      commit("setEditCompany", editCompany);
      this.state.viewTel = getMask(editCompany.tel);
      this.state.viewBusinessNum = businessNumMask(editCompany.business_num);

      commit("setCompany", company.data[0]);
    } catch (e) {
      alert(e);
    }
  },

  //생산 페이지 끝 ////////////////////////////////////////////////////////////////////////

  ///////////////////////////////공용 사용 함수///////////////////////////////
  //approval data  가져오는 함수
  async getaApprovalComId({ commit }, data) {
    try {
      const approval = await getaApprovalRouteComId(
        this.state.auth_company,
        data.approvalId,
        data.route,
      );

      if (approval.status !== 200) throw `error : ${approval.status}`;

      commit("setApprovalForm", approval.data[0]);
    } catch (e) {
      alert(e);
    }
  },

  // 생산페이지 에서 선택한 문서의 approval을 검색해오는 기능
  async PRODUCTION_CALL_GET_APPROVAL_FOR_ID({ commit, state }) {
    try {
      const approval = await productionPageSearchApproval({
        company: state.auth_company,
        id: state.productionProgress.id,
      });
      if (approval.status !== 200) throw `error : ${approval.status}`;

      commit("setApprovalForm", approval.data);
    } catch (e) {
      alert(e);
    }
  },

  //form 데이터 set
  async SET_FORM({ commit }, order) {
    let orderNum = order;
    if (order.order)
      orderNum = order.order.split(" ")[1]
        ? order.order.split(" ")[0]
        : order.order;
    try {
      const result = await getSelectIdOrder(this.state.auth_company, orderNum);
      if (result.status !== 200) throw `error : ${result.status}`;
      const form = result.data[0];
      form.liaison = this.state.userList.find((c) => c._id === form.liaison)
        ? this.state.userList.find((c) => c._id === form.liaison).name
        : "";

      form.ctype = this.state.ctypeList.find((c) => c._id === form.ctype)
        ? this.state.ctypeList.find((c) => c._id === form.ctype)
        : "";

      form.item = this.state.useItemList.find((c) => c._id === form.item)
        ? this.state.useItemList.find((c) => c._id === form.item)
        : "";

      form.client = this.state.useClientList.find((c) => c._id === form.client)
        ? this.state.useClientList.find((c) => c._id === form.client)
        : "";

      form.buyer = this.state.useClientList.find((c) => c._id === form.buyer)
        ? this.state.useClientList.find((c) => c._id === form.buyer)
        : "";

      form.date_register = form.date_register
        ? dateFormat(form.date_register)
        : "";
      form.date_duedate = form.date_duedate
        ? dateFormat(form.date_duedate)
        : "";

      commit("setOrderForm", form);
    } catch (e) {
      alert(e);
    }
  },

  async ORDER_DOWNLOAD_EXCEL() {
    try {
      const workbook = new exceljs.Workbook(); // 엑셀생성
      workbook.creator = `${this.state.auth_name}`; //생성자
      workbook.lastModifiedBy = `${this.state.auth_name}`; //최종 수정자
      workbook.created = new Date(); //생성일
      workbook.modified = new Date(); //수정일

      workbook.addWorksheet(this.state.Today); // 시트명
      const sheetOne = workbook.getWorksheet(1); //첫번쨰 시트 접근

      // workbook.headerFooter.oddHeader = "오더 발주목록";

      sheetOne.columns = [
        { header: "접수", key: "date", width: "10" },
        { header: "년도", key: "year", width: "10" },
        { header: "시즌", key: "season", width: "10" },
        { header: "오더", key: "order", width: "30" },
        { header: "발주일", key: "orderDate", width: "20" },
        { header: "구매처", key: "client", width: "30" },
        { header: "아이템", key: "item", width: "30" },
        { header: "가격", key: "price", width: "30" },
        { header: "경사", key: "slope", width: "30" },
        { header: "위사", key: "oblique", width: "30" },
        { header: "혼용률", key: "variety", width: "30" },
        { header: "폭", key: "width", width: "30" },
        { header: "밀도", key: "density", width: "30" },
        { header: "중량", key: "weight", width: "30" },
        { header: "후가공", key: "post_processing", width: "30" },
        { header: "오더유형", key: "orderType", width: "30" },
        { header: "매입구분", key: "purchaseType", width: "30" },
        { header: "매출구분", key: "salesType", width: "30" },
        { header: "매출단가(₩)", key: "salesPrice_w", width: "30" },
        { header: "매출단가($)", key: "saelsPrice_d", width: "30" },
        { header: "복종", key: "ctype", width: "30" },
        { header: "성별", key: "gender", width: "30" },
        { header: "생산처", key: "place", width: "30" },
        { header: "생산처 아이템", key: "placeItemNo", width: "30" },
        { header: "스타일", key: "style", width: "70" },
        { header: "컬러", key: "color", width: "20" },
        { header: "컬러Surcharge", key: "Surcharge", width: "20" },
        { header: "컬러수량", key: "quantity", width: "20" },
        { header: "컬러단가", key: "unitPrice", width: "20" },
        { header: "담당자", key: "liaison", width: "20" },
      ];
      sheetOne.getRow(1).alignment = {
        vertical: "center",
        horizontal: "center",
      }; // 헤더 가운데정렬
      sheetOne.getRow(1).style = {
        font: { bold: true, name: "Arial Black", size: 30 },
      }; // 헤더 스타일지정
      await this.dispatch("SET_EXCEL_ORDERLIST");

      const order = this.state.excelOrderList;

      const RowData = [];
      order.forEach((x) => {
        RowData.push({
          date: x.date && x.date !== "(비어있음)" ? x.date : "",
          year: x.year && x.year !== "(비어있음)" ? x.year : "",
          season:
            x.order_front_season && x.order_front_season !== "(비어있음)"
              ? x.order_front_season
              : "",
          order: x.order && x.order !== "(비어있음)" ? x.order : "",
          orderDate: x.order_date ? x.order_date : "",
          client: x.client ? x.client.name : "",
          item: x.item ? x.item.name : "",
          price: x.item
            ? `${x.item.unit_price_sign ? x.item.unit_price_sign : ""}${
                x.item.unit_price ? x.item.unit_price : ""
              }`
            : "",
          slope: x.item ? (x.item.slope ? x.item.slope : "") : "",
          oblique: x.item ? (x.item.oblique ? x.item.oblique : "") : "",
          variety: x.item ? (x.item.variety ? x.item.variety : "") : "",
          width: x.item ? (x.item.width ? x.item.width : "") : "",
          density: x.item ? (x.item.density ? x.item.density : "") : "",
          weight: x.item ? (x.item.weight ? x.item.weight : "") : "",
          post_processing: x.item
            ? x.item.post_processing
              ? x.item.post_processing
              : ""
            : "",
          orderType: x.order_kind ? x.order_kind : "",
          purchaseType: x.purchase_classification
            ? x.purchase_classification
            : "",
          ctype: x.ctype ? x.ctype : "",
          gender: x.gender ? x.gender : "",
          place: x.productionName ? x.productionName : "",
          placeItemNo: x.productionItemName ? x.productionItemName : "",
          style: x.style_list ? x.style_list.map((c) => c).join(", ") : "",

          salesPrice_w:
            x.sales_unit_price && x.currency_sign === "₩"
              ? `${x.sales_unit_price ? x.sales_unit_price : ""}`
              : "",
          salesPrice_d:
            x.sales_unit_price && x.currency_sign === "$"
              ? `${x.sales_unit_price ? x.sales_unit_price : ""}`
              : "",
          liaison: x.liaison ? x.liaison : "",
        });

        if (x.color_list.length)
          x.color_list.forEach((c) => {
            RowData.push({
              order: x.order,
              color: c.name,
              Surcharge: c.surcharge_price,
              quantity: c.quantity,
              unitPrice: c.unit_price,
            });
          });
      });
      RowData.map((item, index) => {
        sheetOne.addRow(item);
      });
      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${this.state.Today}_오더.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (e) {
      console.error(e);
    }
  },
  async MAIN_DOWNLOAD_EXCEL() {
    try {
      const workbook = new exceljs.Workbook(); // 엑셀생성
      workbook.creator = `${this.state.auth_name}`; //생성자
      workbook.lastModifiedBy = `${this.state.auth_name}`; //최종 수정자
      workbook.created = new Date(); //생성일
      workbook.modified = new Date(); //수정일

      workbook.addWorksheet(this.state.Today); // 시트명
      const sheetOne = workbook.getWorksheet(1); //첫번쨰 시트 접근

      // workbook.headerFooter.oddHeader = "오더 발주목록";

      sheetOne.columns = [
        { header: "마감", key: "mainDay", width: "10" },
        { header: "구분", key: "type", width: "20" },
        { header: "년도", key: "year", width: "20" },
        { header: "시즌", key: "season", width: "20" },
        { header: "오더", key: "order", width: "30" },
        { header: "담당자", key: "liaison", width: "10" },
        { header: "접수월", key: "day", width: "10" },
        { header: "발주처", key: "client", width: "30" },
        { header: "아이템", key: "item", width: "30" },
        { header: "매입아이템", key: "manuItem", width: "30" },
        { header: "매입처", key: "manuPlace", width: "30" },
        { header: "매입월", key: "manuDate", width: "10" },
        { header: "발주 수량(y)", key: "manuOrderQuantity_y", width: "20" },
        { header: "발주 수량(m)", key: "manuOrderQuantity_m", width: "20" },
        { header: "가공 수량(y)", key: "manuQuantity_y", width: "20" },
        { header: "가공 수량(m)", key: "manuQuantity_m", width: "20" },
        { header: "가공 단가(₩)", key: "manuUnitPrice_w", width: "20" },
        { header: "가공 단가($)", key: "manuUnitPrice_d", width: "20" },
        { header: "검사 수량(y)", key: "inspectionQuantity_y", width: "20" },
        { header: "검사 수량(m)", key: "inspectionQuantity_m", width: "20" },
        { header: "출고 수량(y)", key: "releaseQuantity_y", width: "20" },
        { header: "출고 수량(m)", key: "releaseQuantity_m", width: "20" },
        { header: "마감 수량(y)", key: "manuPurchaseQuantity_y", width: "20" },
        { header: "마감 수량(m)", key: "manuPurchaseQuantity_m", width: "20" },
        { header: "매입 단가(₩)", key: "pur_unit_price_w", width: "20" },
        { header: "매입 단가($)", key: "pur_unit_price_d", width: "20" },
        { header: "매입 금액(₩)", key: "pur_price_w", width: "20" },
        { header: "매입 금액($)", key: "pur_price_d", width: "20" },
        { header: "매출월", key: "relDate", width: "10" },
        { header: "매출처", key: "relPlace", width: "20" },
        { header: "매출 수량(y)", key: "relQuantity_y", width: "20" },
        { header: "매출 수량(m)", key: "relQuantity_m", width: "20" },
        { header: "매출 단가(₩)", key: "relUnitPrice_w", width: "20" },
        { header: "매출 단가($)", key: "relUnitPrice_d", width: "20" },
        { header: "매출 금액(₩)", key: "relAmount_w", width: "20" },
        { header: "매출 금액($)", key: "relAmount_d", width: "20" },
        { header: "매출 이익(₩)", key: "profit_w", width: "20" },

        { header: "마진", key: "margin", width: "10" },
      ];
      sheetOne.getRow(1).alignment = {
        vertical: "center",
        horizontal: "center",
      }; // 헤더 가운데정렬
      sheetOne.getRow(1).style = {
        font: { bold: true, name: "Arial Black", size: 30 },
      }; // 헤더 스타일지정

      const order = this.state.mainOrderList;

      const RowData = [];

      order.forEach((x) => {
        RowData.push({
          mainDay: x.mainDay && x.mainDay !== "(비어있음)" ? x.mainDay : "",
          year: x.year ? x.year : "",
          season: x.season && x.season !== "(비어있음)" ? x.season : "",
          type: x.type ? x.type : "",
          order: x.order ? x.order : "",
          liaison: x.liaison ? x.liaison : "",
          day: x.day ? x.day : "",
          client: x.buyer && x.buyer !== "(비어있음)" ? x.buyer : "",
          item: x.item && x.item !== "(비어있음)" ? x.item : "",
          manuItem: x.pur_item && x.pur_item !== "(비어있음)" ? x.pur_item : "",
          manuPlace:
            x.pur_place && x.pur_place !== "(비어있음)" ? x.pur_place : "",
          manuDate:
            x.pur_month && x.pur_month !== "(비어있음)" ? x.pur_month : "",
          manuOrderQuantity_y:
            x.quantity &&
            x.quantity !== "(비어있음)" &&
            x.manu_unit_sign === "y"
              ? `${x.quantity}`
              : "",
          manuOrderQuantity_m:
            x.quantity &&
            x.quantity !== "(비어있음)" &&
            x.manu_unit_sign === "m"
              ? `${x.quantity}`
              : "",
          manuQuantity_y:
            x.manu_quantity &&
            x.manu_quantity !== "(비어있음)" &&
            x.manu_unit_sign === "y"
              ? `${x.manu_quantity}`
              : ``,
          manuQuantity_m:
            x.manu_quantity &&
            x.manu_quantity !== "(비어있음)" &&
            x.manu_unit_sign === "m"
              ? `${x.manu_quantity}`
              : ``,
          manuUnitPrice_w:
            x.manu_unitprice &&
            x.manu_unitprice !== "(비어있음)" &&
            x.pur_currency_sign === "₩"
              ? `${x.manu_unitprice}`
              : ``,
          manuUnitPrice_d:
            x.manu_unitprice &&
            x.manu_unitprice !== "(비어있음)" &&
            x.pur_currency_sign === "$"
              ? `${x.manu_unitprice}`
              : ``,
          inspectionQuantity_y:
            x.inspect_quantity &&
            x.inspect_quantity !== "(비어있음)" &&
            x.pur_unit_sign === "y"
              ? `${x.inspect_quantity}`
              : "",
          inspectionQuantity_m:
            x.inspect_quantity &&
            x.inspect_quantity !== "(비어있음)" &&
            x.pur_unit_sign === "m"
              ? `${x.inspect_quantity}`
              : "",
          releaseQuantity_y:
            x.rel_quantity &&
            x.rel_quantity !== "(비어있음)" &&
            x.pur_unit_sign === "y"
              ? `${x.rel_quantity}`
              : "",
          releaseQuantity_m:
            x.rel_quantity &&
            x.rel_quantity !== "(비어있음)" &&
            x.pur_unit_sign === "m"
              ? `${x.rel_quantity}`
              : "",
          manuPurchaseQuantity_y:
            x.pur_quantity &&
            x.pur_quantity !== "(비어있음)" &&
            x.pur_unit_sign === "m"
              ? `${x.pur_quantity}`
              : "",
          manuPurchaseQuantity_m:
            x.pur_quantity &&
            x.pur_quantity !== "(비어있음)" &&
            x.pur_unit_sign === "m"
              ? `${x.pur_quantity}`
              : "",
          pur_unit_price_w:
            x.pur_unit_price &&
            x.pur_unit_price !== "(비어있음)" &&
            x.pur_currency_sign === "₩"
              ? `${x.pur_unit_price}`
              : ``,
          pur_unit_price_d:
            x.pur_unit_price &&
            x.pur_unit_price !== "(비어있음)" &&
            x.pur_currency_sign === "$"
              ? `${x.pur_unit_price}`
              : ``,
          pur_price_w:
            x.pur_price &&
            x.pur_price !== "(비어있음)" &&
            x.pur_currency_sign === "₩"
              ? `${x.pur_price}`
              : ``,
          pur_price_d:
            x.pur_price &&
            x.pur_price !== "(비어있음)" &&
            x.pur_currency_sign === "$"
              ? `${x.pur_price}`
              : ``,
          relDate:
            x.sale_month && x.sale_month !== "(비어있음)" ? x.sale_month : "",
          relPlace:
            x.sale_place && x.sale_place !== "(비어있음)" ? x.sale_place : "",
          relQuantity_y:
            x.sale_quantity &&
            x.sale_quantity !== "(비어있음)" &&
            x.sale_unit_sign === "y"
              ? `${x.sale_quantity}`
              : "",
          relQuantity_m:
            x.sale_quantity &&
            x.sale_quantity !== "(비어있음)" &&
            x.sale_unit_sign === "m"
              ? `${x.sale_quantity}`
              : "",
          relUnitPrice_w:
            x.sale_unit_price &&
            x.sale_unit_price !== "(비어있음)" &&
            x.sale_currency_sign === "₩"
              ? `${x.sale_unit_price}`
              : ``,
          relUnitPrice_d:
            x.sale_unit_price &&
            x.sale_unit_price !== "(비어있음)" &&
            x.sale_currency_sign === "$"
              ? `${x.sale_unit_price}`
              : ``,
          relAmount_w:
            x.sale_price &&
            x.sale_price !== "(비어있음)" &&
            x.sale_currency_sign === "₩"
              ? `${x.sale_price}`
              : ``,
          relAmount_d:
            x.sale_price &&
            x.sale_price !== "(비어있음)" &&
            x.sale_currency_sign === "$"
              ? `${x.sale_price}`
              : ``,
          profit_w:
            x.salesProfit && x.salesProfit !== "(비어있음)"
              ? `${x.salesProfit}`
              : ``,

          margin: x.margin && x.margin !== "(비어있음)" ? `${x.margin}%` : "",
        });
      });
      RowData.map((item) => {
        sheetOne.addRow(item);
      });
      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${this.state.Today}_발주현황.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (e) {
      console.error(e);
    }
  },
  async PURCHASE_DOWNLOAD_EXCEL() {
    try {
      const workbook = new exceljs.Workbook(); // 엑셀생성
      workbook.creator = `${this.state.auth_name}`; //생성자
      workbook.lastModifiedBy = `${this.state.auth_name}`; //최종 수정자
      workbook.created = new Date(); //생성일
      workbook.modified = new Date(); //수정일

      workbook.addWorksheet(this.state.Today); // 시트명
      const sheetOne = workbook.getWorksheet(1); //첫번쨰 시트 접근

      // workbook.headerFooter.oddHeader = "오더 발주목록";

      sheetOne.columns = [
        { header: "이월여부", key: "carried", width: "10" },
        { header: "월", key: "month", width: "10" },
        { header: "구분", key: "type", width: "10" },
        { header: "시즌", key: "season", width: "10" },
        { header: "오더", key: "order", width: "20" },
        { header: "발주처", key: "client", width: "20" },
        { header: "아이템", key: "item", width: "20" },
        { header: "거래처", key: "placeFoward", width: "20" },
        { header: "컬러", key: "color", width: "20" },
        { header: "매출 수량(y)", key: "salesQuantity_y", width: "20" },
        { header: "매출 수량(m)", key: "salesQuantity_m", width: "20" },
        { header: "출고 수량(y)", key: "Quantity_y", width: "20" },
        { header: "출고 수량(m)", key: "Quantity_m", width: "20" },
        { header: "매입 수량(y)", key: "purchaseQuantity_y", width: "20" },
        { header: "매입 수량(m)", key: "purchaseQuantity_m", width: "20" },
        { header: "단가(₩)", key: "unitPrice_w", width: "20" },
        { header: "단가($)", key: "unitPrice_d", width: "20" },
        { header: "금액(₩)", key: "amount_w", width: "20" },
        { header: "금액($)", key: "amounit_d", wdth: "20" },
        {
          header: "염색 Surcharge(₩)",
          key: "surchargeDyeing_w",
          width: "20",
        },
        {
          header: "염색 Surcharge($)",
          key: "surchargeDyeing_d",
          width: "20",
        },
        {
          header: "제직 Surcharge(₩)",
          key: "surchargeTextile_w",
          width: "20",
        },
        {
          header: "제직 Surcharge($)",
          key: "surchargeTextile_d",
          width: "20",
        },
        { header: "금액 합(₩)", key: "sumTotalAmount_w", width: "20" },
        { header: "금액 합($)", key: "sumTotalAmount_d", width: "20" },
        { header: "비고", key: "memo", width: "20" },
      ];
      sheetOne.getRow(1).alignment = {
        vertical: "center",
        horizontal: "center",
      }; // 헤더 가운데정렬
      //리팩토링예정 컬럼들 숫자형일 경우 오른쪽 문자열이면 가운데
      sheetOne.getRow(1).style = {
        font: { bold: true, name: "Arial Black", size: 30 },
      }; // 헤더 스타일지정d

      const release = this.state.purchSalesCalculList;

      const RowData = [];
      release.forEach((x) => {
        RowData.push({
          carried: x.carried ? "이월" : "",
          month: x.month ? `${x.month}` : "",
          type: x.kind ? x.kind : "",
          season: x.season && x.season !== "(비어있음)" ? x.season : "",
          order: x.order ? x.order : "",
          client: x.buyer && x.buyer !== "(비어있음)" ? x.buyer : "",
          item: x.item && x.item !== "(비어있음)" ? x.item.name : "",
          placeFoward:
            x.place_forward && x.place_forward !== "(비어있음)"
              ? x.place_forward
              : "",
          color:
            x.color && x.color !== "(비어있음)"
              ? `${x.color}${
                  x.color_list.length ? ` (+ ${x.color_list.length} )` : ""
                }`
              : "",
          salesQuantity_y:
            x.salesQuantity &&
            x.salesQuantity !== "(비어있음)" &&
            x.unitSign === "y"
              ? `${x.salesQuantity}`
              : "",
          salesQuantity_m:
            x.salesQuantity &&
            x.salesQuantity !== "(비어있음)" &&
            x.unitSign === "m"
              ? `${x.salesQuantity}`
              : "",
          Quantity_y:
            x.totalQuantity &&
            x.totalQuantity !== "(비어있음)" &&
            x.unitSign === "y"
              ? `${x.totalQuantity}`
              : "",
          Quantity_m:
            x.totalQuantity &&
            x.totalQuantity !== "(비어있음)" &&
            x.unitSign === "m"
              ? `${x.totalQuantity}`
              : "",
          purchaseQuantity_y:
            x.purchaseQuantity &&
            x.purchaseQuantity !== "(비어있음)" &&
            x.purUnitSign === "y"
              ? `${x.purchaseQuantity}`
              : "",
          purchaseQuantity_m:
            x.purchaseQuantity &&
            x.purchaseQuantity !== "(비어있음)" &&
            x.purUnitSign === "m"
              ? `${x.purchaseQuantity}`
              : "",
          unitPrice_w:
            x.purchaseUnitPrice &&
            x.purchaseUnitPrice !== "(비어있음)" &&
            x.currency_sign === "₩"
              ? `${x.purchaseUnitPrice}`
              : "",
          unitPrice_d:
            x.purchaseUnitPrice &&
            x.purchaseUnitPrice !== "(비어있음)" &&
            x.currency_sign === "$"
              ? `${x.purchaseUnitPrice}`
              : "",
          amount_w:
            x.amount && x.amount !== "(비어있음)" && x.currency_sign === "₩"
              ? `${x.amount}`
              : "",
          amount_d:
            x.amount && x.amount !== "(비어있음)" && x.currency_sign === "$"
              ? `${x.amount}`
              : "",
          surchargeDyeing_w:
            x.colorSurchargePrice &&
            x.colorSurchargePrice !== "(비어있음)" &&
            x.currency_sign === "₩"
              ? `${x.colorSurchargePrice}`
              : "",
          surchargeDyeing_d:
            x.colorSurchargePrice &&
            x.colorSurchargePrice !== "(비어있음)" &&
            x.currency_sign === "$"
              ? `${x.colorSurchargePrice}`
              : "",
          surchargeTextile_w:
            x.surchargePrice &&
            x.surchargePrice !== "(비어있음)" &&
            x.currency_sign === "₩"
              ? `${x.surchargePrice}`
              : "",
          surchargeTextile_d:
            x.surchargePrice &&
            x.surchargePrice !== "(비어있음)" &&
            x.currency_sign === "$"
              ? `${x.surchargePrice}`
              : "",
          sumTotalAmount_w:
            x.totalAmount &&
            x.totalAmount !== "(비어있음)" &&
            x.currency_sign === "₩"
              ? `${x.totalAmount}`
              : "",
          sumTotalAmount_d:
            x.totalAmount &&
            x.totalAmount !== "(비어있음)" &&
            x.currency_sign === "$"
              ? `${x.totalAmount}`
              : "",
          memo: x.memo ? x.memo : "",
        });
        x.color_list.forEach((c, i) => {
          RowData.push({
            carried: x.carried ? "이월" : "",
            month: x.month ? `${x.month}` : "",
            type: x.kind ? x.kind : "",
            season: x.season && x.season !== "(비어있음)" ? x.season : "",
            order: x.order ? x.order : "",
            client: x.buyer && x.buyer !== "(비어있음)" ? x.buyer : "",
            item: x.item && x.item !== "(비어있음)" ? x.item.name : "",
            placeFoward:
              x.place_forward && x.place_forward !== "(비어있음)"
                ? x.place_forward
                : "",
            color: c.color && c.color !== "(비어있음)" ? c.color : "",
            salesQuantity_y:
              c.salesQuantity &&
              c.salesQuantity !== "(비어있음)" &&
              x.unitSign === "y"
                ? `${c.salesQuantity}`
                : "",
            salesQuantity_m:
              c.salesQuantity &&
              c.salesQuantity !== "(비어있음)" &&
              x.unitSign === "m"
                ? `${c.salesQuantity}`
                : "",
            Quantity_y:
              c.totalQuantity &&
              c.totalQuantity !== "(비어있음)" &&
              x.unitSign === "y"
                ? `${c.totalQuantity}`
                : "",
            Quantity_m:
              c.totalQuantity &&
              c.totalQuantity !== "(비어있음)" &&
              x.unitSign === "m"
                ? `${c.totalQuantity}`
                : "",
            purchaseQuantity_y:
              c.purchaseQuantity &&
              c.purchaseQuantity !== "(비어있음)" &&
              x.purUnitSign === "y"
                ? `${c.purchaseQuantity}`
                : "",
            purchaseQuantity_m:
              c.purchaseQuantity &&
              c.purchaseQuantity !== "(비어있음)" &&
              x.purUnitSign === "m"
                ? `${c.purchaseQuantity}`
                : "",
            amount_w:
              c.amount && c.amount !== "(비어있음)" && x.currency_sign === "₩"
                ? `${c.amount}`
                : "",
            amount_d:
              c.amount && c.amount !== "(비어있음)" && x.currency_sign === "$"
                ? `${c.amount}`
                : "",
            surchargeDyeing_w:
              c.colorSurchargePrice &&
              c.colorSurchargePrice !== "(비어있음)" &&
              x.currency_sign === "₩"
                ? `${c.colorSurchargePrice}`
                : "",
            surchargeDyeing_d:
              c.colorSurchargePrice &&
              c.colorSurchargePrice !== "(비어있음)" &&
              x.currency_sign === "$"
                ? `${c.colorSurchargePrice}`
                : "",
            surchargeTextile_w:
              c.surchargePrice &&
              c.surchargePrice !== "(비어있음)" &&
              x.currency_sign === "₩"
                ? `${c.surchargePrice}`
                : "",
            surchargeTextile_d:
              c.surchargePrice &&
              c.surchargePrice !== "(비어있음)" &&
              x.currency_sign === "$"
                ? `${c.surchargePrice}`
                : "",

            sumTotalAmount_w:
              c.totalAmount &&
              c.totalAmount !== "(비어있음)" &&
              x.currency_sign === "₩"
                ? `${c.totalAmount}`
                : "",
            sumTotalAmount_d:
              c.totalAmount &&
              c.totalAmount !== "(비어있음)" &&
              x.currency_sign === "$"
                ? `${c.totalAmount}`
                : "",
          });
        });
      });

      sheetOne.eachRow((row) => {
        row.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });

      RowData.map((item, index) => {
        sheetOne.addRow(item);
      });

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${this.state.Today}_매입마감.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (e) {
      console.error(e);
    }
  },
  async SALES_DOWNLOAD_EXCEL() {
    try {
      const workbook = new exceljs.Workbook(); // 엑셀생성
      workbook.creator = `${this.state.auth_name}`; //생성자
      workbook.lastModifiedBy = `${this.state.auth_name}`; //최종 수정자
      workbook.created = new Date(); //생성일
      workbook.modified = new Date(); //수정일

      workbook.addWorksheet(this.state.Today); // 시트명
      const sheetOne = workbook.getWorksheet(1); //첫번쨰 시트 접근

      // workbook.headerFooter.oddHeader = "오더 발주목록";

      sheetOne.columns = [
        { header: "이월여부", key: "carried", width: "10" },
        { header: "월", key: "month", width: "10" },
        { header: "구분", key: "type", width: "10" },
        { header: "시즌", key: "season", width: "10" },
        { header: "오더", key: "order", width: "20" },
        { header: "발주처", key: "client", width: "20" },
        { header: "아이템", key: "item", width: "20" },
        { header: "거래처", key: "placeFoward", width: "20" },
        { header: "컬러", key: "color", width: "20" },
        { header: "매입 수량(y)", key: "purchaseQuantity_y", width: "20" },
        { header: "매입 수량(m)", key: "purchaseQuantity_m", width: "20" },
        { header: "출고 수량(y)", key: "totalQuantity_y", width: "20" },
        { header: "출고 수량(m)", key: "totalQuantity_m", width: "20" },
        { header: "매출처", key: "salesPlaceFoward", width: "20" },
        { header: "매출 수량(y)", key: "salesQuantity_y", width: "20" },
        { header: "매출 수량(m)", key: "salesQuantity_m", width: "20" },
        { header: "매출 단가(₩)", key: "salesUnitprice_w", width: "20" },
        { header: "매출 단가($)", key: "salesUnitprice_d", width: "20" },
        { header: "컬러 Surcharge(₩)", key: "colorSurcharge_w", width: "20" },
        { header: "컬러 Surcharge($)", key: "colorSurcharge_d", width: "20" },
        {
          header: "건 당 Surcharge(w)",
          key: "surchargeTextile_w",
          width: "20",
        },
        {
          header: "건 당 Surcharge(d)",
          key: "surchargeTextile_d",
          width: "20",
        },
        { header: "매출 금액(₩)", key: "sumTotalAmount_w", width: "20" },
        { header: "매출 금액($)", key: "sumTotalAmount_d", width: "20" },
        { header: "비고", key: "memo", width: "20" },
      ];

      sheetOne.getRow(1).alignment = {
        vertical: "center",
        horizontal: "center",
      };
      // 헤더 가운데정렬
      //리팩토링예정 컬럼들 숫자형일 경우 오른쪽 문자열이면 가운데
      sheetOne.getRow(1).style = {
        font: { bold: true, name: "Arial Black", size: 30 },
      }; // 헤더 스타일지정

      const release = this.state.salesEndList;

      const RowData = [];

      release.forEach((x) => {
        RowData.push({
          carried: x.carried ? "이월" : "",
          month: x.month ? `${x.month}` : "",
          type: x.kind ? x.kind : "",
          season: x.season && x.season !== "(비어있음)" ? x.season : "",
          order: x.order ? x.order : "",
          client: x.buyer && x.buyer !== "(비어있음)" ? x.buyer : "",
          item: x.item ? x.item.name : "",
          placeFoward: x.place_forward ? x.place_forward : "",
          color:
            x.color && x.color !== "(비어있음)"
              ? `${x.color}${
                  x.color_list.length ? ` (+ ${x.color_list.length} )` : ""
                }`
              : "",
          purchaseQuantity_y:
            x.purchaseQuantity &&
            x.purchaseQuantity !== "(비어있음)" &&
            x.unitSign === "y"
              ? `${x.purchaseQuantity}`
              : ``,
          purchaseQuantity_m:
            x.purchaseQuantity &&
            x.purchaseQuantity !== "(비어있음)" &&
            x.unitSign === "m"
              ? `${x.purchaseQuantity}`
              : ``,
          totalQuantity_y:
            x.totalQuantity &&
            x.totalQuantity !== "(비어있음)" &&
            x.unitSign === "y"
              ? `${x.totalQuantity}`
              : "",
          totalQuantity_m:
            x.totalQuantity &&
            x.totalQuantity !== "(비어있음)" &&
            x.unitSign === "m"
              ? `${x.totalQuantity}`
              : "",
          salesPlaceFoward:
            x.placeSales && x.placeSales !== "(비어있음)" ? x.placeSales : "",
          salesQuantity_y:
            x.salesQuantity &&
            x.salesQuantity !== "(비어있음)" &&
            x.unitSign === "y"
              ? `${x.salesQuantity}`
              : "",
          salesQuantity_m:
            x.salesQuantity &&
            x.salesQuantity !== "(비어있음)" &&
            x.unitSign === "m"
              ? `${x.salesQuantity}`
              : "",
          salesUnitprice_w:
            x.salesUnitPrice &&
            x.salesUnitPrice !== "(비어있음)" &&
            x.currency_sign === "₩"
              ? `${x.salesUnitPrice}`
              : "",
          salesUnitprice_d:
            x.salesUnitPrice &&
            x.salesUnitPrice !== "(비어있음)" &&
            x.currency_sign === "$"
              ? `${x.salesUnitPrice}`
              : "",
          sumTotalAmount_w:
            x.salesTotalAmount &&
            x.salesTotalAmount !== "(비어있음)" &&
            x.currency_sign === "₩"
              ? `${x.salesTotalAmount}`
              : "",
          colorSurcharge_w:
            x.colorSurchargeUnit &&
            x.colorSurchargeUnit !== "(비어있음)" &&
            x.currency_sign === "₩"
              ? `${x.colorSurchargeUnit}`
              : "",
          colorSurcharge_d:
            x.colorSurchargeUnit &&
            x.colorSurchargeUnit !== "(비어있음)" &&
            x.currency_sign === "$"
              ? `${x.colorSurchargeUnit}`
              : "",
          surchargeTextile_w:
            x.salesSurchargeUnit &&
            x.salesSurchargeUnit !== "(비어있음)" &&
            x.currency_sign === "₩"
              ? `${x.salesSurchargeUnit}`
              : "",
          surchargeTextile_d:
            x.salesSurchargeUnit &&
            x.salesSurchargeUnit !== "(비어있음)" &&
            x.currency_sign === "$"
              ? `${x.salesSurchargeUnit}`
              : "",
          sumTotalAmount_d:
            x.salesTotalAmount &&
            x.salesTotalAmount !== "(비어있음)" &&
            x.currency_sign === "$"
              ? `${x.salesTotalAmount}`
              : "",
          memo: x.memo ? x.memo : "",
        });
        x.color_list.forEach((c, i) => {
          RowData.push({
            carried: x.carried ? "이월" : "",
            month: x.month ? `${x.month}` : "",
            type: x.kind ? x.kind : "",
            season: x.season && x.season !== "(비어있음)" ? x.season : "",
            order: x.order ? x.order : "",
            client: x.buyer && x.buyer !== "(비어있음)" ? x.buyer : "",
            item: x.item ? x.item.name : "",
            placeFoward: x.place_forward ? x.place_forward : "",
            salesPlaceFoward:
              x.placeSales && x.placeSales !== "(비어있음)" ? x.placeSales : "",
            color: c.color ? c.color : "",
            totalQuantity_y:
              c.totalQuantity &&
              c.totalQuantity !== "(비어있음)" &&
              x.unitSign === "y"
                ? `${c.totalQuantity}`
                : "",
            totalQuantity_m:
              c.totalQuantity &&
              c.totalQuantity !== "(비어있음)" &&
              x.unitSign === "m"
                ? `${c.totalQuantity}`
                : "",
            salesQuantity_y:
              c.salesQuantity &&
              c.salesQuantity !== "(비어있음)" &&
              x.unitSign === "y"
                ? `${c.salesQuantity}`
                : "",
            salesQuantity_m:
              c.salesQuantity &&
              c.salesQuantity !== "(비어있음)" &&
              x.unitSign === "m"
                ? `${c.salesQuantity}`
                : "",
            colorSurcharge_w:
              c.colorSurchargeUnit &&
              c.colorSurchargeUnit !== "(비어있음)" &&
              x.currency_sign === "₩"
                ? `${c.colorSurchargeUnit}`
                : "",
            colorSurcharge_d:
              c.colorSurchargeUnit &&
              c.colorSurchargeUnit !== "(비어있음)" &&
              x.currency_sign === "$"
                ? `${c.colorSurchargeUnit}`
                : "",
            sumTotalAmount_w:
              c.salesTotalAmount &&
              c.salesTotalAmount !== "(비어있음)" &&
              x.currency_sign === "₩"
                ? `${c.salesTotalAmount}`
                : "",
            sumTotalAmount_d:
              c.salesTotalAmount &&
              c.salesTotalAmount !== "(비어있음)" &&
              x.currency_sign === "$"
                ? `${c.salesTotalAmount}`
                : "",
          });
        });
      });

      sheetOne.eachRow((row) => {
        row.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });

      RowData.map((item, index) => {
        sheetOne.addRow(item);
      });

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${this.state.Today}_매출마감.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (e) {
      console.error(e);
    }
  },
  async CLAIM_DOWNLOAD_EXCEL() {
    try {
      const workbook = new exceljs.Workbook(); // 엑셀생성
      workbook.creator = `${this.state.auth_name}`; //생성자
      workbook.lastModifiedBy = `${this.state.auth_name}`; //최종 수정자
      workbook.created = new Date(); //생성일
      workbook.modified = new Date(); //수정일

      workbook.addWorksheet(this.state.Today); // 시트명
      const sheetOne = workbook.getWorksheet(1); //첫번쨰 시트 접근

      // workbook.headerFooter.oddHeader = "오더 발주목록";

      // sheetOne.autoFilter = { from: "A1", to: "R1" };
      sheetOne.getRow(1).alignment = {
        vertical: "center",
        horizontal: "center",
      }; // 헤더 가운데정렬
      //리팩토링예정 컬럼들 숫자형일 경우 오른쪽 문자열이면 가운데
      sheetOne.getRow(1).style = {
        font: { bold: true, name: "Arial Black", size: 10 },
      }; // 헤더 스타일지정
      sheetOne.getRow(2).style = {
        font: { bold: true, name: "Arial Black", size: 13 },
      }; // 헤더 스타일지정

      const claim =
        router.app._route.name === "claim"
          ? this.state.claimList
          : this.state.endClaimList;

      const RowData = [];
      sheetOne.mergeCells("C2:H2");
      sheetOne.mergeCells("J2:O2");
      sheetOne.getRow(2).height = 20;
      sheetOne.getCell("C2").value = "발생";
      sheetOne.getCell("J2").value = "전가";

      sheetOne.columns = [
        { header: "오더", key: "order", width: "20" },
        { header: "담당자", key: "liaison", width: "10" },
        { header: "월", key: "arise_month", width: "10" },
        { header: "구분", key: "arise_type", width: "10" },
        { header: "업체명", key: "arise_client", width: "20" },
        { header: "금액(₩)", key: "arise_price_w", width: "20" },
        { header: "금액($)", key: "arise_price_d", width: "20" },
        { header: "내용", key: "arise_passtype", width: "20" },
        { header: "내용", key: "arise_content", width: "20" },
        { header: "상태", key: "arise_status", width: "20" },
        { header: "", key: "empty", width: "20" },
        { header: "월", key: "pass_month", width: "10" },
        { header: "구분", key: "pass_type", width: "10" },
        { header: "업체명", key: "pass_client", width: "20" },
        { header: "금액(₩)", key: "pass_price_w", width: "20" },
        { header: "금액($)", key: "pass_price_d", width: "20" },
        { header: "내용", key: "pass_content", width: "20" },
        { header: "상태", key: "pass_status", width: "20" },
      ];
      claim.forEach((x) => {
        RowData.push({
          arise_month: x.arise_month ? x.arise_month : "",
          order: x.order ? x.order : "",
          liaison: x.liaison ? x.liaison : "",
          arise_type: x.arise_type ? x.arise_type : "",
          arise_client: x.arise_client ? x.arise_client : "",

          arise_price_w:
            x.arise_price && x.arise_currency_sign === "₩"
              ? `${x.arise_price}${
                  x.arise_eschamge ? `${x.arise_eschamge}` : ""
                }`
              : "",
          arise_price_d:
            x.arise_price && x.arise_currency_sign === "$"
              ? `${x.arise_price}${
                  x.arise_eschamge ? `${x.arise_eschamge}` : ""
                }`
              : "",
          arise_passtype: x.arise_passtype
            ? x.arise_passtype === 1
              ? "협의중"
              : x.arise_passtype === 2
              ? "전가불가"
              : x.arise_passtype === 3
              ? "전가"
              : ""
            : "",
          arise_content: x.arise_detail ? x.arise_detail : "",
          arise_status: x.arise_status ? OrderStatus[x.arise_status] : "",
          pass_year: x.pass_year ? x.pass_year : "",
          pass_month: x.pass_month ? x.pass_month : "",
          pass_type: x.pass_type ? x.pass_type : "",
          pass_client: x.pass_client ? x.pass_client : "",
          pass_price_w:
            x.pass_price && x.pass_currency_sign === "₩"
              ? `${x.pass_price}${x.pass_eschamge ? `${x.pass_eschamge}` : ""}`
              : "",
          pass_price_d:
            x.pass_price && x.pass_currency_sign === "$"
              ? `${x.pass_price}${x.pass_eschamge ? `${x.pass_eschamge}` : ""}`
              : "",
          pass_content: x.pass_detail ? x.pass_detail : "",
          pass_status: x.pass_status ? OrderStatus[x.pass_status] : "",
        });
      });

      sheetOne.eachRow((row) => {
        row.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });

      RowData.map((item, index) => {
        sheetOne.addRow(item);
      });

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${this.state.Today}${
          router.app._route.name === "claim" ? "_클레임" : "_마감클레임"
        }.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (e) {
      console.error(e);
    }
  },
  async SALESCAC_DOWNLOAD_EXCEL() {
    try {
      const workbook = new exceljs.Workbook(); // 엑셀생성
      workbook.creator = `${this.state.auth_name}`; //생성자
      workbook.lastModifiedBy = `${this.state.auth_name}`; //최종 수정자
      workbook.created = new Date(); //생성일
      workbook.modified = new Date(); //수정일

      workbook.addWorksheet(this.state.Today); // 시트명
      const sheetOne = workbook.getWorksheet(1); //첫번쨰 시트 접근

      // workbook.headerFooter.oddHeader = "오더 발주목록";

      sheetOne.columns = [
        { header: "이월여부", key: "carried", width: "10" },
        { header: "월", key: "month", width: "10" },
        { header: "구분", key: "type", width: "10" },
        { header: "시즌", key: "season", width: "10" },
        { header: "오더", key: "order", width: "20" },
        { header: "발주처", key: "client", width: "20" },
        { header: "아이템", key: "item", width: "20" },
        { header: "거래처", key: "placeFoward", width: "20" },
        { header: "컬러", key: "color", width: "20" },
        { header: "매입 수량(y)", key: "purchaseQuantity_y", width: "20" },
        { header: "매입 수량(m)", key: "purchaseQuantity_m", width: "20" },
        { header: "출고 수량(y)", key: "totalQuantity_y", width: "20" },
        { header: "출고 수량(m)", key: "totalQuantity_m", width: "20" },
        { header: "매출처", key: "salesPlaceFoward", width: "20" },
        { header: "매출 수량(y)", key: "salesQuantity_y", width: "20" },
        { header: "매출 수량(m)", key: "salesQuantity_m", width: "20" },
        { header: "매출 단가(₩)", key: "salesUnitprice_w", width: "20" },
        { header: "매출 단가($)", key: "salesUnitprice_d", width: "20" },
        { header: "컬러 Surcharge(₩)", key: "colorSurcharge_w", width: "20" },
        { header: "컬러 Surcharge($)", key: "colorSurcharge_d", width: "20" },
        {
          header: "건 당 Surcharge(w)",
          key: "surchargeTextile_w",
          width: "20",
        },
        {
          header: "건 당 Surcharge(d)",
          key: "surchargeTextile_d",
          width: "20",
        },
        { header: "매출 금액(₩)", key: "sumTotalAmount_w", width: "20" },
        { header: "매출 금액($)", key: "sumTotalAmount_d", width: "20" },
        { header: "비고", key: "memo", width: "20" },
      ];

      sheetOne.getRow(1).alignment = {
        vertical: "center",
        horizontal: "center",
      };
      // 헤더 가운데정렬
      //리팩토링예정 컬럼들 숫자형일 경우 오른쪽 문자열이면 가운데
      sheetOne.getRow(1).style = {
        font: { bold: true, name: "Arial Black", size: 30 },
      }; // 헤더 스타일지정

      const release = this.state.salesCacList;

      const RowData = [];

      release.forEach((x) => {
        RowData.push({
          carried: x.carried ? "이월" : "",
          month: x.month ? `${x.month}` : "",
          type: x.kind ? x.kind : "",
          season: x.season && x.season !== "(비어있음)" ? x.season : "",
          order: x.order ? x.order : "",
          client: x.buyer && x.buyer !== "(비어있음)" ? x.buyer : "",
          item: x.item ? x.item.name : "",
          placeFoward: x.place_forward ? x.place_forward : "",
          color:
            x.color && x.color !== "(비어있음)"
              ? `${x.color}${
                  x.color_list.length ? ` (+ ${x.color_list.length} )` : ""
                }`
              : "",
          purchaseQuantity_y:
            x.purchaseQuantity &&
            x.purchaseQuantity !== "(비어있음)" &&
            x.unitSign === "y"
              ? `${x.purchaseQuantity}`
              : ``,
          purchaseQuantity_m:
            x.purchaseQuantity &&
            x.purchaseQuantity !== "(비어있음)" &&
            x.unitSign === "m"
              ? `${x.purchaseQuantity}`
              : ``,
          totalQuantity_y:
            x.totalQuantity &&
            x.totalQuantity !== "(비어있음)" &&
            x.unitSign === "y"
              ? `${x.totalQuantity}`
              : "",
          totalQuantity_m:
            x.totalQuantity &&
            x.totalQuantity !== "(비어있음)" &&
            x.unitSign === "m"
              ? `${x.totalQuantity}`
              : "",
          salesPlaceFoward:
            x.placeSales && x.placeSales !== "(비어있음)" ? x.placeSales : "",
          salesQuantity_y:
            x.salesQuantity &&
            x.salesQuantity !== "(비어있음)" &&
            x.unitSign === "y"
              ? `${x.salesQuantity}`
              : "",
          salesQuantity_m:
            x.salesQuantity &&
            x.salesQuantity !== "(비어있음)" &&
            x.unitSign === "m"
              ? `${x.salesQuantity}`
              : "",
          salesUnitprice_w:
            x.salesUnitPrice &&
            x.salesUnitPrice !== "(비어있음)" &&
            x.currency_sign === "₩"
              ? `${x.salesUnitPrice}`
              : "",
          salesUnitprice_d:
            x.salesUnitPrice &&
            x.salesUnitPrice !== "(비어있음)" &&
            x.currency_sign === "$"
              ? `${x.salesUnitPrice}`
              : "",
          sumTotalAmount_w:
            x.salesTotalAmount &&
            x.salesTotalAmount !== "(비어있음)" &&
            x.currency_sign === "₩"
              ? `${x.salesTotalAmount}`
              : "",
          colorSurcharge_w:
            x.colorSurchargeUnit &&
            x.colorSurchargeUnit !== "(비어있음)" &&
            x.currency_sign === "₩"
              ? `${x.colorSurchargeUnit}`
              : "",
          colorSurcharge_d:
            x.colorSurchargeUnit &&
            x.colorSurchargeUnit !== "(비어있음)" &&
            x.currency_sign === "$"
              ? `${x.colorSurchargeUnit}`
              : "",
          surchargeTextile_w:
            x.salesSurchargeUnit &&
            x.salesSurchargeUnit !== "(비어있음)" &&
            x.currency_sign === "₩"
              ? `${x.salesSurchargeUnit}`
              : "",
          surchargeTextile_d:
            x.salesSurchargeUnit &&
            x.salesSurchargeUnit !== "(비어있음)" &&
            x.currency_sign === "$"
              ? `${x.salesSurchargeUnit}`
              : "",
          sumTotalAmount_d:
            x.salesTotalAmount &&
            x.salesTotalAmount !== "(비어있음)" &&
            x.currency_sign === "$"
              ? `${x.salesTotalAmount}`
              : "",
          memo: x.memo ? x.memo : "",
        });
        x.color_list.forEach((c, i) => {
          RowData.push({
            carried: x.carried ? "이월" : "",
            month: x.month ? `${x.month}` : "",
            type: x.kind ? x.kind : "",
            season: x.season && x.season !== "(비어있음)" ? x.season : "",
            order: x.order ? x.order : "",
            client: x.buyer && x.buyer !== "(비어있음)" ? x.buyer : "",
            item: x.item ? x.item.name : "",
            placeFoward: x.place_forward ? x.place_forward : "",
            salesPlaceFoward:
              x.placeSales && x.placeSales !== "(비어있음)" ? x.placeSales : "",
            color: c.color ? c.color : "",
            totalQuantity_y:
              c.totalQuantity &&
              c.totalQuantity !== "(비어있음)" &&
              x.unitSign === "y"
                ? `${c.totalQuantity}`
                : "",
            totalQuantity_m:
              c.totalQuantity &&
              c.totalQuantity !== "(비어있음)" &&
              x.unitSign === "m"
                ? `${c.totalQuantity}`
                : "",
            salesQuantity_y:
              c.salesQuantity &&
              c.salesQuantity !== "(비어있음)" &&
              x.unitSign === "y"
                ? `${c.salesQuantity}`
                : "",
            salesQuantity_m:
              c.salesQuantity &&
              c.salesQuantity !== "(비어있음)" &&
              x.unitSign === "m"
                ? `${c.salesQuantity}`
                : "",
            colorSurcharge_w:
              c.colorSurchargeUnit &&
              c.colorSurchargeUnit !== "(비어있음)" &&
              x.currency_sign === "₩"
                ? `${c.colorSurchargeUnit}`
                : "",
            colorSurcharge_d:
              c.colorSurchargeUnit &&
              c.colorSurchargeUnit !== "(비어있음)" &&
              x.currency_sign === "$"
                ? `${c.colorSurchargeUnit}`
                : "",
            sumTotalAmount_w:
              c.salesTotalAmount &&
              c.salesTotalAmount !== "(비어있음)" &&
              x.currency_sign === "₩"
                ? `${c.salesTotalAmount}`
                : "",
            sumTotalAmount_d:
              c.salesTotalAmount &&
              c.salesTotalAmount !== "(비어있음)" &&
              x.currency_sign === "$"
                ? `${c.salesTotalAmount}`
                : "",
          });
        });
      });

      sheetOne.eachRow((row) => {
        row.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });

      RowData.map((item, index) => {
        sheetOne.addRow(item);
      });

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${this.state.Today}_매출정산.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (e) {
      console.error(e);
    }
  },
  async PURCHASECAC_DOWNLOAD_EXCEL() {
    try {
      const workbook = new exceljs.Workbook(); // 엑셀생성
      workbook.creator = `${this.state.auth_name}`; //생성자
      workbook.lastModifiedBy = `${this.state.auth_name}`; //최종 수정자
      workbook.created = new Date(); //생성일
      workbook.modified = new Date(); //수정일

      workbook.addWorksheet(this.state.Today); // 시트명
      const sheetOne = workbook.getWorksheet(1); //첫번쨰 시트 접근

      // workbook.headerFooter.oddHeader = "오더 발주목록";

      sheetOne.columns = [
        { header: "이월여부", key: "carried", width: "10" },
        { header: "월", key: "month", width: "10" },
        { header: "구분", key: "type", width: "10" },
        { header: "시즌", key: "season", width: "10" },
        { header: "오더", key: "order", width: "20" },
        { header: "발주처", key: "client", width: "20" },
        { header: "아이템", key: "item", width: "20" },
        { header: "거래처", key: "placeFoward", width: "20" },
        { header: "컬러", key: "color", width: "20" },
        { header: "매출 수량(y)", key: "salesQuantity_y", width: "20" },
        { header: "매출 수량(m)", key: "salesQuantity_m", width: "20" },
        { header: "출고 수량(y)", key: "Quantity_y", width: "20" },
        { header: "출고 수량(m)", key: "Quantity_m", width: "20" },
        { header: "매입 수량(y)", key: "purchaseQuantity_y", width: "20" },
        { header: "매입 수량(m)", key: "purchaseQuantity_m", width: "20" },
        { header: "단가(₩)", key: "unitPrice_w", width: "20" },
        { header: "단가($)", key: "unitPrice_d", width: "20" },
        { header: "금액(₩)", key: "amount_w", width: "20" },
        { header: "금액($)", key: "amounit_d", wdth: "20" },
        {
          header: "염색 Surcharge(₩)",
          key: "surchargeDyeing_w",
          width: "20",
        },
        {
          header: "염색 Surcharge($)",
          key: "surchargeDyeing_d",
          width: "20",
        },
        {
          header: "제직 Surcharge(₩)",
          key: "surchargeTextile_w",
          width: "20",
        },
        {
          header: "제직 Surcharge($)",
          key: "surchargeTextile_d",
          width: "20",
        },
        { header: "금액 합(₩)", key: "sumTotalAmount_w", width: "20" },
        { header: "금액 합($)", key: "sumTotalAmount_d", width: "20" },
        { header: "비고", key: "memo", width: "20" },
      ];

      sheetOne.getRow(1).alignment = {
        vertical: "center",
        horizontal: "center",
      }; // 헤더 가운데정렬
      //리팩토링예정 컬럼들 숫자형일 경우 오른쪽 문자열이면 가운데
      sheetOne.getRow(1).style = {
        font: { bold: true, name: "Arial Black", size: 30 },
      }; // 헤더 스타일지정

      const release = this.state.purchCalculList;

      const RowData = [];
      release.forEach((x) => {
        RowData.push({
          carried: x.carried ? "이월" : "",
          month: x.month ? `${x.month}` : "",
          type: x.kind ? x.kind : "",
          season: x.season && x.season !== "(비어있음)" ? x.season : "",
          order: x.order ? x.order : "",
          client: x.buyer && x.buyer !== "(비어있음)" ? x.buyer : "",
          item: x.item && x.item !== "(비어있음)" ? x.item.name : "",
          placeFoward:
            x.place_forward && x.place_forward !== "(비어있음)"
              ? x.place_forward
              : "",
          color:
            x.color && x.color !== "(비어있음)"
              ? `${x.color}${
                  x.color_list.length ? ` (+ ${x.color_list.length} )` : ""
                }`
              : "",
          salesQuantity_y:
            x.salesQuantity &&
            x.salesQuantity !== "(비어있음)" &&
            x.unitSign === "y"
              ? `${x.salesQuantity}`
              : "",
          salesQuantity_m:
            x.salesQuantity &&
            x.salesQuantity !== "(비어있음)" &&
            x.unitSign === "m"
              ? `${x.salesQuantity}`
              : "",
          Quantity_y:
            x.totalQuantity &&
            x.totalQuantity !== "(비어있음)" &&
            x.unitSign === "y"
              ? `${x.totalQuantity}`
              : "",
          Quantity_m:
            x.totalQuantity &&
            x.totalQuantity !== "(비어있음)" &&
            x.unitSign === "m"
              ? `${x.totalQuantity}`
              : "",
          purchaseQuantity_y:
            x.purchaseQuantity &&
            x.purchaseQuantity !== "(비어있음)" &&
            x.purUnitSign === "y"
              ? `${x.purchaseQuantity}`
              : "",
          purchaseQuantity_m:
            x.purchaseQuantity &&
            x.purchaseQuantity !== "(비어있음)" &&
            x.purUnitSign === "m"
              ? `${x.purchaseQuantity}`
              : "",
          unitPrice_w:
            x.purchaseUnitPrice &&
            x.purchaseUnitPrice !== "(비어있음)" &&
            x.currency_sign === "₩"
              ? `${x.purchaseUnitPrice}`
              : "",
          unitPrice_d:
            x.purchaseUnitPrice &&
            x.purchaseUnitPrice !== "(비어있음)" &&
            x.currency_sign === "$"
              ? `${x.purchaseUnitPrice}`
              : "",
          amount_w:
            x.amount && x.amount !== "(비어있음)" && x.currency_sign === "₩"
              ? `${x.amount}`
              : "",
          amount_d:
            x.amount && x.amount !== "(비어있음)" && x.currency_sign === "$"
              ? `${x.amount}`
              : "",
          surchargeDyeing_w:
            x.colorSurchargePrice &&
            x.colorSurchargePrice !== "(비어있음)" &&
            x.currency_sign === "₩"
              ? `${x.colorSurchargePrice}`
              : "",
          surchargeDyeing_d:
            x.colorSurchargePrice &&
            x.colorSurchargePrice !== "(비어있음)" &&
            x.currency_sign === "$"
              ? `${x.colorSurchargePrice}`
              : "",
          surchargeTextile_w:
            x.surchargePrice &&
            x.surchargePrice !== "(비어있음)" &&
            x.currency_sign === "₩"
              ? `${x.surchargePrice}`
              : "",
          surchargeTextile_d:
            x.surchargePrice &&
            x.surchargePrice !== "(비어있음)" &&
            x.currency_sign === "$"
              ? `${x.surchargePrice}`
              : "",
          sumTotalAmount_w:
            x.totalAmount &&
            x.totalAmount !== "(비어있음)" &&
            x.currency_sign === "₩"
              ? `${x.totalAmount}`
              : "",
          sumTotalAmount_d:
            x.totalAmount &&
            x.totalAmount !== "(비어있음)" &&
            x.currency_sign === "$"
              ? `${x.totalAmount}`
              : "",
          memo: x.memo ? x.memo : "",
        });
        x.color_list.forEach((c, i) => {
          RowData.push({
            carried: x.carried ? "이월" : "",
            month: x.month ? `${x.month}` : "",
            type: x.kind ? x.kind : "",
            season: x.season && x.season !== "(비어있음)" ? x.season : "",
            order: x.order ? x.order : "",
            client: x.buyer && x.buyer !== "(비어있음)" ? x.buyer : "",
            item: x.item && x.item !== "(비어있음)" ? x.item.name : "",
            placeFoward:
              x.place_forward && x.place_forward !== "(비어있음)"
                ? x.place_forward
                : "",
            color: c.color && c.color !== "(비어있음)" ? c.color : "",
            salesQuantity_y:
              c.salesQuantity &&
              c.salesQuantity !== "(비어있음)" &&
              x.unitSign === "y"
                ? `${c.salesQuantity}`
                : "",
            salesQuantity_m:
              c.salesQuantity &&
              c.salesQuantity !== "(비어있음)" &&
              x.unitSign === "m"
                ? `${c.salesQuantity}`
                : "",
            Quantity_y:
              c.totalQuantity &&
              c.totalQuantity !== "(비어있음)" &&
              x.unitSign === "y"
                ? `${c.totalQuantity}`
                : "",
            Quantity_m:
              c.totalQuantity &&
              c.totalQuantity !== "(비어있음)" &&
              x.unitSign === "m"
                ? `${c.totalQuantity}`
                : "",
            purchaseQuantity_y:
              c.purchaseQuantity &&
              c.purchaseQuantity !== "(비어있음)" &&
              x.purUnitSign === "y"
                ? `${c.purchaseQuantity}`
                : "",
            purchaseQuantity_m:
              c.purchaseQuantity &&
              c.purchaseQuantity !== "(비어있음)" &&
              x.purUnitSign === "m"
                ? `${c.purchaseQuantity}`
                : "",
            amount_w:
              c.amount && c.amount !== "(비어있음)" && x.currency_sign === "₩"
                ? `${c.amount}`
                : "",
            amount_d:
              c.amount && c.amount !== "(비어있음)" && x.currency_sign === "$"
                ? `${c.amount}`
                : "",
            surchargeDyeing_w:
              c.colorSurchargePrice &&
              c.colorSurchargePrice !== "(비어있음)" &&
              x.currency_sign === "₩"
                ? `${c.colorSurchargePrice}`
                : "",
            surchargeDyeing_d:
              c.colorSurchargePrice &&
              c.colorSurchargePrice !== "(비어있음)" &&
              x.currency_sign === "$"
                ? `${c.colorSurchargePrice}`
                : "",
            surchargeTextile_w:
              c.surchargePrice &&
              c.surchargePrice !== "(비어있음)" &&
              x.currency_sign === "₩"
                ? `${c.surchargePrice}`
                : "",
            surchargeTextile_d:
              c.surchargePrice &&
              c.surchargePrice !== "(비어있음)" &&
              x.currency_sign === "$"
                ? `${c.surchargePrice}`
                : "",

            sumTotalAmount_w:
              c.totalAmount &&
              c.totalAmount !== "(비어있음)" &&
              x.currency_sign === "₩"
                ? `${c.totalAmount}`
                : "",
            sumTotalAmount_d:
              c.totalAmount &&
              c.totalAmount !== "(비어있음)" &&
              x.currency_sign === "$"
                ? `${c.totalAmount}`
                : "",
          });
        });
      });

      sheetOne.eachRow((row) => {
        row.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });

      RowData.map((item, index) => {
        sheetOne.addRow(item);
      });

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${this.state.Today}_매입정산.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (e) {
      console.error(e);
    }
  },
  async PURVAT_DOWNLOAD_EXCEL() {
    try {
      const workbook = new exceljs.Workbook(); // 엑셀생성
      workbook.creator = `${this.state.auth_name}`; //생성자
      workbook.lastModifiedBy = `${this.state.auth_name}`; //최종 수정자
      workbook.created = new Date(); //생성일
      workbook.modified = new Date(); //수정일

      workbook.addWorksheet(this.state.Today); // 시트명
      const sheetOne = workbook.getWorksheet(1); //첫번쨰 시트 접근

      sheetOne.columns = [
        { header: "년도", key: "year", width: "20" },
        { header: "월", key: "month", width: "10" },
        { header: "업체명(매입처)", key: "name", width: "20" },
        { header: "매입 금액(₩)", key: "amount_w", width: "20" },
        { header: "매입 금액($)", key: "amount_d", width: "20" },
        { header: "매입 금액(₩) (vat 포함)", key: "vat_amount_w", width: "20" },
        { header: "매입 금액($)(vat 포함)", key: "vat_amount_d", width: "20" },
        { header: "지급액(₩)", key: "deposit_amount_w", width: "20" },
        { header: "지급액($)", key: "deposit_amount_d", width: "20" },
        { header: "지급날짜", key: "deposit_date", width: "20" },
        { header: "지급형태", key: "type", width: "20" },
        { header: "만기일", key: "expiration", width: "20" },
        { header: "비고", key: "memo", width: "20" },
        { header: "잔액(₩)", key: "balance_w", width: "20" },
        { header: "잔액($)", key: "balance_d", width: "20" },
      ];

      //리팩토링예정 컬럼들 숫자형일 경우 오른쪽 문자열이면 가운데
      sheetOne.getRow(1).style = {
        font: { bold: true, name: "Arial Black", size: 30 },
      }; // 헤더 스타일지정

      const vatList = this.state.vatList;

      const RowData = [];

      vatList.forEach((x) => {
        RowData.push({
          year: x.year ? x.year : "",
          month: x.month ? x.month : "",
          name: x.name ? x.name : "",
          amount_w:
            x.amount &&
            x.amount !== "(비어있음)" &&
            (x.currency_sign === "₩" || !x.currency_sign || x.orginal_amount)
              ? x.amount
              : "",
          amount_d:
            x.amount &&
            x.amount !== "(비어있음)" &&
            !x.orginal_amount &&
            x.currency_sign === "$"
              ? x.amount
              : "",
          vat_amount_w:
            x.vat_amount &&
            x.vat_amount !== "(비어있음)" &&
            (x.currency_sign === "₩" || !x.currency_sign || x.orginal_amount)
              ? x.vat_amount
              : "",
          vat_amount_d:
            x.vat_amount &&
            x.vat_amount !== "(비어있음)" &&
            !x.orginal_amount &&
            x.currency_sign === "$"
              ? x.vat_amount
              : "",

          deposit_amount_w:
            x.deposit_amount &&
            x.deposit_amount !== "(비어있음)" &&
            (x.currency_sign === "₩" || !x.currency_sign || x.orginal_amount)
              ? x.deposit_amount
              : "",
          deposit_amount_d:
            x.deposit_amount &&
            x.deposit_amount !== "(비어있음)" &&
            !x.orginal_amount &&
            x.currency_sign === "$"
              ? x.deposit_amount
              : "",
          deposit_date:
            x.deposit_date && x.deposit_date !== "(비어있음)"
              ? x.deposit_date
              : "",
          type: x.type && x.type !== "(비어있음)" ? x.type : "",
          memo: x.memo && x.memo !== "(비어있음)" ? x.memo : "",
          expiration:
            x.expiration && x.expiration !== "(비어있음)" ? x.expiration : "",

          balance_w:
            x.balance &&
            x.balance !== "(비어있음)" &&
            (x.currency_sign === "₩" || !x.currency_sign || x.orginal_amount)
              ? x.balance
              : "",
          balance_d:
            x.balance &&
            x.balance !== "(비어있음)" &&
            !x.orginal_amount &&
            x.currency_sign === "$"
              ? x.balance
              : "",
        });
      });

      sheetOne.eachRow((row) => {
        row.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });

      RowData.map((item, index) => {
        sheetOne.addRow(item);
      });

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${this.state.Today}_외상매입금.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (e) {
      console.error(e);
    }
  },
  async SALEVAT_DOWNLOAD_EXCEL() {
    try {
      const workbook = new exceljs.Workbook(); // 엑셀생성
      workbook.creator = `${this.state.auth_name}`; //생성자
      workbook.lastModifiedBy = `${this.state.auth_name}`; //최종 수정자
      workbook.created = new Date(); //생성일
      workbook.modified = new Date(); //수정일

      workbook.addWorksheet(this.state.Today); // 시트명
      const sheetOne = workbook.getWorksheet(1); //첫번쨰 시트 접근

      sheetOne.columns = [
        { header: "년도", key: "year", width: "20" },
        { header: "월", key: "month", width: "10" },
        { header: "업체명(매출처)", key: "name", width: "20" },
        { header: "매출 금액(₩)", key: "amount_w", width: "20" },
        { header: "매출 금액($)", key: "amount_d", width: "20" },
        { header: "매출 금액(₩) (vat 포함)", key: "vat_amount_w", width: "20" },
        { header: "매출 금액($)(vat 포함)", key: "vat_amount_d", width: "20" },
        { header: "입금액(₩)", key: "deposit_amount_w", width: "20" },
        { header: "입금액($)", key: "deposit_amount_d", width: "20" },
        { header: "입금날짜", key: "deposit_date", width: "20" },
        { header: "입금형태", key: "type", width: "20" },
        { header: "만기일", key: "expiration", width: "20" },
        { header: "비고", key: "memo", width: "20" },
        { header: "잔액(₩)", key: "balance_w", width: "20" },
        { header: "잔액($)", key: "balance_d", width: "20" },
      ];

      //리팩토링예정 컬럼들 숫자형일 경우 오른쪽 문자열이면 가운데
      sheetOne.getRow(1).style = {
        font: { bold: true, name: "Arial Black", size: 30 },
      }; // 헤더 스타일지정

      const vatList = this.state.vatSalesList;

      const RowData = [];
      vatList.forEach((x) => {
        RowData.push({
          year: x.year ? x.year : "",
          month: x.month ? x.month : "",
          name: x.name ? x.name : "",
          amount_w:
            x.amount &&
            x.amount !== "(비어있음)" &&
            (x.currency_sign === "₩" || !x.currency_sign || x.orginal_amount)
              ? x.amount
              : "",
          amount_d:
            x.amount &&
            x.amount !== "(비어있음)" &&
            !x.orginal_amount &&
            x.currency_sign === "$"
              ? x.amount
              : "",
          vat_amount_w:
            x.vat_amount &&
            x.vat_amount !== "(비어있음)" &&
            (x.currency_sign === "₩" || !x.currency_sign || x.orginal_amount)
              ? x.vat_amount
              : "",
          vat_amount_d:
            x.vat_amount &&
            x.vat_amount !== "(비어있음)" &&
            !x.orginal_amount &&
            x.currency_sign === "$"
              ? x.vat_amount
              : "",

          deposit_amount_w:
            x.deposit_amount &&
            x.deposit_amount !== "(비어있음)" &&
            (x.currency_sign === "₩" || !x.currency_sign || x.orginal_amount)
              ? x.deposit_amount
              : "",
          deposit_amount_d:
            x.deposit_amount &&
            x.deposit_amount !== "(비어있음)" &&
            !x.orginal_amount &&
            x.currency_sign === "$"
              ? x.deposit_amount
              : "",
          deposit_date:
            x.deposit_date && x.deposit_date !== "(비어있음)"
              ? x.deposit_date
              : "",
          type: x.type && x.type !== "(비어있음)" ? x.type : "",
          memo: x.memo && x.memo !== "(비어있음)" ? x.memo : "",
          expiration:
            x.expiration && x.expiration !== "(비어있음)" ? x.expiration : "",

          balance_w:
            x.balance &&
            x.balance !== "(비어있음)" &&
            (x.currency_sign === "₩" || !x.currency_sign || x.orginal_amount)
              ? x.balance
              : "",
          balance_d:
            x.balance &&
            x.balance !== "(비어있음)" &&
            !x.orginal_amount &&
            x.currency_sign === "$"
              ? x.balance
              : "",
        });
      });

      sheetOne.eachRow((row) => {
        row.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });

      RowData.map((item, index) => {
        sheetOne.addRow(item);
      });

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${this.state.Today}_외상매출금.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (e) {
      console.error(e);
    }
  },
  async EXCEL_UPLOAD({ commit }, data) {
    const readFile = (data) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(data);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };
    const downType = this.state.excelUploadType;
    const buffer = await readFile(data);

    const workbook = new exceljs.Workbook();
    const file = await workbook.xlsx.load(buffer);
    const sheet = await file.getWorksheet(1);
    if (downType === "client") this.commit("setExcelFormList");
    if (downType === "item") this.commit("setItemExcelFormList");
    let saveData = {};
    const excelList = [];
    sheet.eachRow(function (row, rowNumber) {
      if (rowNumber !== 1 && rowNumber !== 2) {
        if (downType === "item") {
          saveData = {
            company: store.state.auth_company,
            name: row.getCell(1).value,
            unit_price: row.getCell(2).value,
            currency_sign: row.getCell(3).value,
            unit_length: row.getCell(4).value
              ? row.getCell(4).value.toLowerCase()
              : "",
            inspect_status:
              row.getCell(5).value === "y"
                ? true
                : row.getCell(5).value === "n"
                ? false
                : true,
            production: store.state.clientList.find(
              (x) => x.name === row.getCell(6).value,
            ),
            production_item: row.getCell(7).value,
            slope: row.getCell(8).value,
            oblique: row.getCell(9).value,
            variety: row.getCell(10).value,
            width: row.getCell(11).value,
            full_width: row.getCell(12).value,
            unit_width: row.getCell(13).value
              ? row.getCell(13).value.toLowerCase()
              : "",
            density: row.getCell(14).value,
            weight: row.getCell(15).value,
            unit_weight: row.getCell(16).value
              ? row.getCell(16).value.toLowerCase()
              : "",
            post_processing: row.getCell(17).value,
            content: row.getCell(18).value,
            liaison: row.getCell(19).value,
          };
        }
        if (downType === "client") {
          saveData = {
            company_type: row.getCell(1).value,
            type_postion: row.getCell(2).value,
            zone: row.getCell(3).value,
            name_only: row.getCell(4).value,
            bill_client: row.getCell(5).value,
            officer: row.getCell(6).value,
            business_num: "" + row.getCell(7).value,
            industry: row.getCell(8).value,
            tel: row.getCell(9).value
              ? ("" + row.getCell(9).value).charAt(0) === "0"
                ? row.getCell(9).value
                : "0" + row.getCell(9).value
              : "",
            fax: row.getCell(10).value
              ? ("" + row.getCell(10).value).charAt(0) === "0"
                ? row.getCell(10).value
                : "0" + row.getCell(10).value
              : "",
            email: row.getCell(11).value
              ? row.getCell(11).value["text"]
                ? row.getCell(11).value["text"].richText[0].text
                : row.getCell(11).value
              : "",
            address: row.getCell(12).value,
            liaison: row.getCell(13).value,
          };
        }
        if (downType === "ctype") {
          saveData = {
            company: store.state.auth_company,
            name: row.getCell(1).value,
          };
        }
        excelList.push(saveData);
      }
    });

    if (downType === "client") this.commit("setExcelFormList", excelList);
    if (downType === "item") this.commit("setItemExcelFormList", excelList);
    if (downType === "ctype") this.commit("setCtypeExcelFormList", excelList);
  },
  async CLIENT_DOWNLOAD_EXCEL() {
    try {
      const workbook = new exceljs.Workbook(); // 엑셀생성
      workbook.creator = `${this.state.auth_name}`; //생성자
      workbook.lastModifiedBy = `${this.state.auth_name}`; //최종 수정자
      workbook.created = new Date(); //생성일
      workbook.modified = new Date(); //수정일

      workbook.addWorksheet(this.state.Today); // 시트명
      const sheetOne = workbook.getWorksheet(1); //첫번쨰 시트 접근

      // workbook.headerFooter.oddHeader = "오더 발주목록";

      sheetOne.columns = [
        { header: "구분", key: "company_type", width: "30" },
        { header: "좌우구분", key: "type_postion", width: "10" },
        { header: "지점", key: "zone", width: "30" },
        { header: "회사명", key: "name", width: "20" },
        { header: "계산서발행처", key: "bill_client", width: "30" },
        { header: "대표", key: "officer", width: "30" },
        { header: "사업자번호", key: "business_num", width: "30" },
        { header: "업종", key: "industry", width: "30" },
        { header: "전화번호", key: "tel", width: "30" },
        { header: "팩스번호", key: "fax", width: "30" },
        { header: "이메일", key: "email", width: "30" },
        { header: "주소", key: "address", width: "30" },
        { header: "담당자", key: "liaison", width: "30" },
      ];
      sheetOne.getRow(1).alignment = {
        vertical: "center",
        horizontal: "center",
      }; // 헤더 가운데정렬
      sheetOne.getRow(1).style = {
        font: { bold: true, name: "Arial Black", size: 30 },
      }; // 헤더 스타일지정
      await this.dispatch("SET_CLIENTLIST");
      const client = this.state.clientList;

      const RowData = [];
      client.forEach((x) => {
        if (x.status === "40")
          RowData.push({
            company_type: x.company_type
              ? this.state.client_type[x.company_type]
                ? this.state.client_type[x.company_type - 1].label
                : ""
              : "",
            type_postion: x.type_postion
              ? x.type_postion === "left"
                ? "좌"
                : x.type_postion === "right"
                ? "우"
                : ""
              : "",
            zone: x.zone ? x.zone : "",
            name: x.name ? x.name : x.name,
            bill_client: x.bill_client ? x.bill_client.name : "",
            officer: x.officer ? x.officer : "",
            business_num: x.business_num ? x.business_num : "",
            industry: x.industry ? x.industry : "",
            tel: x.tel ? x.tel : "",
            fax: x.fax ? x.fax : "",
            email: x.email ? x.email : "",
            address: x.address ? x.address : "",
            liaison: x.liaison ? x.liaison : "",
          });
      });
      RowData.map((item, index) => {
        sheetOne.addRow(item);
      });
      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${this.state.Today}_거래처.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (e) {
      console.error(e);
    }
  },
  async ITEM_DOWNLOAD_EXCEL() {
    try {
      const workbook = new exceljs.Workbook(); // 엑셀생성
      workbook.creator = `${this.state.auth_name}`; //생성자
      workbook.lastModifiedBy = `${this.state.auth_name}`; //최종 수정자
      workbook.created = new Date(); //생성일
      workbook.modified = new Date(); //수정일

      workbook.addWorksheet(this.state.Today); // 시트명
      const sheetOne = workbook.getWorksheet(1); //첫번쨰 시트 접근

      // workbook.headerFooter.oddHeader = "오더 발주목록";

      sheetOne.columns = [
        { header: "아이템명", key: "name", width: "30" },
        { header: "가격", key: "unit_price", width: "30" },
        { header: "단위(가격)", key: "currency_sign", width: "10" },
        { header: "단위(길이)", key: "unit_length", width: "10" },
        { header: "검사포함구분", key: "inspect_status", width: "30" },
        { header: "생산처", key: "production", width: "30" },
        { header: "생산처 Item", key: "production_item", width: "30" },
        { header: "경사", key: "slope", width: "30" },
        { header: "위사", key: "oblique", width: "30" },
        { header: "혼용률", key: "variety", width: "30" },
        { header: "가용 폭", key: "width", width: "30" },
        { header: "전체 폭", key: "full_width", width: "30" },
        { header: "단위(폭 길이)", key: "unit_width", width: "30" },
        { header: "밀도", key: "density", width: "30" },
        { header: "중량", key: "weight", width: "30" },
        { header: "단위(무게)", key: "unit_weight", width: "30" },
        { header: "후가공", key: "post_processing", width: "30" },
        { header: "내용", key: "content", width: "30" },
        { header: "담당자", key: "liaison", width: "30" },
      ];
      sheetOne.getRow(1).alignment = {
        vertical: "center",
        horizontal: "center",
      }; // 헤더 가운데정렬
      sheetOne.getRow(1).style = {
        font: { bold: true, name: "Arial Black", size: 30 },
      }; // 헤더 스타일지정
      await this.dispatch("SET_ITEMLIST");
      const item = this.state.itemList;

      const RowData = [];
      item.forEach((x) => {
        if (x.status === "40")
          RowData.push({
            name: x.name ? x.name : "",
            unit_price: x.unit_price ? x.unit_price : "",
            currency_sign: x.currency_sign ? x.currency_sign : "",
            unit_length: x.unit_length ? x.unit_length : "",
            inspect_status: x.inspect_status
              ? x.inspect_status
                ? "포함"
                : !x.inspect_status
                ? "비포함"
                : ""
              : "",
            production: x.production ? x.production.name : "",
            production_item: x.production_item ? x.production_item : "",
            slope: x.slope ? x.slope : "",
            oblique: x.oblique ? x.oblique : "",
            variety: x.variety ? x.variety : "",
            width: x.width ? x.width : "",
            full_width: x.full_width ? x.full_width : "",
            unit_width: x.unit_width ? x.unit_width : "",
            density: x.density ? x.density : "",
            weight: x.weight ? x.weight : "",
            unit_weight: x.unit_weight ? x.unit_weight : "",
            post_processing: x.post_processing ? x.post_processing : "",
            content: x.content ? x.content : "",
            liaison: x.liaison ? x.liaison : "",
          });
      });
      RowData.map((item, index) => {
        sheetOne.addRow(item);
      });
      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${this.state.Today}_아이템.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (e) {
      console.error(e);
    }
  },
  async CTYPE_DOWNLOAD_EXCEL() {
    try {
      const workbook = new exceljs.Workbook(); // 엑셀생성
      workbook.creator = `${this.state.auth_name}`; //생성자
      workbook.lastModifiedBy = `${this.state.auth_name}`; //최종 수정자
      workbook.created = new Date(); //생성일
      workbook.modified = new Date(); //수정일

      workbook.addWorksheet(this.state.Today); // 시트명
      const sheetOne = workbook.getWorksheet(1); //첫번쨰 시트 접근

      // workbook.headerFooter.oddHeader = "오더 발주목록";

      sheetOne.columns = [{ header: "복종명", key: "name", width: "30" }];
      sheetOne.getRow(1).alignment = {
        vertical: "center",
        horizontal: "center",
      }; // 헤더 가운데정렬
      sheetOne.getRow(1).style = {
        font: { bold: true, name: "Arial Black", size: 30 },
      }; // 헤더 스타일지정
      await this.dispatch("SET_CTYPELIST");
      const ctype = this.state.ctypeList;

      const RowData = [];
      ctype.forEach((x) => {
        if (x.status === "40")
          RowData.push({
            name: x.name ? x.name : "",
          });
      });
      RowData.map((item, index) => {
        sheetOne.addRow(item);
      });
      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${this.state.Today}_복종.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } catch (e) {
      console.error(e);
    }
  },
  EXCEL_EXAMPLE_DOWNLOAD() {
    const downType = this.state.excelUploadType;
    const workbook = new exceljs.Workbook(); // 엑셀생성
    workbook.creator = `${this.state.auth_name}`; //생성자
    workbook.lastModifiedBy = `${this.state.auth_name}`; //최종 수정자
    workbook.created = new Date(); //생성일
    workbook.modified = new Date(); //수정일
    workbook.addWorksheet(); // 시트명
    const sheetOne = workbook.getWorksheet(1); //첫번쨰 시트 접근

    sheetOne.getRow(1).alignment = {
      vertical: "center",
      horizontal: "center",
    };

    let RowData = {};
    if (downType === "item") {
      sheetOne.columns = itemHeader;
      RowData = {
        name: "TEST 9103 (필수입력)",
        unit_price: "400",
        currency_sign: "₩, $ , € , £ , ¥ , 円 (중 입력)",
        unit_sign: "y , m  (중 입력)",
        inspect_status: " y, n (중 입력)",
        production: "소프트마니 (등록된 거래처만 입력가능) (필수입력)",
        production_item: "HBC-1123 (필수입력)",
        slope: "10",
        oblique: "20",
        variety: "P 111",
        width: "56",
        full_width: "100",
        unit_width: "in , cm(중 입력)",
        density: "30",
        weight: "10",
        unit_weight: "g/y, g/m² , oz/y (중 입력)",
        post_processing: "PD",
        content: "특이사항",
        liaison: "담당자",
      };
      sheetOne.addRow(RowData);
      sheetOne.getCell("A2").note = {
        texts: [
          {
            font: { size: 12, color: { theme: 1 } },
            text: "아이템명은 필수값입니다",
          },
        ],
        margins: {
          insetmode: "custom",
          inset: [0.25, 0.25, 0.35, 0.35],
        },
        protection: {
          locked: true,
          lockText: false,
        },
      };
      sheetOne.getCell("F2").note = {
        texts: [
          {
            font: { size: 12, color: { theme: 1 } },
            text: "생산처는 등록된 거래처중에서만 입력 가능합니다.",
          },
        ],
        margins: {
          insetmode: "custom",
          inset: [0.25, 0.25, 0.35, 0.35],
        },
        protection: {
          locked: true,
          lockText: false,
        },
      };
      sheetOne.getCell("G2").note = {
        texts: [
          {
            font: { size: 12, color: { theme: 1 } },
            text: "생산처 itemNo는 필수 값입니다.",
          },
        ],
        margins: {
          insetmode: "custom",
          inset: [0.25, 0.25, 0.35, 0.35],
        },
        protection: {
          locked: true,
          lockText: false,
        },
      };
    }
    if (downType === "client") {
      sheetOne.columns = clientHeader;
      RowData = {
        company_type: "주,유,합,합명,책임,재단,사단 (중 입력)",
        type_postion: "좌,우(회사 구분 위치를 입력해주세요)",
        zone: "서울지점",
        name_only: "소프트마니",
        bill_client: "소프트마니",
        representative: "홍길동",
        number: "2148631231 (10자리로 입력)",
        industry: "의류업",
        tel: "01012341234 (하이픈(-)을 뺀 번호만 입력해주세요)",
        fax: "025554444 (하이픈(-)을 뺀 번호만 입력해주세요)",
        email: "exmaple@exmaple.com (이메일 형태로 입력해주세요)",
        address: "서울시 강남구 역삼로 164",
        liaison: "아무개",
      };
      sheetOne.addRow(RowData);
      sheetOne.getCell("A2").note = {
        texts: [
          {
            font: { size: 12, color: { theme: 1 } },
            text: "구분은 필수 값입니다",
          },
        ],
        margins: {
          insetmode: "custom",
          inset: [0.25, 0.25, 0.35, 0.35],
        },
        protection: {
          locked: true,
          lockText: false,
        },
      };
      sheetOne.getCell("B2").note = {
        texts: [
          {
            font: { size: 12, color: { theme: 1 } },
            text: "회사명구분은 필수 값입니다",
          },
        ],
        margins: {
          insetmode: "custom",
          inset: [0.25, 0.25, 0.35, 0.35],
        },
        protection: {
          locked: true,
          lockText: false,
        },
      };
      sheetOne.getCell("D2").note = {
        texts: [
          {
            font: { size: 12, color: { theme: 1 } },
            text: "회사명은 필수 값입니다",
          },
        ],
        margins: {
          insetmode: "custom",
          inset: [0.25, 0.25, 0.35, 0.35],
        },
        protection: {
          locked: true,
          lockText: false,
        },
      };
      sheetOne.getCell("E2").note = {
        texts: [
          {
            font: { size: 12, color: { theme: 1 } },
            text: "계산서 발행처는 이미 등록된 거래처만 입력 가능합니다",
          },
        ],
        margins: {
          insetmode: "custom",
          inset: [0.25, 0.25, 0.35, 0.35],
        },
        protection: {
          locked: true,
          lockText: false,
        },
      };
    }
    if (downType === "ctype") {
      sheetOne.columns = ctypeHeader;
      RowData = {
        name: "상의",
      };
      sheetOne.addRow(RowData);
    }

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `엑셀업로드양식_${downType}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  },
  //엑셀 폼 저장
  async EXCEL_UPLOAD_SAVE({ commit }, kind) {
    this.dispatch("SET_API_LOADING", true);
    const saveList =
      kind === "item"
        ? this.state.itemExcelFormList
        : kind === "client"
        ? this.state.excelFormList
        : this.state.ctypeExcelFormList;
    for (let i = 0; i < saveList.length; i++) {
      if (kind === "client") {
        await this.dispatch("CLIENT_FORM_PARSE", saveList[i]);
        await this.dispatch("EXCEL_SAVE_CLIENT");
      }
      if (kind === "item") {
        await this.dispatch("ITEM_FORM_PARSE", saveList[i]);
        await this.dispatch("EXCEL_SAVE_ITEM");
      }
      if (kind === "ctype") {
        await this.dispatch("CTYPE_FORM_PARSE", saveList[i]);
        await this.dispatch("EXCEL_SAVE_CTYPE");
      }
    }
    if (kind === "client") {
      await this.dispatch("SET_CLIENTLIST");
      await this.dispatch("SET_USE_CLIENTLIST");
      this.commit("setSnackBar", "거래처 정보가 저장되었습니다.");
      this.commit("setExcelFormList");
    }
    if (kind === "item") {
      await this.dispatch("SET_ITEMLIST");
      this.commit("setSnackBar", "아이템이 저장되었습니다.");
      this.commit("setItemExcelFormList");
    }
    if (kind === "ctype") {
      await this.dispatch("SET_ITEMLIST");
      this.commit("setSnackBar", "복종이 저장되었습니다.");
      this.commit("setCtypeExcelFormList");
    }
    this.dispatch("SET_API_LOADING", false);
  },
  async CLIENT_FORM_PARSE({ commit }, form) {
    commit("setEditedItemForm");
    const companyType =
      form.company_type === "주" || form.company_type === "주식회사"
        ? 1
        : form.company_type === "유" || form.company_type === "유한회사"
        ? 2
        : form.company_type === "합" || form.company_type === "합자회사"
        ? 3
        : form.company_type === "합명" || form.company_type === "합명회사"
        ? 4
        : form.company_type === "책임" || form.company_type === "유한책임회사"
        ? 5
        : form.company_type === "재단" || form.company_type === "재단법인"
        ? 6
        : form.company_type === "사단" || form.company_type === "사단법인"
        ? 7
        : null;
    const fullName = `${
      form.type_postion === "좌" && companyType
        ? ClienTypeAcronyms[companyType - 1].label
        : ""
    }${form.name_only ? form.name_only : ""}${
      form.type_postion === "우" && companyType
        ? ClienTypeAcronyms[companyType - 1].label
        : ""
    }${form.name_only && form.zone ? `[${form.zone}]` : ""}`;
    const saveClient = this.state.clientList.find((x) => x.name === fullName);
    if (saveClient) {
      saveClient.company_type = companyType;
      saveClient.address = form.address;
      saveClient.business_num = form.business_num;
      saveClient.email = form.email;
      saveClient.fax = form.fax;
      saveClient.industry = form.industry;

      saveClient.type_postion =
        form.type_postion === "좌"
          ? "left"
          : form.type_postion === "우"
          ? "right"
          : "";
      saveClient.zone = form.zone;
      saveClient.kind = form.kind;
      saveClient.liaison = form.liaison;
      saveClient.name_only = form.name_only;
      saveClient.name = fullName;
      saveClient.officer = form.officer;
      saveClient.tel = form.tel;
      saveClient.bill_client = form.bill_client
        ? this.state.clientList.find((x) => x.name === form.bill_client)
        : null;

      commit("setEditedItemForm", saveClient);
    } else {
      form.company_type = companyType;
      form.type_postion =
        form.type_postion === "좌"
          ? "left"
          : form.type_postion === "우"
          ? "right"
          : "";

      form.name = fullName;
      form.bill_client = form.bill_client
        ? this.state.clientList.find((x) => x.name === form.bill_client)
        : null;
      commit("setEditedItemForm", form);
    }
  },
  async ITEM_FORM_PARSE({ commit }, form) {
    commit("setEditedItemForm");
    const saveItem = this.state.itemList.find((x) => x.name === form.name);
    if (saveItem) {
      saveItem.production = form.production._id;
      saveItem.production_item = form.production_item;
      saveItem.unit_length = form.unit_length;
      saveItem.weight = form.weight;
      saveItem.variety = form.variety;
      saveItem.unit_width = form.unit_width;
      saveItem.unit_weight = form.unit_weight;
      saveItem.unit_price = form.unit_price;
      saveItem.slope = form.slope;
      saveItem.post_processing = form.post_processing;
      saveItem.oblique = form.oblique;
      saveItem.inspect_status = form.inspect_status;
      saveItem.full_width = form.full_width;
      saveItem.density = form.density;
      saveItem.currency_sign = form.currency_sign;
      saveItem.content = form.content;
      commit("setEditedItemForm", saveItem);
    } else {
      form.production = form.production ? form.production._id : null;
      commit("setEditedItemForm", form);
    }
  },
  async CTYPE_FORM_PARSE({ commit }, form) {
    commit("setEditedItemForm");
    commit("setEditedItemForm", form);
  },
  /* 1. 공통 로직 함수 */

  // forms Init
  INIT({ commit }, kind) {
    if (kind === "order") this.dispatch("INIT_ORDER");
    if (kind === "claim") this.dispatch("INIT_CLAIM");
  },

  //save Data Parse
  SAVE_PARSE_DATA({ commit }, kind) {
    if (kind === "production") this.dispatch("PARSE_PRODUCTION_DATA");
    if (kind === "order") this.dispatch("PARSE_ORDER_DATA");
    if (kind === "purchaseCac") this.dispatch("PARSE_PURCHASECAC");
    if (kind === "claim") this.dispatch("PARSE_CLAIM_DATA");
  },
  // 오더 저장 로직별 순차 함수 실행
  // async TASK_ORDER_SAVE({ commit }) {
  //   this.dispatch("SET_API_LOADING", true),
  //     await this.dispatch("getaApprovalComId", {
  //       id: this.state.orderForm.order,
  //       route: router.app._route.name,
  //     });

  //   if (this.state.orderForm.saveKind === "modify" && this.state.auth_grade < 2)
  //     await this.dispatch("PARSE_ORDER_MIDFY_RESPONE");
  //   else await this.dispatch("PARSE_ORDER_DATA");
  //   if (this.state.orderForm.advance_pay)
  //     await this.dispatch("TASK_ADVANCE_PAY");
  //   await this.dispatch("SAVE_ORDER");
  // },

  /* 페이지 별 함수 */
  // 1. 오더 페이지
  async INIT_ORDER({ commit }) {
    // await this.dispatch("ORDER_ORDERLIST");
    await this.dispatch("SELECT_APPROVAL_LIST");
    commit("setApprovalForm");
    this.dispatch("SET_API_LOADING", false);
    this.commit("setDailogStatus", {
      status: false,
      kind: "confirm_dialog",
    });
  },

  ORDER_SAVE_SUCCESS({ commit }) {
    this._vm.$socket.emit("order", {
      company: this.state.auth_company,
      order: this.state.saveData.order,
    });
    commit("setOrderForm");
    commit("setNewOrderNo");

    this.dispatch("INIT_ORDER");
  },

  async ORDER_SAVE_ERROR() {
    if (!this.state.saveData._id) {
      const order = this.state.orderForm;
      if (this.state.saveData.kind !== 3) {
        await this.dispatch("NEW_ORDER_NO");
        order.order_back = this.state.newOrderNo.order_back;
      } else {
        await this.dispatch("NEW_REPEAT_ORDER_NO");
        order.order_back_repeat = this.state.newOrderNo.order_back_repeat;
      }
      order.order = this.state.newOrderNo.order;
    }
    alert(
      this.state.saveData._id
        ? "오더 변경 사항이 있습니다."
        : "오더 번호가 변경되었습니다. 오더 번호를 확인 해주세요",
    );
    this.dispatch("INIT_ORDER");
  },

  async PARSE_ORDER_DATA({ commit }) {
    const form = JSON.parse(JSON.stringify(this.state.orderForm));
    if (form.color_list.length)
      form.color_list.forEach((color) => {
        praseSaveDataComma(color);
      });
    praseSaveDataComma(form);
    if (form.saveKind === "create") {
      form.kind = form.SampleStatus ? 2 : form.RepeatStatus ? 3 : 1;
      form.is_modify = true;

      form.status = this.state.auth_grade >= 2 ? "40" : "20";
      form.progress = [
        {
          index: 0,
          date_modify: new Date(Date.now()),
          kind: 1,
          content: "confirm",
          log: "오더 승인",
          status: "40",
        },
      ];
      //relase,claim,manufacture 제거 로직
      if (form.kind === 3) {
        form.liaison = this.state.auth_name;
        form.appearance = [];
        form.claim = [];
        form.manufacture = [];
        form.release = [];
      }
    }

    if (form.saveKind === "modify") {
      form.status = this.state.auth_grade >= 2 ? "40" : "50";
    }

    //관리자 오더 반려
    if (form.saveKind === "reject") form.status = "30";

    //관리자 삭제
    if (form.saveKind === "delete") {
      form.status = this.state.auth_grade >= 2 ? "99" : "90";
      const DelOrders = this.state.orderList.filter((x) => x.status === "99");
      if (this.state.auth_grade >= 2)
        form.order = `${form.order}_Del${DelOrders.length++}`;
    }

    if (form.saveKind === "deleteReject") form.status = "40";

    form.liaison = form.liaison
      ? this.state.userList.find((c) => c.name === form.liaison)._id
      : this.state.auth_id;

    form.item = form.item ? form.item._id : null;

    if (form.ctype !== "" || form.ctype !== undefined) {
      form.ctype = form.ctype ? form.ctype._id : null;
    } else {
      form.ctype = this.state.ctypeList.find((x) => x.name === "선택 안함")._id;
    }
    form.client = form.client ? form.client._id : null;

    form.buyer = form.buyer ? form.buyer._id : null;
    form.date_modify = new Date(Date.now());
    form.company = this.state.auth_company;

    commit("setSaveData", form);
    this.dispatch("SET_APPROVAL_SAVE_DATA", form);
  },

  async PARSE_ORDER_MIDFY_RESPONE({ commit }) {
    await this.dispatch("SELECT_ORDER", this.state.orderForm);

    const form = this.state.selectOrder;
    const modifyOrder = this.state.orderForm;
    if (modifyOrder.color_list.length)
      modifyOrder.color_list.forEach((color) => {
        praseSaveDataComma(color);
      });
    praseSaveDataComma(modifyOrder);

    form.status = "50";

    modifyOrder.liaison = modifyOrder.liaison
      ? this.state.userList.find((c) => c.name === modifyOrder.liaison)._id
      : this.state.auth_id;
    if (modifyOrder.item)
      modifyOrder.item = modifyOrder.item ? modifyOrder.item._id : null;

    if (modifyOrder.ctype !== "" || modifyOrder.ctype !== undefined) {
      modifyOrder.ctype = modifyOrder.ctype ? modifyOrder.ctype._id : null;
    } else {
      modifyOrder.ctype = this.state.ctypeList.find(
        (x) => x.name === "선택 안함",
      )._id;
    }

    modifyOrder.client = modifyOrder.client ? modifyOrder.client._id : null;

    modifyOrder.buyer = modifyOrder.buyer ? modifyOrder.buyer._id : null;
    modifyOrder.date_modify = new Date(Date.now());
    modifyOrder.company = this.state.auth_company;
    modifyOrder.status = form.status;

    commit("setSaveData", form);
    commit("setSelectOrder");
    this.dispatch("SET_APPROVAL_SAVE_DATA", modifyOrder);
  },

  async SAVE_CLAIM() {
    try {
      const result = this.state.claimForm._id
        ? await putClaim(this.state.claimForm)
        : await postClaim(this.state.claimForm);

      if (result.status !== 200) throw `error : ${result.status}`;
      this._vm.$socket.emit("claim", { company: this.state.auth_company });
      await this.dispatch("SAVE_APPROVAL");

      this.commit("setApprovalMemo");
    } catch (e) {
      throw "error";
    }
  },
  async SAVE_END_CLAIM() {
    try {
      const result = this.state.claimForm._id
        ? await putClaim(this.state.claimForm)
        : await postClaim(this.state.claimForm);

      if (result.status !== 200) throw `error : ${result.status}`;

      this.commit("setApprovalMemo");
    } catch (e) {
      console.log(e);
    }
  },

  async SAVE_ORDER({ commit }) {
    try {
      const result = this.state.saveData._id
        ? await putOrder(this.state.saveData)
        : await postOrder(this.state.saveData);

      if (result.status !== 200) throw `error : ${result.status}`;

      this.dispatch("SAVE_INIT_PAGE");

      if (this.state.saveApproval.id) await this.dispatch("SAVE_APPROVAL");
      this.commit("setApprovalMemo");

      if (this.state.relApprovalList.length)
        await this.dispatch("CANCEL_CALCULATE_APPROVAL");

      if (this.state.deleteStock.length) {
        await this.dispatch("DELETE_STOCK_DATE");

        commit("setDeleteStock");
      }

      if (this.state.saveStock.length) await this.dispatch("SAVE_STOCK_DATA");
    } catch (e) {
      if (router.app._route.name === "order") this.dispatch("ORDER_SAVE_ERROR");
      if (router.app._route.name === "production") {
        this.dispatch("PRODUCTION_SAVE_ERROR");
      }
    }
  },

  SAVE_INIT_PAGE() {
    const route = router.app._route.name;

    if (route === "order") this.dispatch("ORDER_SAVE_SUCCESS");

    if (route === "production") this.dispatch("PRODUCTION_SAVE_END");

    if (route === "purchasecalculate" || route === "purchasedeadline")
      this._vm.$socket.emit("purchase", { company: this.state.auth_company });

    if (route === "salescalculate" || route === "salesdeadline")
      this._vm.$socket.emit("sales", { company: this.state.auth_company });
  },

  async SAVE_CLAIM_RELEASE() {
    try {
      const order = await putOrder(this.state.saveData);
      if (order.status !== 200) throw `error: ${order.status}`;
    } catch (e) {
      throw "error";
    }
  },
  async SAVE_BACK_CLAIM_RELEASE() {
    try {
      const order = await putOrder(this.state.beforeOrder);
      if (order.status !== 200) throw `error: ${order.status}`;
    } catch (e) {
      throw "error";
    }
  },
  async SAVE_AFTER_CLAIM_RELEASE() {
    try {
      const order = await putOrder(this.state.afterOrder);
      if (order.status !== 200) throw `error: ${order.status}`;
    } catch (e) {
      throw "error";
    }
  },

  async SAVE_APPROVA_ORDER({ commit }, route) {
    try {
      const order = await putOrder(this.state.saveData);
      if (order.status !== 200) throw `error: ${order.status}`;
      if (route === "order")
        this._vm.$socket.emit("order", {
          company: this.state.auth_company,
          order: this.state.saveData.order,
        });

      await this.dispatch("SAVE_APPROVAL");

      if (this.state.deleteStock.length) {
        await this.dispatch("DELETE_STOCK_DATE");
        commit("setDeleteStock");
      }
      if (this.state.saveStock.length) await this.dispatch("SAVE_STOCK_DATA");
    } catch (e) {
      throw "error";
    }
  },

  async APPROVAL_STOCK_DATA_SET({ commit }, data) {
    const saveList = [];
    const checkList = [...data.minuse, ...data.pluse];

    for (let i = 0; i < checkList.length; i++) {
      if (!saveList.find((x) => x.id === checkList[i].indexColorName)) {
        const saveStock = await getStock(
          this.state.auth_company,
          checkList[i].indexColorName,
        );

        const pluse = data.pluse
          .filter((x) => x.indexColorName === checkList[i].indexColorName)
          .reduce(
            (r, c) => r + (c.success_quantity ? +c.success_quantity : 0),
            0,
          );

        const minuse = data.minuse
          .filter((x) => x.indexColorName === checkList[i].indexColorName)
          .reduce(
            (r, c) => r + (c.success_quantity ? +c.success_quantity : 0),
            0,
          );

        saveStock.remain_quantity =
          saveStock.remain_quantity -
          (saveStock.unit_sign !== data.unit_sign
            ? +unitChange(saveStock.unit_sign, minuse)
            : minuse) +
          (saveStock.unit_sign !== data.unit_sign
            ? +unitChange(saveStock.unit_sign, pluse)
            : pluse);

        saveList.push(saveStock);
      }
    }
    commit("setSaveStock", saveList);
  },

  PARSE_ORDERLIST_DATA({ commit }, data) {
    data.forEach((x) => {
      let purlength = 0;
      let saleslength = 0;

      //MAINDATA_PARSE
      if (x.release)
        x.release.forEach((c) => {
          // * 매입
          if (c.purchase && x.order === c.order) purlength++;
          if (c.sales && x.order === c.order) saleslength++;
          x.main.pur_month = x.main_pur_month
            ? x.main_pur_month
            : monthDateFormat(c.date_delivery);
          x.main.pur_place = c.place_forward
            ? `${
                this.state.clientList.find((d) => d._id === c.place_forward)
                  ? this.state.clientList.find((d) => d._id === c.place_forward)
                      .name
                  : ""
              }`
            : x.main.pur_place;

          x.main.pur_item = c.item
            ? `${
                this.state.itemList.find((d) => d._id === c.item)
                  ? this.state.itemList.find((d) => d._id === c.item).name
                  : ""
              }`
            : x.main.pur_item;
          x.main.quantity = x.color_list
            ? x.color_list.reduce(
                (r, c) => r + (c.quantity ? c.quantity : 0),
                0,
              )
            : x.main.quantity;

          x.main.manu_quantity = x.manufacture
            ? x.manufacture[x.manufacture.length - 1].color_list.reduce(
                (r, c) => r + (c.quantity ? c.quantity : 0),
                0,
              )
            : x.main.manu_quantity;
          x.main.inspect_quantity = x.appearance.color_list
            ? x.appearance[x.appearance.length - 1].color_list.reduce(
                (r, c) => r + (c.release_quantity ? c.release_quantity : 0),
                0,
              )
            : x.main.inspect_quantity;
          x.main.rel_quantity = c.total_quantity
            ? c.total_quantity
            : x.main.rel_quantity;
          x.main.pur_quantity = c.purchase
            ? c.purchase.quantity
            : x.main.pur_quantity;
          x.main.pur_unit_price = c.purchase
            ? c.purchase.unit_price
            : x.main.pur_unit_price;
          x.main.pur_price = c.purchase
            ? c.purchase.total_amount
            : x.main.pur_price;
          // * 매출

          x.main.sale_month = x.main.sale_month
            ? x.main.sale_month
            : monthDateFormat(c.date_delivery);
          x.main.sale_place = c.place_sales
            ? `${
                this.state.clientList.find((d) => d._id === c.place_sales)
                  ? this.state.clientList.find((d) => d._id === c.place_sales)
                      .name
                  : ""
              }`
            : x.main.sale_place;
          x.main.sale_quantity = c.sales.quantity
            ? c.sales.quantity
            : x.main.sale_quantity;
          x.main.sale_unit_price = c.sales_unit_price
            ? c.sales_unit_price
            : x.main.sale_unit_price;
          x.main.sale_price = c.sales.total_amount
            ? c.sales.total_amount
            : x.main.sale_price;
          x.main.progress = c.sales
            ? 4
            : x.appearance
            ? 3
            : x.manufacture
            ? 2
            : 1;

          // 정산데이터 파싱
          c.item = c.item
            ? this.state.itemList.find((d) => d._id === c.item)
            : "";

          c.buyer = x.client
            ? this.state.clientList.find((d) => d._id === x.client)
            : "";
          c.place_forward
            ? (c.place_forward = this.state.clientList.find(
                (d) => d._id === c.place_forward,
              ))
            : "";
          c.place_sales
            ? (c.place_sales = this.state.clientList.find(
                (d) => d._id === c.place_sales,
              ))
            : "";
          if (
            router.app._route.name === "purchasecalculate" ||
            router.app._route.name === "purchasedeadline"
          ) {
            c.color = c.purchase.color_list[0]
              ? c.purchase.color_list[0].name
              : "";
            c.colorTotal = c.purchase.color_list.reduce(
              (r, c) => r + (c.total_amount ? c.total_amount : 0),
              0,
            );
            c.month = c.purchase.date
              ? `${monthDateFormat(c.purchase.date)} (이월)`
              : monthDateFormat(c.date_delivery);
          }
          if (
            router.app._route.name === "salescalculate" ||
            router.app._route.name === "salesdeadline"
          ) {
            c.color = c.sales.color_list[0] ? c.sales.color_list[0].name : "";
            c.colorTotal = c.sales.color_list.reduce(
              (r, c) => r + (c.total_amount ? c.total_amount : 0),
              0,
            );
            c.month = c.sales.date
              ? `${monthDateFormat(c.sales.date)} (이월)`
              : monthDateFormat(c.date_delivery);
          }
          c.season = x.order_front_season === "ss" ? "S/S" : "F/W";
        });

      // 클레임페이지 파싱

      x.claim.forEach((c) => {
        c.liaison = this.state.userList.find((d) => d._id === c.liaison);

        if (c.process)
          c.process.forEach((d) => {
            d.client = this.state.clientList.find((f) => f._id === d.client);

            d.process_status =
              d.process_status === 1
                ? "발행 예정"
                : d.process_status === 2
                ? "매입"
                : d.process_status === 3
                ? "매출"
                : "";
            d.month = new Date(d.date_register).getMonth() + 1;
            d.kind = d.kind === 1 ? "발생" : d.kind === 2 ? "전가" : "";
            if (c.status !== "99" && c.status !== "00") {
              this.state.claimTotalPrice += d.price;
              this.state.claimTotalLength++;
            }
          });
      });
      x.main.pur_length = x.main.pur_length ? x.main.pur_length : purlength;
      x.main.sale_length = x.main.sale_length
        ? x.main.sale_length
        : saleslength;

      x.main.salesProfit = x.main.sale_price
        ? (
            x.main.sale_price - (x.main.pur_price ? x.main.pur_price : 0)
          ).toFixed()
        : 0;
      x.main.type = x.main
        ? x.kind === 1
          ? "오더"
          : x.kind === 2
          ? "샘플"
          : "리피트"
        : "";

      x.main.claimLength = x.claim
        ? x.claim.filter((x) => x.status !== "99").length
        : 0;
      x.main.margin =
        x.main.sale_price && x.main.pur_price
          ? ((1 - +x.main.pur_price / +x.main.sale_price) * 100).toFixed(2)
          : 0;

      //ORDER DATA PARSE

      typeof x.liaison !== "object"
        ? (x.liaison = this.state.userList.find((c) => c._id === x.liaison)
            ? this.state.userList.find((c) => c._id === x.liaison)
            : "")
        : "";

      x.ctype = this.state.ctypeList.find((c) => c._id === x.ctype)
        ? this.state.ctypeList.find((c) => c._id === x.ctype)
        : "";
      typeof x.item !== "object"
        ? (x.item = this.state.itemList.find((c) => c._id === x.item)
            ? this.state.itemList.find((c) => c._id === x.item)
            : "")
        : "";
      typeof x.client !== "object"
        ? (x.client = this.state.clientList.find((c) => c._id === x.client)
            ? this.state.clientList.find((c) => c._id === x.client)
            : "")
        : "";

      x.date_register = x.date_register ? dateFormat(x.date_register) : "";
      x.order_date = x.date_register ? monthFormat(x.date_register) : "";
      // x.year = new Date(x.date_register).getFullYear();

      x.productionItem = x.item
        ? this.state.clientList
          ? this.state.clientList.find((c) => c._id === x.item.production).name
          : ""
        : "";
    });
  },
  // 2. 클레임 페이지
  async SELECT_CLAIM_PARSE({ commit }, data) {
    commit("setClaimReleaseForm");

    const form = data.form;
    const type = data.type;
    const target = data.target;
    // 해당 클레임 조회

    const claim = await targetClaimFind(this.state.auth_company, form._id);
    const saveClaim = claim.data[0];

    const saveForm = { ...saveClaim };

    //클레임 리스트 조회
    const findClaim = await getClaim(this.state.auth_company);
    const claimList = findClaim.data;
    // 정산데이터 조회
    const selectOrder = await getSelectIdOrder(
      this.state.auth_company,
      form.order,
    );

    let targetReleaseList = {};
    let targetOrder = {};
    let targetRelease = {};
    let targetReleaseIndex = "";
    if (type === "end") {
      if (target === "arise") {
        saveForm.arise_status = this.state.auth_grade >= 2 ? "00" : "60";
        saveForm.arise_isEnd = true;
        // if (saveForm.pass_id)
        //   saveForm.pass_status = this.state.auth_grade >= 2 ? "00" : "60";
      }
      if (target === "pass") {
        saveForm.pass_status = this.state.auth_grade >= 2 ? "00" : "60";
        saveForm.pass_isEnd = true;
        // if (saveForm.arise_id)
        //   saveForm.arise_status = this.state.auth_grade >= 2 ? "00" : "60";
      }

      if (this.state.auth_grade <= 2) {
        saveForm.saveData = { ...saveClaim };
      } else saveForm.isEndCase = true;
    }
    if (type === "delete" || type === "cancel") {
      let saveStatCheck = "";
      if (selectOrder.data.length) {
        targetOrder = selectOrder.data[0];
        targetReleaseList = targetOrder.release;
        saveForm.item = targetOrder.item;
        saveForm.buyer = targetOrder.client;
        const saveType = {
          newOrder: false,
          orderChange: false,
          modifyRelease: false,
          type: false,
          subType: false,
          addType: false,
          releaseEnd: false,
        };

        targetRelease = targetReleaseList.find(
          (x) =>
            x.claim ===
            (target === "arise" ? saveForm.arise_id : saveForm.pass_id),
        );

        targetReleaseIndex = targetReleaseList.findIndex(
          (x) =>
            x.claim ===
            (target === "arise" ? saveForm.arise_id : saveForm.pass_id),
        );
      }

      if (targetRelease && Object.keys(targetRelease).length) {
        saveStatCheck =
          Object.keys(targetRelease.purchase).length > 2
            ? targetRelease.purchase.status
            : targetRelease.sales.status;

        targetRelease.saveType =
          Object.keys(targetRelease.purchase).length > 2 ? "매입" : "매출";
      }

      if (target === "arise") {
        if (type === "delete") {
          saveForm.arise_id =
            this.state.auth_grade >= 2
              ? `${saveForm.arise_id}_delete_${numFromat(
                  claimList.filter((x) => x.arise_status === "99").length + 1,
                )}`
              : saveForm.arise_id;
          saveForm.arise_status = this.state.auth_grade >= 2 ? "99" : "10";
        }
        if (type === "cancel") {
          if (this.state.auth_grade >= 2) saveForm.isEndCase = false;
          saveForm.arise_status = this.state.auth_grade >= 2 ? "40" : "90";
          if (this.state.auth_grade >= 2) saveForm.arise_isEnd = false;
        }
      } else {
        if (type === "delete") {
          saveForm.pass_id =
            this.state.auth_grade >= 2
              ? `${saveForm.pass_id}_delete_${numFromat(
                  claimList.filter((x) => x.pass_status === "99").length + 1,
                )}`
              : saveForm.pass_id;
          saveForm.pass_status = this.state.auth_grade >= 2 ? "99" : "10";
        }
        if (type === "cancel") {
          if (this.state.auth_grade >= 2) saveForm.isEndCase = false;
          saveForm.pass_status = this.state.auth_grade >= 2 ? "40" : "90";
          if (this.state.auth_grade >= 2) saveForm.pass_isEnd = false;
        }
      }

      if (this.state.auth_grade <= 2) {
        saveForm.saveData = { ...saveForm };
        if (type === "delete" || type === "cancel" || type === "end") {
          if (targetReleaseIndex !== -1) targetRelease.status = "20";
        }
      } else {
        if (!_.isEmpty(targetRelease)) {
          if (type === "delete") {
            if (targetReleaseIndex !== -1)
              targetReleaseList.splice(targetReleaseIndex, 1);
          }
          if (type === "cancel") {
            if (targetRelease.saveType === "매입")
              targetRelease.purchase.status = "40";
            else targetRelease.sales.status = "40";
            targetRelease.status = "40";
          }

          if (saveStatCheck === "00" || type === "delete" || type === "cancel")
            commit("setClaimReleaseForm", targetRelease);
        }
      }
    }

    commit("setClaimForm", saveForm);
    commit("setSaveData", targetOrder);
  },

  CLAIM_MODIFY_TYPE_CHECK({ commit }, data) {
    const form = data.form;
    const saveClaim = data.saveClaim;
    const saveType = data.saveType;
    const target = data.target;
    const release = data.beforeOrder && data.beforeOrder.release;

    const saveFormOrder =
      form.order !== "미선택"
        ? typeof form.order === "object"
          ? form.order._id
          : this.state.orderList.find((x) => x.order === form.order)._id
        : "미선택";

    const beforeClient =
      target === "arise" ? saveClaim.arise_client : saveClaim.pass_client;
    const afterClient =
      target === "arise"
        ? "" + form.arise_client._id
        : "" + form.pass_client._id;

    const beforePrice =
      target === "arise" ? saveClaim.arise_price : saveClaim.pass_price;
    const afterPrice = target === "arise" ? form.arise_price : form.pass_price;

    const beforeEscPrice =
      target === "arise" ? saveClaim.arise_eschamge : saveClaim.pass_eschamge;
    const afterEscPrice =
      target === "arise" ? form.arise_eschamge : form.pass_eschamge;

    const beforeMonth =
      target === "arise"
        ? dayjs(saveClaim.arise_month).format("YYYY-MM")
        : dayjs(saveClaim.pass_month).format("YYYY-MM");
    const afterMonth = target === "arise" ? form.arise_month : form.pass_month;

    const beforeType =
      target === "arise" ? saveClaim.arise_type : saveClaim.pass_type;
    const afterType = target === "arise" ? form.arise_type : form.pass_type;

    const beforeDetail =
      target === "arise" ? saveClaim.arise_detail : saveClaim.pass_detail;
    const afterDetail =
      target === "arise" ? form.arise_detail : form.pass_detail;

    if (!saveClaim.order && form.order !== "미선택") saveType.newOrder = true;

    if (form.order !== "미선택" && saveClaim.order !== saveFormOrder)
      saveType.orderChange = true;

    if (
      beforeClient !== afterClient ||
      beforePrice !== afterPrice ||
      beforeMonth !== afterMonth ||
      beforeEscPrice !== afterEscPrice ||
      beforeDetail !== afterDetail
    )
      saveType.modifyRelease = true;

    if (
      beforeType !== 1 &&
      beforeType !== 4 &&
      beforeType !== afterType &&
      afterType !== 1 &&
      afterType !== 4
    )
      saveType.type = true;

    if ((beforeType === 1 || beforeType === 4) && beforeType !== afterType)
      saveType.addType = true;

    if (beforeType !== 1 && (afterType === 1 || afterType === 4))
      saveType.subType = true;

    if (release) {
      const beforeRelease = release.find(
        (x) =>
          x.claim ===
          (target === "arise" ? saveClaim.arise_id : saveClaim.pass_id),
      );
      const releaseType = beforeType === 2 ? "purchase" : "sales";
      if (beforeRelease && beforeRelease[releaseType].status === "00")
        saveType.releaseEnd = true;
    }
  },

  // async TASK_CLAIM_SAVE({ commit }, form) {
  //   const type = form.type;
  //   const target = form.target;
  //   const saveForm = form.saveForm;
  //   this.commit("setBeforeOrder");
  //   this.commit("setAfterOrder");

  //   commit("setClaimForm", saveForm);
  //   this.dispatch("SET_API_LOADING", true);
  //   if (type === "create") await this.dispatch("CREATE_CLAIM_PARSE", target);
  //   if (type === "process") await this.dispatch("PROCESS_CLAIM_PARSE", target);
  //   if (type === "modify") await this.dispatch("MODIFY_CLAIM_PARSE", target);

  //   if (type === "userModify")
  //     await this.dispatch("USER_MODIFY_CLAIM_PARSE", target);
  //   await this.dispatch("SET_CLAIM_APPROVAL_SAVE_DATA", target);

  //   try {
  //     if (this.state.auth_grade >= 2 && this.state.beforeOrder._id)
  //       await this.dispatch("SAVE_BACK_CLAIM_RELEASE");
  //     if (this.state.auth_grade >= 2 && this.state.afterOrder._id)
  //       await this.dispatch("SAVE_AFTER_CLAIM_RELEASE");

  //     await this.dispatch("SAVE_CLAIM");
  //   } catch (e) {
  //     alert("오더에 변경사항이 있습니다. 다시 저장해주세요.");
  //     await this.dispatch("SET_ORDERLIST_DATA");
  //     await this.dispatch("SET_CLAIMLIST");
  //     this.dispatch("SET_API_LOADING", false);
  //     return;
  //   }

  //   //초기화

  //   this.commit("setBeforeOrder");
  //   this.commit("setAfterOrder");
  //   this.commit("setApprovalForm");
  //   await this.dispatch("SELECT_APPROVAL_LIST");
  //   this.commit("setClaimForm");
  //   if (type === "create") this.commit("clearClaimNewRows");
  //   this.dispatch("SET_API_LOADING", false);
  //   await this.dispatch("SET_CLAIMLIST");
  // },
  // 신규 클레임생성
  async CREATE_CLAIM_PARSE({ commit }, target) {
    const form = this.state.claimForm;
    praseSaveDataComma(form);
    //생성된 클레임 목록 조회
    const findClaim = await getClaim(this.state.auth_company);
    const targetClaim = findClaim.data;

    const selectOrder = await getSelectIdOrder(
      this.state.auth_company,
      form.order.order,
    );

    const targetOrder = selectOrder.data[0];

    let NumList = [];

    if (targetClaim.length)
      NumList = targetClaim.map((x) => x.id.split("_")[1]);
    const maxClaimId = NumList.length ? Math.max(...NumList) : 0;

    //approval 용
    form.buyer = form.order.client ? form.order.client : "";
    form.item = form.order.item ? form.order.item : "";
    //생성 클레임 데이터 파싱
    if (form.order.order === "미선택") delete form.order;
    else {
      const order = form.order ? form.order.order : "";
      form.order = typeof form.order === "object" ? form.order._id : order._id;
    }
    if (form.liaison.name === "미선택") delete form.liaison;
    else
      form.liaison =
        typeof form.liaison === "object"
          ? form.liaison._id
          : this.state.userList.find((x) => x.name === form.liaison)._id;
    form.id = `claim_${numFromat(maxClaimId + 1)}`;
    if (target === "arise") {
      let arise_NumList = [];
      if (targetClaim)
        arise_NumList = targetClaim.map((x) =>
          x.arise_id ? x.arise_id.split("_")[2] : 0,
        );

      const maxAriseId = arise_NumList.length ? Math.max(...arise_NumList) : 0;

      form.arise_id = `${this.state.auth_company}_arise_${numFromat(
        maxAriseId + 1,
      )}`;
      form.arise_month = new Date(`${form.arise_month}-02`);
      delete form.pass_month;
      form.arise_date_modify = new Date(Date.now()); // 수정 날짜
      form.arise_date_register = new Date(Date.now()); // 저장 날짜
      form.arise_status = this.state.auth_grade >= 2 ? "40" : "20"; // 문서
      form.arise_client =
        typeof form.arise_client === "object"
          ? form.arise_client._id
          : this.state.clientList.find((x) => x.name === form.arise_client)._id;
      form.arise_price = +form.arise_price;
      form.arise_year = dayjs().get("year");
      form.arise_isEnd = false;
      form.arise_kind = 1;
      form.arise_type =
        typeof form.arise_type === "string"
          ? `${
              form.arise_type === "매출"
                ? 3
                : form.arise_type === "매입"
                ? 2
                : 1
            }`
          : form.arise_type;
    } else {
      let pass_NumList = [];
      if (targetClaim)
        pass_NumList = targetClaim.map((x) =>
          x.pass_id ? x.pass_id.split("_")[2] : 0,
        );
      const maxPassId = pass_NumList.length ? Math.max(...pass_NumList) : 0;
      form.pass_id = `${this.state.auth_company}_pass_${numFromat(
        maxPassId + 1,
      )}`;
      form.pass_month = new Date(`${form.pass_month}-02`);
      delete form.arise_month;
      form.pass_date_modify = new Date(Date.now()); // 수정 날짜
      form.pass_date_register = new Date(Date.now()); // 저장 날짜
      form.pass_status = this.state.auth_grade >= 2 ? "40" : "20"; // 문서
      form.pass_client =
        typeof form.pass_client === "object"
          ? form.pass_client._id
          : this.state.clientList.find((x) => x.name === form.pass_client)._id;
      form.pass_price = +form.pass_price;
      form.pass_year = dayjs().get("year");
      form.pass_isEnd = false;
      form.pass_kind = 2;
      form.pass_type =
        typeof form.pass_type === "string"
          ? `${
              form.pass_type === "매출" ? 3 : form.pass_type === "매입" ? 2 : 1
            }`
          : form.pass_type;
    }
    form.saveData = { ...form };

    form.company = this.state.auth_company;
    if (selectOrder.data.length) {
      let targetReleaseList = targetOrder.release;
      await this.dispatch("CLAIM_RELEASE_PARSE", {
        form: form,
        target: target,
        release: targetReleaseList,
      });

      if (this.state.claimReleaseForm.id && this.state.auth_grade >= 2)
        targetReleaseList.push(this.state.claimReleaseForm);

      this.commit("setBeforeOrder", targetOrder);
    }

    this.commit("setClaimForm", form);
  },

  async CLAIM_RELEASE_PARSE({ commit }, data) {
    commit("setClaimReleaseForm");
    commit("setOtherClaimReleaseForm");

    let releaseForm = {};
    const form = data.form;
    const target = data.target;
    const otherStatus = data.other;
    const beforeStatus = data.beforeStatus;
    try {
      const findOrder = await getSelectOrder(
        this.state.auth_company,
        form.order,
      );

      const order = findOrder && findOrder.data[0];

      const release = findOrder && order.release;

      let NumList = 0;
      if (release.length) {
        NumList = order.release.map((x) => x.id.split("_")[2]);
      }
      const releaseLength = NumList.length ? Math.max(...NumList) : 0;
      const targetTypeCheck =
        (target === "arise" &&
          form.arise_type !== 1 &&
          form.arise_type !== 4) ||
        (target === "pass" && form.pass_type !== 1 && form.pass_type !== 4)
          ? true
          : false;
      if (targetTypeCheck) {
        releaseForm = {
          company: this.state.auth_company,
          id: `${order.order}_claimRelease_${numFromat(releaseLength + 1)}`,
          liaison: form.liaison,
          order_kind: 2,
          order: order.order,
          status: "40",
          date_delivery: new Date(
            target === "arise"
              ? form.arise_month
              : target === "pass"
              ? form.pass_month
              : "",
          ),
          date_register: new Date(Date.now()),
          claim:
            target === "arise"
              ? form.arise_id
              : target === "pass"
              ? form.pass_id
              : "",
          currency_sign:
            target === "arise"
              ? form.arise_currency_sign
              : target === "pass"
              ? form.pass_currency_sign
              : "",

          purchase:
            (target === "arise" && form.arise_type === 2) ||
            (target === "pass" && form.pass_type === 2)
              ? {
                  total_amount:
                    target === "arise"
                      ? +form.arise_price
                      : target === "pass"
                      ? +form.pass_price
                      : "",
                  color_list: [],
                  status:
                    target === "arise" && form.arise_status === "00"
                      ? form.arise_status
                      : target === "pass" && form.pass_status === "00"
                      ? form.pass_status
                      : beforeStatus
                      ? beforeStatus
                      : "40",

                  eschamge_price:
                    target === "arise"
                      ? form.arise_eschamge
                        ? parseFloat(+form.arise_price * +form.arise_eschamge)
                            .toFixed(2)
                            .replace(/(.?0+$)/, "")
                        : 0
                      : target === "pass"
                      ? form.pass_eschamge
                        ? parseFloat(+form.pass_price * +form.pass_eschamge)
                            .toFixed(2)
                            .replace(/(.?0+$)/, "")
                        : 0
                      : "",

                  currency_sign:
                    target === "arise"
                      ? form.arise_currency_sign
                      : target === "pass"
                      ? form.pass_currency_sign
                      : "",

                  memo:
                    target === "arise"
                      ? form.arise_detail
                      : target === "pass"
                      ? form.pass_detail
                      : "",
                }
              : {},
          sales:
            (target === "arise" && form.arise_type === 3) ||
            (target === "pass" && form.pass_type === 3)
              ? {
                  total_amount:
                    target === "arise"
                      ? +form.arise_price
                      : target === "pass"
                      ? +form.pass_price
                      : "",
                  color_list: [],
                  status:
                    target === "arise" && form.arise_status === "00"
                      ? form.arise_status
                      : target === "pass" && form.pass_status === "00"
                      ? form.pass_status
                      : beforeStatus
                      ? beforeStatus
                      : "40",

                  eschamge_price:
                    target === "arise"
                      ? form.arise_eschamge
                        ? parseFloat(+form.arise_price * +form.arise_eschamge)
                            .toFixed(2)
                            .replace(/(.?0+$)/, "")
                        : 0
                      : target === "pass"
                      ? form.pass_eschamge
                        ? parseFloat(+form.pass_price * +form.pass_eschamge)
                            .toFixed(2)
                            .replace(/(.?0+$)/, "")
                        : 0
                      : "",
                  currency_sign:
                    target === "arise"
                      ? form.arise_currency_sign
                      : target === "pass"
                      ? form.pass_currency_sign
                      : "",

                  memo:
                    target === "arise"
                      ? form.arise_detail
                      : target === "pass"
                      ? form.pass_detail
                      : "",
                }
              : {},
        };
        if (
          (target === "arise" && form.arise_type === 2) ||
          (target === "pass" && form.pass_type === 2)
        )
          releaseForm.place_forward =
            target === "arise"
              ? form.arise_client
              : target === "pass"
              ? form.pass_client
              : "";
        if (
          (target === "arise" && form.arise_type === 3) ||
          (target === "pass" && form.pass_type === 3)
        )
          releaseForm.place_sales =
            target === "arise"
              ? form.arise_client
              : target === "pass"
              ? form.pass_client
              : "";

        commit(
          `${otherStatus ? "setOtherClaimReleaseForm" : "setClaimReleaseForm"}`,
          releaseForm,
        );
      }
    } catch (e) {
      console.log(e);
      this.commit("setErrorStatus", true);
    }
  },
  async OTHER_CLAIM_RELEASE_PARSE({ commit }, data) {
    commit("setClaimReleaseForm");
    commit("setOtherClaimReleaseForm");

    let releaseForm = {};
    const form = data.form;
    const target = data.target;
    const otherStatus = data.other;
    const beforeStatus = data.beforeStatus;
    try {
      const findOrder = await getSelectOrder(
        this.state.auth_company,
        form.order,
      );

      const order = findOrder && findOrder.data[0];
      const release = findOrder && order.release;
      let NumList = 0;
      if (release.length) {
        NumList = order.release.map((x) => x.id.split("_")[2]);
      }
      const releaseLength = NumList.length ? Math.max(...NumList) : 0;
      const targetTypeCheck =
        (target === "arise" && form.arise_type !== 1) ||
        (target === "pass" && form.pass_type !== 1)
          ? true
          : false;
      if (targetTypeCheck) {
        releaseForm = {
          company: this.state.auth_company,
          id: `${order.order}_claimRelease_${numFromat(
            releaseLength + (otherStatus ? 2 : 1),
          )}`,
          liaison: form.liaison,
          order_kind: 2,
          order: order.order,
          status: "40",
          date_delivery: new Date(
            target === "arise"
              ? form.arise_month
              : target === "pass"
              ? form.pass_month
              : "",
          ),
          date_register: new Date(Date.now()),
          claim:
            target === "arise"
              ? form.arise_id
              : target === "pass"
              ? form.pass_id
              : "",
          currency_sign:
            target === "arise"
              ? form.arise_currency_sign
              : target === "pass"
              ? form.pass_currency_sign
              : "",

          purchase:
            (target === "arise" && form.arise_type === 2) ||
            (target === "pass" && form.pass_type === 2)
              ? {
                  total_amount:
                    target === "arise"
                      ? +form.arise_price
                      : target === "pass"
                      ? +form.pass_price
                      : "",
                  color_list: [],
                  status:
                    target === "arise" && form.arise_status === "00"
                      ? form.arise_status
                      : target === "pass" && form.pass_status === "00"
                      ? form.pass_status
                      : beforeStatus
                      ? beforeStatus
                      : "40",

                  eschamge_price:
                    target === "arise"
                      ? form.arise_eschamge
                        ? parseFloat(+form.arise_price * +form.arise_eschamge)
                            .toFixed(2)
                            .replace(/(.?0+$)/, "")
                        : 0
                      : target === "pass"
                      ? form.pass_eschamge
                        ? parseFloat(+form.pass_price * +form.pass_eschamge)
                            .toFixed(2)
                            .replace(/(.?0+$)/, "")
                        : 0
                      : "",

                  currency_sign:
                    target === "arise"
                      ? form.arise_currency_sign
                      : target === "pass"
                      ? form.pass_currency_sign
                      : "",

                  date:
                    target === "arise"
                      ? form.arise_date_register
                      : target === "pass"
                      ? form.pass_date_register
                      : "",
                  memo:
                    target === "arise"
                      ? form.arise_detail
                      : target === "pass"
                      ? form.pass_detail
                      : "",
                }
              : {},
          sales:
            (target === "arise" && form.arise_type === 3) ||
            (target === "pass" && form.pass_type === 3)
              ? {
                  total_amount:
                    target === "arise"
                      ? +form.arise_price
                      : target === "pass"
                      ? +form.pass_price
                      : "",
                  color_list: [],
                  status:
                    target === "arise" && form.arise_status === "00"
                      ? form.arise_status
                      : target === "pass" && form.pass_status === "00"
                      ? form.pass_status
                      : beforeStatus
                      ? beforeStatus
                      : "40",

                  eschamge_price:
                    target === "arise"
                      ? form.arise_eschamge
                        ? parseFloat(+form.arise_price * +form.arise_eschamge)
                            .toFixed(2)
                            .replace(/(.?0+$)/, "")
                        : 0
                      : target === "pass"
                      ? form.pass_eschamge
                        ? parseFloat(+form.pass_price * +form.pass_eschamge)
                            .toFixed(2)
                            .replace(/(.?0+$)/, "")
                        : 0
                      : "",
                  currency_sign:
                    target === "arise"
                      ? form.arise_currency_sign
                      : target === "pass"
                      ? form.pass_currency_sign
                      : "",
                  date:
                    target === "arise"
                      ? form.arise_date_register
                      : target === "pass"
                      ? form.pass_date_register
                      : "",
                  memo:
                    target === "arise"
                      ? form.arise_detail
                      : target === "pass"
                      ? form.pass_detail
                      : "",
                }
              : {},
        };
        if (
          (target === "arise" && form.arise_type === 2) ||
          (target === "pass" && form.pass_type === 2)
        )
          releaseForm.place_forward =
            target === "arise"
              ? form.arise_client
              : target === "pass"
              ? form.pass_client
              : "";
        if (
          (target === "arise" && form.arise_type === 3) ||
          (target === "pass" && form.pass_type === 3)
        )
          releaseForm.place_sales =
            target === "arise"
              ? form.arise_client
              : target === "pass"
              ? form.pass_client
              : "";

        commit("setOtherClaimReleaseForm", releaseForm);
      }
    } catch (e) {
      console.log(e);
      this.commit("setErrorStatus", true);
    }
  },
  // 생성된 클레임의 발생 or 전가 등록
  async PROCESS_CLAIM_PARSE({ commit }, target) {
    try {
      const form = this.state.claimForm;
      praseSaveDataComma(form);
      //클레임 번호 매핑
      const findClaim = await getClaim(this.state.auth_company);

      const claimList = findClaim.data;
      const findOrder = await getSelectIdOrder(
        this.state.auth_company,
        form.order,
      );
      const order = findOrder.data[0];

      const claim = await targetClaimFind(this.state.auth_company, form._id);
      const saveClaim = claim.data[0];

      if (order) {
        saveClaim.item = order.item;
        saveClaim.buyer = order.client;
      }
      if (target === "arise") {
        let arise_NumList = [];
        if (claimList)
          arise_NumList = claimList.map((x) =>
            x.arise_id ? x.arise_id.split("_")[2] : 0,
          );
        const maxAriseId = arise_NumList.length
          ? Math.max(...arise_NumList)
          : 0;
        saveClaim.arise_id = `${this.state.auth_company}_arise_${numFromat(
          maxAriseId + 1,
        )}`;
        saveClaim.arise_date_modify = new Date(Date.now()); // 수정 날짜
        saveClaim.arise_date_register = new Date(Date.now()); // 저장 날짜
        saveClaim.arise_status = this.state.auth_grade >= 2 ? "40" : "20"; // 문서
        saveClaim.arise_client =
          typeof form.arise_client === "object"
            ? form.arise_client._id
            : this.state.clientList.find((x) => x.name === form.arise_client)
                ._id;
        saveClaim.arise_price = +form.arise_price;
        saveClaim.arise_month = new Date(`${form.arise_month}-02`);

        saveClaim.arise_year = dayjs().get("year");
        saveClaim.arise_isEnd = false;
        saveClaim.arise_kind = 1;
        saveClaim.arise_type = form.arise_type;
        saveClaim.arise_currency_sign = form.arise_currency_sign;
        saveClaim.arise_detail = form.arise_detail;
        saveClaim.arise_carried = false;
        if (form.arise_eschamge)
          saveClaim.arise_eschamge = +form.arise_eschamge;
      } else {
        let pass_NumList = [];
        if (claimList)
          pass_NumList = claimList.map((x) =>
            x.pass_id ? x.pass_id.split("_")[2] : 0,
          );
        const maxPassId = pass_NumList.length ? Math.max(...pass_NumList) : 0;

        saveClaim.pass_id = `${this.state.auth_company}_pass_${numFromat(
          maxPassId + 1,
        )}`;
        saveClaim.pass_month = new Date(`${form.pass_month}-02`);
        saveClaim.pass_date_modify = new Date(Date.now()); // 수정 날짜
        saveClaim.pass_date_register = new Date(Date.now()); // 저장 날짜
        saveClaim.pass_status = this.state.auth_grade >= 2 ? "40" : "20"; // 문서
        saveClaim.pass_client =
          typeof form.pass_client === "object"
            ? form.pass_client._id
            : this.state.clientList.find((x) => x.name === form.pass_client)
                ._id;

        saveClaim.pass_price = +form.pass_price;
        saveClaim.pass_year = dayjs().get("year");
        saveClaim.pass_isEnd = false;
        saveClaim.pass_kind = 2;
        saveClaim.pass_type = form.pass_type;
        saveClaim.pass_currency_sign = form.pass_currency_sign;
        saveClaim.pass_detail = form.pass_detail;
        saveClaim.pass_carried = false;
        if (form.pass_eschamge) saveClaim.pass_eschamge = +form.pass_eschamge;
      }
      saveClaim.saveData = { ...saveClaim };

      saveClaim.company = this.state.auth_company;
      if (findOrder.data.length) {
        let targetReleaseList = order.release;
        await this.dispatch("CLAIM_RELEASE_PARSE", {
          form: saveClaim,
          target: target,
          release: targetReleaseList,
        });

        if (this.state.claimReleaseForm.id && this.state.auth_grade >= 2)
          targetReleaseList.push(this.state.claimReleaseForm);

        commit("setBeforeOrder", order);
      }

      this.commit("setClaimForm", saveClaim);
    } catch (e) {
      console.log(e);
      this.commit("setErrorStatus", true);
    }
  },

  async MODIFY_CLAIM_PARSE({ commit }, target) {
    const form = this.state.claimForm;
    praseSaveDataComma(form);
    //해당 클레임 조회
    const claim = await targetClaimFind(this.state.auth_company, form._id);

    const saveClaim = claim && claim.data[0];
    const saveType = {
      newOrder: false,
      orderChange: false,
      modifyRelease: false,
      type: false,
      subType: false,
      addType: false,
      releaseEnd: false,
    };

    let beforeOrder = {};
    let afterOrder = {};

    if (saveClaim.order) {
      const beforeFindOrder = await getSelectIdOrder(
        this.state.auth_company,
        form.before_order ? form.before_order : form.order.order,
      );
      beforeOrder = beforeFindOrder.data[0];
    }

    //저장 타입 구분
    await this.dispatch("CLAIM_MODIFY_TYPE_CHECK", {
      saveType: saveType,
      saveClaim: saveClaim,
      form: form,
      target: target,
      beforeOrder: beforeOrder,
    });

    if (saveType.orderChange) {
      const afterFindOrder = await getSelectIdOrder(
        this.state.auth_company,
        form.order.order,
      );
      afterOrder = afterFindOrder.data[0];
    }

    //유저 권한일 경우 저장
    const userData = { ...saveClaim };

    //클레임폼 파싱
    await this.dispatch("CLAIM_MODIFY_FORM_PARSE", {
      target: target,
      saveClaim: saveClaim,
      form: form,
    });

    if (this.state.auth_grade <= 2) {
      userData.saveData = { ...this.state.claimModifyForm };
      const order = this.state.orderList.find((x) => x._id === form.orderId);

      if (order) userData.item = order.item;
      if (order) userData.buyer = order.client;

      target === "arise"
        ? (userData.arise_status = "50")
        : (userData.pass_status = "50");
      if (beforeOrder.order) userData.item = beforeOrder.item;
    }

    if ((beforeOrder && beforeOrder.order) || (afterOrder && afterOrder.order))
      await this.dispatch("MODIFY_CLAIM_RELEASE_PARSE", {
        target: target,
        saveType: saveType,
        beforeRelease: beforeOrder && beforeOrder.release,
        afterRelease: afterOrder && afterOrder.release,
        claim: saveClaim,
      });

    if (beforeOrder && beforeOrder._id)
      this.commit("setBeforeOrder", beforeOrder);
    if (afterOrder && afterOrder._id) this.commit("setAfterOrder", afterOrder);

    this.commit(
      "setClaimForm",
      this.state.auth_grade >= 2 ? this.state.claimModifyForm : userData,
    );
  },
  async MODIFY_CLAIM_RELEASE_PARSE({ commit }, data) {
    this.commit("setClaimReleaseForm");
    const target = data.target;
    const saveType = data.saveType;
    const beforeRelease = data.beforeRelease;
    const afterRelease = data.afterRelease;
    const claim = data.claim;

    if (saveType.orderChange && afterRelease) {
      await this.dispatch("CLAIM_RELEASE_PARSE", {
        form: claim,
        target: target,
        release: beforeRelease,
        other: true,
      });
      // 이전 전가나 발생 수정시 반대 데이터도 같이 추가
      if (this.state.otherClaimReleaseForm.id)
        afterRelease.push(this.state.otherClaimReleaseForm);
      if (
        (target === "arise" &&
          claim.pass_id &&
          claim.pass_type !== 1 &&
          claim.pass_type !== 4) ||
        (target === "pass" &&
          claim.arise_id &&
          claim.arise_type !== 1 &&
          claim.arise_type !== 4)
      ) {
        const otherBeforeIndex =
          beforeRelease &&
          beforeRelease.findIndex(
            (x) =>
              x.claim === (target === "arise" ? claim.pass_id : claim.arise_id),
          );

        await this.dispatch("OTHER_CLAIM_RELEASE_PARSE", {
          form: claim,
          target: target === "pass" ? "arise" : "pass",
          release: beforeRelease,
          other: true,
          beforeStatus: beforeRelease && beforeRelease[otherBeforeIndex].status,
        });
        if (this.state.otherClaimReleaseForm.id)
          afterRelease.push(this.state.otherClaimReleaseForm);
      }
    }

    const beforeIndex =
      beforeRelease &&
      beforeRelease.findIndex(
        (x) =>
          x.claim === (target === "arise" ? claim.arise_id : claim.pass_id),
      );
    if (beforeRelease) {
      await this.dispatch("CLAIM_RELEASE_PARSE", {
        form: claim,
        target: target,
        release: beforeRelease,
        beforeStatus: "40",
      });
      const beforeIndex =
        beforeRelease &&
        beforeRelease.findIndex(
          (x) =>
            x.claim === (target === "arise" ? claim.arise_id : claim.pass_id),
        );

      if (beforeIndex !== undefined && beforeIndex !== -1)
        beforeRelease.splice(beforeIndex, 1);
      if (saveType.orderChange) {
        const otherBeforeIndex =
          beforeRelease &&
          beforeRelease.findIndex(
            (x) =>
              x.claim === (target === "arise" ? claim.pass_id : claim.arise_id),
          );

        if (otherBeforeIndex !== undefined && otherBeforeIndex !== -1)
          beforeRelease.splice(otherBeforeIndex, 1);
      }

      if (
        this.state.claimReleaseForm.id &&
        !saveType.subType &&
        !saveType.orderChange
      )
        if (this.state.claimReleaseForm.id)
          beforeRelease.push(this.state.claimReleaseForm);
    }
  },

  async USER_MODIFY_CLAIM_PARSE({ commit }, target) {
    const form = this.state.claimForm;
    praseSaveDataComma(form);
    const claim = await targetClaimFind(this.state.auth_company, form._id);
    const saveClaim = { ...claim.data[0] };

    let beforeOrder = {};
    const saveType = {
      newOrder: false,
      orderChange: false,
      modifyRelease: false,
      type: false,
      subType: false,
      addType: false,
      releaseEnd: false,
    };
    if (saveClaim.order) {
      const beforeFindOrder = await getSelectIdOrder(
        this.state.auth_company,
        form.before_order ? form.before_order : form.order.order,
      );
      beforeOrder = beforeFindOrder.data[0];
    }
    await this.dispatch("CLAIM_MODIFY_TYPE_CHECK", {
      saveType: saveType,
      saveClaim: saveClaim,
      form: form,
      target: target,
      beforeOrder: beforeOrder,
    });
    const userData = { ...saveClaim };
    await this.dispatch("CLAIM_MODIFY_FORM_PARSE", {
      target: target,
      saveClaim: saveClaim,
      form: form,
    });
    userData.saveData = { ...this.state.claimModifyForm };
    target === "arise"
      ? (userData.arise_status = "50")
      : (userData.pass_status = "50");
    if (beforeOrder.order) userData.item = beforeOrder.item;
    const order = this.state.orderList.find((x) => x._id === userData.order);
    if (order) userData.item = order.item;
    if (order) userData.buyer = order.client;

    const release = beforeOrder.release;
    const beforeIndex =
      release &&
      release.findIndex(
        (x) =>
          x.claim ===
          (target === "arise" ? saveClaim.arise_id : saveClaim.pass_id),
      );
    if (beforeIndex !== undefined && beforeIndex !== -1)
      release[beforeIndex].status = 20;

    if (saveType.orderChange) {
      const otherIndex =
        release &&
        release.findIndex(
          (x) =>
            x.claim ===
            (target === "arise" ? saveClaim.pass_id : saveClaim.arise_id),
        );
      if (otherIndex !== undefined && otherIndex !== -1)
        release[otherIndex].status = 20;
    }
    if (beforeOrder && beforeOrder._id)
      this.commit("setBeforeOrder", beforeOrder);
    this.commit("setClaimForm", userData);
  },
  async MODIFY_CHANGE_CLAIM_RELEASE_PARSE({ commit }, data) {
    this.commit("setClaimReleaseForm");
    const target = data.target;
    const saveType = data.saveType;
    const beforeOrder = data.beforeOrder;
    const afterOrder = data.afterOrder;
    const release = data.beforeOrder.release;
    const afterRelease = data.afterOrder.release;
    const beforeClaim = data.beforeClaim;
    const saveClaim = data.saveClaim;

    const beforeRelease = release.find(
      (x) =>
        x.claim ===
        (target === "arise" ? beforeClaim.arise_id : beforeClaim.pass_id),
    );

    if (beforeRelease) {
      const firstReleaseIndex = beforeOrder.release.findIndex(
        (x) =>
          x.claim ===
          (target === "arise" ? beforeClaim.arise_id : beforeClaim.pass_id),
      );

      await this.dispatch("CLAIM_RELEASE_PARSE", {
        form: saveClaim,
        target: target,
        release: release,
        beforeStatus: release[firstReleaseIndex].status,
      });
      release.splice(firstReleaseIndex, 1);
      if (Object.keys(this.state.claimReleaseForm).length)
        afterRelease.push(this.state.claimReleaseForm);
      if (beforeClaim.arise_id && beforeClaim.pass_id) {
        const secReleaseIndex = beforeOrder.release.findIndex(
          (x) =>
            x.claim ===
            (target === "arise" ? beforeClaim.pass_id : beforeClaim.arise_id),
        );

        await this.dispatch("CLAIM_RELEASE_PARSE", {
          form: saveClaim,
          target: target === "arise" ? "pass" : "arise",
          release: release,
          beforeStatus: release[secReleaseIndex].status,
        });
        release.splice(secReleaseIndex, 1);
        if (Object.keys(this.state.claimReleaseForm).length)
          afterRelease.push(this.state.claimReleaseForm);
      }

      const beforeType =
        target === "arise" ? beforeClaim.arise_type : beforeClaim.pass_type;

      if (saveType.releaseEnd) {
        if (beforeType === "purchase") {
          await this.dispatch("DELETE_RECIVABLE_AMOUNT", {
            release: beforeRelease,
            kind: beforeType,
          });
          await this.dispatch("MAKE_RECIVABLE_AMOUNT", {
            release: beforeRelease,
            type: beforeType,
          });
          await this.dispatch("SAVE_RECIVABLE_AMOUNT");
        }
      }
    }
  },

  async CLAIM_END_FORM_PARSE({ commit }, data) {
    this.commit("setClaimForm");
    await this.dispatch("SELECT_TYPE_CLAIM", data.claim);
    const saveForm = this.state.selectClaim;

    if (data.claim.split("_")[1] === "arise") {
      saveForm.arise_status = this.state.auth_grade >= 2 ? "00" : "60";
      saveForm.arise_isEnd = this.state.auth_grade >= 2 ? true : false;
    } else {
      saveForm.pass_status = this.state.auth_grade >= 2 ? "00" : "60";
      saveForm.pass_isEnd = this.state.auth_grade >= 2 ? true : false;
    }

    commit("setClaimForm", saveForm);
  },
  async CLAIM_CANCEL_FORM_PARSE({ commit }, data) {
    this.commit("setClaimForm");
    await this.dispatch("SELECT_TYPE_CLAIM", data.claim);
    const saveForm = this.state.selectClaim;
    if (data.claim.split("_")[1] === "arise") {
      saveForm.arise_status = "40";
      saveForm.arise_isEnd = false;
    } else {
      saveForm.pass_status = "40";
      saveForm.pass_isEnd = false;
    }
    saveForm.isEndCase = false;
    commit("setClaimForm", saveForm);
  },
  CLAIM_MODIFY_FORM_PARSE({ commit }, data) {
    const saveClaim = data.saveClaim;
    const form = data.form;
    const target = data.target;

    if (form.order !== "미선택")
      saveClaim.order =
        form.order !== "미선택"
          ? typeof form.order === "object"
            ? form.order._id
            : this.state.orderList.find((x) => x.order === form.order)._id
          : "";

    if (form.liaison !== "미선택")
      saveClaim.liaison =
        form.liaison !== "미선택"
          ? typeof form.liaison === "object"
            ? form.liaison._id
            : this.state.userList.find((x) => x.name === form.liaison)._id
          : "";
    if (target === "arise") {
      saveClaim.arise_date_modify = new Date(Date.now()); // 수정 날짜

      saveClaim.arise_month = new Date(form.arise_month);
      if (form.arise_status === "20" || form.arise_status === "30")
        saveClaim.arise_status = "20";
      if (form.arise_status === "00") form.arise_status = "00";
      else saveClaim.arise_status = this.state.auth_grade >= 2 ? "40" : "50"; // 문서
      saveClaim.arise_client = form.arise_client._id
        ? form.arise_client._id
        : this.state.clientList.find((x) => x.name === form.arise_client)._id;
      saveClaim.arise_price = +form.arise_price;
      saveClaim.arise_detail = form.arise_detail;
      saveClaim.arise_passtype = form.arise_passtype;

      saveClaim.arise_type = form.arise_type;

      saveClaim.arise_currency_sign = form.arise_currency_sign;
      if (form.arise_currency_sign === "₩") saveClaim.arise_eschamge = "";
      form.arise_currency_sign !== "₩"
        ? (saveClaim.arise_eschamge = +form.arise_eschamge)
        : "";
    } else {
      saveClaim.pass_date_modify = new Date(Date.now()); // 수정 날짜
      saveClaim.pass_month = new Date(form.pass_month);
      if (form.pass_status === "20" || form.pass_status === "30")
        saveClaim.pass_status = "20";
      if (form.pass_status === "00") form.pass_status = "00";
      else saveClaim.pass_status = this.state.auth_grade >= 2 ? "40" : "50"; // 문서
      saveClaim.pass_client = form.pass_client._id
        ? form.pass_client._id
        : this.state.clientList.find((x) => x.name === form.pass_client)._id;
      saveClaim.pass_price = +form.pass_price;
      saveClaim.pass_detail = form.pass_detail;
      saveClaim.pass_type = form.pass_type;
      saveClaim.pass_currency_sign = form.pass_currency_sign;
      if (form.pass_currency_sign === "₩") saveClaim.pass_eschamge = "";
      form.pass_currency_sign !== "₩"
        ? (saveClaim.pass_eschamge = +form.pass_eschamge)
        : "";
    }

    this.commit("setClaimModifyForm", saveClaim);
  },
  // async TASK_SELECT_CLAIM_SAVE({ commit }, saveTarget) {
  //   const type = saveTarget.type;

  //   this.dispatch("SET_API_LOADING", true);

  //   for (let i = 0; i < this.state.selected.length; i++) {
  //     await this.dispatch("SELECT_CLAIM_PARSE", {
  //       form: this.state.selected[i],
  //       type: type,
  //       target: this.state.selected[i].saveTarget,
  //     });
  //     await this.dispatch(
  //       "SET_CLAIM_APPROVAL_SAVE_DATA",
  //       this.state.selected[i].saveTarget,
  //     );
  //     const releaseType =
  //       this.state.selected[i].saveTarget === "arise"
  //         ? this.state.selected[i].arise_type
  //         : this.state.selected[i].pass_type;

  //     try {
  //       if (type === "cancel" && this.state.auth_grade >= 2) {
  //         if (Object.keys(this.state.claimReleaseForm).length > 1) {
  //           await this.dispatch("DELETE_RECIVABLE_AMOUNT", {
  //             release: this.state.claimReleaseForm,
  //             kind: releaseType === "매출" ? "sales" : "purchase",
  //           });
  //         }
  //       }
  //       if (this.state.saveData._id) await this.dispatch("SAVE_CLAIM_RELEASE");

  //       await this.dispatch("SAVE_CLAIM");
  //     } catch (e) {
  //       await this.dispatch("SET_ORDERLIST_DATA");
  //       type === "cancel"
  //         ? await this.dispatch("SET_END_CLAIMLIST")
  //         : await this.dispatch("SET_CLAIMLIST");
  //       alert("오더에 변경사항이 있습니다. 다시 저장해주세요.");
  //       this.dispatch("SET_API_LOADING", false);
  //     }
  //   }
  //   this.commit("setAriseSelected");
  //   this.commit("setPassSelected");
  //   this.commit("setSelected");
  //   this.commit("setClaimForm"),
  //     this.commit("setSaveData"),
  //     this.commit("setApprovalForm"),
  //     type === "cancel"
  //       ? await this.dispatch("SET_END_CLAIMLIST")
  //       : await this.dispatch("SET_CLAIMLIST");
  //   await this.dispatch("SELECT_APPROVAL_LIST"),
  //     this.dispatch("SET_API_LOADING", false);
  // },

  // ----------------------------------------

  // 3. 매입정산 페이지
  // async TASK_PURCHASECAC_SAVE({ commit }, kind) {
  //   compose([
  //     this.dispatch("SET_API_LOADING", true),
  //     await this.dispatch("PARSE_PURCHASECAC", kind),

  //     await this.dispatch("SAVE_ORDER", kind),

  //     this.commit("setApprovalForm"),
  //     await this.dispatch("SELECT_APPROVAL_LIST"),
  //     await this.dispatch("SET_ORDERLIST_DATA"),
  //     this.dispatch("SET_API_LOADING", false),
  //     this.commit("setDailogStatus", {
  //       status: false,
  //       kind: "dialog_edit",
  //     }),
  //   ]);
  // },
  async SELECT_PURCHASECAC_PARSE({ commit }, data) {
    const form = data.form;
    praseSaveDataComma(form);
    const selectOrder = await getSelectIdOrder(
      this.state.auth_company,
      form.order.split(" ")[1] ? form.order.split(" ")[0] : form.order,
    );

    const targetOrder = selectOrder.data[0];
    let targetRelease = targetOrder.release.find((x) => x.id === form.id);

    if (data.kind === "carry") {
      let date = dayjs(new Date(form.date_delivery)).locale("kr");
      date = date.add(1, "month");

      targetRelease.purchase.date = date;
      targetRelease.purchase.carried = true;
    }

    if (data.kind === "end") {
      form.status = this.state.auth_grade >= 2 ? "00" : "20";
      targetRelease.purchase.status = this.state.auth_grade >= 2 ? "00" : "20";
    }
    if (data.kind === "reject") {
      targetRelease.purchase.status = "40";
      form.status = "40";
    }
    if (data.kind === "cancel") {
      targetRelease.purchase.status = this.state.auth_grade >= 2 ? "40" : "10";
      form.status = this.state.auth_grade >= 2 ? "40" : "10";
    }
    form.buyer = form.buyer ? form.buyer : form.place_forward;
    form.saveData = { ...form };
    targetOrder.targetId = targetRelease.id;

    form.saveRelease = targetRelease;
    commit("setSaveData", targetOrder);
  },

  // async TASK_SELECT_PURCHASECAC_SAVE({ commit }, kind) {
  //   this.dispatch("SET_API_LOADING", true);
  //   await this.dispatch("SET_CLIENTLIST");
  //   for (let i = 0; i < this.state.selected.length; i++) {
  //     compose([
  //       await this.dispatch("SELECT_PURCHASECAC_PARSE", {
  //         form: this.state.selected[i],
  //         kind: kind,
  //       }),

  //       await this.dispatch("getaApprovalComId", {
  //         id: this.state.saveData.targetId,
  //         route: router.app._route.name,
  //       }),

  //       await this.dispatch("SET_APPROVAL_SAVE_DATA", this.state.selected[i]),
  //       await this.dispatch("SAVE_ORDER"),
  //     ]);

  //     if (
  //       this.state.auth_grade >= 2 &&
  //       kind === "end" &&
  //       this.state.saveData.order_kind === "raw" &&
  //       (this.state.selected[i].saveRelease.kind === 8 ||
  //         this.state.selected[i].saveRelease.kind === 15) &&
  //       this.state.selected[i].totalQuantity !==
  //         this.state.selected[i].purchaseQuantity
  //     ) {
  //       await this.dispatch(
  //         "SET_NEW_FABRIC_STOCK_DATA",
  //         this.state.selected[i],
  //       );
  //       await stockSave(this.state.saveStock);
  //       this.commit("setSaveStock");
  //     }

  //     if (this.state.auth_grade >= 2 && kind === "end") {
  //       await this.dispatch(
  //         "MAKE_RECIVABLE_PURCHARSE",
  //         this.state.selected[i].saveRelease,
  //       );
  //     }
  //     if (
  //       kind === "end" &&
  //       this.state.selected[i].saveRelease.order_kind === 2
  //     ) {
  //       await this.dispatch(
  //         "CLAIM_END_FORM_PARSE",
  //         this.state.selected[i].saveRelease,
  //       );

  //       if (this.state.claimForm._id) await this.dispatch("SAVE_END_CLAIM");
  //     }

  //     if (this.state.auth_grade >= 2 && kind === "cancel") {
  //       // await this.dispatch(
  //       //   "RECIVABLE_PURCHARSE_SUBTRACT",
  //       //   this.state.selected[i].saveRelease,
  //       // );
  //       if (this.state.selected[i].saveRelease.order_kind === 2) {
  //         await this.dispatch(
  //           "CLAIM_CANCEL_FORM_PARSE",
  //           this.state.selected[i].saveRelease,
  //         );
  //         if (this.state.claimForm._id) await this.dispatch("SAVE_END_CLAIM");
  //       }
  //       await this.dispatch("DELETE_RECIVABLE_AMOUNT", {
  //         release: this.state.selected[i].saveRelease,
  //         kind: "purchase",
  //       });
  //     }

  //     if (this.state.saveRecivable.name) await this.dispatch("SAVE_RECIVABLE");
  //     await this.dispatch("MAKE_RECIVABLE_AMOUNT", {
  //       release: this.state.selected[i].saveRelease,
  //       type: "purchase",
  //     });

  //     if (this.state.auth_grade >= 2 && kind === "end")
  //       await this.dispatch("SAVE_RECIVABLE_AMOUNT");
  //   }

  //   this.commit("setSaveData");
  //   this.commit("setApprovalForm");
  //   await this.dispatch("SELECT_APPROVAL_LIST");
  //   this.dispatch("SET_HEADER_ALERT");

  //   await this.dispatch("GET_RELEASELIST", {
  //     kind: router.app._route.name,
  //   });
  //   commit("setPurCheckBox", false);
  //   this.dispatch("SET_API_LOADING", false);
  //   this.commit("setSelected");
  // },

  async PARSE_PURCHASECAC({ commit }, kind) {
    const selectOrder = await getSelectIdOrder(
      this.state.auth_company,
      this.state.form.order,
    );

    typeof this.state.form.place_forward === "object"
      ? (this.state.form.place_forward = this.state.form.place_forward._id)
      : "";
    typeof this.state.form.item === "object"
      ? (this.state.form.item = this.state.form.item._id)
      : "";
    typeof this.state.form.buyer === "object"
      ? (this.state.form.buyer = this.state.form.buyer._id)
      : "";

    const targetOrder = selectOrder.data[0];
    let targetRelease = targetOrder.release.find(
      (x) => x._id === this.state.form._id,
    );
    this.state.form.quantity_truth
      ? (targetRelease.purchase.color_list[this.state.form.index].quantity =
          this.state.form.quantity_truth)
      : "";
    // RELEASE_QUANTITY_CHANGE
    targetRelease.purchase.quantity = targetRelease.purchase.color_list.reduce(
      (r, c) => r + (c.quantity ? +c.quantity : 0),
      0,
    );

    this.state.form.place_forward
      ? (targetRelease.place_forward = this.state.form.place_forward)
      : "";
    this.state.form.memo
      ? (targetRelease.purchase.memo = this.state.form.memo)
      : "";

    // this.state.form.quantity_truth ? targetRelease.place_forward = this.state.form.place_forward : '';
    targetRelease = JSON.parse(JSON.stringify(this.state.form));

    await commit("setSaveData", targetOrder);
  },

  async MAKE_RECIVABLE_PURCHARSE({ commit }, release) {
    const month = monthDateFormat(
      release.purchase.date
        ? release.purchase.date
        : release.date_delivery
        ? release.date_delivery
        : release.date_modify,
    );
    const year = new Date(
      release.purchase.date
        ? release.purchase.date
        : release.date_delivery
        ? release.date_delivery
        : release.date_modify,
    ).getFullYear();
    try {
      const result = await getReceivableByClient(
        this.state.auth_company,
        release.place_forward?._id
          ? release.place_forward._id
          : release.place_forward,
        year,
        month,
        "purchase",
      );

      if (result.status !== 200) throw `error : ${result.status}`;
      const selectOrder = await getSelectIdOrder(
        this.state.auth_company,
        release.order,
      );
      const order = selectOrder.data && selectOrder.data[0];

      let data = {};
      if (!result.data.length) {
        data.company = this.state.auth_company;
        data.name = release.place_forward
          ? this.state.clientList.find(
              (x) =>
                x._id ===
                (release.place_forward?._id
                  ? release.place_forward._id
                  : release.place_forward),
            ).name
          : "";
        data.client = release.place_forward?._id
          ? release.place_forward._id
          : release.place_forward;
        data.year = new Date(
          release.purchase.date
            ? release.purchase.date
            : release.date_delivery
            ? release.date_delivery
            : release.date_modify,
        )
          .getFullYear()
          .toString();
        data.month = month;
        data.kind = "purchase";
        data.usage = true;
        data.classification = order
          ? order.purchase_classification === "overseas"
            ? "2"
            : "1"
          : "";
      } else {
        data = result.data[0];
      }

      commit("setSaveRecivable", data);
    } catch (e) {
      console.log(e);
    }
  },

  async RECIVABLE_PURCHARSE_SUBTRACT({ commit }, release) {
    const month = monthDateFormat(
      release.purchase.date
        ? release.purchase.date
        : release.date_delivery
        ? release.date_delivery
        : release.date_modify,
    );
    const year = new Date(
      release.purchase.date
        ? release.purchase.date
        : release.date_delivery
        ? release.date_delivery
        : release.date_modify,
    ).getFullYear();
    try {
      const result = await getReceivableByClient(
        this.state.auth_company,
        release.place_forward._id
          ? release.place_forward._id
          : release.place_forward,
        year,
        month,
        "purchase",
      );

      if (result.status !== 200) throw `error : ${result.status}`;

      if (result.data) {
        const recivable = result.data.find((x) => x.amount !== 0);

        recivable.amount -= release.purchase.eschamge_price
          ? +release.purchase.eschamge_price
          : +release.purchase.total_amount;

        recivable.vat_amount -= Math.round(
          (release.purchase.eschamge_price
            ? +release.purchase.eschamge_price
            : +release.purchase.total_amount) * 1.1,
        );

        if (recivable.amount < 0) {
          recivable.amount = 0;
          recivable.vat_amount = 0;
        }

        commit("setSaveRecivable", recivable);
      }
    } catch (e) {
      console.log(e);
    }
  },

  // ---------------------------

  //매출정산 페이지

  async PARSE_SALESCAC({ commit }, kind) {
    const selectOrder = await getSelectIdOrder(
      this.state.auth_company,
      this.state.form.order,
    );

    typeof this.state.form.place_forward === "object"
      ? (this.state.form.place_forward = this.state.form.place_forward._id)
      : "";
    typeof this.state.form.place_sales === "object"
      ? (this.state.form.place_sales = this.state.form.place_sales._id)
      : "";
    typeof this.state.form.item === "object"
      ? (this.state.form.item = this.state.form.item._id)
      : "";
    typeof this.state.form.buyer === "object"
      ? (this.state.form.buyer = this.state.form.buyer._id)
      : "";

    const targetOrder = selectOrder.data[0];
    let targetRelease = targetOrder.release.find(
      (x) => x._id === this.state.form._id,
    );

    this.state.form.place_sales
      ? (targetRelease.place_sales = this.state.form.place_sales)
      : "";
    this.state.form.sales_total_quantity
      ? (targetRelease.sales.quantity = this.state.form.sales_total_quantity)
      : "";
    this.state.form.unit_price_of_sales
      ? (targetRelease.sales_unit_price = this.state.form.unit_price_of_sales)
      : "";
    this.state.form.memo
      ? (targetRelease.sales.memo = this.state.form.memo)
      : "";

    targetRelease.sales.total_amount =
      (targetRelease.sales.quantity ? targetRelease.sales.quantity : 0) *
      (targetRelease.sales_unit_price ? targetRelease.sales_unit_price : 0);

    if (targetRelease.currency_sign && targetRelease.currency_sign !== "₩")
      targetRelease.sales.eschamge_price = Math.round(
        targetRelease.sales.eschamge * targetRelease.sales.total_amount,
      );

    targetRelease = JSON.parse(JSON.stringify(this.state.form));

    await commit("setSaveData", targetOrder);
  },

  async TASK_SALESCAC_SAVE({ commit }, kind) {
    compose([
      this.dispatch("SET_API_LOADING", true),
      await this.dispatch("PARSE_SALESCAC", kind),

      await this.dispatch("SAVE_ORDER", kind),

      this.commit("setApprovalForm"),
      await this.dispatch("SELECT_APPROVAL_LIST"),

      await this.dispatch("GET_RELEASELIST", {
        kind: "salescalculate",
      }),

      this.dispatch("SET_API_LOADING", false),
      this.commit("setDailogStatus", {
        status: false,
        kind: "dialog_edit",
      }),
    ]);
  },

  async SELECT_SALESECAC_PARSE({ commit }, data) {
    const form = data.form;
    praseSaveDataComma(form);

    const selectOrder = await getSelectIdOrder(
      this.state.auth_company,
      form.order.split(" ")[1] ? form.order.split(" ")[0] : form.order,
    );
    const targetOrder = selectOrder.data[0];

    let targetRelease = targetOrder.release.find((x) => x._id === form._id);
    form.id = targetRelease.id;
    form.liaison = targetRelease.liaison;

    if (data.kind === "carry") {
      let date = dayjs(new Date(form.date_delivery)).locale("kr");
      date = date.add(1, "month");
      targetRelease.sales.date = date;
      targetRelease.sales.carried = true;
    }

    if (data.kind === "end") {
      form.status = this.state.auth_grade >= 2 ? "00" : "20";
      form.date = new Date(Date.now());
      targetRelease.sales.status = this.state.auth_grade >= 2 ? "00" : "20";
    }
    if (data.kind === "reject") {
      targetRelease.sales.status = "40";
      form.status = "40";
    }
    if (data.kind === "cancel") {
      targetRelease.sales.status = this.state.auth_grade >= 2 ? "40" : "10";
      form.status = this.state.auth_grade >= 2 ? "40" : "10";
    }
    form.saveData = { ...form };
    targetOrder.targetId = targetRelease.id;
    form.saveRelease = targetRelease;

    form.buyer = form.buyer ? form.buyer : form.placeSales;
    commit("setSaveData", targetOrder);
  },

  async TASK_SELECT_SALESCAC_SAVE({ commit }, kind) {
    this.dispatch("SET_API_LOADING", true);
    for (let i = 0; i < this.state.selected.length; i++) {
      compose([
        await this.dispatch("SELECT_SALESECAC_PARSE", {
          form: this.state.selected[i],
          kind: kind,
        }),
        await this.dispatch("getaApprovalComId", {
          id: this.state.saveData.targetId,
          route: router.app._route.name,
        }),

        await this.dispatch("SET_APPROVAL_SAVE_DATA", this.state.selected[i]),
        await this.dispatch("SAVE_ORDER"),
      ]);
      if (this.state.auth_grade >= 2 && kind === "end") {
        await this.dispatch(
          "MAKE_RECIVABLE_SALES",
          this.state.selected[i].saveRelease,
        );
      }
      if (
        kind === "end" &&
        this.state.selected[i].saveRelease.order_kind === 2
      ) {
        await this.dispatch(
          "CLAIM_END_FORM_PARSE",
          this.state.selected[i].saveRelease,
        );
        if (this.state.claimForm._id) await this.dispatch("SAVE_END_CLAIM");
      }
      if (this.state.auth_grade >= 2 && kind === "cancel") {
        if (this.state.selected[i].saveRelease.order_kind === 2) {
          await this.dispatch(
            "CLAIM_CANCEL_FORM_PARSE",
            this.state.selected[i].saveRelease,
          );
          if (this.state.claimForm._id) await this.dispatch("SAVE_END_CLAIM");
        }

        await this.dispatch("DELETE_RECIVABLE_AMOUNT", {
          release: this.state.selected[i].saveRelease,
          kind: "sales",
        });
      }
      if (this.state.saveRecivable.name) await this.dispatch("SAVE_RECIVABLE");
      await this.dispatch("MAKE_RECIVABLE_AMOUNT", {
        release: this.state.selected[i].saveRelease,
        type: "sales",
      });

      if (this.state.auth_grade >= 2 && kind === "end")
        await this.dispatch("SAVE_RECIVABLE_AMOUNT");
    }
    this.commit("setSaveData");
    this.commit("setApprovalForm");
    await this.dispatch("SELECT_APPROVAL_LIST");
    this.dispatch("SET_HEADER_ALERT");

    await this.dispatch("GET_RELEASELIST", {
      kind: router.app._route.name,
    });

    this.commit("setEndSalesCheckBox", false);

    this.dispatch("SET_API_LOADING", false);
    this.commit("setSelected");
  },

  async MAKE_RECIVABLE_SALES({ commit }, form) {
    const release = this.state.saveData.release.find((x) => x._id === form._id);

    const month = monthDateFormat(
      release.sales.date
        ? release.sales.date
        : release.date_delivery
        ? release.date_delivery
        : release.date_modify,
    );
    const year = new Date(
      release.sales.date
        ? release.sales.date
        : release.date_delivery
        ? release.date_delivery
        : release.date_modify,
    ).getFullYear();
    try {
      const result = await getReceivableByClient(
        this.state.auth_company,
        release.place_sales?._id
          ? release.place_sales._id
          : release.place_sales,
        year,
        month,
        "sales",
      );

      if (result.status !== 200) throw `error : ${result.status}`;

      let data = {};
      const selectOrder = await getSelectIdOrder(
        this.state.auth_company,
        release.order,
      );
      const order = selectOrder.data && selectOrder.data[0];
      if (!result.data.length) {
        data.company = this.state.auth_company;
        data.name = release.place_sales
          ? this.state.clientList.find(
              (x) =>
                x._id ===
                (release.place_sales?._id
                  ? release.place_sales._id
                  : release.place_sales),
            ).name
          : "";

        data.client = release.place_sales?._id
          ? release.place_sales._id
          : release.place_sales;
        data.year = new Date(
          release.sales.date
            ? release.sales.date
            : release.date_delivery
            ? release.date_delivery
            : release.date_modify,
        )
          .getFullYear()
          .toString();
        data.month = month;
        data.kind = "sales";
        data.usage = true;
        data.classification = order
          ? order.sales_classification === "export"
            ? 2
            : 1
          : null;
      } else {
        data = result.data[0];
      }

      commit("setSaveRecivable", data);
    } catch (e) {
      console.log(e);
    }
  },

  async RECIVABLE_SALES_SUBTRACT({ commit }, release) {
    let month = "";
    if (release.sales.date) month = monthDateFormat(release.sales.date);
    else if (release.date_delivery)
      month = monthDateFormat(release.date_delivery);
    else month = monthDateFormat(release.date_modify);

    try {
      const result = await getReceivableByClient(
        this.state.auth_company,
        release.place_sales._id ? release.place_sales._id : release.place_sales,
        month,
        "sales",
      );

      if (result.status !== 200) throw `error : ${result.status}`;

      if (result.data) {
        const recivable = result.data.find((x) => x.amount !== 0);

        recivable.amount -= release.sales.eschamge_price
          ? +release.sales.eschamge_price
          : +release.sales.total_amount;

        recivable.vat_amount -= Math.round(
          (release.sales.eschamge_price
            ? +release.sales.eschamge_price
            : +release.sales.total_amount) * 1.1,
        );

        if (recivable.amount < 0) {
          recivable.amount = 0;
          recivable.vat_amount = 0;
        }
        commit("setSaveRecivable", recivable);
      }
    } catch (e) {
      console.log(e);
    }
  },

  // --------------------
  async SAVE_RECIVABLE({ commit }) {
    try {
      const result = await (!this.state.saveRecivable._id
        ? postReceivable(this.state.saveRecivable)
        : putReceivable(this.state.saveRecivable));
      if (result.status !== 200) throw `error : ${result.status}`;
      commit("setSaveRecivable");
    } catch (e) {
      console.log(e);
    }
  },
  async DELETE_RECIVABLE_AMOUNT({ commit }, data) {
    try {
      const result = await deleteReceivableAmount(
        this.state.auth_company,
        data.release._id,
        data.kind,
      );
      if (result.status !== 200) throw `error : ${result.status}`;
    } catch (e) {
      console.log(e);
    }
  },
  async SAVE_RECIVABLE_AMOUNT({ commit }) {
    try {
      const result = await (!this.state.saveRecivableAmount._id
        ? postReceivableAmount(this.state.saveRecivableAmount)
        : putReceivableAmount(this.state.saveRecivableAmount));
      if (result.status !== 200) throw `error : ${result.status}`;
      commit("setSaveRecivableAmount");
    } catch (e) {
      console.log(e);
    }
  },

  //생산 페이지 시작  -----------------------------------------

  //생산 페이지 시작 =========================================================================
  //1.생산 API 함수 ------------------------------------------------

  async SET_PRODUCTION_ORDER({ commit }, order) {
    await this.dispatch("SELECT_ORDER", order);
    this.dispatch("SET_USE_CLIENTLIST");
    this.dispatch("SET_CTYPELIST");
    this.dispatch("SET_ITEMLIST");
    this.dispatch("GET_STOCK_LIST");
    this.dispatch("EDIT_FORM_CLOSE");

    commit("setProductionOrderForm", copyData(this.state.selectOrder));
    commit("setSelectOrder");

    this.dispatch("SET_DOC_DATA_LIST");
  },

  SET_DOC_DATA_LIST({ commit }) {
    const docDataList = [
      {
        kind: 0,
        doc: `1. 오더정보 - ${this.state.productionOrderForm.order}`,
      },
    ];

    if (this.state.productionOrderForm.progress) {
      this.state.productionOrderForm.progress.forEach((x, i) => {
        if (x.kind === 7) {
          x.doc = `${docDataList.length + 1}. 가공의뢰서 - ${x.log}`;
        }
        if (x.kind === 8) {
          x.doc = `${docDataList.length + 1}. 매입 출고의뢰서 -  ${x.log}`;
        }
        if (x.kind === 9) {
          x.doc = `${docDataList.length + 1}. 매출 출고의뢰서 - ${x.log}`;
        }
        if (x.kind === 10) {
          x.doc = `${docDataList.length + 1}. 검사 의뢰서 - ${x.log}`;
        }
        if (x.kind === 15) {
          x.doc = `${docDataList.length + 1}. 매입/매출 출고의뢰서 - ${x.log}`;
        }
        if (x.kind === 16) {
          x.doc = `${docDataList.length + 1}. 출고의뢰서 - ${x.log}`;
        }

        if (x.doc) docDataList.push(x);
      });
    }

    commit("setDocDataList", docDataList);
  },

  async SELECT_ORDER({ commit }, order) {
    try {
      const orderNum = order.order
        ? order.order.split(" ")[1]
          ? order.order.split(" ")[0]
          : order.order
        : order;
      const result = await getSelectIdOrder(this.state.auth_company, orderNum);
      if (result.status !== 200) throw `error: ${result.status}`;
      commit("setSelectOrder", result.data[0]);
    } catch (e) {
      console.log(e);
    }
  },

  async SELECT_BEFORE_ORDER({ commit }, order) {
    try {
      const result = await getSelectOrder(this.state.auth_company, order.order);

      if (result.status !== 200) throw `error: ${result.status}`;
      commit("setBeforeOrder", result.data[0]);
    } catch (e) {
      console.log(e);
    }
  },
  async SELECT_AFTER_ORDER({ commit }, order) {
    try {
      const result = await getSelectOrder(this.state.auth_company, order.order);

      if (result.status !== 200) throw `error: ${result.status}`;
      commit("setAfterOrder", result.data[0]);
    } catch (e) {
      console.log(e);
    }
  },
  async SELECT_CLAIM({ commit }, id) {
    try {
      const result = await claimIdFind(this.state.auth_company, id);
      if (result.status !== 200) throw `error: ${result.status}`;
      commit("setSelectClaim", result.data.claimList[0]);
    } catch (e) {
      console.log(e);
    }
  },
  async NOT_PARSE_SELECT_CLAIM({ commit }, id) {
    try {
      const result = await notParseClaimFInd(this.state.auth_company, id);
      if (result.status !== 200) throw `error: ${result.status}`;
      commit("setSelectClaim", result.data[0]);
    } catch (e) {
      console.log(e);
    }
  },

  async SELECT_TYPE_CLAIM({ commit }, id) {
    try {
      const result = await claimTypeIdFind(this.state.auth_company, id);

      if (result.status !== 200) throw `error: ${result.status}`;

      commit("setSelectClaim", result.data[0]);
    } catch (e) {
      console.log(e);
    }
  },

  SET_DOC_LIST({ commit }, kind) {
    const orderDocList = JSON.parse(
      JSON.stringify(
        [
          ...this.state.productionOrderForm.release,
          ...this.state.productionOrderForm.manufacture,
          ...this.state.productionOrderForm.appearance,
        ].filter((x) => x.kind && x.status === "40"),
      ),
    ).filter((x) =>
      kind ? x.kind === 12 || x.kind === 7 || x.kind === 11 : x.kind === 7,
    );

    commit("setProductionDoc", orderDocList);
  },

  // approvla 저장 함수ㄹ
  async SAVE_APPROVAL({ commit }) {
    try {
      // this.state.saveApproval.date = new Date(Date.now());
      // this.state.saveApproval.approvalId = "approval_feelnine_010";
      // this.state.saveApproval.releaseData =
      //   this.state.saveData.release[this.state.saveData.release.length - 1];

      const result = this.state.saveApproval._id
        ? await putApproval(this.state.saveApproval)
        : await postApproval(this.state.saveApproval);

      if (result.status !== 200) throw `error ${result.status}`;

      commit("setSaveApproval");
      this._vm.$socket.emit("approval", { company: this.state.auth_company });
    } catch (error) {
      console.log(error);
      console.error();
    }
  },

  //--------------------------------------------------------------
  //2.생산 dialog Open 함수 ----------------------------------------
  async PRODUCTION_ORDER_CHANGE({ commit }, order) {
    await this.dispatch("SELECT_ORDER", order);
    commit("setProductionOrderForm", this.state.selectOrder);
    commit("setSelectOrder");
    this.dispatch("SET_DOC_DATA_LIST");
  },

  // 생산페이지 가공,출고,외관 문서 화면 초기화
  PRODUCTION_DIALOG_INIT({ commit }) {
    commit("setManufactureForm");
    commit("setReleaseForm");
    commit("setAppearanceForm");
    // this.state.manufactureForm.dialog = false;
    // this.state.releaseForm.dialog = false;
    // this.state.appearanceForm.dialog = false;

    if (this.state.isFabricRelease) commit("setIsFabricRelease");
    if (this.state.isFabricManufacture) commit("setIsFabricManufacture");
    if (this.state.saveStock) commit("setSaveStock");
  },
  // progress 클릭시 각 의뢰서 open
  PRODUCTION_OPENDIALOG({ commit }) {
    this.dispatch("PRODUCTION_DIALOG_INIT");

    this.state.productionProgress.kind === 7
      ? this.dispatch("SET_OPEN_MANUFACTURE_DIALOG")
      : this.state.productionProgress.kind === 11
      ? this.dispatch("SET_OPEN_APPEARANCE_DIALOG")
      : this.dispatch("SET_OPEN_RELEASE_DIALOG");
    if (this.state.isDocDataBtn) commit("setIsDocDataBtn");
  },

  // //생지 오더의 progress 클릭시
  // FABRIC_PRODUCTION_DOC_OPEN() {
  //   this.dispatch("PRODUCTION_DIALOG_INIT");

  //   if (
  //     this.state.productionProgress.kind === 7 ||
  //     this.state.productionProgress.kind === 18
  //   )
  //     this.dispatch("SET_OPEN_MANUFACTURE_DIALOG");
  //   else this.dispatch("SET_FABRIC_RELEASEFORM");
  // },

  //2.1 생산 가공 parse ----------------------------------------------
  //작성된 가공의뢰서 Dialog open
  async SET_OPEN_MANUFACTURE_DIALOG({ commit }) {
    await this.dispatch("PRODUCTION_CALL_GET_APPROVAL_FOR_ID");

    const findDoc =
      this.state.productionProgress.status === "50"
        ? this.state.approvalForm.manufactureData
        : this.state.productionOrderForm.manufacture.find(
            (x) => x.id === this.state.productionProgress.id,
          );

    const manufacture = JSON.parse(JSON.stringify(findDoc));

    // if (manufacture.status === "70")
    //   this.dispatch("SET_TEMPORARY_MANUFACTURE", manufacture);
    // else {
    const order = this.state.productionOrderForm;
    this.dispatch("SET_MANUFACTURE_COLOR", manufacture.color_list);
    // manufacture.fabricBtn = manufacture.kind === 18;
    manufacture.history = this.state.productionProgress.history;
    manufacture.date_payment = dateFormat(manufacture.date_payment);

    manufacture.sucharge_price = usingComma(manufacture.sucharge_price);

    manufacture.place_manufacture = this.state.clientList.find(
      (x) => x._id === manufacture.place_manufacture,
    );

    manufacture.item = this.state.itemList.find(
      (x) => x._id === manufacture.item,
    );

    manufacture.liaison = this.state.userList.find(
      (x) => x._id === manufacture.liaison,
    );
    if (manufacture.inspection)
      manufacture.inspection = this.state.clientList.find(
        (x) => x._id === manufacture.inspection,
      );

    if (manufacture.surcharge_unit)
      manufacture.surcharge_unit = usingComma(manufacture.surcharge_unit.price);

    manufacture.total_quantity_sum = decimalCacul(
      this.state.productionColor
        .filter((x) => x.quantity || x.quantity_sample)
        .reduce(
          (r, c) =>
            r +
            (c.quantity_sample
              ? +c.quantity
                ? +c.quantity + +c.quantity_sample
                : +c.quantity_sample
              : +c.quantity),
          0,
        ),
    );

    manufacture.isModify = !(
      order.release.filter(
        (x) => x.manufacture === manufacture.id && x.status !== "99",
      ).length !== 0 ||
      (manufacture.status !== "40" && manufacture.status !== "30")
    );

    // if (order.order_kind === "raw") {
    //   const doc = copyData(
    //     [...order.release, ...order.manufacture].find(
    //       (x) => x.id === manufacture.referenceDoc,
    //     ),
    //   );
    //   if (doc) manufacture.referenceDoc = doc;
    // }

    manufacture.dialog = true;

    commit("setManufactureForm", manufacture);
    // }
  },

  SET_TEMPORARY_MANUFACTURE({ commit }, manufacture) {
    const manufactureForm = {
      ...manufacture,
      item: this.state.itemList.find((x) => x._id === manufacture.item),
      liaison: this.state.userList.find((x) => x._id === manufacture.liaison),
      place_manufacture: manufacture.place_manufacture
        ? this.state.clientList.find(
            (x) => x._id === manufacture.place_manufacture,
          )
        : "",
      date_payment: dateFormat(new Date(Date.now())),
      currency_sign: manufacture.currency_sign
        ? manufacture.currency_sign
        : "₩",
      total_quantity_sum: manufacture.color_list.reduce(
        (r, c) => r + (c.quantity ? c.quantity : 0),
        0,
      ),
      referenceDoc: copyData(
        [
          ...this.state.productionOrderForm.release,
          ...this.state.productionOrderForm.manufacture,
        ].find((x) => x.id === manufacture.referenceDoc),
      ),
      isModify: true,
      dialog: true,
    };

    this.dispatch("SET_MANUFACTURE_COLOR", manufacture.color_list);
    commit("setManufactureForm", manufactureForm);
  },

  SET_MANUFACTURE_COLOR({ commit }, data) {
    let productionColor = [];
    if (data.length < 13)
      for (let i = 0; i < 13; i++) {
        if (data[i] && data[i].color_surcharge_price) {
          data[i].color_surcharge_price = data[i].color_surcharge_price.price;
        }
        productionColor.push(data[i] !== undefined ? data[i] : {});
      }
    else
      productionColor = data.map((x) => {
        if (x && x.color_surcharge_price) {
          x.color_surcharge_price = x.color_surcharge_price.price;
        }
        return x;
      });

    const commaColor = productionColor.map((x) => {
      const color = {
        name: x.name,
        quantity: usingComma(x.quantity),
        quantity_sample: usingComma(x.quantity_sample),
        color_surcharge_price: usingComma(x.color_surcharge_price),
        color_surcharge_unit: usingComma(x.color_surcharge_unit),
        quantity_shrigkage: usingComma(x.quantity_shrigkage),
      };
      return color;
    });

    commit("setProductionColor", productionColor);
    commit("setCommaColor", commaColor);
  },
  //--------------------------------------------------------------
  //2.2 생산 출고 parse ----------------------------------------------
  // progress 클릭시 open
  SET_OPEN_RELEASE_DIALOG({ commit }) {
    // if (this.state.productionProgress.kind === 19)
    //   this.dispatch("SET_FABRIC_RELEASEFORM");
    // else
    this.dispatch("SET_RELEASEFORM");
  },

  async SET_RELEASEFORM({ commit }) {
    await this.dispatch("PRODUCTION_CALL_GET_APPROVAL_FOR_ID");

    const order = this.state.productionOrderForm;
    const release = copyData(
      this.state.productionProgress.status === "50"
        ? this.state.approvalForm.releaseData
        : order.release.find((x) => x.id === this.state.productionProgress.id),
    );

    this.dispatch("INIT_RELEASE_DOC_CALCULATE");

    release.salesBtn = release.kind === 9 || release.kind === 15;
    release.purBtn =
      release.kind === 8 ||
      release.kind === 15 ||
      release.kind === 12 ||
      release.kind === 17;
    release.appenBtn = release.kind === 10;
    release.basicBtn = release.kind === 16;
    release.preBtn = release.kind === 12;
    release.stockBtn = release.kind === 17;

    if (release.stock) release.requestDoc = { kind: 17, placeName: "재고수량" };

    //기존 매출 출고 의뢰서 매입 여부를 를 체크하기 위한 임시 로직
    if (
      (release.kind === 9 &&
        release.purchase &&
        release.purchase.color_list.length) ||
      release.kind === 12
    )
      release.purBtn = true;

    release.history = this.state.productionProgress.history;

    if (release.status !== "99")
      this.dispatch("SET_DOC_LIST", release.salesBtn);
    else
      this.commit(
        "setProductionDoc",
        JSON.parse(
          JSON.stringify([
            ...this.state.productionOrderForm.release,
            ...this.state.productionOrderForm.manufacture,
            ...this.state.productionOrderForm.appearance,
          ]),
        ),
      );

    if (!release.stock)
      release.requestDoc = this.state.productionDocList.find(
        (x) =>
          x.id ===
          (release.appearance
            ? release.appearance
            : release.release
            ? release.release
            : release.manufacture),
      );

    if (release.kind === 17 && !release.requestDoc) {
      release.requestDoc = { kind: 0 };
      release.purBtn = false;
    }
    release.place_forward = this.state.clientList.find(
      (x) => x._id === release.place_forward,
    );
    release.place_receive = this.state.clientList.find(
      (x) => x._id === release.place_receive,
    );
    release.liaison = this.state.userList.find(
      (x) => x._id === release.liaison,
    );
    release.item = this.state.itemList.find((x) => x._id === release.item);
    if (release.kind === "9") {
      release.place_sales = this.state.clientList.find(
        (x) => x._id === release.place_sales,
      )
        ? this.state.clientList.find((x) => x._id === release.place_sales)
        : "";
    }

    release.currency_sign =
      release.kind === 9
        ? release.currency_sign
        : release.currency_sign
        ? release.currency_sign
        : "₩";

    release.isModify = true;

    if (release.place_sales)
      release.place_sales = this.state.clientList.find(
        (x) => x._id === release.place_sales,
      );

    if (release.kind === 10 && release.status === "40")
      release.isModify =
        order.appearance.filter(
          (x) => x.release === release.id && x.status !== "99",
        ).length === 0;

    // 재고 version 으로 수정 가능 여부 확인
    if (
      (release.kind === 12 || release.kind === 17) &&
      release.status === "40"
    ) {
      await this.dispatch("FIND_STOCK_DATA", release.id);
      release.isStock =
        !this.state.selectStock.length || this.state.selectStock[0].kind !== 1;
      release.isModify = !this.state.selectStock.find(
        (x) => x.stock_quantity !== x.remain_quantity,
      );
    }

    // 선매입 출고
    if (release.kind === 12 && release.status === "40") {
      const isModify =
        order.release.filter(
          (x) => x.release === release.id && x.status !== "99",
        ).length === 0;

      release.isModify = release.sales.isStock
        ? release.isModify && isModify
        : isModify;
    }

    if (
      (release.purBtn && release.purchase.status !== "40") ||
      (release.salesBtn && release.sales.status !== "40")
    ) {
      release.isModify = false;
      release.status = "00";
    }

    if ((release.kind === 9 || release.kind === 15) && release.sales.eschamge)
      release.eschamge = usingComma(release.sales.eschamge);

    release.sales_unit_price = usingComma(release.sales_unit_price);

    release.dialog = true;

    if (release.sales && release.sales.color_surcharge_unit)
      release.color_list = release.color_list.map((x, i) => {
        const salesColor = release.sales.color_list[i];
        x.surcharge_unit = salesColor.surcharge_unit
          ? salesColor.surcharge_unit
          : "";
        return x;
      });

    if (release.purBtn) commit("setPurchasCacul", release.purchase);
    if (release.salesBtn) {
      release.surcharge_unit = release.sales.surcharge_unit
        ? usingComma(release.sales.surcharge_unit)
        : "";
      release.color_surcharge_unit = release.sales.color_surcharge_unit
        ? usingComma(release.sales.color_surcharge_unit)
        : "";
      commit("setSalesCacul", release.sales);
    }

    if (release.requestDoc.kind !== 17 && release.requestDoc.kind !== 0) {
      commit("setReleaseForm", release);
      const manufacture = copyData(
        release.requestDoc.kind === 7
          ? release.requestDoc
          : this.state.productionOrderForm.manufacture.find(
              (x) => x.id === release.requestDoc.manufacture,
            ),
      );

      manufacture.color_list = manufacture.color_list.map((x, i) => {
        x.indexColorName = x.indexColorName
          ? x.indexColorName
          : `${i + 1}_${x.name}`;
        return x;
      });

      if (release.requestDoc.kind === 7) {
        this.dispatch("SET_RELEASE_COLORLIST", manufacture);

        this.dispatch("SET_RELEASE_COLOR_STYLE_LIST", {
          color: release.color_list,
          purchase:
            release.purchase && release.purchase.color_list
              ? release.purchase.color_list
              : [],
          manufColor: manufacture.color_list,
        });
      }

      if (release.requestDoc.kind === 11)
        this.dispatch("SET_RELEASE_APPEARANCE_COLORLIST", release.requestDoc);

      if (release.requestDoc.kind === 12) {
        if (release.requestDoc.sales.isStock) release.isModify = false;
        this.dispatch("SET_RELEASE_PRE");
      }
    }

    if (release.requestDoc.kind === 17)
      this.dispatch("OPEN_STOCK_REQUEST", release);

    if (release.requestDoc.kind === 0)
      this.dispatch("SET_STOCK_DOC_COLORLIST", release);

    // this.dispatch("GET_ATTACHMENTLIST", release.id);
  },

  async OPEN_STOCK_REQUEST({ commit }, release) {
    await this.dispatch("GET_STOCK_LIST");
    const stockList = this.state.stockList;
    release.item = stockList.find((x) => x.item === release.item.name);
    release.stockList = [];

    const list = release.sales.color_list.map((x) => {
      const stock = stockList.find((y) => y.id === x.indexColorName);
      const color = {
        order: stock.order ? stock.order : "",
        item: stock.item,
        setColorName: stock.indexColorName,
        indexColorName: stock.id,
        name: stock.name,
        style: stock.style ? stock.style : "",
        success_quantity: x.success_quantity,
        index: stock.index,
      };

      if (release.unit_sign !== stock.unit_sign) {
        stock.releaseUnit = release.unit_sign;
        stock.quantity = +unitChange(stock.releaseUnit, stock.quantity);
        stock.remain_quantity = +unitChange(
          stock.releaseUnit,
          stock.remain_quantity,
        );
      }

      stock.quantity = stock.quantity + x.success_quantity;
      release.stockList.push(stock.id);
      return color;
    });

    if (list.length < 9) {
      const num = 9 - list.length;
      for (let i = 0; i < num; i++) {
        list.push({});
      }
    }

    commit("setReleaseForm", release);
    commit("setProductionColor", list);
    commit(
      "setCommaColor",
      JSON.parse(JSON.stringify(list)).map((x) => {
        x.success_quantity = usingComma(+x.success_quantity);
        return x;
      }),
    );
    this.dispatch("CALCULATE_STOCK_COLOR_TOTAL", list);
    this.dispatch("SET_STOCK_COLORLIST");
  },

  SET_STOCK_DOC_COLORLIST({ commit }, release) {
    const colorList = copyData(release.color_list).map((x) => {
      const color = x;
      color.isSuccess = true;
      return color;
    });
    commit("setReleaseForm", release);

    if (colorList.length < 9) {
      const num = 9 - colorList.length;
      for (let i = 0; i < num; i++) {
        colorList.push({});
      }
    }

    commit("setProductionColor", colorList);
    commit(
      "setCommaColor",
      JSON.parse(JSON.stringify(colorList)).map((x) => {
        x.quantity = usingComma(+x.quantity);
        return x;
      }),
    );
  },

  // 출고의뢰서 가공처 선택
  SET_RELEASE_REQUEST({ commit }, preset) {
    const release = this.state.isFabricRelease
      ? this.state.fabricRelease
      : this.state.releaseForm;

    const requestDoc = JSON.parse(JSON.stringify(release.requestDoc));

    if (!preset) {
      release.item = this.state.itemList.find((x) => x._id === requestDoc.item);

      release.place_forward = this.state.clientList.find(
        (x) =>
          x._id ===
          (requestDoc.kind === 7
            ? requestDoc.place_manufacture
            : requestDoc.inspection),
      );

      release.date_delivery =
        requestDoc.kind === 7 ? requestDoc.date_payment : release.date_delivery;
    }

    release.unit_sign = requestDoc.unit_sign ? requestDoc.unit_sign : "y";
    if (
      requestDoc.kind === 7 ||
      requestDoc.kind === 18 ||
      requestDoc.kind === 12
    ) {
      requestDoc.color_list = requestDoc.color_list.map((x, i) => {
        x.indexColorName = x.indexColorName
          ? x.indexColorName
          : `${i + 1}_${x.name}`;
        return x;
      });
      this.dispatch("SET_RELEASE_COLORLIST", requestDoc);
      this.dispatch("SET_RELEASE_COLOR_STYLE_LIST");
    }

    if (requestDoc.kind === 11) {
      release.unit_sign = requestDoc.unit_sign;
      this.dispatch("SET_RELEASE_APPEARANCE_COLORLIST", requestDoc);
    }

    if (requestDoc.kind === 12) {
      this.dispatch("SET_RELEASE_PRE");
      release.unit_sign = requestDoc.unit_sign;
    }

    if (requestDoc.kind === 0) {
      commit("setReleaseColorList");
      this.dispatch("SET_RELEASE_COLOR_STYLE_LIST");
    }

    if (requestDoc.shrigkage) release.shrigkage = requestDoc.shrigkage;

    this.dispatch("INIT_RELEASE_DOC_CALCULATE");
    commit("setReleaseForm", release);
  },

  // 재고 수량 출고
  async SET_STOCKDOC_REQUEST({ commit }) {
    const release = this.state.releaseForm;
    const colorList = this.state.productionColor.map((x) => {
      return {};
    });
    commit("setProductionColor", colorList);
    commit("setCommaColor", colorList);
    await this.dispatch("GET_STOCK_LIST");
    release.item = "";
    this.dispatch("INIT_RELEASE_DOC_CALCULATE");
    commit("setStockColorList");
    commit("setReleaseForm", release);
  },

  async SET_STOCK_COLORLIST({ commit }) {
    if (this.state.releaseForm.item)
      commit(
        "setStockColorList",
        this.state.stockList
          .filter((x) => x.item === this.state.releaseForm.item.item)
          .map((x, i) => {
            x.indexColorName = `${i + 1}_${x.name}`;
            return x;
          }),
      );
  },

  // 출고의뢰서 선택 가능한 color, style list 셋팅
  SET_RELEASE_COLORLIST({ commit }, data) {
    const list = { style: [], color: [] };

    list.style = data.style_list;
    list.color = data.color_list;

    commit("setReleaseColorList", list);
  },

  SET_RELEASE_FABRIC_COLORS({ commit }) {
    commit("setReleaseColorList", {
      color: [
        {
          indexColorName: "생 지",
          name: "생 지",
          quantity: "",
          isSuccess: true,
          _id: 1,
        },
      ],
    });
  },

  // 출고의뢰서 color List 설정
  SET_RELEASE_COLOR_STYLE_LIST({ commit }, data) {
    const list = [];
    if (data) {
      for (let i = 0; i < data.color.length; i++) {
        const color = {
          name: data.color[i].name,
          quantity: data.color[i].quantity,
          style: data.color[i].style,
          isSuccess: true,
          surcharge_unit: data.color[i].surcharge_unit
            ? data.color[i].surcharge_unit
            : "",
          indexColorName: data.color[i].indexColorName
            ? data.color[i].indexColorName
            : data.manufColor.find((x) => x.name === data.color[i].name)
                .indexColorName,
        };

        if (color.surcharge_unit)
          color.surchargeCommma = usingComma(color.surcharge_unit);

        if (data.purchase.length && data.purchase[i].color_surcharge_price)
          color.color_surcharge_price = data.purchase[i].color_surcharge_price;

        if (data.purchase.length && data.purchase[i].color_surcharge_unit)
          color.color_surcharge_unit = data.manufColor.find(
            (x) => x.indexColorName === color.indexColorName,
          ).color_surcharge_unit;

        list.push(color);
      }
    }

    if (list.length < 9) {
      const num = 9 - list.length;
      for (let i = 0; i < num; i++) {
        list.push({ isSuccess: false });
      }
    }

    this.dispatch("CALCULATE_COLOR_TOTAL", list);
    commit("setProductionColor", list);
    commit(
      "setCommaColor",
      JSON.parse(JSON.stringify(list)).map((x) => {
        x.quantity = usingComma(x.quantity);
        return x;
      }),
    );
  },

  //외관 검사서 ColorList
  SET_RELEASE_APPEARANCE_COLORLIST({ commit }, data) {
    const release = this.state.releaseForm;
    const item = this.state.productionOrderForm.manufacture.find(
      (x) => x.id === data.manufacture,
    ).item;
    const salesColor = this.state.releaseForm.sales
      ? this.state.releaseForm.sales.color_list
      : "";
    const manufactureColor = this.state.releaseForm.purchase
      ? this.state.releaseForm.purchase.color_list
      : "";

    const colorList = [];

    data.color_list.forEach((x, i) => {
      const color = {
        name: x.name,
        style: x.style,
        success_quantity: salesColor ? salesColor[i].success_quantity : "",
        remaing_quantity: x.remaing_quantity,
        fix_remaing_quantity: salesColor
          ? +salesColor[i].success_quantity + +x.remaing_quantity
          : x.remaing_quantity,
        fail_quantity: salesColor ? salesColor[i].fail_quantity : "",
        remaing_fail_quantity: x.remaing_fail_quantity,
        fix_remaing_fail_quantity: salesColor
          ? +salesColor[i].fail_quantity + +x.remaing_fail_quantity
          : x.remaing_fail_quantity,
        isSuccess: true,
        isFaill: true,
        indexColorName: x.indexColorName ? x.indexColorName : "",
        surcharge_unit:
          salesColor && salesColor[i].surcharge_unit
            ? salesColor[i].surcharge_unit
            : "",
      };

      if (color.surcharge_unit)
        color.surchargeCommma = usingComma(color.surcharge_unit);

      if (!color.success_quantity && !color.remaing_quantity)
        color.isSuccess = false;
      if (!color.fail_quantity && !color.remaing_fail_quantity)
        color.isFaill = false;

      if (manufactureColor && manufactureColor[i].color_surcharge_price)
        color.color_surcharge_price = manufactureColor[i].color_surcharge_price;

      release.place_sales = this.state.clientList.find(
        (x) => x._id === this.state.productionOrderForm.client,
      );
      release.item = this.state.itemList.find((x) => x._id === item);

      colorList.push(color);
    });

    if (colorList.length < 9) {
      const num = 9 - colorList.length;
      for (let i = 0; i < num; i++) {
        colorList.push({});
      }
    }

    this.dispatch("CALCULATE_APPEARANCE_COLOR_TOTAL", colorList);
    commit("setProductionColor", colorList);
    commit(
      "setCommaColor",
      JSON.parse(JSON.stringify(colorList)).map((x) => {
        x.success_quantity = usingComma(x.success_quantity);
        x.fail_quantity = usingComma(x.fail_quantity);
        return x;
      }),
    );

    this.state.releaseForm.salesBtn = true;
    this.state.releaseForm.purBtn = true;
    this.state.releaseForm.appenBtn = false;
  },

  // 선매입 ColorList
  SET_RELEASE_PRE({ commit }) {
    const list = [];
    if (!this.state.releaseForm._id && this.state.releaseForm.status !== "50")
      for (let i = 0; i < 9; i++) {
        list.push({});
      }
    else {
      this.state.releaseForm.sales.color_list.forEach((x) => {
        this.state.releaseForm.requestDoc.sales.color_list.findIndex(
          (y) => y.name === x.name,
        );

        list.push({
          name: x.name,
          style: x.style ? x.style : "",
          indexColorName: x.indexColorName ?? "",
          // success_quantity: usingComma(+x.success_quantity),
          success_quantity: +x.success_quantity,
          surcharge_unit: x.surcharge_unit ? x.surcharge_unit : "",
          surchargeCommma: x.surcharge_unit ? usingComma(x.surcharge_unit) : "",
          pur_index:
            x.pur_index && x.pur_index === 0
              ? x.pur_index
              : this.state.releaseForm.requestDoc.sales.color_list.findIndex(
                  (y) => y.name === x.name,
                ),
        });
      });

      if (list.length < 9) {
        const num = 9 - list.length;
        for (let i = 0; i < num; i++) {
          list.push({});
        }
      }
    }

    this.dispatch("SET_RELEASE_PRE_LIST");
    this.dispatch("CALCULATE_PRE_COLOR_TOTAL", list);
    commit("setProductionColor", list);

    commit(
      "setCommaColor",
      JSON.parse(JSON.stringify(list)).map((x) => {
        x.success_quantity = usingComma(+x.success_quantity);
        return x;
      }),
    );
  },

  // SET_COLOR_SALES_SURCHARGE_LIST({ commit }, release) {
  //   console.log(release);
  // },

  SET_RELEASE_PRE_LIST() {
    const list = this.state.releaseForm._id
      ? this.state.releaseForm.sales.color_list
      : [];

    this.state.releaseForm.requestDoc.remainList =
      this.state.releaseForm.requestDoc.sales.color_list.map((x, i) => {
        if (
          this.state.releaseForm.unit_sign !==
          this.state.releaseForm.requestDoc.unit_sign
        )
          x.success_quantity = +unitChange(
            this.state.releaseForm.unit_sign,
            x.success_quantity,
          );

        const color = {
          name: x.name,
          indexColorName: x.indexColorName ?? "",
          style: this.state.releaseForm.requestDoc.color_list[i].style
            ? this.state.releaseForm.requestDoc.color_list[i].style
            : "",
          success_quantity:
            +x.success_quantity +
            (list.length
              ? list
                  .filter((y) => i === y.pur_index)
                  .reduce(
                    (r, c) =>
                      r + (c.success_quantity ? +c.success_quantity : 0),
                    0,
                  )
              : 0),
          remaing_quantity: x.success_quantity,
          index: i,
        };

        return color;
      });
  },

  PUR_COLOR_UNIT_CHANGE({ commit }) {
    const release = this.state.releaseForm;

    release.requestDoc.remainList.forEach((x, i) => {
      x.success_quantity = +unitChange(release.unit_sign, x.success_quantity);
      if (
        release.requestDoc.color_list[i].quantity < x.success_quantity &&
        release.requestDoc.unit_sign === release.unit_sign
      )
        x.success_quantity = release.requestDoc.color_list[i].quantity;

      x.remaing_quantity = x.success_quantity;
    });

    this.state.productionColor.forEach((x) => {
      if (x.name) x.success_quantity = "";
    });

    commit(
      "setCommaColor",
      JSON.parse(JSON.stringify(this.state.productionColor)),
    );
  },

  // 출고의뢰서 color 수량 계산
  CALCULATE_COLOR_TOTAL({ commit }, data) {
    const total = data.reduce((r, c) => r + (c.quantity ? +c.quantity : 0), 0);
    commit("setColorTotal", total);
  },

  // 외관 검사서 출고 color 수량 계산
  CALCULATE_APPEARANCE_COLOR_TOTAL({ commit }, data) {
    const total = {
      sucessTot: 0,
      remaingTot: 0,
      failTot: 0,
      failRemaingTot: 0,
    };
    data.forEach((x) => {
      if (x.name) {
        total.sucessTot += +x.success_quantity;
        total.remaingTot += +x.remaing_quantity;
        total.failTot += +x.fail_quantity;
        total.failRemaingTot += +x.remaing_fail_quantity;
      }
    });
    commit("setColorTotal", total);
  },

  CALCULATE_PRE_COLOR_TOTAL({ commit }, data) {
    const total = {
      quantitTot: data.reduce(
        (r, c) => r + (c.success_quantity ? +c.success_quantity : 0),
        0,
      ),

      remaingTot: [
        ...new Set(
          data
            .map((x) => {
              return x.pur_index;
            })
            .filter((x) => x !== undefined),
        ),
      ].reduce(
        (r, c) =>
          r +
          (this.state.releaseForm.requestDoc.remainList[c]
            ? +this.state.releaseForm.requestDoc.remainList[c].remaing_quantity
            : 0),
        0,
      ),
    };
    commit("setColorTotal", total);
  },

  CALCULATE_STOCK_COLOR_TOTAL({ commit }, data) {
    const total = {
      quantitTot: data.reduce(
        (r, c) => r + (c.success_quantity ? +c.success_quantity : 0),
        0,
      ),
      remaingTot: [
        ...new Set(
          data
            .map((x) => {
              return x.index;
            })
            .filter((x) => x !== undefined),
        ),
      ].reduce((r, c) => r + +this.state.stockList[c].remain_quantity, 0),
    };

    commit("setColorTotal", total);
  },

  //출고의뢰서 초기화
  RELEASEFORM_CLEAR({ commit }) {
    commit("setReleaseColorList");
    this.dispatch("INIT_RELEASE_DOC_CALCULATE");
    this.dispatch("SET_RELEASE_COLOR_STYLE_LIST");
    this.dispatch("RELEACSE_DOC_INIT");
    this.dispatch("SET_PRESET_LIST", "release");
  },

  INIT_RELEASE_DOC_CALCULATE({ commit }) {
    commit("setPurchasCacul");
    commit("setSalesCacul");
  },

  // 신규 출고의뢰서 문서 초기화
  RELEACSE_DOC_INIT({ commit }) {
    const form = this.state.productionOrderForm;
    const user = {
      name: this.state.auth_name,
      grade: this.state.auth_grade,
      _id: this.state.auth_id,
    };
    const company = this.state.company;
    const release = {
      company: form.company,
      setKind: "", // 초기 가공의뢰서 또는 출고의뢰서 셋팅
      date_register: dateFormat(new Date(Date.now())), //작성일
      date_delivery: dateFormat(new Date(Date.now())), //출고일
      sales_unit_price: null, //매출 단가
      purchaseUnitPrice: null, // 생지 매입단가
      liaison: user, //로그인 유저
      currency_sign: form.eschamge_sign, // 단가 단위
      unit_sign: form.unit_sign,
      salesBtn: false,
      purBtn: true,
      appenBtn: false,
      basicBtn: false,
      stockBtn: false,
      fabricBtn: false,
      isModify: true,
      order: form.order,
      requestDoc: { kind: 7 },
      dialog: true,
      place_receive: "",
      place_sales: "",
      place_receive_memo: "", //입고지 메모
      remark: "",

      precautions:
        company.release_pre_status && company.release_pre
          ? company.release_pre
          : "",
    };

    // if (
    //   form.order_kind === "raw" &&
    //   this.state.productionOrderForm.manufacture.filter(
    //     (x) => x.status === "40",
    //   ).length === 0
    // ) {
    //   release.requestDoc.kind = "90";
    //   release.fabricBtn = true;
    //   this.dispatch("SET_FABRIC_COLOR");
    // }

    commit("setReleaseForm", release);
  },

  SET_FABRIC_COLOR({ commit }) {
    const list = [];
    for (let i = 0; i < 8; i++) list.push({ isSuccess: false });
    list[0].name = "생 지";

    commit("setProductionColor", list);
    commit("setCommaColor", list);
  },

  //가공의뢰서 초기화
  MANUFACTURE_CLEAR({ commit }, kind) {
    const form = this.state.productionOrderForm;
    const user = {
      name: this.state.auth_name,
      grade: this.state.auth_grade,
      _id: this.state.auth_id,
    };

    const colorData = colorEditedItemSet(
      kind === "clear" ? [] : form.color_list,
    );
    const company = this.state.company;

    const manufacture = {
      company: form.company,
      date_register: dateFormat(new Date(Date.now())), //작성일
      date_payment: dateFormat(new Date(Date.now())), //출고납기
      color_List: this.state.productionColor,
      liaison: user, //로그인 유저
      item: form.item
        ? this.state.itemList.find((x) => x._id === form.item)
        : "", //기본 item
      productionKind: "manufacture", //dialog 문서종류
      total_quantity_sum: colorData.total_quantity, //color 수량 총합
      unit_sign: form.unit_sign,
      currency_sign: form.eschamge_sign,
      order: form.order,
      isModify: true,
      dialog: true,
      style_list: [],
      shrigkage: "",
      isOrder: !form.progress.filter((x) => x.kind === 7 && x.status !== "99")
        .length,
      kind: 7,
      precautions:
        company.manu_pre_status && company.manu_pre ? company.manu_pre : "",
      confirmation:
        company.manu_confirm_status && company.manu_confirm
          ? company.manu_confirm
          : "",
      fabricBtn: false,
    };

    manufacture.post_processing = manufacture.item
      ? manufacture.item.post_processing
      : "";
    manufacture.width = manufacture.item ? manufacture.item.width : "";
    manufacture.full_width = manufacture.item
      ? manufacture.item.full_width
      : "";

    if (form.style_list.length && kind === "new")
      manufacture.style_list = JSON.parse(JSON.stringify(form.style_list));

    if (manufacture.item)
      manufacture.place_manufacture = this.state.clientList.find(
        (x) => x._id === manufacture.item.production,
      );

    this.dispatch("MANUFACTURE_COLOR_SET", colorData);

    manufacture.total_quantity_sum = this.state.productionColor
      .filter((x) => x.quantity || x.quantity_sample)
      .reduce(
        (r, c) =>
          r +
          (c.quantity_sample
            ? +c.quantity
              ? +c.quantity + +c.quantity_sample
              : +c.quantity_sample
            : +c.quantity),
        0,
      );

    commit("setManufactureForm", manufacture);

    // if (form.order_kind === "raw") {
    //   this.dispatch("SET_REFERANCEGROP");
    //   this.dispatch("SET_FABRIC_MANUFACTURE_FORM");
    // } else {
    //   this.dispatch("SET_PRESET_LIST", "manufacture");
    // }
    this.dispatch("SET_PRESET_LIST", "manufacture");
  },

  SET_REFERANCEGROP({ commit }) {
    const grop = copyData(
      [
        ...this.state.productionOrderForm.release,
        ...this.state.productionOrderForm.manufacture,
      ].filter(
        (x) =>
          (x.kind === 7 || x.kind === 18 || x.kind === 19) && x.status === "40",
      ),
    ).map((x, i) => {
      const doc = x;
      const client = this.state.clientList.find(
        (x) =>
          x.id ===
          (x.kind === 7 || x.kind === 18
            ? x.place_manufacture
            : x.place_forward),
      );
      doc.referenceName = `${i + 1}. ${client ? client.name : "미지정"}(${
        x.kind === 7
          ? "가공의뢰서"
          : x.kind === 18
          ? "생지 가공"
          : x.kind === 8
          ? "출고의뢰서"
          : "생지매입"
      })`;
      return doc;
    });
    commit("setDocReferanceGroup", grop);
  },

  SET_FABRIC_MANUFACTURE_FORM({ commit }) {
    const referenceDoc = this.state.docReferanceGroup.length
      ? this.state.docReferanceGroup[this.state.docReferanceGroup.length - 1]
      : "";

    if (referenceDoc) this.dispatch("SET_MANUFACTURE_REFERENCE", referenceDoc);
  },

  SET_MANUFACTURE_REFERENCE({ commit }, referenceDoc) {
    const manufacture = this.state.manufactureForm;
    manufacture.referenceDoc = referenceDoc;
    manufacture.item = this.state.itemList.find(
      (x) => x._id === referenceDoc.item,
    );
    manufacture.shrigkage = "";
    this.dispatch("SET_MANUFACTURE_SHRIGKAGE_COLOR", referenceDoc);
  },

  SET_MANUFACTURE_SHRIGKAGE_COLOR({ commit }, data) {
    const manufactureColors = this.state.productionColor.map((x) => {
      const color = x;
      if (x.name) {
        color.name = "";
        color.quantity = "";
        color.quantity_shrigkage = "";
      }
      return color;
    });

    if (data.kind !== 19) {
      copyData(data).color_list.forEach((x, i) => {
        const color = manufactureColors[i];
        color.name = x.name;
        color.quantity = "";
        color.quantity_shrigkage = x.quantity;
      });
    }

    commit("setProductionColor", manufactureColors);
    commit(
      "setCommaColor",
      copyData(manufactureColors).map((x) => {
        x.quantity_shrigkage = usingComma(+x.quantity_shrigkage);
        return x;
      }),
    );
  },

  MANUFACTURE_COLOR_SET({ commit }, colorData) {
    commit("setProductionColor", colorData.productionColor);
    commit(
      "setCommaColor",
      JSON.parse(JSON.stringify(colorData.productionColor)).map((x) => {
        x.quantity = usingComma(+x.quantity);
        x.quantity_shrigkage = usingComma(+x.quantity_shrigkage);
        return x;
      }),
    );
  },

  // preset List set
  SET_PRESET_LIST({ commit }, kind) {
    const progress = copyData(this.state.productionOrderForm.progress);
    const list = progress
      .filter((x) =>
        kind === "manufacture"
          ? x.kind === 7
          : x.kind === 8 ||
            x.kind === 9 ||
            x.kind === 10 ||
            x.kind === 12 ||
            x.kind === 15 ||
            x.kind === 16 ||
            x.kind === 17,
      )
      .map((x, i) => {
        let progress = "";
        if (kind === "manufacture") progress = presetProgress(x, i);
        else {
          const release = this.state.productionOrderForm.release.find(
            (y) => y.id === x.id,
          );

          if (!release) return;

          const id = release.appearance
            ? release.appearance
            : release.release
            ? release.release
            : release.manufacture;

          if (id) {
            const data = this.state.productionOrderForm.progress.find(
              (y) => y.id === id,
            );

            if (data) progress = presetProgress(x, i);
          } else {
            progress = presetProgress(x, i);
          }
        }

        if (progress) return progress;
      });

    commit("setPresetList", list);
  },

  //--------------------------------------------------------------
  //2.3 외관 검사서 parse ----------------------------------------------
  //외관 검사서 초기화
  async APPEARANCE_CLEAR({ commit }) {
    const form = this.state.productionOrderForm;
    const user = {
      name: this.state.auth_name,
      grade: this.state.auth_grade,
      _id: this.state.auth_id,
    };
    const appearance = {
      company: form.company,
      date_register: dateFormat(new Date(Date.now())), //작성일
      kind: 11, // 검사서 종류
      liaison: user, //로그인 유저
      order: form.order,
      dialog: true,
      color_list: [],
      release: form.release.find((x) => x.kind === 10 && x.status === "40"),
    };

    commit("setAppearanceList");
    commit("setAppearanceForm", appearance);

    this.dispatch("SET_APPEARANCE_REQUESTAPPEARANCE");
  },

  SET_OPEN_APPEARANCE_DIALOG({ commit }) {
    commit("setAppearanceList");
    const appearance = JSON.parse(
      JSON.stringify(
        this.state.productionOrderForm.appearance.find(
          (x) => x.id === this.state.productionProgress.id,
        ),
      ),
    );
    const release = this.state.requsestAppearance.find(
      (doc) => doc.id === appearance.release,
    );

    appearance.liaison = this.state.userList.find(
      (x) => x._id === appearance.liaison,
    );
    appearance.release = release;

    appearance.order = this.state.productionOrderForm.order;
    appearance.date_register = dateFormat(appearance.date_register);
    appearance.dialog = true;

    appearance.isModify =
      this.state.productionOrderForm.release.filter(
        (x) =>
          x.appearance && x.status === "40" && x.appearance === appearance.id,
      ).length !== 0;

    commit("setAppearanceForm", appearance);
    this.dispatch("SET_APPEARANCE_REQUESTAPPEARANCE");
  },

  // 외관 검사서 에 검사 의뢰서 set
  SET_APPEARANCE_REQUESTAPPEARANCE() {
    const appearance = this.state.appearanceForm;

    appearance.item = this.state.itemList.find(
      (x) => x._id === appearance.release.item,
    );
    appearance.inspection = this.state.clientList.find(
      (x) => x._id === appearance.release.place_receive,
    );
    appearance.unit_sign = appearance.release.unit_sign;

    if (!appearance.id) {
      appearance.color_list = [];
      appearance.release.color_list.forEach((x) => {
        const color = {
          name: x.name,
          style: x.style,
          check_quantity: x.quantity,
          sucess_quantity: "",
          fail_quantity: "",
          indexColorName: x.indexColorName ? x.indexColorName : "",
        };
        appearance.color_list.push(color);
      });
    }

    if (appearance.color_list.length < 5) {
      let num = 5 - appearance.color_list.length;
      for (let i = 0; i < num; i++) {
        appearance.color_list.push({});
      }
    }

    appearance.commaColor = appearance.color_list.map((x) => {
      const color = {
        sucess_quantity: usingComma(x.sucess_quantity),
        fail_quantity: usingComma(x.fail_quantity),
      };
      return color;
    });
  },
  //--------------------------------------------------------------

  //3.생산 공통 parse ----------------------------------------------
  /**
   * 저장 데이터 파싱 하는 함수
   * 파싱된 데이터 저장
   */
  async PARSE_PRODUCTION_DATA({ commit, state }) {
    commit("setAPILoading", true);

    if (this.state.cancelDialog.dialog)
      await this.dispatch("PRODUCTION_PAGE_DELETE");
    else if (
      (state.manufactureForm.dialog ||
        state.releaseForm.dialog ||
        state.appearanceForm.dialog) &&
      !this.state.cancelDialog.dialog
    )
      this.dispatch("PRODUCTION_PAGE_SAVE");

    commit("setAPILoading", false);
  },

  PRODUCTION_PAGE_SAVE({ commit, state }) {
    //
    const manufacture = state.manufactureForm;
    const release = state.releaseForm;
    const appearance = state.appearanceForm;
    // 전달 받은 release 값에 kind 또는 참조 문서에 따라 stock 문서 변경 형식의 kind 를 반환 한다.
    // 0 : 검색 x
    // 1: 재고의뢰서, 선매입 신규 작성 (반려 문서 재승인 요청)
    // 2 : 재고 의뢰서, 선매입 수정
    // 3 : 선매입
    // 4 : 재고 수량 출고
    const makeModifyStockKind = (release) => {
      let kind = 0;

      if (!release.id && (release.stockBtn || release.preBtn)) kind = 1;

      if (release.id && (release.kind === 12 || release.kind === 17)) {
        // 반려 문서를 재승인 요청 또는 저장 할 경우 신규 작성과 동일 하기에 kind 를 1번으로 예외 처리 한다.
        kind = release.status === "30" ? 1 : 2;
      }

      if (release.requestDoc && release.requestDoc.kind === 12) kind = 3;

      if (release.requestDoc && release.requestDoc.kind === 17) kind = 4;

      return kind;
    };

    // 가공의뢰서 surcharge color_list 를 반환 하는 함수
    // 만약 출고의뢰서의 매입
    const makeManufactuerColor = (grade, isModify) => {
      let saveManufactureColors = state.releaseColorList.color;
      if (grade < 2 && isModify && release.status === "40") {
        const manufacture = state.saveData.manufacture.find(
          (doc) => doc.id === release.requestDoc.id,
        );

        for (let i = 0; i < saveManufactureColors.length; i++) {
          const oldColor = manufacture.color_list[i];
          const newColor = saveManufactureColors[i];

          if (
            oldColor.color_surcharge_price &&
            oldColor.color_surcharge_price.is_used
          )
            newColor.color_surcharge_price.is_used = true;
        }
      }

      return saveManufactureColors;
    };

    try {
      let param = {
        order: state.productionOrderForm.order,
        company: state.productionOrderForm.company,
        grade: +state.auth_grade,
        loginUser: state.auth_id,
      };

      if (manufacture.dialog)
        param = {
          ...param,
          ...{
            docType: "manufacture",
            inputDoc: manufacture,
            isModify: !!manufacture.id,
            id: manufacture.id,
            inputColor: state.productionColor.length
              ? state.productionColor.filter(
                  (color) => color.name && color.quantity,
                )
              : [],
          },
        };

      if (release.dialog) {
        const releaseParam = {
          docType: "release",
          inputDoc: release,
          isModify: !!release.id,
          id: release.id,
          saveReleaseColor: state.saveReleaseColor,
          modifyStockKind: makeModifyStockKind(release),
        };

        if (release.purBtn && release.requestDoc.kind !== 11) {
          releaseParam.saveManufactureColors = makeManufactuerColor(
            param.grade,
            releaseParam.isModify,
          );
        }

        if (release.requestDoc.kind === 11)
          releaseParam.saveAppearanceColor = state.productionColor.filter(
            (color) => color.name,
          );

        if (release.requestDoc.kind === 17)
          releaseParam.modifyStocks = state.saveStock;

        param = {
          ...param,
          ...releaseParam,
        };
      }

      if (appearance.dialog)
        param = {
          ...param,
          ...{
            order: state.productionOrderForm.order,
            docType: "appearance",
            inputDoc: appearance,
            isModify: !!appearance.id,
            id: appearance.id,
          },
        };

      this.dispatch("CALL_PRODUCTION_PAGE_SAVE_API", param);
    } catch (e) {
      console.log(e);
    }
  },

  async CALL_PRODUCTION_PAGE_SAVE_API({ commit }, param) {
    try {
      const result = await productionPageSave(param);

      if (result.status !== 200) throw `error : ${result.status}`;

      this.dispatch("SAVE_INIT_PAGE");
      this.dispatch("PRODUCTION_DIALOG_INIT");
      if (this.state.confirmDialog) commit("setProductionConfirm");
    } catch (e) {
      console.log(e);
    }
  },

  async PRODUCTION_PAGE_DELETE({ commit, state }) {
    commit("setCancelDialog");
    const manufacture = state.manufactureForm;
    const release = state.releaseForm;
    const appearance = state.appearanceForm;
    // 출고의뢰서
    const makeModifyStockKind = (release) => {
      let kind = 0;
      if (release.kind === 12 || release.kind === 17) kind = 2;
      if (release.requestDoc.kind === 12 && 2 < +state.auth_grade) kind = 3;
      if (release.requestDoc.kind === 17 && 2 < +state.auth_grade) kind = 4;

      return kind;
    };
    const param = {
      order: state.productionOrderForm.order,
      company: state.productionOrderForm.company,
      grade: +state.auth_grade,
      loginUser: state.auth_id,
    };

    if (manufacture.dialog) {
      param.docType = "manufacture";
      param.docId = manufacture.id;
    }

    if (release.dialog) {
      param.docType = "release";
      param.docId = release.id;
      param.inputDoc = release;
      param.modifyStockKind = makeModifyStockKind(release);

      if (release.requestDoc.kind === 17 && 2 < param.grade)
        param.modifyStocks = state.saveStock;
    }

    if (appearance.dialog) {
      param.docType = "appearance";
      param.docId = appearance.id;
    }

    this.dispatch("CALL_PRODUCTION_PAGE_DELETE_API", param);
  },

  async CALL_PRODUCTION_PAGE_DELETE_API({ commit }, param) {
    try {
      const result = await productionPageDelete(param);
      if (result.status !== 200) throw `error : ${result.status}`;

      this.dispatch("SAVE_INIT_PAGE");
      this.dispatch("PRODUCTION_DIALOG_INIT");
    } catch (e) {
      console.log(e);
    }
  },

  async CALL_PRODUCTION_PAGE_NEW_REQ_DELETE({ commit }, param) {
    try {
      const result = await productionPageNewDocDelete(param);

      if (result.status !== 200) throw `error : ${result.status}`;

      this.dispatch("SAVE_INIT_PAGE");
      this.dispatch("PRODUCTION_DIALOG_INIT");
      commit("setCancelDialog");
    } catch (e) {
      console.log(e);
    }
  },

  async STOCK_VERSION_CHECK({ commit }, release) {
    await this.dispatch("FIND_STOCK_DATA", release);
    if (!this.state.selectStock.find((x) => x.version !== 0))
      this.dispatch("SAVE_PARSE_DATA", "production");
    else {
      commit("setProductionConfirm");
      commit("setReleaseForm");
      alert("재고 수량에 변경이 있습니다");
      this.dispatch("PRODUCTION_SAVE_END");
    }
  },

  async REQUEST_STOCK_VERSION_CHECK({ commit }) {
    const saveStockList = [];
    const checkColorList = copyData(this.state.salesCacul.color_list);
    let isSave = true;

    if (this.state.releaseForm.stockList)
      this.state.releaseForm.stockList.forEach((x) => {
        const color = checkColorList.find((y) => y.indexColorName === x);
        if (!color)
          checkColorList.push({
            indexColorName: x,
          });
      });

    for (let i = 0; i < checkColorList.length; i++) {
      if (
        !saveStockList.find((x) => checkColorList[i].indexColorName === x.id)
      ) {
        const stock = this.state.stockList.find(
          (x) => x.id === checkColorList[i].indexColorName,
        );
        const saveStock = await getStock(this.state.auth_company, stock.id);
        isSave = saveStock.version === stock.version;
        if (!isSave) break;
        else {
          saveStock.version = ++saveStock.version;
          saveStock.remain_quantity =
            this.state.releaseForm.unit_sign !== saveStock.unit_sign
              ? +unitChange(saveStock.unit_sign, stock.remain_quantity)
              : stock.remain_quantity;
          saveStockList.push(saveStock);
        }
      }
    }

    if (isSave) {
      commit("setSaveStock", saveStockList);
      this.state.releaseForm.item = this.state.itemList.find(
        (x) => x.name === this.state.releaseForm.item.item,
      );
      this.dispatch("SAVE_PARSE_DATA", "production");
    } else {
      commit("setProductionConfirm");
      commit("setReleaseForm");
      alert("재고 수량에 변경이 있습니다");
      this.dispatch("PRODUCTION_SAVE_END");
    }
  },

  async REQUEST_STOCK_VERSION_CHECK_CANCEL({ commit }) {
    const saveStockList = [];
    const checkColorList = this.state.productionOrderForm.release.find(
      (x) => x.id === this.state.releaseForm.id,
    ).color_list;
    let isSave = true;

    for (let i = 0; i < checkColorList.length; i++) {
      if (
        !saveStockList.find((x) => checkColorList[i].indexColorName === x.id)
      ) {
        const stock = this.state.stockList.find(
          (x) => x.id === checkColorList[i].indexColorName,
        );
        const saveStock = await getStock(this.state.auth_company, stock.id);
        isSave = saveStock.version === stock.version;
        if (!isSave) break;
        else {
          saveStock.version = saveStock.version ? saveStock.version + 1 : 1;
          saveStock.remain_quantity =
            this.state.releaseForm.unit_sign !== saveStock.unit_sign
              ? +unitChange(saveStock.unit_sign, stock.quantity)
              : stock.quantity;
        }
        saveStockList.push(saveStock);
      }
    }

    if (isSave) {
      commit("setSaveStock", saveStockList);
      this.state.releaseForm.item = this.state.itemList.find(
        (x) => x.name === this.state.releaseForm.item.item,
      );
      this.dispatch("SAVE_PARSE_DATA", "production");
    } else {
      commit("setProductionConfirm");
      commit("setReleaseForm");
      alert("재고 수량에 변경이 있습니다");
      this.dispatch("PRODUCTION_SAVE_END");
    }
  },

  async PRODUCTION_SAVE_END({ commit }) {
    this._vm.$socket.emit("order", { company: this.state.auth_company });
    await this.dispatch(
      "SELECT_ORDER",
      this.state.productionOrderForm
        ? this.state.productionOrderForm
        : this.state.saveOrder,
    );
    this.dispatch("PARSE_ROUTE_API_DATA_CALL");
    await this.dispatch("SET_PRODUCTION_ORDER", this.state.selectOrder);
    this.dispatch("INIT_RELEASE_DOC_CALCULATE");
    commit("setProductionProgress");
    commit("setAPILoading", false);
  },

  PRODUCTION_SAVE_ERROR() {
    alert("order 변경이 있습니다");
    this.dispatch("PRODUCTION_SAVE_END");
  },

  // 생산 프로그래스 생성 함수
  ADD_ORDER_PROGRESS({ commit }, order) {
    const beforeProgress = this.state.productionProgress;
    const saveData =
      beforeProgress.kind === 7 || beforeProgress.kind === 18
        ? order.manufacture.find((x) => x.id === beforeProgress.id)
        : beforeProgress.kind === 11
        ? order.appearance.find((x) => x.id === beforeProgress.id)
        : order.release.find((x) => x.id === beforeProgress.id);
    saveData.order = order.order;
    if (
      beforeProgress.kind === 7 ||
      beforeProgress.kind === 18 ||
      beforeProgress.kind === 8 ||
      beforeProgress.kind === 9 ||
      beforeProgress.kind === 10 ||
      beforeProgress.kind === 12 ||
      beforeProgress.kind === 15 ||
      beforeProgress.kind === 16 ||
      beforeProgress.kind === 17 ||
      beforeProgress.kind === 19
    )
      this.dispatch("SET_APPROVAL_SAVE_DATA", saveData);

    const progress = {
      status: saveData.status, //  승인상태
      date_register: beforeProgress.date_register
        ? beforeProgress.date_register
        : new Date(Date.now()), // 작성 날짜
      user: beforeProgress.user ? beforeProgress.user : this.state.auth_name, // 작성 날짜, // 작성자 이름
      kind: beforeProgress.kind, // 의뢰서 종류
      memo: "", // 메모
      log:
        this.state.auth_grade <= 2 && saveData.status === "50"
          ? beforeProgress.log
          : progressLogMake(
              saveData.kind === 7 || saveData.kind === 18
                ? this.state.manufactureForm
                : saveData.kind === 11
                ? this.state.appearanceForm
                : this.state.releaseForm,
              saveData.kind,
            ), // 내용
      id: beforeProgress.id, // 문서 id
      history: beforeProgress.history ? beforeProgress.history : [],
    };

    if (saveData.bakId)
      progress.history.push({
        id: saveData.bakId,
        log: beforeProgress.log,
        date: new Date(Date.now()),
      });

    beforeProgress.index || beforeProgress.isIndex
      ? (order.progress[beforeProgress.index] = progress)
      : order.progress.push(progress);
  },

  // approval 파싱 함수
  SET_APPROVAL_SAVE_DATA({ commit }, form) {
    const beforApproval = this.state.approvalForm._id
      ? this.state.approvalForm
      : "";
    form.memo = this.state.approvalMemo;

    const approvalData = {
      company: this.state.auth_company,
      status:
        form.status === "승인"
          ? "40"
          : form.status === "요청"
          ? "20"
          : form.status,
      name:
        router.app._route.name === "production"
          ? form.kind === 7
            ? "manufacture"
            : "release"
          : form.name
          ? form.name
          : router.app._route.name,

      route: router.app._route.name,
      id: router.app._route.name === "order" ? form.order : form.id,
      order: form.order ? form.order : "",
      liaison:
        router.app._route.name === "purchasecalculate" ||
        router.app._route.name === "purchasedeadline"
          ? form.saveRelease.liaison
          : form.liaison,
      item:
        router.app._route.name === "claim"
          ? form.item
          : router.app._route.name === "purchasecalculate" ||
            router.app._route.name === "purchasedeadline" ||
            router.app._route.name === "salescalculate" ||
            router.app._route.name === "salesdeadline"
          ? form.item._id
          : form.item,
      buyer:
        router.app._route.name === "order" || router.app._route.name === "stock"
          ? form.client
          : router.app._route.name === "claim"
          ? form.client
          : router.app._route.name === "purchasecalculate" ||
            router.app._route.name === "purchasedeadline" ||
            router.app._route.name === "salescalculate" ||
            router.app._route.name === "salesdeadline"
          ? this.state.clientList.find((x) => x.name === form.buyer)._id
          : this.state.productionOrderForm?.client,

      data:
        router.app._route.name === "order"
          ? form.saveKind === "modify"
            ? JSON.parse(JSON.stringify(form))
            : {}
          : form.modifyData
          ? form.modifyData
          : form.saveData,
      memo: form.memo,
    };
    if (beforApproval) approvalData._id = beforApproval._id;

    commit("setSaveApproval", approvalData);
    commit("setApprovalForm");
  },

  async SET_CLAIM_APPROVAL_SAVE_DATA({ commit }, target) {
    const form = this.state.claimForm;
    await this.dispatch("getaApprovalComId", {
      id: target === "arise" ? form.arise_id : form.pass_id,
      route: router.app._route.name,
    });
    let approvalData = {};
    const approval = this.state.approvalForm;

    if (Object.keys(approval).length !== 0) {
      approvalData = { ...approval };
      approvalData.status =
        target === "arise" ? form.arise_status : form.pass_status;
      approvalData.data = form.saveData;
      approvalData.memo = this.state.approvalMemo;
      approvalData.item = null;
      approvalData.buyer = null;
      if (form.item) approvalData.item = form.item;
      if (form.buyer) approvalData.buyer = form.buyer;
    } else {
      approvalData = {
        company: this.state.auth_company,
        status: target === "arise" ? form.arise_status : form.pass_status,
        name: router.app._route.name,
        route: router.app._route.name,
        id: target === "arise" ? form.arise_id : form.pass_id,
        liaison: this.state.auth_id,

        data: form.saveData,
        memo: this.state.approvalMemo,
      };
      approvalData.item = null;
      approvalData.buyer = null;
      if (form.item) approvalData.item = form.item;
      if (form.buyer) approvalData.buyer = form.buyer;
    }

    commit("setSaveApproval", approvalData);
    commit("setApprovalForm");
  },

  // 생산페이지 매입 매출 촐고의뢰서 정산 approval 검색 로직
  async FIND_CALCULATE_APPROVAL({ commit }, id) {
    try {
      const approvalList = await getaApprovalRouteComId(
        this.state.auth_company,
        id,
      );
      const list = [];
      approvalList.data.forEach((x) => {
        if (x.router !== "production") {
          x.status = "99";
          list.push(x);
        }
      });

      list.length ? commit("setRelApprovalList", list) : "";
    } catch (e) {
      alert(e);
    }
  },

  // 매입 매출 출고의뢰서 정산 approval 취소 로직
  async CANCEL_CALCULATE_APPROVAL({ commit }) {
    for (let i = 0; i < this.state.relApprovalList.length; i++) {
      try {
        const result = await putApproval(this.state.relApprovalList[i]);

        if (result.status !== 200) throw `error ${result.status}`;
      } catch (e) {
        alert(e);
      }
    }
    commit("setRelApprovalList");
  },

  //생산 페이지 끝 =========================================================================

  //requestDialog 시작 =========================================================================

  async SELECT_APPROVAL_LIST({ commit }) {
    try {
      const result = await getApprovalByStatus(
        this.state.auth_company,
        {},
        [10, 20, 50, 60, 90],
      );

      commit("setRequestList");
      // console.log(result.data);
      // await this.dispatch("SET_ITEMLIST");
      // await this.dispatch("SET_CLIENTLIST");
      // await this.dispatch("SET_USERLIST");
      // await this.dispatch("SET_CTYPELIST");
      if (result.data.length)
        this.state.auth_grade > 2
          ? this.dispatch("SET_REQUEST_APPROVAL", result.data)
          : this.dispatch("SET_USER_APPROVAL_LIST", result.data);
    } catch (e) {
      alert(e);
    }
  },

  SET_REQUEST_APPROVAL({ commit }, approvalList) {
    if (this.state.requestList.length) return;
    const requestList = this.state.requestList;
    const order = { name: "order", list: [] };
    const release = { name: "release", list: [] };
    const manufacture = { name: "manufacture", list: [] };
    const claim = { name: "claim", list: [] };
    const purchasecalculate = {
      name: "purchasecalculate",
      list: [],
      route: "purchasecalculate",
    };
    const salescalculate = {
      name: "salescalculate",
      list: [],
      route: "salescalculate",
    };
    const purchasedeadline = {
      name: "purchasedeadline",
      list: [],
      route: "purchasedeadline",
    };
    const salesdeadline = {
      name: "salesdeadline",
      list: [],
      route: "salesdeadline",
    };
    const stockline = {
      name: "stock",
      list: [],
      route: "stock",
    };
    const useStockline = {
      name: "useStock",
      list: [],
      route: "stock",
    };
    const deleteStockline = {
      name: "deleteStock",
      list: [],
      route: "stock",
    };

    approvalList.forEach(async (x) => {
      // if (x.name === "claim") {
      //   x.order = x.beforeOrder ? x.beforeOrder : "미선택";
      // } else if (x.name === "endClaim") {
      //   x.order = x.beforeOrder ? x.beforeOrder : "미선택";
      // } else if (x.name === "stock") x.order;
      // else x.order = x.id.split("_")[0];

      if (x.route === "order") order.list.push(x);
      if (x.route === "production")
        x.name === "manufacture"
          ? manufacture.list.push(x)
          : release.list.push(x);
      if (x.route === "claim") claim.list.push(x);
      if (x.route === "endClaim") claim.list.push(x);
      if (x.route === "purchasecalculate") purchasecalculate.list.push(x);
      if (x.route === "purchasedeadline") purchasedeadline.list.push(x);
      if (x.route === "salescalculate") salescalculate.list.push(x);
      if (x.route === "salesdeadline") salesdeadline.list.push(x);
      if (x.name === "stock") stockline.list.push(x);
      if (x.name === "useStock") useStockline.list.push(x);
      if (x.name === "deleteStock") deleteStockline.list.push(x);
    });

    if (order.list.length) requestList.push(order);
    if (release.list.length) requestList.push(release);
    if (manufacture.list.length) requestList.push(manufacture);
    if (claim.list.length) requestList.push(claim);

    if (purchasecalculate.list.length) requestList.push(purchasecalculate);
    if (purchasedeadline.list.length) requestList.push(purchasedeadline);
    if (salescalculate.list.length) requestList.push(salescalculate);
    if (salesdeadline.list.length) requestList.push(salesdeadline);
    if (stockline.list.length) requestList.push(stockline);
    if (useStockline.list.length) requestList.push(useStockline);
    if (deleteStockline.list.length) requestList.push(deleteStockline);
  },

  SET_USER_APPROVAL_LIST({ commit }, approvalList) {
    const requestList = [];
    approvalList.forEach((x) => {
      if (x.liaisonName === this.state.auth_name) {
        // if (x.name === "claim") {
        //   x.order = x.beforeOrder ? x.beforeOrder : "미선택";
        // } else if (x.name === "endClaim") {
        //   x.order = x.beforeOrder ? x.beforeOrder : "미선택";
        // } else if (x.name === "stock") x.order = x.order ? x.order : "";
        // else {
        //   if (x.data && x.data.kind === "클레임") x.order = x.data.order;
        //   else x.order = x.id.split("_")[0];
        // }

        requestList.push(x);
      }
    });

    requestList.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
    commit("setRequestList", requestList);
  },

  async SET_REQUEST_DATA({ commit }, name) {
    commit("setRequestForm");
    let requestData = this.state.requestList.find((x) => x.name === name);

    if (!requestData && this.state.requestList.length)
      requestData = this.state.requestList[0];

    if (!requestData) {
      this.commit("setRequestDialog");
    }

    if (requestData) requestData.kind = 1;

    commit("setRequestSetList", requestData);
  },

  SET_REQUEST_CALCULATE_DEADLINE({ commit }, name) {
    let requestData = this.state.requestList.find((x) => x.name === name);
    this.dispatch("SET_REQUEST_ORDER_LIST", name);
    requestData.kind = 2;

    commit("setRequestSetList", requestData);
  },

  SET_REQUEST_ORDER_LIST({ commit }, name) {
    if (name === "order") this.dispatch("ORDER_ORDERLIST");

    if (name === "manufacture" || name === "release")
      this.dispatch("PRODUCTION_ORDERLIST");

    if (name === "claim") {
      this.dispatch("SET_CLAIMLIST");
      this.dispatch("SET_ORDERLIST_DATA");
    }

    if (
      name === "purchasecalculate" &&
      router.app._route.name === "purchasecalculate"
    )
      this.dispatch("GET_RELEASELIST", {
        kind: "purchasecalculate",
      });

    if (
      name === "purchasedeadline" &&
      router.app._route.name === "purchasedeadline"
    )
      this.dispatch("GET_RELEASELIST", {
        kind: "purchasedeadline",
      });

    if (
      name === "salescalculate" &&
      router.app._route.name === "salescalculate"
    )
      this.dispatch("GET_RELEASELIST", {
        kind: "salescalculate",
      });

    if (name === "salesdeadline" && router.app._route.name === "salesdeadline")
      this.dispatch("GET_RELEASELIST", {
        kind: "salesdeadline",
      });
  },

  async SET_HEADER_ALERT({ commit }) {
    try {
      commit("clearHeaderAlert");

      const approvalList =
        this.state.auth_grade < 2
          ? await getApprovalCountByStatus(
              this.state.auth_company,
              { liaison: this.state.auth_id },
              [30, 40],
            )
          : await getApprovalCountByStatus(
              this.state.auth_company,
              {},
              [10, 20, 50, 90],
            );
      approvalList.data = approvalList.data.sort((a, b) => {
        if (a._id.name < b._id.name) return 1;
        if (a._id.name > b._id.name) return -1;
        if (a._id.name === b._id.name) return 0;
      });

      commit("setHeaderAlert", approvalList.data);
    } catch (e) {
      alert(e.response.data.message ? e.response.data.message : e.message);
    }
  },
  //requestDialog 끝 =========================================================================

  //프린트  =========================================================================
  async SET_ORDER_PRINT({ commit }, order) {
    const printForm = JSON.parse(JSON.stringify(order));
    if (!this.state.orderList.length) await this.dispatch("SET_ORDERLIST_DATA");
    const findOrder = this.state.orderList.find(
      (x) => x.order === printForm.order,
    );
    if (!_.isEmpty(findOrder))
      printForm.order =
        findOrder.input_order && this.state.company.input_order_status
          ? findOrder.input_order
          : printForm.order;
    printForm.purchase_classification =
      printForm.purchase_classification === "domestic" ? "내수 " : "수입";
    printForm.client = this.state.clientList.find(
      (x) => x._id === order.client,
    );
    printForm.order_kind =
      order.order_kind === "finished"
        ? "완제품"
        : order.order_kind === "post"
        ? "후가공"
        : "생지";
    printForm.ctype = this.state.ctypeList.find((x) => x._id === order.ctype);
    printForm.item = this.state.itemList.find((x) => x._id === order.item);
    printForm.eschamge_sign = order.eschamge_sign;

    printForm.full_width = printForm.item?.full_width
      ? String(printForm.item.full_width)
      : "";

    printForm.unit_width = printForm.item?.unit_width
      ? printForm.item.unit_width === "in"
        ? "inch"
        : "cm"
      : "";
    printForm.unit_weight = printForm.item?.unit_weight
      ? printForm.item.unit_weight
      : "";
    printForm.liaison = this.state.userList.find(
      (x) => x._id === order.liaison,
    );
    printForm.color_total = parseFloat(
      printForm.color_list
        .reduce((r, i) => (i.quantity ? r + +i.quantity : r), 0)
        .toFixed(2),
    );
    printForm.color_surchage_total = parseFloat(
      printForm.color_list
        .reduce((r, i) => (i.surcharge_price ? r + +i.surcharge_price : r), 0)
        .toFixed(2),
    );
    const colorPrice = parseFloat(
      printForm.color_list
        .reduce((r, i) => (i.unit_price ? r + i.unit_price * i.quantity : r), 0)
        .toFixed(2),
    );

    printForm.color_unit_total = colorPrice + printForm.color_surchage_total;
    printForm.date_duedate = printForm.date_duedate
      ? dateFormat(printForm.date_duedate)
      : "";
    printForm.date_modify = printForm.date_modify
      ? dateFormat(printForm.date_modify)
      : "";
    printForm.date_register = printForm.date_register
      ? dateFormat(printForm.date_register)
      : "";

    commit("setPrintForm", printForm);
  },
  async SET_MANUFACTURE_PRINT({ commit }, manufacture) {
    const printForm = JSON.parse(JSON.stringify(manufacture));

    printForm.order = this.state.productionOrderForm.order;
    if (!this.state.orderList.length) await this.dispatch("SET_ORDERLIST_DATA");
    const findOrder = this.state.orderList.find(
      (x) => x.order === printForm.order,
    );

    if (!_.isEmpty(findOrder))
      printForm.order =
        findOrder.input_order && this.state.company.input_order_status
          ? findOrder.input_order
          : printForm.order;

    printForm.place_manufacture = this.state.clientList.find(
      (x) => x._id === manufacture.place_manufacture,
    );
    printForm.liaison = this.state.userList.find(
      (x) => x._id === manufacture.liaison,
    );
    const item = this.state.itemList.find((x) => x._id === manufacture.item);
    if (item)
      printForm.item = this.state.company.manu_item
        ? item.name
        : item.production_item;
    if (manufacture.inspection)
      printForm.inspection = this.state.clientList.find(
        (x) => x._id === manufacture.inspection,
      );
    printForm.date_payment = dateFormat(printForm.date_payment);

    printForm.total_quantity = manufacture.color_list.reduce(
      (r, c) => r + c.quantity,
      0,
    );
    printForm.full_width = manufacture.full_width
      ? String(manufacture.full_width)
      : printForm?.item.full_width
      ? String(printForm.item.full_width)
      : "";

    printForm.unit_width = manufacture.unit_width
      ? manufacture.unit_width === "in"
        ? "inch"
        : "cm"
      : printForm?.item.unit_width
      ? printForm?.item.unit_width === "in"
        ? "inch"
        : "cm"
      : "";
    printForm.shrigkage = manufacture.shrigkage
      ? "" + manufacture.shrigkage
      : "";

    const emailClinet = this.state.clientList.find(
      (x) =>
        x.name ===
        (typeof printForm.place_manufacture === "object"
          ? printForm.place_manufacture.name
          : printForm.place_forward),
    );

    printForm.logo = this.state.auth_logo ? this.state.auth_logo : null;
    printForm.logoType = this.state.company.logoType
      ? this.state.company.logoType
      : null;
    printForm.reciver = emailClinet ? emailClinet.email : "";
    printForm.tel = this.state.company.tel;
    printForm.fax = this.state.company.fax;

    printForm.print_paymentList = this.state.company.print_paymentList.length
      ? this.state.company.print_paymentList
      : "";
    printForm.paymentList_count = printForm.print_paymentList
      ? printForm.print_paymentList.length
      : 0;

    printForm.company = this.state.auth_company.toUpperCase();
    printForm.style = printForm.style_list.join(", ");

    commit("setPrintForm", printForm);
  },
  async SET_RELEASE_PRINT({ commit }, release) {
    const printForm = JSON.parse(JSON.stringify(release));
    if (!this.state.orderList.length) await this.dispatch("SET_ORDERLIST_DATA");

    const findOrder = this.state.orderList.find(
      (x) => x.order === printForm.order,
    );

    if (!_.isEmpty(findOrder))
      printForm.order =
        findOrder.input_order && this.state.company.input_order_status
          ? findOrder.input_order
          : printForm.order;
    printForm.place_forward = printForm.place_forward
      ? this.state.clientList.find((x) => x._id === printForm.place_forward)
          .name
      : printForm.place_forward;

    printForm.place_receive = printForm.place_receive
      ? this.state.clientList.find((x) => x._id === printForm.place_receive)
      : "";
    printForm.type = this.state.printType;

    printForm.reference =
      printForm.type === "검사의뢰서" ? "검단의뢰서" : "원단 출고의뢰서";
    printForm.liaison = printForm.liaison
      ? this.state.userList.find((x) => x._id === "" + printForm.liaison).name
      : "";
    printForm.item = printForm.item
      ? this.state.itemList.find((x) => x._id === printForm.item)
      : "";
    printForm.client = printForm.buyer ? printForm.buyer : "";
    const emailClinet = this.state.clientList.find(
      (x) =>
        x.name ===
        (printForm.place_forward
          ? printForm.place_forward
          : this.state.printForm.place_forward),
    );
    printForm.reciver = emailClinet.email ? emailClinet.email : "";
    printForm.tel = this.state.company.tel;
    printForm.fax = this.state.company.fax;
    printForm.address = this.state.company.address;

    printForm.company = this.state.auth_company.toUpperCase();
    printForm.production_item = printForm.item
      ? printForm.item.production_item
      : "";

    const client = printForm.place_receive ? printForm.place_receive : "";
    printForm.warehouse = printForm.place_receive_memo
      ? printForm.place_receive_memo
      : "";

    printForm.remark = printForm.remark ? printForm.remark : "";
    printForm.precautions = printForm.precautions ? printForm.precautions : "";

    printForm.style = printForm.color_list.map((d) => d.style);
    printForm.style = [...new Set(printForm.style)].join(", ");
    printForm.total_quantity = printForm.color_list.reduce(
      (r, c) => r + c.quantity,
      0,
    );
    printForm.color_list.forEach((x, i) => {
      x.index = i + 1;
    });
    printForm.width = release.width
      ? String(release.width)
      : printForm.item?.width
      ? String(printForm.item.width)
      : "";
    printForm.full_width = release.full_width
      ? String(release.full_width)
      : printForm.item?.full_width
      ? String(printForm.item.full_width)
      : "";
    printForm.unit_width = release.unit_width
      ? release.unit_width === "in"
        ? "inch"
        : "cm"
      : printForm?.item.unit_width
      ? printForm?.item.unit_width === "in"
        ? "inch"
        : "cm"
      : "";
    printForm.logo = this.state.auth_logo ? this.state.auth_logo : null;
    printForm.logoType = this.state.company.logoType
      ? this.state.company.logoType
      : null;
    printForm.date_delivery = dateFormat(printForm.date_delivery);
    printForm.date_register = dateFormat(printForm.date_register);

    commit("setPrintForm", printForm);
  },

  async PRINT_DIALOG_OPEN({ commit }, data) {
    this.dispatch("SET_API_LOADING", true);
    const orderBuyer = await orderBuyerFind(
      this.state.auth_company,
      data.data.order,
    );
    if (orderBuyer.data.length)
      data.data.buyer = orderBuyer.data.length ? orderBuyer.data[0].buyer : "";
    commit("setPrintType", data.type);

    await this.dispatch(
      data.type === "가공의뢰서"
        ? "SET_MANUFACTURE_PRINT"
        : data.type === "출고의뢰서" || data.type === "검사의뢰서"
        ? "SET_RELEASE_PRINT"
        : "SET_ORDER_PRINT",
      data.data,
    );
    commit("setIsPrintDialog", true);
  },
  //프린트 끝 =========================================================================
  //기타 기능 =========================================================================
  // 생산페이지 이동 기능
  async PRODUCTION_PAGE_MOVE({ commit }, order) {
    await this.dispatch("SET_PRODUCTION_ORDER", { order });
    router.push({
      name: "production",
    });
  },

  ///임시 필터링용
  SET_FILTER_DATA({ commit }, data) {
    commit("setFilterData");
    commit("setFilterData", data);
  },
  SET_CLAIM_FILTER_DATA({ commit }, data) {
    commit("setPurchaseFilterData");
    commit("setPurchaseFilterData", data);
  },
  SET_ENDCLAIM_FILTER_DATA({ commit }, data) {
    commit("setEndClaimFilterData");
    commit("setEndClaimFilterData", data);
  },

  // filter  =========================================================================
  SET_FILER_DATA({ commit }, result) {
    const list = setExistingFilter(result);

    commit(orderList[result.kind], list.orderList);
    commit(orginList[result.kind], list.originList);
    commit(filterList[result.kind], list.header);
  },
  async SET_FILTERD_LIST({ commit }, data) {
    try {
      const result = await filteringFindList[router.app._route.name]({
        company: this.state.auth_company,
        page: 1,
        filter: data,
        type: "filterData",
        kind: router.app._route.name,
        vatKind:
          router.app._route.name === "purchaseVat" ? "purchase" : "sales",
      });

      if (result.status !== 200) throw `error: ${result.status}`;

      if (result?.data?.orderList) {
        if (!result.data.header) result.data.header = {};
        if (this.state[pageOrderList[router.app._route.name]].length) {
          result.data.orderList.forEach((data) => {
            const beforeData = this.state[
              pageOrderList[router.app._route.name]
            ].find((beforeList) => data._id === beforeList._id);

            if (beforeData) {
              data.checkBox = beforeData.checkBox;
              data.pass_checked = beforeData.pass_checked;
              data.arise_checked = beforeData.arise_checked;
            }
          });
        }
        commit(orderList[router.app._route.name], result.data.orderList);

        commit("setPageTotalSize", result.data.totalSize);
      }
    } catch (e) {
      console.log(e);
    }
  },
  async MAIN_ORDERLIST({ commit }, data) {
    try {
      const result = await mainListFind({
        company: this.state.auth_company,
        page: data && data.page ? data.page : 1,
        filter: this.state.applyFilterList["main"]
          ? this.state.applyFilterList["main"]
          : [],
        type: "mainData",
        sortHeader: data && data.sortHeader ? data.sortHeader : [],
      });

      if (result.status !== 200) throw `error: ${result.status}`;

      if (result?.data?.orderList) {
        if (!result.data.header) result.data.header = {};

        commit(orderList["main"], result.data.orderList);
        if (this.state.applyFilterList["main"])
          commit(filteredOriginFilter["main"], result.data.orderList);
        this.commit("setPageIndex");
        commit("setPageTotalSize", result.data.totalSize);
      }
    } catch (e) {
      console.log(e);
    }
  },
  async ORIGIN_LIST({ commit }, data) {
    try {
      const result = await filteringFindList[router.app._route.name]({
        company: this.state.auth_company,
        page: 1,
        filter: [],
        type: "mainData",
        kind: router.app._route.name,
        vatKind:
          router.app._route.name === "purchaseVat" ? "purchase" : "sales",
        stockType: data && data.stockType ? data.stockType : "stock",
        vatType: data && data.vatType ? data.vatType : "common",
        grade: this.state.auth_grade,
      });
      if (result.status !== 200) throw `error: ${result.status}`;

      if (result?.data?.orderList) {
        commit(orginList[router.app._route.name], result.data.orderList);
      }
    } catch (e) {
      console.log(e);
    }
  },
  async ORDER_ORDERLIST({ commit }, data) {
    try {
      const result = await orderListFind({
        company: this.state.auth_company,
        grade: this.state.auth_grade,
      });

      if (result.status !== 200) throw `error: ${result.status}`;

      if (result?.data?.orderList) {
        if (!result.data.header) result.data.header = {};
        commit(
          "setOriginFilter",
          JSON.parse(JSON.stringify(result.data.header)),
        );
        result.data.oldData = await this.state.orderPageFilter;

        this.dispatch("SET_FILER_DATA", { data: result.data, kind: "order" });
      }
    } catch (e) {
      console.log(e);
    }
  },

  async PRODUCTION_ORDERLIST({ commit }, data) {
    try {
      const result = await productionListFind({
        company: this.state.auth_company,
        grade: this.state.auth_grade,
      });
      if (result.status !== 200) throw `error: ${result.status}`;
      if (result?.data?.orderList) {
        if (!result.data.header) result.data.header = {};
        commit(
          "setOriginFilter",
          JSON.parse(JSON.stringify(result.data.header)),
        );
        result.data.oldData = this.state.productionFilterList;

        this.dispatch("SET_FILER_DATA", {
          data: result.data,
          kind: "production",
        });
      }
    } catch (e) {
      console.log(e);
    }
  },

  async GET_RELEASELIST({ commit }, data) {
    try {
      const result = await releaseListFind({
        listType: data.type,
        status: data.status,
        company: this.state.auth_company,
        kind: data.kind,
      });

      if (result.status !== 200) throw `error: ${result.status}`;

      if (result?.data?.orderList) {
        if (!result.data.header) result.data.header = {};
        if (result)
          commit(
            "setOriginFilter",
            JSON.parse(JSON.stringify(result.data.header)),
          );
        const resultData = {
          orderList: result.data.orderList,
          header: result.data.header,
        };

        resultData.oldData =
          data.kind === "purchasecalculate"
            ? this.state.purchCalculFilter
            : data.kind === "purchasedeadline"
            ? this.state.purchSalesCalculFilter
            : data.kind === "salescalculate"
            ? this.state.salesCacFilter
            : data.kind === "salesdeadline"
            ? this.state.salesEndFilter
            : "";

        this.dispatch("SET_FILER_DATA", { data: resultData, kind: data.kind });
        this.dispatch("EDIT_FORM_CLOSE");
      }
    } catch (e) {
      console.log(e);
    }
  },

  //외상 매입, 매출금
  async GET_VAT_RECEIVABLE({ commit }, data) {
    try {
      const route =
        data && data.vatKind === "purchase" ? "purchaseVat" : "salesVat";

      const receivable = await getReceivable({
        company: this.state.auth_company,

        vatType: data.vatType,
        vatKind: data.vatKind,
      });

      if (receivable.status !== 200) throw `error : ${receivable.status}`;

      const pageRouter = data.kind === "purchase" ? "purchaseVat" : "salesVat";

      if (receivable?.data?.orderList) {
        commit(
          "setOriginFilter",
          JSON.parse(JSON.stringify(receivable.data.header)),
        );
        const pageRouter =
          data.vatKind === "purchase" ? "purchaseVat" : "salesVat";
        const resultData = {
          orderList: receivable.data.orderList,
          header: receivable.data.header,
        };

        resultData.oldData =
          pageRouter === "purchaseVat"
            ? this.state.vatFilterList
            : pageRouter === "salesVat"
            ? this.state.salesVatFilterList
            : "";

        this.dispatch("SET_FILER_DATA", { data: resultData, kind: pageRouter });
      }
    } catch (e) {
      console.log(e);
      alert(e);
    }
    commit("setAPILoading", false);
  },

  /** header, filterList 전달 받아  데이터 파싱 후 existingFilter 에 데이터 저장*/
  // EXISTINGFILTER_PARSING({ commit }, data) {
  //   const existingFilter = this.state.existingFilter;
  //   const first = data.header.find((x) => x.first);
  //   const filterList = {};
  //   const header = data.header
  //     .map((x) => {
  //       if (x.checked) return x.value;
  //     })
  //     .filter((x) => x);

  //   if (header.length)
  //     header.forEach((x) => {
  //       if (x !== "checked") {
  //         const list = data.filter[x]
  //           .map((y) => {
  //             if (y.checked) return y.name;
  //           })
  //           .filter((z) => z);

  //         filterList[x] = list;
  //       }
  //     });
  //   existingFilter[router.app._route.name + (data.type ? data.type : "")] = {
  //     header,
  //     filterList,
  //     first: first.name ? first.name : "",
  //   };
  // },

  // /**전체 필터 해제 existingFilter 삭제  */
  // INIT_EXISTING_FILTER({ commit }, type) {
  //   const existingFilter = this.state.existingFilter;
  //   delete existingFilter[router.app._route.name + (type ? type : "")];
  // },

  // filter 끝 =========================================================================

  /** 신규 오더 번호 생성 기능
   * 1. 함수 호출 하면 오더 번호 생성 해주는 api 호출
   * 2. 파라메터 값은 년도, 시즌 값을 받음
   * 3. api 로 리턴 받은 값을 오더 페이지 form 데이터에 대입
   */
  async NEW_ORDER_NO({ commit }) {
    const order = this.state.orderForm;
    if (!order._id) {
      const result = await orderBakNumFind(
        this.state.auth_company,
        order.order_front
          ? order.order_front
          : `${order.order_front_year.substr(2, 4)}${order.order_front_season}`,
        order.kind ? order.kind : 1,
      );

      commit("setNewOrderNo", result.data);
    }
  },

  async NEW_REPEAT_ORDER_NO({ commit }) {
    const result = await repeatNum(
      this.state.auth_company,
      this.state.orderForm.originOrder,
    );
    commit("setNewOrderNo", result.data);
  },
  //세팅 페이지 저장 로직
  async SAVE_ITEM({ commit }) {
    const form = this.state.editedItem;
    const name = this.state.editedItem.name;
    if (!form._id) form.status = "40";
    form.company = this.state.auth_company;

    form.item = this.state.auth_company;
    form.id = `${this.state.auth_company}_item_${
      this.state.itemList.length + 1
    }`;
    try {
      const result = form._id ? await putItem(form) : await postItem(form);
      if (result.status !== 200) throw `error : ${result.status}`;
      this.commit("setEditedItemForm");
      this.state.isDialog = false;
      await this.dispatch("SET_ITEMLIST");
      this.commit(
        "setSnackBar",
        form._id
          ? `${name} 변경내용이 저장되었습니다.`
          : `신규 아이템 ${name}이(가) 저장되었습니다.`,
      );
    } catch (e) {
      alert(e);
    }
  },
  async EXCEL_SAVE_ITEM({ commit }) {
    const form = this.state.editedItem;
    form.status = "40";
    form.company = this.state.auth_company;
    try {
      const result = form._id ? await putItem(form) : await postItem(form);

      if (result.status !== 200) throw `error : ${result.status}`;
      this.commit("setEditedItemForm");
      this.state.isDialog = false;
      await this.dispatch("SET_ITEMLIST");
    } catch (e) {
      alert(e);
    }
  },
  async EXCEL_SAVE_CLIENT({ commit }) {
    const form = this.state.editedItem;
    form.status = "40";
    form.tel ? (form.tel = form.tel.replace(/[^0-9]/g, "")) : "";
    form.business_num
      ? (form.business_num = form.business_num.replace(/[^0-9]/g, ""))
      : "";
    try {
      form.company = this.state.auth_company;
      const result = form._id ? await putClient(form) : await postClient(form);

      if (result.status !== 200) throw `error : ${result.status}`;
      this.commit("setEditedItemForm");

      this.state.isDialog = false;
    } catch (e) {
      console.log(e);
    }
  },
  async SAVE_CLIENT({ commit }) {
    const form = this.state.editedItem;
    const name = this.state.editedItem.name;
    if (!form._id) form.status = "40";
    form.tel ? (form.tel = form.tel.replace(/[^0-9]/g, "")) : "";
    form.business_num
      ? (form.business_num = form.business_num.replace(/[^0-9]/g, ""))
      : "";
    form.company = this.state.auth_company;
    try {
      const result = form._id ? await putClient(form) : await postClient(form);

      if (result.status !== 200) throw `error : ${result.status}`;
      this.commit("setEditedItemForm");

      this.state.isDialog = false;
      if (form.status !== "99") {
        await this.dispatch("SET_CLIENTLIST");
        await this.dispatch("SET_USE_CLIENTLIST");

        this.commit(
          "setSnackBar",
          form._id
            ? `${name} 변경내용이 저장되었습니다.`
            : `신규 클라이언트 ${name}이(가) 저장되었습니다.`,
        );
      }
    } catch (e) {
      alert(e);
    }
  },
  async EXCEL_SAVE_CTYPE({ commit }) {
    const form = this.state.editedItem;
    form.company = this.state.auth_company;
    form.status = "40";
    try {
      const result = form._id
        ? await putClothestype(form)
        : await postClothestype(form);

      if (result.status !== 200) throw `error : ${result.status}`;
      this.commit("setEditedItemForm");
      this.state.isDialog = false;
      await this.dispatch("SET_CTYPELIST");
      await this.dispatch("SET_USE_CTYPELIST");
    } catch (e) {
      alert(e);
    }
  },
  async SAVE_CTYPE({ commit }) {
    const form = this.state.editedItem;
    const name = this.state.editedItem.name;
    form.company = this.state.auth_company;
    if (!form._id) form.status = "40";
    try {
      const result = form._id
        ? await putClothestype(form)
        : await postClothestype(form);

      if (result.status !== 200) throw `error : ${result.status}`;
      this.commit("setEditedItemForm");
      this.state.isDialog = false;
      await this.dispatch("SET_CTYPELIST");
      await this.dispatch("SET_USE_CTYPELIST");
      this.commit(
        "setSnackBar",
        form._id
          ? `${name} 변경내용이 저장되었습니다.`
          : `신규 복종 ${name}이(가) 저장되었습니다.`,
      );
    } catch (e) {
      alert(e);
    }
  },

  // 회원가입 기능 시작 =========================================================================
  //회원가입 함수
  async SIGNUP({ commit }, signupData) {
    try {
      const auth = await register({
        uuid: signupData.uuid,
        password: signupData.password,
        company: signupData.company,
        name: signupData.name,
        grade: signupData.grade,
        isActive: signupData.grade === 5,
        signStatus: signupData.grade === 5 ? "40" : "20",
      });
      if (auth.status === 200) alert(`회원가입이 완료 되었습니다.`);
      commit("setSignType");
      // router.push("/login");
      this.commit("setLoginForm");
      this.commit("setSignType");
    } catch (e) {
      alert(e.response.data.message ? e.response.data.message : e.message);
    }
  },

  //이메일 보안코드 발송 함수
  async EMAIL_SEND({ commit }, data) {
    try {
      // const email = await certifiedEmail(data);
      const email = await verificationCode(data);

      if (email.status !== 200) throw `error : ${email.status}`;
    } catch (e) {
      console.log(e);
    }
  },
  // 회원가입 기능 끝 =========================================================================
  // 비밀번호 변경 시작 =========================================================================
  async MODIFY_PW({ commit }, data) {
    try {
      const result = await modifyPw(data);

      if (result.status !== 200) throw `error: ${result.status}`;
    } catch (e) {
      console.log(e);
    }
  },
  // 비밀번호 변경 끝 =========================================================================

  async CONTEXT_MENU_DATA_PARSE({ commit }, data) {
    commit("setContextData");
    await this.dispatch("SELECT_ORDER", data.item);
    const order = { ...this.state.selectOrder };

    if (data.name) {
      await this.dispatch("GET_APPROVAL_HISTORY", {
        order: order.order,
        name: data.name,
        type: data.target,
        id: data.id,
      });
    }

    if (order.order) {
      const claim = await getClaimListStatus(this.state.auth_company, {
        order: order._id,
      });

      const purchasePriceTotal = claim.data.reduce(
        (r, c) =>
          r +
          ((c.arise_type === 2 &&
          (c.arise_status === "40" || c.arise_status === "00")
            ? c.arise_eschamge
              ? c.arise_eschamge
              : c.arise_price
            : 0) +
            (c.pass_type === 2 &&
            (c.pass_status === "40" || c.pass_status === "00")
              ? c.pass_eschamge
                ? c.pass_eschamge
                : c.pass_price
              : 0)),
        0,
      );
      const salesPriceTotal = claim.data.reduce(
        (r, c) =>
          r +
          ((c.arise_type === 3 &&
          (c.arise_status === "40" || c.arise_status === "00")
            ? c.arise_eschamge
              ? c.arise_eschamge
              : c.arise_price
            : 0) +
            (c.pass_type === 3 &&
            (c.pass_status === "40" || c.pass_status === "00")
              ? c.pass_eschamge
                ? c.pass_eschamge
                : c.pass_price
              : 0)),
        0,
      );

      claim.data.purchasePriceTotal = purchasePriceTotal;
      claim.data.salesPriceTotal = salesPriceTotal;

      order.releaseProgress = order.progress.filter((x, i) => {
        x.index = i;
        if (
          x.kind === 7 ||
          x.kind === 8 ||
          x.kind === 9 ||
          x.kind === 10 ||
          x.kind === 15 ||
          x.kind === 16 ||
          x.kind === 12
        ) {
          return x;
        }
      });

      commit("setContextData", {
        item: data.item,
        order: order,
        claim: claim.data,
        target: data.target,
      });
    }
  },

  // 가공/출고 의뢰서 문서 열람 기능
  PARSE_DOCDATA() {
    this.state.selectDocData.kind === 0
      ? this.dispatch("ORDER_DOC_PARSE")
      : this.state.selectDocData.kind === 7
      ? this.dispatch("MANUFACTURE_DOC_PARSE")
      : this.dispatch("RELEASE_DOC_PARSE");
  },

  ON_OFF_DOC_DATA({ commit }) {
    commit("setIsDocDataBtn");
    if (this.state.isDocDataBtn) {
      commit("setSelectDocData", this.state.docDataList[0]);
      this.dispatch("PARSE_DOCDATA");
    } else {
      commit("setSelectDocData");
      commit("setDocData");
    }
  },

  ORDER_DOC_PARSE({ commit }) {
    const parsingData = {
      order: this.state.productionOrderForm.order
        ? this.state.productionOrderForm.order
        : "",
      client: this.state.productionOrderForm.client
        ? this.state.clientList.find(
            (x) => x._id === this.state.productionOrderForm.client,
          )
        : "",
      ctype: this.state.productionOrderForm.ctype
        ? this.state.ctypeList.find(
            (x) => x._id === this.state.productionOrderForm.ctype,
          )
        : "",
      gender: this.state.productionOrderForm.gender
        ? "미선택"
        : this.state.productionOrderForm.gender === "m"
        ? "남성"
        : "여성",
      item: this.state.productionOrderForm.item
        ? this.state.itemList.find(
            (x) => x._id === this.state.productionOrderForm.item,
          )
        : "",
      order_kind: this.state.order_kind.find(
        (x) => x.value === this.state.productionOrderForm.order_kind,
      ),
      purchase_classification: this.state.productionOrderForm.order
        ? this.state.productionOrderForm.purchase_classification === "domestic"
          ? "내수"
          : "수입"
        : "",
      eschamge_sign: this.state.productionOrderForm.eschamge_sign
        ? this.state.productionOrderForm.eschamge_sign
        : "",
      liaison: this.state.productionOrderForm.order
        ? this.state.userList.find(
            (x) => x._id === this.state.productionOrderForm.liaison,
          )
        : "",
      sales_unit_price: this.state.productionOrderForm.sales_unit_price
        ? this.state.productionOrderForm.sales_unit_price
        : "",
      style_list: this.state.productionOrderForm.order
        ? this.state.productionOrderForm.style_list
        : [],
      color_list: this.state.productionOrderForm.order
        ? this.state.productionOrderForm.color_list
        : [],
      unit_sign: this.state.productionOrderForm.unit_sign
        ? this.state.productionOrderForm.unit_sign
        : "y",
    };

    if (parsingData.color_list && parsingData.color_list.length) {
      parsingData.colorTota = this.state.productionOrderForm.color_list.reduce(
        (a, r) => a + (+r.quantity ? r.quantity : 0),
        0,
      );
    }

    if (parsingData.style_list.length < 7) {
      const num = 7 - parsingData.style_list.length;
      for (let i = 0; i < num; i++) {
        parsingData.style_list.push("");
      }
    }

    if (parsingData.color_list.length < 7) {
      const num = 7 - parsingData.color_list.length;
      for (let i = 0; i < num; i++) {
        parsingData.color_list.push("");
      }
    }

    commit("setDocData", parsingData);
  },

  MANUFACTURE_DOC_PARSE({ commit }) {
    const parsingData = this.state.productionOrderForm.manufacture.find(
      (x) => x.id === this.state.selectDocData.id,
    );
    parsingData.order = this.state.productionOrderForm.order;
    parsingData.place_manufacture = this.state.clientList.find(
      (x) => x._id === parsingData.place_manufacture,
    );
    parsingData.liaison = this.state.userList.find(
      (x) => x._id === parsingData.liaison,
    );
    parsingData.item = this.state.itemList.find(
      (x) => x._id === parsingData.item,
    );
    parsingData.inspection = this.state.clientList.find(
      (x) => x._id === parsingData.inspection,
    );

    if (parsingData.color_list.length < 14) {
      const num = 14 - parsingData.color_list.length;
      for (let i = 0; i < num; i++) {
        parsingData.color_list.push({});
      }
    }
    commit("setDocData", parsingData);
  },

  RELEASE_DOC_PARSE({ commit }) {
    const parsingData = this.state.productionOrderForm.release.find(
      (x) => x.id === this.state.selectDocData.id,
    );
    parsingData.order = this.state.productionOrderForm.order;
    parsingData.liaison = this.state.userList.find(
      (x) => x._id === parsingData.liaison,
    );
    parsingData.requestColor = parsingData.color_list
      ? parsingData.color_list
      : [];

    parsingData.place_forward = this.state.clientList.find(
      (x) => x._id === parsingData.place_forward,
    );

    parsingData.place_receive = this.state.clientList.find(
      (x) => x._id === parsingData.place_receive,
    );
    parsingData.place_sales = this.state.clientList.find(
      (x) => x._id === parsingData.place_sales,
    );
    parsingData.item = this.state.itemList.find(
      (x) => x._id === parsingData.item,
    );

    if (parsingData.requestColor.length < 12) {
      const num = 12 - parsingData.requestColor.length;
      for (let i = 0; i < num; i++) {
        parsingData.requestColor.push({});
      }
    }

    commit("setDocData", parsingData);
  },
  // async SET_SELECT_ORDER_RELEASE({ commit }, data) {
  //   try {
  //     const result = await releaseListFind(
  //       this.state.auth_company,
  //       data.type,
  //       data.status,
  //     );

  //     this.commit("setContextTargetList", result.data);
  //   } catch (e) {
  //     alert(e);
  //   }
  // },

  async ORDER_SELECT_MOVE({ commit }, data) {
    const contextList = this.state.contextTargetList;
    let orders = [];
    if (
      data.target === "purchasecalculate" ||
      data.target === "salescalculate" ||
      data.target === "purchasedeadline" ||
      data.target === "salesdeadline"
    ) {
      const result = await releaseListFind({
        company: this.state.auth_company,
        kind: data.target,
      });
      const list = result.data;
      list.orderList
        .filter((x) => x.order === data.order)
        .map((x) =>
          orders.push({
            name: x.order,
            checked: true,
          }),
        );
    }
    if (data.target === "order" || data.target === "main") {
      const result =
        data.target === "order"
          ? await orderListFind({
              company: this.state.auth_company,
              grade: this.state.auth_grade,
            })
          : await mainListFind(this.state.auth_company);

      const list = result.data;

      const targetOrder = list.orderList.filter((x) => x.order === data.order);

      targetOrder.map((x) =>
        orders.push({
          name: x.order,
          checked: true,
        }),
      );

      if (targetOrder)
        await this.dispatch(
          "SET_FORM",
          JSON.parse(JSON.stringify(targetOrder[0])),
        );
    }

    if (data.target === "claim") {
      const result = await claimListFind({
        company: this.state.auth_company,
        grade: this.state.auth_grade,
      });
      const list = result.data;

      list.claimList
        .filter((x) => x.order === data.order)
        .map((x) =>
          orders.push({
            name: x.order,
            checked: true,
          }),
        );
    }
    if (data.target === "endClaim") {
      const result = await endClaimListFind({
        company: this.state.auth_company,
        grade: this.state.auth_grade,
      });
      const list = result.data;

      list.claimList
        .filter((x) => x.order === data.order)
        .map((x) =>
          orders.push({
            name: x.order,
            checked: true,
          }),
        );
    }
    if (data.target === "stock") {
      const result = await getStockTableList({
        company: this.state.auth_company,
        grade: this.state.auth_grade,
        stockType: "stock",
      });
      const list = result.data;

      list.stockList
        .filter((x) => x.order === data.order)
        .map((x) =>
          orders.push({
            name: x.order,
            checked: true,
          }),
        );
    }
    const header =
      data.target === "claim"
        ? claimHeader
        : data.target === "endClaim"
        ? endClaimHeader
        : data.target === "purchasecalculate"
        ? purCacHeader
        : data.target === "purchasedeadline"
        ? purDedHeader
        : data.target === "salescalculate"
        ? salesCacHeader
        : data.target === "salesdeadline"
        ? salesDedHeader
        : data.target === "main"
        ? mainHeader
        : data.target === "order"
        ? orderHeader
        : data.target === "stock"
        ? stockHeader
        : null;
    if (orders.length) {
      orders = [...new Map(orders.map((x) => [x.name, x])).values()];

      const headerFilter = {
        ...this.state[`${headerFilterList[data.target]}`],
      };

      headerFilter.order = orders;
      commit(filterList[data.target], headerFilter);

      header.forEach((x) => {
        x.checked = false;
      });
      header.find((x) => x.value === "order").checked = true;
    }

    if (router.app._route.name !== data.target)
      router.push({
        name: data.target,
      });
  },
  FILTER_SELECT_MOVE({ commit }, data) {
    const header =
      data.target === "claim"
        ? claimHeader
        : data.target === "endClaim"
        ? endClaimHeader
        : data.target === "purchasecalculate"
        ? purCacHeader
        : data.target === "purchasedeadline"
        ? purDedHeader
        : data.target === "salescalculate"
        ? salesCacHeader
        : data.target === "salesdeadline"
        ? salesDedHeader
        : data.target === "main"
        ? mainHeader
        : data.target === "order"
        ? orderHeader
        : data.target === "stock"
        ? stockHeader
        : null;

    let filters = [];
    if (
      data.target === "purchasecalculate" ||
      data.target === "salescalculate" ||
      data.target === "salesdeadline" ||
      data.target === "purchasedeadline" ||
      data.target === "stock"
    ) {
      filters.push(
        data.target === "purchasecalculate" ||
          data.target === "salescalculate" ||
          data.target === "stock"
          ? {
              name: "마감 요청",
              checked: true,
            }
          : {
              name: "취소 요청",
              checked: true,
            },
      );

      const headerFilter = {
        ...this.state[`${headerFilterList[data.target]}`],
      };
      if (data.name === "stock") commit("setStockView", true);
      headerFilter.status = filters;
      commit(filterList[data.target], headerFilter);

      header.forEach((x) => {
        x.checked = false;
      });
      header.find((x) => x.value === "status").checked = true;
    }
    if (data.name === "useStock" || data.name === "deleteStock") {
      commit("setStockView", false);
    }
    router.push({
      name: data.target,
    });
  },
  EDIT_ROW(commit, data) {
    this.commit("setForm"); //초기화
    const editRowData = this.state.editRowData;
    const header = data.header;
    const index = data.index;
    const type = data.type;
    const item = data.item;
    const items = data.items;

    this.commit("setForm", { ...items });
    editRowData.value = item;
    editRowData.value2 = items.salesEschamge ? items.salesEschamge : "";
    editRowData.target = header.value;
    editRowData.items = items;

    editRowData.index = index;
    editRowData.type = type;
  },

  async SAVE_EDIT_FROM({ commit }) {
    const target = this.state.editRowData.target;
    const routeName = router.app._route.name;
    if (router.app._route.name === "production") {
      commit("setAPILoading", true);
      await this.dispatch("SELECT_ORDER", this.state.productionOrderForm);

      const saveOrder = this.state.selectOrder;

      commit("setSelectOrder");

      saveOrder.progress[this.state.editRowData.index[0]].memo =
        this.state.editRowData.value;

      await this.dispatch("SAVE_EDIT_FROM_ORDER", saveOrder);

      this.dispatch("EDIT_FORM_SAVE_INIT");
    }
    if (
      router.app._route.name === "purchasecalculate" ||
      router.app._route.name === "salescalculate"
    ) {
      commit("setAPILoading", true);

      await this.dispatch("EDIT_FORM_SAVE", {
        form: this.state.editRowData,
        id: this.state.form.releaseId,
        company: this.state.auth_company,
        page:
          router.app._route.name === "purchasecalculate" ? "purchase" : "sales",
      });
      if (
        router.app._route.name === "purchasecalculate" ||
        router.app._route.name === "salescalculate"
      ) {
        this.dispatch("EDIT_FORM_SAVE_INIT");
      }
    }
    this.dispatch("PARSE_ROUTE_API_DATA_CALL");
  },
  EDIT_FORM_CLOSE() {
    this.commit("setEditRowData");
  },
  async EDIT_FORM_SAVE({ commit }, form) {
    try {
      await calculateEditSave(form);
    } catch (e) {
      alert("저장에 실패하였습니다. 다시 시도해주세요.");
    }
  },
  EDIT_FORM_SAVE_INIT() {
    this.commit("setEditRowData");
    this.dispatch("SET_API_LOADING", false);
  },

  async PURCHASE_COLOR_LIST_CALCULATE() {
    this.dispatch("SET_API_LOADING", true);
    const form = this.state.form;
    await this.dispatch("SELECT_ORDER", this.state.form);
    const order = this.state.selectOrder;
    const release = order.release.find((x) => x.id === form.id);
    const manufacture = order.manufacture.find(
      (x) => x.id === release.manufacture,
    );

    const color = release.purchase.color_list[this.state.editRowData.index[1]];

    color.quantity = +this.state.editRowData.value;
    color.total_amount = parseFloat(
      (color.quantity * release.purchase.unit_price).toFixed(2),
    );
    release.purchase.quantity = release.purchase.color_list.reduce(
      (r, c) => r + +(c.quantity ? c.quantity : 0),
      0,
    );

    if (color.color_surcharge_unit) {
      color.color_surcharge_unit = parseFloat(
        (
          color.quantity *
          manufacture.color_list.find((x) => x.name === color.name)
            .color_surcharge_unit
        ).toFixed(2),
      );
      release.purchase.color_surcharge_price = parseFloat(
        release.purchase.color_list
          .reduce(
            (r, c) =>
              r +
              (c.color_surcharge_unit
                ? c.color_surcharge_unit
                : c.color_surcharge_price
                ? c.color_surcharge_price
                : 0),
            0,
          )
          .toFixed(2),
      );
    }

    if (color.surcharge_price) {
      color.surcharge_price = parseFloat(
        (color.quantity * manufacture.sucharge_price).toFixed(2),
      );
    }

    if (release.purchase.color_list) {
      if (color.surcharge_price) {
        release.purchase.surcharge_price = parseFloat(
          release.purchase.color_list
            .reduce(
              (r, c) => r + (c.surcharge_price ? c.surcharge_price : 0),
              0,
            )
            .toFixed(2),
        );
      }
    }

    release.purchase.total_amount = parseFloat(
      release.purchase.quantity * release.purchase.unit_price +
        (release.purchase.surcharge_price +
          release.purchase.color_surcharge_price),
    ).toFixed(2);

    if (release.purchase.eschamge)
      release.purchase.eschamge_price = parseFloat(
        (release.purchase.total_amount * release.purchase.eschamge).toFixed(2),
      );

    if (release.purchase.check_status !== 1) release.purchase.check_status = 2;
    await this.dispatch("SAVE_EDIT_FROM_ORDER", order);
    this.dispatch("EDIT_FORM_SAVE_INIT");
  },

  async MEMO_WRITE() {
    this.dispatch("SET_API_LOADING", true);
    const form = this.state.form;
    await this.dispatch("SELECT_ORDER", this.state.form);
    const order = this.state.selectOrder;
    const release = order.release.find((x) => x.id === form.id);

    if (router.app._route.name === "salescalculate")
      release.sales.memo = this.state.editRowData.value;
    else release.purchase.memo = this.state.editRowData.value;

    this.dispatch("SAVE_EDIT_FROM_ORDER", this.state.selectOrder);
    this.dispatch("EDIT_FORM_SAVE_INIT");
    await this.dispatch("GET_RELEASELIST", {
      type: router.app._route.name === "salescalculate" ? "sales" : "purchase",

      kind: router.app._route.name,
    });
  },
  async CHECHK_STATUS_CHANGE() {
    this.dispatch("SET_API_LOADING", true);
    const form = this.state.form;
    await this.dispatch("SELECT_ORDER", this.state.form);
    const order = this.state.selectOrder;
    const release = order.release.find((x) => x.id === form.id);

    if (router.app._route.name === "salescalculate")
      release.sales.check_status = this.state.editRowData.value;
    else release.purchase.check_status = this.state.editRowData.value;

    this.dispatch("SAVE_EDIT_FROM_ORDER", this.state.selectOrder);
    this.dispatch("EDIT_FORM_SAVE_INIT");
  },
  async PLACE_CHANGE() {
    this.dispatch("SET_API_LOADING", true);
    await this.dispatch("SELECT_ORDER", this.state.form);
    const release = this.state.selectOrder.release.find(
      (x) => x.id === this.state.form.id,
    );
    if (router.app._route.name === "salescalculate")
      release.place_sales = this.state.editRowData.value._id;
    else release.place_forward = this.state.editRowData.value._id;
    if (typeof this.state.editRowData.value === "string") {
      this.dispatch("EDIT_FORM_SAVE_INIT");

      return;
    }

    this.dispatch("SAVE_EDIT_FROM_ORDER", this.state.selectOrder);
    this.dispatch("EDIT_FORM_SAVE_INIT");
  },
  async PUR_ESCHAMGE_CACULATE() {
    this.dispatch("SET_API_LOADING", true);
    await this.dispatch("SELECT_ORDER", this.state.form);
    const order = this.state.selectOrder;
    const form = this.state.form;
    const release = order.release.find((x) => x.id === form.id);

    release.purchase.eschamge = +this.state.editRowData.value;
    release.purchase.eschamge_price = parseFloat(
      (release.purchase.total_amount * release.purchase.eschamge).toFixed(2),
    );
    if (release.purchase.check_status !== 1) release.purchase.check_status = 2;
    this.dispatch("SAVE_EDIT_FROM_ORDER", order);
    this.dispatch("EDIT_FORM_SAVE_INIT");
  },
  async SALES_ESCHAMGE_CACULATE() {
    this.dispatch("SET_API_LOADING", true);

    await this.dispatch("SELECT_ORDER", this.state.form);
    const editRowData = this.state.editRowData;
    const order = this.state.selectOrder;
    const form = this.state.form;
    const release = order.release.find((x) => x.id === form.id);
    if (editRowData.target === "salesUnitPrice") {
      release.sales_unit_price = +editRowData.value;
      release.sales.eschamge = +editRowData.value2;
    }

    if (editRowData.target === "salesQuantity") {
      release.sales.color_list[editRowData.index[1]].success_quantity =
        editRowData.value;
      release.sales.quantity = release.sales.color_list.reduce(
        (r, c) => r + (c.success_quantity ? +c.success_quantity : 0),
        0,
      );
    }

    if (editRowData.target === "salesSurchargeUnit")
      release.sales.surcharge_unit = +editRowData.value;

    if (editRowData.target === "colorSurchargeUnit") {
      release.sales.color_list[editRowData.index[1]].surcharge_unit =
        editRowData.value;
      release.sales.color_surcharge_unit = release.sales.color_list.reduce(
        (r, c) => r + (c.surcharge_unit ? +c.surcharge_unit : 0),
        0,
      );
    }

    release.sales.total_amount = parseFloat(
      (release.sales.quantity ? release.sales.quantity : 0) *
        (release.sales_unit_price ? release.sales_unit_price : 0) +
        (release.sales.surcharge_unit ? release.sales.surcharge_unit : 0) +
        (release.sales.color_surcharge_unit
          ? release.sales.color_surcharge_unit
          : 0),
    ).toFixed(2);

    release.sales.eschamge_price = parseFloat(
      (+release.sales.eschamge * +release.sales.total_amount).toFixed(2),
    );
    if (release.sales.check_status !== 1) release.sales.check_status = 2;
    this.dispatch("SAVE_EDIT_FROM_ORDER", order);
    this.dispatch("EDIT_FORM_SAVE_INIT");
  },

  async SAVE_EDIT_FROM_ORDER(commit, order) {
    this.commit("setSaveData", order);

    await this.dispatch("SAVE_ORDER");
    this.commit("setSelectOrder");

    if (
      router.app._route.name === "purchasecalculate" ||
      router.app._route.name === "salescalculate"
    )
      // await this.dispatch("GET_RELEASELIST", {
      //   kind: router.app._route.name,
      // });
      await this.dispatch("GET_RELEASELIST", {
        type:
          router.app._route.name === "salescalculate" ? "sales" : "purchase",
        status: "calculate",
        isExisting: true,
        kind: router.app._route.name,
      });

    // await this.dispatch("HEADER_FILTER_DATA");

    this.state.editRowData.isSaved = true;
  },

  async EDIT_ROW_DATA_CHECK(commit, data) {
    if (
      JSON.stringify(data.index) !==
        JSON.stringify(this.state.editRowData.index) ||
      data.header !== this.state.editRowData.target
    ) {
      this.commit("setForm");
      this.commit("setForm", { ...this.state.editRowData.items });

      // if (this.state.editRowData.value) await this.dispatch("SAVE_EDIT_FROM");
    }
    // else {
    //   this.dispatch("EDITFORM_INIT");
    // }
  },
  EDITFORM_INIT() {
    this.commit("setEditRowData");

    this.dispatch("SET_API_LOADING", false);
  },

  // 외상금 금액입력

  async MAKE_RECIVABLE_AMOUNT(commit, data) {
    const release = data.release;
    const type = data.type;
    let saveForm = {};
    const date =
      type === "purchase"
        ? release.purchase.date
          ? release.purchase.date
          : release.date_delivery
          ? release.date_delivery
          : release.date_modify
        : type === "sales"
        ? release.sales.date
          ? release.sales.date
          : release.date_delivery
          ? release.date_delivery
          : release.date_modify
        : "";

    const client =
      type === "purchase"
        ? release.place_forward._id
          ? release.place_forward._id
          : release.place_forward
        : type === "sales"
        ? release.place_sales._id
          ? release.place_sales._id
          : release.place_sales
        : "";

    try {
      const result = await getReceivableByClient(
        this.state.auth_company,
        client,
        new Date(date).getFullYear(),
        monthDateFormat(date),
        type,
      );
      const amount =
        type === "purchase"
          ? release.purchase.eschamge_price
            ? +release.purchase.eschamge_price
            : +release.purchase.total_amount
          : type === "sales"
          ? release.sales.eschamge_price
            ? +release.sales.eschamge_price
            : +release.sales.total_amount
          : "";

      const vat_amount =
        type === "purchase"
          ? release.purchase.eschamge_price
            ? (+release.purchase.eschamge_price * 1.1 * 100) / 100
            : (+release.purchase.total_amount * 1.1 * 100) / 100
          : type === "sales"
          ? release.sales.eschamge_price
            ? (+release.sales.eschamge_price * 1.1 * 100) / 100
            : (+release.sales.total_amount * 1.1 * 100) / 100
          : "";
      const original_amount =
        type === "purchase"
          ? release.purchase.eschamge_price
            ? +release.purchase.total_amount
            : null
          : type === "sales"
          ? release.sales.eschamge_price
            ? +release.sales.total_amount
            : null
          : null;
      const eschamge =
        type === "purchase"
          ? release.purchase.eschamge
            ? release.purchase.eschamge
            : null
          : type === "sales"
          ? release.sales.eschamge
            ? release.sales.eschamge
            : null
          : null;
      const currency_sign =
        type === "purchase"
          ? release.purchase.currency_sign
            ? release.purchase.currency_sign
            : null
          : type === "sales"
          ? release.currency_sign
            ? release.currency_sign
            : null
          : null;
      if (result.status !== 200) throw `error : ${result.status}`;

      const receviable = result.data ? result.data[0] : "";
      saveForm.company = this.state.auth_company;
      saveForm.release = release._id;
      saveForm.receivable = receviable && receviable._id;

      saveForm.date = new Date(date);
      saveForm.amount = amount;

      if (currency_sign === "$" && !eschamge ? false : true)
        saveForm.vat_amount = vat_amount;

      if (original_amount) saveForm.original_amount = original_amount;
      saveForm.kind = type;
      if (type === "purchase") {
        if (release.purchase.currency_sign !== "₩") {
          saveForm.currency_sign = release.purchase.currency_sign;
        }
      }
      if (type === "sales") {
        if (release.currency_sign !== "₩") {
          saveForm.currency_sign = release.currency_sign;
        }
      }

      this.commit("setSaveRecivableAmount", saveForm);
    } catch (e) {
      console.log(e);
    }
  },

  async REQUEST_DIALOG_SET({ commit }) {
    commit("setAPILoading", true);
    await this.dispatch("SELECT_APPROVAL_LIST");
    this.dispatch("SET_REQUEST_DATA", "order");
    commit("setAPILoading", false);
  },

  NUM_DIALOG_OPEN({ commit }, data) {
    commit("setNumDialogData", data);
    commit("setDailogStatus", { status: true, kind: "num_dialog" });
  },

  OPEN_ATTACHMENT_DIALOG({ commit }, id) {
    this.dispatch("GET_ATTACHMENTLIST", id);
    commit("setDailogStatus", {
      status: true,
      kind: "dialog_file_upload",
      id: id,
    });
  },

  async GET_ATTACHMENTLIST({ commit }, id) {
    try {
      const result = await getfileOneById(this.state.auth_company, id);

      if (result.status !== 200) throw `eror : ${result.status}`;

      const fileList = result.data.map((x) => {
        x.name = x.filename;
        x.size = x.length;
        x.isSave = true;
        return x;
      });

      commit("setAttachmentList", fileList);
    } catch (e) {
      console.log(e);
    }
  },

  async GET_LOGO_ID({ commit }) {
    try {
      const result = await getfileOneById(
        this.state.auth_company,
        this.state.auth_company,
      );

      if (result.status !== 200) throw `eror : ${result.status}`;

      commit("setLogoImg", result.data.reverse()[0]);
    } catch (e) {
      console.log(e);
    }
  },
  async GET_LOGO_IMG({ commit }) {
    if (this.state.logoImg._id) {
      try {
        const result = await downloadFile(
          this.state.auth_company,
          this.state.logoImg._id,
        );

        if (result.status !== 200) throw `error: ${result.status}`;
        const downFile = new File(
          [new Blob([result.data])],
          this.state.logoImg.filename,
          {
            lastModified: new Date().getTime(),
            type: this.state.logoImg.metadata.type,
          },
        );
        commit("setAuthLogo");
        const toBase64 = (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
          });
        const img = await toBase64(downFile);

        commit("setAuthLogo", img);
      } catch (e) {
        console.log(e);
      }
    }
  },

  SET_NEW_FABRIC_STOCK_DATA({ commit }, data) {
    const release = data.saveRelease;
    const num = data.totalQuantity - data.purchaseQuantity;
    const quantity = decimalCacul(num + (num * release.shrigkage) / 100);

    const stockData = {
      company: release.company,
      id: `${release.id}_stock`,
      order: release.order,
      release: release.id,
      manufacture: release.manufacture,
      date_register: new Date(Date.now()),
      date_modify: new Date(Date.now()),
      kind: 5,
      name: `${data.place_forward}_생산`,
      status: "40",
      item: data.itemName,
      eschamge: release.purchase.eschamge,
      unit_price: release.purchase.unit_price,
      currency_sign: release.purchase.currency_sign,
      unit_sign: release.unit_sign ? release.unit_sign : "y",
      input_type: quantity < 0 ? 2 : 1,
      client: release.place_forward,
      stock_quantity: quantity,
      remain_quantity: quantity,
      version: 0,
    };

    commit("setSaveStock", stockData);
  },

  SET_NEW_STOCK_DATA({ commit }, data) {
    const release = data.release;

    if (!release.sales || !release.sales.color_list.length)
      release.sales = {
        color_list: release.color_list.map((x, i) => {
          const data = {
            name: x.name,
            success_quantity: x.quantity,
            indexColorName: `${release.id}_stock_${i}`,
          };
          return data;
        }),
      };

    const stockList = release.sales.color_list.map((x, i) => {
      const color = {
        company: release.company,
        id: release.kind === 12 ? x.indexColorName : `${release.id}_stock_${i}`,
        order: release.order,
        release: release.id,
        date_register: new Date(Date.now()),
        date_modify: new Date(Date.now()),
        kind: release.kind === 12 ? 2 : 1,
        name: x.name,
        status: release.status,
        item: data.item,
        unit_sign: release.unit_sign ? release.unit_sign : "y",
        client: release.place_forward,
        stock_quantity: x.success_quantity,
        remain_quantity: x.success_quantity,
        version: 0,
      };
      return color;
    });

    commit("setSaveStock", stockList);
  },

  async SET_MODIFY_STOCK_DATA({ commit }, release) {
    if (release.status === "40") {
      commit("setDeleteStock", copyData(this.state.selectStock));
      this.dispatch("SET_NEW_STOCK_DATA", {
        release,
        item: this.state.releaseForm.item.name,
      });
    } else if (release.status === "50") {
      commit(
        "setSaveStock",
        this.state.selectStock.map((x) => {
          const color = x;
          color.status = "50";
          return color;
        }),
      );
      // if (release.kind === 12)
      //   release.sales = {
      //     color_list: this.state.saveStock.map((x) => {
      //       const color = {
      //         name: x.name,
      //         success_quantity: x.stock_quantity,
      //         indexColorName: x.id,
      //       };
      //       return color;
      //     }),
      //   };

      // console.log(release.sales);
    }

    commit("setSelectStock");
  },

  DELETE_STOCK_DATA_SET({ commit }, status) {
    status === "99"
      ? commit("setDeleteStock", copyData(this.state.selectStock))
      : commit(
          "setSaveStock",
          this.state.selectStock.map((x) => {
            const color = x;
            x.status = status;
            return color;
          }),
        );

    commit("setSelectStock");
  },

  //매출 출고 금액 계산
  CALCULATE_RELEASE_SALES({ commit }) {
    const release = this.state.isFabricRelease
      ? this.state.fabricRelease
      : this.state.releaseForm;

    const sales = {
      status: "40", // 매출 정산, 마감 상태
      total_amount: 0, // 매출 금액
      quantity: 0, // 매출 수량
      memo: release._id ? release.sales.memo : "", // 매출 정산, 마감 메모
      color_list: [],
      surcharge_unit: release.surcharge_unit
        ? parsingNum(release.surcharge_unit)
        : 0, // surcharge 건당 금액
      color_surcharge_unit: release.color_surcharge_unit
        ? parsingNum(release.color_surcharge_unit)
        : 0,
    };

    this.state.productionColor.forEach((x) => {
      if (x.name) {
        const color = {
          name: x.name,
          style: x.style ? x.style : "",
          indexColorName: x.indexColorName ? x.indexColorName : "",
          success_quantity: x.quantity
            ? x.quantity
            : x.success_quantity
            ? x.success_quantity
            : 0,
          fail_quantity: x.fail_quantity ? x.fail_quantity : 0,
          pur_index: x.pur_index || x.pur_index === 0 ? x.pur_index : "",
          surcharge_unit: x.surcharge_unit ? x.surcharge_unit : "",
        };

        sales.quantity += +color.success_quantity;
        sales.color_list.push(color);
      }
    });

    sales.total_amount +=
      sales.quantity *
        (release.sales_unit_price ? parsingNum(release.sales_unit_price) : 0) +
      sales.surcharge_unit +
      sales.color_surcharge_unit;

    if (release.currency_sign !== "₩") {
      sales.eschamge_price = sales.total_amount * parsingNum(release.eschamge);
    }

    if (release.currency_sign === "₩") {
      sales.eschamge_price = 0;
      sales.eschamge = 0;
    }

    sales.quantity = decimalCacul(sales.quantity);
    sales.eschamge_price = decimalCacul(sales.eschamge_price);
    sales.total_amount = decimalCacul(sales.total_amount);

    commit("setSalesCacul", sales);
  },

  async GET_REQUEST_APPROVAL({ commit }, type) {
    try {
      const result = await getRequestApproval(
        this.state.auth_company,
        this.state.auth_id,
        this.state.auth_grade,
        type,
      );

      if (result.status !== 200) throw `eror : ${result.status}`;
      const header = filterParsing(result.data);

      commit(
        "setRequestApprovaloriginFilter",
        JSON.parse(JSON.stringify(header)),
      );
      this.commit("setRequestApprovalOriginalList", result.data);
      this.commit("setRequestApprovalFilterList", header);
      this.commit("setRequestApprovalList", result.data);
    } catch (e) {
      console.log(e);
    }
  },

  // Stock api 호출 함수
  async STOCK_KIND_CHANGE({ commit }, kind) {
    await this.dispatch("FIND_STOCK_DATA", this.state.saveStock.release);
    if (this.state.saveStock.kind !== kind) {
      this.state.saveStock.kind = kind;
      commit("setSaveStock", await stockSave(this.state.saveStock));
    }
  },

  // 재고 수량 저장
  async SAVE_STOCK_DATA({ commit }) {
    for (let i = 0; i < this.state.saveStock.length; i++) {
      await stockSave(this.state.saveStock[i]);
    }
    commit("setSaveStock");
  },

  // 재고 수량 삭제
  async DELETE_STOCK_DATE() {
    for (let i = 0; i < this.state.deleteStock.length; i++) {
      await stockDelete(this.state.deleteStock[i]);
    }
  },

  // 출고의뢰서로 재고 컬러 검색
  async FIND_STOCK_DATA({ commit }, id) {
    try {
      const result = await getStockForRelease(this.state.auth_company, id);
      if (result.status !== 200) throw `error: ${result.status}`;

      commit("setSelectStock", result.data);
    } catch (e) {
      console.log(e);
    }
  },

  // 전체 재고 컬러 검색
  async GET_STOCK_LIST({ commit }) {
    try {
      const result = await getStockList(this.state.auth_company);
      if (result.status !== 200) throw `error: ${result.status}`;

      commit(
        "setStockList",
        result.data.map((x, i) => {
          x.index = i;
          x.quantity = x.remain_quantity;
          x.releaseUnit = x.unit_sign;
          x.itemName = `${x.item}(재고)`;
          return x;
        }),
      );
    } catch (e) {
      console.log(e);
    }
  },

  async GET_SET_STOCK_LIST({ commit }, client) {
    try {
      const result = await getSetStockList(this.state.auth_company, client);
      if (result.status !== 200) throw `error: ${result.status}`;
      const outputSetStockList = _.cloneDeep(result.data.List).filter(
        (data) => {
          if (data.kind === "생지" && data.input_type === 2) {
            data.use_quantity = usingComma(Math.abs(data.remain_quantity));
            data.checkBox = false;
            return data;
          }
        },
      );
      const inputSetStockList = _.cloneDeep(result.data.List).filter((data) => {
        if (data.kind === "생지" && data.input_type === 1) {
          data.use_quantity = usingComma(Math.abs(data.remain_quantity));
          data.checkBox = false;
          return data;
        }
      });

      commit("setInputSetStockList", inputSetStockList);
      commit("setOutputSetStockList", outputSetStockList);
    } catch (e) {
      console.log(e);
    }
  },

  async GET_STOCK_TABLE_LIST({ commit }, data) {
    try {
      const result = await getStockTableList({
        company: this.state.auth_company,
        grade: this.state.auth_grade,
        stockType: data.stockType,
      });

      if (result.status !== 200) throw `error: ${result.status}`;
      if (result?.data?.orderList) {
        if (!result.data.header) result.data.header = {};
        const resultData = {
          orderList: result.data.orderList,
          header: result.data.header,
        };
        commit(
          "setOriginFilter",
          JSON.parse(JSON.stringify(result.data.header)),
        );
        resultData.oldData = this.state.stockTableFilterList;

        this.dispatch("SET_FILER_DATA", {
          data: resultData,
          kind: "stock",
        });
      }
    } catch (e) {
      console.log(e);
    }
  },
  async GET_USE_STOCK_TABLE_LIST({ commit }, data) {
    try {
      const result = await getUseStockList(this.state.auth_company);
      if (result.status !== 200) throw `error: ${result.status}`;

      this.commit("setUsedStockList", result.data);
    } catch (e) {
      console.log(e);
    }
  },
  async GET_USE_STOCK_CLIENT_LIST({ commit }, data) {
    try {
      const result = await getUseStockClientList({
        company: this.state.auth_company,
      });
      if (result.status !== 200) throw `error: ${result.status}`;

      this.commit("setUseStockClientList", result.data);
    } catch (e) {
      console.log(e);
    }
  },
  // 선매입 출고 재고 수량 전체 오더에서 사용 할수 있도록 변경
  async CHANGE_STOCK({ commit }) {
    await this.dispatch("SELECT_ORDER", this.state.releaseForm);
    await this.dispatch("FIND_STOCK_DATA", this.state.releaseForm.id);
    const release = this.state.selectOrder.release.find(
      (x) => x.id === this.state.releaseForm.id,
    );
    release.kind = 17;

    if (this.state.selectStock.length) {
      const stockList = this.state.selectStock.map((x) => {
        const color = x;
        x.kind = 1;
        return color;
      });
      commit("setSaveStock", stockList);
    } else {
      this.dispatch("SET_NEW_STOCK_DATA", {
        release,
        item: this.state.releaseForm.item.name,
      });
    }

    if (this.state.saveStock.length) {
      release.kind = 12;

      release.sales.isStock = true;
      commit("setSaveData", this.state.selectOrder);
      await this.dispatch("SAVE_ORDER");
    }
  },
  async STOCK_SAVE_FORM_PARSE(commit, data) {
    const saveForm = data.data;
    const type = data.type;
    const stockId = await saveStockId(this.state.auth_company);
    const approvalForm = { ...data.data };

    saveForm.client = saveForm?.client?._id
      ? saveForm.client._id
      : saveForm.client && saveForm.client !== "미선택"
      ? this.state.clientList.find((x) => x.name === saveForm.client)._id
      : null;
    saveForm.item = saveForm.item?._id
      ? saveForm.item.name
      : saveForm.item
      ? saveForm.item
      : null;
    saveForm.order = saveForm.order?._id
      ? saveForm.order.order
      : saveForm.order && saveForm.order.order !== "미선택"
      ? saveForm.order
      : null;

    saveForm.kind =
      type === "edit"
        ? this.state.editStockForm.kind.value
        : saveForm.kind.value;
    saveForm.date_register = new Date(saveForm.date);
    saveForm.date_modify = new Date(saveForm.date);
    saveForm.company = this.state.auth_company;
    saveForm.status =
      type === "end" ? (this.state.auth_grade >= 3 ? "00" : "60") : "40";
    if (saveForm.kind === 4)
      saveForm.input_type = +saveForm.stock_quantity < 0 ? 2 : 1;
    saveForm.remain_quantity =
      type === "save"
        ? saveForm.stock_quantity
        : saveForm.input_type === 2
        ? saveForm.remain_quantity * -1
        : saveForm.remain_quantity;
    if (type === "save")
      saveForm.id = `${this.state.auth_company}_${
        stockId.data ? stockId.data + 1 : 1
      }`;
    saveForm.name = saveForm.color;
    //approval 저장용 데이터파싱
    approvalForm.liaison = this.state.auth_id;
    approvalForm.saveData = { ...data.form };

    approvalForm.item =
      approvalForm.item &&
      this.state.itemList.find((data) =>
        data.name === approvalForm.item.name
          ? approvalForm.item.name
          : approvalForm.item,
      )._id;
    approvalForm.client = approvalForm.original_client;
    approvalForm.name = "stock";
    approvalForm.status =
      type === "end" ? (this.state.auth_grade >= 3 ? "00" : "60") : "40";
    this.commit("setSaveApproval", approvalForm);
  },
  async SAVE_USE_STOCK({ commit }) {
    try {
      const result = this.state.saveData._id
        ? await putUseStock(this.state.saveData)
        : await postUseStock(this.state.saveData);
      await this.dispatch("SAVE_APPROVAL");
      if (result.status !== 200) throw `error: ${result.status}`;
    } catch (e) {
      console.log(e);
    }
  },
  async DELETE_USE_STOCK({ commit }, data) {
    try {
      const result = await deleteUseStock(this.state.auth_company, data);
      // await this.dispatch("SAVE_APPROVAL");
      if (result.status !== 200) throw `error: ${result.status}`;
    } catch (e) {
      console.log(e);
    }
  },
  async TASK_USE_STOCK({ commit }, data) {
    const form = data.form,
      type = data.type,
      client = data.client;

    this.dispatch("SET_API_LOADING", true);
    if (type === "use") {
      for (let i = 0; i < form.length; i++) {
        await this.dispatch(
          this.state.auth_grade >= 3
            ? "USE_STOCK_FORM_PARSE"
            : "USER_USE_STOCK_FORM_PARSE",
          {
            form: form[i],
            type: type,
          },
        );

        await this.dispatch("SET_APPROVAL_SAVE_DATA", this.state.saveApproval);

        if (this.state.auth_grade >= 3) await this.dispatch("SAVE_USE_STOCK");
        if (this.state.auth_grade < 3) await this.dispatch("SAVE_APPROVAL");
        await stockSave(this.state.secondSaveData);
      }
    } else {
      await this.dispatch(
        this.state.auth_grade >= 3
          ? "USE_STOCK_FORM_PARSE"
          : "USER_USE_STOCK_FORM_PARSE",
        {
          form: form,
          type: type,
        },
      );

      await this.dispatch("SET_APPROVAL_SAVE_DATA", this.state.saveApproval);

      if (type === "edit" || (this.state.auth_grade < 3 && type === "delete"))
        this.dispatch("SAVE_USE_STOCK");

      if (this.state.auth_grade >= 3) {
        if (type === "delete")
          await this.dispatch("DELETE_USE_STOCK", this.state.saveData._id);

        await stockSave(this.state.secondSaveData);
      }
      await this.dispatch("SAVE_APPROVAL");
    }

    await this.dispatch("GET_USE_STOCK_TABLE_LIST");

    if (client) await this.dispatch("GET_SET_STOCK_LIST", client);
    await this.dispatch("SELECT_APPROVAL_LIST");
    this.dispatch("SET_API_LOADING", false);
    this.dispatch("PARSE_ROUTE_API_DATA_CALL");
  },
  async USER_USE_STOCK_FORM_PARSE({ commit }, data) {
    const form = { ...data.form };

    const saveStock = await getStock(
      this.state.auth_company,
      form.stock ? form.stock : form.id,
    );
    const saveUseStocks = await getIdStockList(
      this.state.auth_company,
      form.stock,
    );

    await this.dispatch("getaApprovalComId", {
      id: form.id,
      route: router.app._route.name,
    });

    const approvalForm = !_.isEmpty(this.state.approvalForm)
      ? this.state.approvalForm
      : { ...data.form };

    const type = data.type;
    praseSaveDataComma(form);

    form.company = this.state.auth_company;
    form.status = type === "use" ? "20" : type === "delete" ? "90" : "50";
    type === "use"
      ? (form.date_register = new Date(Date.now()))
      : (form.date_modify = new Date(Date.now()));
    form.kind = form.original_kind;

    form.client = this.state.clientList.find((x) => x.name === form.client)._id;

    if (type === "use") delete form._id;
    //stockData 파싱

    saveStock.status = "20";
    saveStock.client = saveStock.original_client;
    saveStock.kind = saveStock.original_kind;
    //approval 저장용 데이터파싱
    approvalForm.liaison = this.state.auth_id;
    approvalForm.saveData = { form };
    approvalForm.item =
      approvalForm.item &&
      this.state.itemList.find((data) => data.name === form.item)._id;
    approvalForm.client = approvalForm.original_client;
    approvalForm.name = type === "delete" ? "deleteStock" : "useStock";
    approvalForm.status =
      type === "use" ? "20" : type === "delete" ? "90" : "50";
    this.commit("setSaveData", form);
    this.commit("setSaveApproval", approvalForm);
    this.commit("setSecondSaveData", saveStock);
  },
  async USE_STOCK_FORM_PARSE({ commit }, data) {
    const form = { ...data.form };

    const saveStock = await getStock(
      this.state.auth_company,
      form.stock ? form.stock : form.id,
    );

    const saveUseStocks = await getIdStockList(
      this.state.auth_company,
      form.stock,
    );
    const saveLength = await saveUseStockLength(this.state.auth_company);

    const use_quantity =
      saveUseStocks &&
      saveUseStocks.data
        .filter((data) => data._id !== form._id)
        .reduce((r, c) => r + +c.use_quantity, 0);
    await this.dispatch("getaApprovalComId", {
      id: form.id,
      route: router.app._route.name,
    });

    const approvalForm = !_.isEmpty(this.state.approvalForm)
      ? this.state.approvalForm
      : { ...data.form };
    const type = data.type;
    praseSaveDataComma(form);

    form.company = this.state.auth_company;
    form.status = this.state.auth_grade >= 3 ? "40" : "20";
    type === "use"
      ? (form.date_register = new Date(Date.now()))
      : (form.date_modify = new Date(Date.now()));
    form.kind = form.original_kind;

    form.client = this.state.clientList.find((x) => x.name === form.client)._id;

    if (type === "use") {
      delete form._id;
      form.stock = form.id;
      form.id = `${this.state.auth_company}_useStock_${
        saveLength.data ? saveLength.data + 1 : 1
      }`;
    }
    //stockData 파싱
    // 잔여수량이 0이면 마감
    if (
      Math.abs(saveStock.remain_quantity) -
        (+form.use_quantity + use_quantity) <=
        0 &&
      this.state.auth_grade >= 3
    ) {
      saveStock.status = "00";
    }
    if (type === "edit")
      saveStock.remain_quantity =
        Math.abs(saveStock.stock_quantity) -
        (+form.use_quantity + use_quantity);
    else
      saveStock.remain_quantity =
        type === "use"
          ? Math.abs(saveStock.remain_quantity) - +form.use_quantity
          : type === "delete"
          ? Math.abs(saveStock.stock_quantity) + use_quantity * -1
          : 0;
    saveStock.remain_quantity = decimalCacul(saveStock.remain_quantity);
    if (saveStock.remain_quantity !== 0 && this.state.auth_grade >= 3)
      saveStock.status = "40";

    saveStock.client = saveStock.original_client;
    saveStock.kind = saveStock.original_kind;
    //approval 저장용 데이터파싱
    approvalForm.liaison = this.state.auth_id;
    approvalForm.saveData = { form };
    approvalForm.item =
      approvalForm.item &&
      this.state.itemList.find((data) => data.name === form.item)._id;
    approvalForm.client = approvalForm.original_client;
    approvalForm.name = "useStock";
    approvalForm.status = type === "delete" ? "99" : "40";
    this.commit("setSaveData", form);
    this.commit("setSaveApproval", approvalForm);
    this.commit("setSecondSaveData", saveStock);
  },
  // async TASK_SAVE_STOCK(commit, data) {
  //   this.dispatch("SET_API_LOADING", true);
  //   const saveForm = data.data;
  //   const type = data.type;

  //   if (type === "end" || type === "cancel") {
  //     for (let i = 0; i < this.state.selected.length; i++) {
  //       await this.dispatch("STOCK_SAVE_FORM_PARSE", {
  //         data: this.state.selected[i],
  //         type: type,
  //       });
  //       await this.dispatch("SET_APPROVAL_SAVE_DATA", this.state.saveApproval);
  //       await stockSave(this.state.selected[i]);
  //       await this.dispatch("SAVE_APPROVAL");
  //       this.state.stockSelected = "stock";
  //     }
  //   } else if (type === "delete")
  //     deleteStock(this.state.auth_company, saveForm.id);
  //   else {
  //     await praseSaveDataComma(saveForm);

  //     await this.dispatch("STOCK_SAVE_FORM_PARSE", data);
  //     await this.dispatch("SET_APPROVAL_SAVE_DATA", this.state.saveApproval);
  //     await stockSave(saveForm);
  //     await this.dispatch("SAVE_APPROVAL");
  //   }

  //   if (type === "save") this.commit("setNewStock");
  //   if (type === "edit") this.commit("setEditStockForm");
  //   await this.dispatch("SELECT_APPROVAL_LIST");
  //   this.dispatch("SET_API_LOADING", false);
  //   this.dispatch("PARSE_ROUTE_API_DATA_CALL");
  // },

  async NEW_PROGRESS({ commit }) {
    const order = this.state.saveData;
    const progress = this.state.progressData;
    progress.id = `${order.order}_${
      progress.kind === 2
        ? "bt_request"
        : progress.kind === 3
        ? "bt_delivery"
        : progress.kind === 4
        ? "bt_confirm"
        : progress.kind === 5
        ? "main_request"
        : progress.kind === 6
        ? "main_cmf"
        : progress.kind === 13
        ? "chemistry_request"
        : progress.kind === 14
        ? "chemistry_result"
        : ""
    }_${numFromat(order.progress.length + 1)}`;
    order.progress.push(progress);
    commit("setSaveData", order);
  },

  async MODIFY_PROGRESS({ commit }) {
    const order = this.state.saveData;
    order.progress[this.state.progressData.index] = this.state.progressData;
    order.progress[this.state.progressData.index].file_list = [];
    this.state.prgoressFileList.forEach((data) => {
      order.progress[this.state.progressData.index].file_list.push({
        name: data.filename ?? data.name,
        date: data.uploadDate ?? new Date(Date.now()),
      });
    });

    commit("setSaveData", order);
  },

  // 가공의뢰서 preset 사용
  MANUFACTURE_PRESET({ commit }, data) {
    this.dispatch("MANUFACTURE_CLEAR");
    const manufacture = copyData(
      this.state.productionOrderForm.manufacture.find((x) => x.id === data.id),
    );
    const newManufacture = this.state.manufactureForm;

    newManufacture.preset = data;

    newManufacture.place_manufacture = this.state.clientList.find(
      (x) => x._id === manufacture.place_manufacture,
    );
    newManufacture.item = this.state.itemList.find(
      (x) => x._id === manufacture.item,
    );
    newManufacture.post_processing = manufacture.post_processing
      ? manufacture.post_processing
      : "";
    newManufacture.width = manufacture.width ? manufacture.width : "";
    newManufacture.full_width = manufacture.full_width
      ? manufacture.full_width
      : "";
    newManufacture.currency_sign = manufacture.currency_sign
      ? manufacture.currency_sign
      : "";
    newManufacture.unit_price = manufacture.unit_price;
    newManufacture.unit_sign = manufacture.unit_sign;
    newManufacture.confirmation = manufacture.confirmation;
    newManufacture.precautions = manufacture.precautions;
    newManufacture.style_list = manufacture.style_list;
    newManufacture.date_payment = dateFormat(manufacture.date_payment);

    if (manufacture.eschamge) newManufacture.eschamge = manufacture.eschamge;

    newManufacture.total_quantity_sum = manufacture.color_list
      .filter((x) => x.quantity || x.quantity_sample)
      .reduce(
        (r, c) =>
          r +
          (c.quantity_sample
            ? +c.quantity
              ? +c.quantity + +c.quantity_sample
              : +c.quantity_sample
            : +c.quantity),
        0,
      );

    if (manufacture.sucharge_price) {
      delete newManufacture.surcharge_unit;
      newManufacture.sucharge_price = manufacture.sucharge_price;
    }
    if (manufacture.surcharge_unit) {
      delete newManufacture.sucharge_price;
      newManufacture.surcharge_unit = manufacture.surcharge_unit.price;
    }

    this.dispatch("SET_MANUFACTURE_COLOR", manufacture.color_list);
  },

  // 출고의뢰서 preset 사용
  async RELEASE_PRESET({ commit }, data) {
    this.dispatch("RELEASEFORM_CLEAR");

    const release = copyData(
      this.state.productionOrderForm.release.find((x) => x.id === data.id),
    );

    const newRelease = copyData(this.state.releaseForm);

    newRelease.dialog = true;
    newRelease.order = release.order;
    newRelease.item = "";
    newRelease.preset = data;

    newRelease.salesBtn = release.kind === 9 || release.kind === 15;
    newRelease.purBtn =
      release.kind === 8 ||
      release.kind === 15 ||
      release.kind === 12 ||
      release.kind === 17;
    newRelease.appenBtn = release.kind === 10;
    newRelease.basicBtn = release.kind === 16;
    newRelease.preBtn = release.kind === 12;
    newRelease.stockBtn = release.kind === 17;

    this.dispatch("SET_DOC_LIST", newRelease.salesBtn);

    newRelease.unit_sign = release.unit_sign;

    newRelease.currency_sign = release.currency_sign
      ? release.currency_sign
      : "₩";

    newRelease.precautions = release.precautions;
    newRelease.remark = release.remark;
    newRelease.place_receive_memo = release.place_receive_memo;
    newRelease.sales_unit_price = release.sales_unit_price;
    newRelease.place_receive_memo = release.place_receive_memo;
    newRelease.date_delivery = release.date_delivery;
    newRelease.item = this.state.itemList.find((x) => x._id === release.item);

    newRelease.place_forward = release.place_forward
      ? this.state.useClientList.find((x) => x._id === release.place_forward)
      : "";
    newRelease.place_receive = release.place_receive
      ? this.state.useClientList.find((x) => x._id === release.place_receive)
      : "";

    if (newRelease.salesBtn) {
      newRelease.surcharge_unit = release.sales.surcharge_unit;
      if (release.sales.eschamge) newRelease.eschamge = release.sales.eschamge;
      newRelease.color_surcharge_unit = release.sales.color_surcharge_unit;
      newRelease.place_sales = release.place_sales
        ? this.state.useClientList.find((x) => x._id === release.place_sales)
        : "";
    }

    if (release.stock) newRelease.requestDoc = { kind: 17 };
    else if (release.kind === 17) newRelease.requestDoc = { kind: 0 };
    else
      newRelease.requestDoc = this.state.productionDocList.find(
        (x) =>
          x.id ===
          (release.appearance
            ? release.appearance
            : release.release
            ? release.release
            : release.manufacture),
      );

    if (newRelease.requestDoc) {
      if (newRelease.requestDoc.kind === 17) {
        await this.dispatch("GET_STOCK_LIST");
        const stockList = this.state.stockList;
        newRelease.item = stockList.find(
          (x) => x.item === newRelease.item.name,
        );
      }

      commit("setReleaseForm", newRelease);

      if (newRelease.requestDoc.kind !== 17)
        this.dispatch("SET_RELEASE_REQUEST", "preset");
      else this.dispatch("SET_STOCK_COLORLIST");

      if (newRelease.requestDoc.kind === 0)
        this.dispatch("PARSE_PRESET_NONDOC", release.color_list);

      if (newRelease.requestDoc.kind === 7)
        this.dispatch("PARSE_PRESET_MANUFACTURE", release.color_list);

      if (newRelease.requestDoc.kind === 12)
        this.dispatch("PARSE_PRESET_RELEASE", release.sales.color_list);

      if (newRelease.requestDoc.kind === 17)
        this.dispatch("PARSE_PRESET_STOCK", release.sales.color_list);

      if (newRelease.salesBtn && release.sales.color_surcharge_unit) {
        const colorList = this.state.productionColor;
        release.sales.color_list.forEach((x, i) => {
          if (x.surcharge_unit) {
            colorList[i].surcharge_unit = x.surcharge_unit;
            colorList[i].surchargeCommma = usingComma(x.surcharge_unit);
          }
        });
        this.dispatch("CALCULATE_RELEASE_SALES");
      }
    }
  },

  PARSE_PRESET_NONDOC({ commit }, colorList) {
    const releaseColorList = copyData(this.state.productionColor);
    colorList.forEach((x, i) => {
      releaseColorList[i].name = x.name;
      releaseColorList[i].quantity = +x.quantity;
      releaseColorList[i].isSuccess = true;
    });
    commit("setProductionColor", releaseColorList);
    commit(
      "setCommaColor",
      copyData(releaseColorList).map((x) => {
        x.quantity = usingComma(x.quantity);
        return x;
      }),
    );
  },

  PARSE_PRESET_MANUFACTURE({ commit }, colorList) {
    const releaseColorList = copyData(this.state.productionColor);
    colorList.forEach((x, i) => {
      const color = this.state.releaseColorList.color.find((y) =>
        x.indexColorName && y.indexColorName
          ? x.indexColorName === y.indexColorName
          : x.name === y.name,
      );

      if (!releaseColorList[i]) {
        releaseColorList.push({ isSuccess: false });
      }

      const relaseColor = releaseColorList[i];

      relaseColor.name = color.name;
      relaseColor.quantity = +x.quantity;
      relaseColor.style = x.style;
      relaseColor.isSuccess = true;
      relaseColor.indexColorName = color.indexColorName;

      // if (
      //   !this.state.releaseForm.basicBtn &&
      //   !this.state.releaseForm.appenBtn
      // ) {
      if (color.color_surcharge_unit)
        relaseColor.color_surcharge_unit = color.color_surcharge_unit;

      if (color.color_surcharge_price && !color.color_surcharge_price.is_used) {
        relaseColor.color_surcharge_price = color.color_surcharge_price.price;
        color.color_surcharge_price.is_used = true;
      }
      relaseColor.purchQuantity = color.quantity;
      // }
    });

    commit("setProductionColor", releaseColorList);
    commit(
      "setCommaColor",
      copyData(releaseColorList).map((x) => {
        x.quantity = usingComma(x.quantity);
        return x;
      }),
    );
    this.dispatch("CALCULATE_COLOR_TOTAL", colorList);
  },

  PARSE_PRESET_RELEASE({ commit }, colorList) {
    const releaseColorList = copyData(this.state.productionColor);

    colorList.forEach((x, i) => {
      const color = {
        name: x.name,
        style: x.style,
        success_quantity: "",
        pur_index: x.pur_index,
      };
      releaseColorList[i] = color;
    });
    commit("setProductionColor", releaseColorList);
    commit("setCommaColor", releaseColorList);

    this.dispatch("CALCULATE_PRE_COLOR_TOTAL", releaseColorList);
  },

  PARSE_PRESET_STOCK({ commit }, colorList) {
    const releaseColorList = copyData(this.state.productionColor);
    colorList.forEach((x, i) => {
      const stock = this.state.stockList.find((y) => x.indexColorName === y.id);
      if (stock.status === "40") {
        if (stock.releaseUnit !== this.state.releaseForm.unit_sign) {
          stock.releaseUnit = this.state.releaseForm.unit_sign;
          stock.quantity = unitChange(stock.releaseUnit, stock.quantity);
          stock.remain_quantity = unitChange(
            stock.releaseUnit,
            stock.remain_quantity,
          );
        }
        const color = {
          order: stock.order,
          item: stock.item,
          setColorName: stock.indexColorName,
          indexColorName: stock.id,
          name: stock.name,
          style: stock.style ? stock.style : "",
          success_quantity: "",
          index: stock.index,
        };
        releaseColorList[i] = color;
      }
    });

    releaseColorList.sort((a, b) => {
      if (a.name && !b.name) return -1;
    });

    commit("setProductionColor", releaseColorList);
    commit("setCommaColor", releaseColorList);
    this.dispatch("CALCULATE_STOCK_COLOR_TOTAL", releaseColorList);
  },

  async HEADER_FILTER_DATA({ commit }, data) {
    try {
      const result = await getFilterData({
        company: this.state.auth_company,
        route: router.app._route.name,
        stockType: data && data.stockType ? data.stockType : [],
        vatType: data && data.vatType ? data.vatType : "",
        vatKind: data && data.vatKind ? data.vatKind : "",
        filter: [],
        grade: this.state.auth_grade,
      });
      //필터링 걸려있던 항목들 적용

      if (!this.state.applyFilterList[router.app._route.name]) {
        commit(filterList[router.app._route.name], { ...result.data.header });
      }
      // else {
      //   commit(
      //     filterList[router.app._route.name],
      //     filteredListPasing(
      //       { ...result.data.header },
      //       [...result.data.orderList],
      //       { ...this.state.applyFilterList },
      //     ),
      //   );
      // }

      commit(totalList[router.app._route.name], result.data.orderList);
      commit(originFilter[router.app._route.name], { ...result.data.header });
      commit("setOriginPageTotalSize", result.data.totalSize);
    } catch (e) {
      alert(e);
    }
  },
  async SET_FABRIC_RELEASEFORM({ commit }) {
    await this.dispatch("getaApprovalComId", {
      id: this.state.productionProgress.id,
      route: router.app._route.name,
    });

    const release = copyData(
      this.state.productionProgress.status === "50"
        ? this.state.approvalForm.data
        : this.state.productionOrderForm.release.find(
            (x) => x.id === this.state.productionProgress.id,
          ),
    );

    const form = {
      place_forward: this.state.clientList.find(
        (x) => x._id === release.place_forward,
      ),
      place_sales: release.place_sales
        ? this.state.clientList.find((x) => x._id === release.place_sales)
        : "",
      liaison: this.state.userList.find((x) => x._id === release.liaison),
      item: this.state.itemList.find((x) => x._id === release.item),
      currency_sign: release.currency_sign ? release.currency_sign : "₩",
      unit_sign: release.unit_sign,
      eschamge: release.eschamge ? usingComma(release.eschamge) : "",
      status: release.status,
      place_receive: release.place_receive
        ? this.state.clientList.find((x) => x._id === release.place_receive)
        : "",
      sales_unit_price: release.sales_unit_price
        ? usingComma(release.sales_unit_price)
        : "",
      color_list: release.color_list,
      date_register: release.date_register, // 작성 날짜
      date_modify: release.date_modify, // 저장 날짜
      date_delivery: release.date_delivery
        ? release.date_delivery
        : release.date_register,
      order: release.order,
      kind: release.kind,
      salesBtn: false,
      requestDoc: { name: "생지 출고" },
      purchaseUnitPrice: release.purchase.unit_price,
      purBtn: true,
      appenBtn: false,
      basicBtn: false,
      stockBtn: false,
      fabricBtn: true,
      dialog: true,
      isModify: true,
      company: release.company,
      id: release.id,
      _id: release._id,
    };

    this.dispatch("SET_FABRRIC_COLORLIST", release);
    commit("setPurchasCacul", release.purchase);

    commit("setReleaseForm", form);
  },

  SET_FABRRIC_COLORLIST({ commit }, release) {
    let colorList = copyData(
      release.purchase.color_list.map((x) => {
        const color = {
          name: x.name,
          style: x.style,
          quantity: x.quantity,
          isSuccess: true,
          indexColorName: "생 지",
        };
        return color;
      }),
    );

    if (release.purchase) commit("setPurchasCacul", release.purchase);
    if (colorList.length < 8) colorList = setEmptyValue(8, colorList);

    this.dispatch("CALCULATE_COLOR_TOTAL", colorList);
    commit("setProductionColor", colorList);
    commit("setCommaColor", setColorQuantityComma(colorList));
  },

  async SAVE_TEMPORARY_FABIRC_SAVE({ commit }, doc) {
    commit("setAPILoading", true);
    await this.dispatch("SELECT_ORDER", this.state.productionOrderForm);
    if (this.state.productionOrderForm.__v !== this.state.selectOrder.__v)
      alert("오더에 변경사항이 있습니다. 다시 저장해주세요.");
    else {
      const order = copyData(this.state.selectOrder);

      const saveDocIndex = order[
        doc.kind === 7 ? "manufacture" : "release"
      ].findIndex((x) => x.id === doc.id);

      const saveDoc = {
        ...(doc.kind === 19
          ? releaseParse(doc, this.state.saveReleaseColor)
          : manufactureParse(doc, this.state.productionColor)),
        id: doc.id,
        status: this.state.auth_grade >= 2 ? "40" : "20",
      };

      order[doc.kind === 7 ? "manufacture" : "release"][saveDocIndex] = saveDoc;
      this.dispatch("SET_APPROVAL_SAVE_DATA", saveDoc);
      this.dispatch("ADD_ORDER_PROGRESS", order);

      commit("setSaveData", order);
      this.dispatch("PRODUCTION_DIALOG_INIT");
      await this.dispatch("SAVE_ORDER");
      commit("setProductionConfirm");
    }
  },
  //오더 선수금 입력 로직
  async TASK_ADVANCE_PAY({ commit }) {
    const order = JSON.parse(JSON.stringify(this.state.orderForm));
    const receivableForm = {};

    praseSaveDataComma(order);

    receivableForm.company = this.state.auth_company;
    receivableForm.name = order.client && order.client.name;
    receivableForm.client = order.client && order.client._id;
    receivableForm.year = new Date(order.date_register).getFullYear();
    receivableForm.month = new Date(order.date_register).getMonth() + 1;
    receivableForm.kind = "sales";
    receivableForm.usage = true;
    receivableForm.classification = order
      ? order.sales_classification === "export"
        ? 2
        : 1
      : null;
    commit("setSaveRecivable", receivableForm);
    const saveRecivableCheck = await getReceivableByClient(
      this.state.auth_company,
      receivableForm.client,
      receivableForm.year,
      receivableForm.month,
      receivableForm.kind,
    );
    if (!saveRecivableCheck.data.length)
      try {
        await this.dispatch("SAVE_RECIVABLE");
      } catch (e) {
        console.log(e);
      }

    const saveRecivable = await getReceivableByClient(
      this.state.auth_company,
      receivableForm.client,
      receivableForm.year,
      receivableForm.month,
      receivableForm.kind,
    );
    const receivableAmmountForm = { ...receivableForm };
    receivableAmmountForm.receivable = saveRecivable
      ? saveRecivable.data[0]._id
      : null;
    receivableAmmountForm.type = 5;
    receivableAmmountForm.deposit_amount = order.advance_pay;
    receivableAmmountForm.amount = 0;
    receivableAmmountForm.deposit_date = new Date(order.date_register);
    if (order.eschamge_sign !== "₩") {
      receivableAmmountForm.currency_sign = order.eschamge_sign;
    }

    this.commit("setSaveRecivableAmount", receivableAmmountForm);
    try {
      await this.dispatch("SAVE_RECIVABLE_AMOUNT");
    } catch (e) {
      console.log(e);
    }
  },
  async GET_SELECT_USER_LIST({ commit }) {
    try {
      const userList = await getSelectUserList(this.state.auth_company);
      if (userList.status !== 200) throw `error : ${userList.status}`;

      commit("setUserList", userList.data);

      return true;
    } catch (e) {
      alert(e);
    }
  },
  async GET_SELECT_CLIENT_LIST({ commit }) {
    try {
      const clientList = await getSelectClientList(
        this.state.auth_company,
        "40",
      );
      if (clientList.status !== 200) throw `error : ${clientList.status}`;

      commit("setUseClientList", clientList.data);
    } catch (e) {
      alert(e);
    }
  },
  async GET_SELECT_ORDER_LIST({ commit }) {
    try {
      const result = await getSelectOrderList(this.state.auth_company, "40");

      commit("setOrderList", result.data);
    } catch (e) {
      alert(e);
    }
  },
  async TASK_CLAIM_SAVE({ commit }, form) {
    const type = form.type;
    const target = form.target;
    const saveForm = form.saveForm;

    try {
      this.dispatch("SET_API_LOADING", true);

      const result = await saveClaim({
        type: type,
        target: target,
        saveForm: saveForm,
        grade: this.state.auth_grade,
        company: this.state.auth_company,
        liaison: this.state.auth_id,
      });
      if (result.status !== 200) return;
      if (type === "create") this.commit("clearClaimNewRows");
      // await this.dispatch("SET_CLAIMLIST");
      if (this.state.auth_grade >= 2)
        await this.dispatch("SELECT_APPROVAL_LIST");
      this.dispatch("SET_API_LOADING", false);
      this._vm.$socket.emit("approval", { company: this.state.auth_company });
    } catch (e) {
      // await this.dispatch("SET_CLAIMLIST");

      alert("클레임 저장에 실패하였습니다. 다시 시도해주세요.");
      this.dispatch("SET_API_LOADING", false);
      console.log(e);
    }
    this.dispatch("PARSE_ROUTE_API_DATA_CALL");
  },
  async TASK_SELECT_CLAIM_SAVE({ commit }, saveTarget) {
    const type = saveTarget.type;
    const saveForms = this.state.selected;

    try {
      this.dispatch("SET_API_LOADING", true);

      const result = await claimMultiSave({
        type: type,
        saveForm: saveForms,
        grade: this.state.auth_grade,
        company: this.state.auth_company,
        liaison: this.state.auth_id,
      });
      if (result.status !== 200) return;
      // if (router.app._route.name === "claim")
      //   await this.dispatch("SET_CLAIMLIST");
      // if (router.app._route.name === "endClaim")
      //   await this.dispatch("SET_END_CLAIMLIST");
      if (this.state.auth_grade >= 2)
        await this.dispatch("SELECT_APPROVAL_LIST");
      this._vm.$socket.emit("approval", { company: this.state.auth_company });
      this.dispatch("SET_API_LOADING", false);
    } catch (e) {
      // await this.dispatch("SET_CLAIMLIST");
      if (this.state.auth_grade >= 2)
        await this.dispatch("SELECT_APPROVAL_LIST");
      alert("저장에 실패하였습니다. 다시 시도해주세요.");
      this.dispatch("SET_API_LOADING", false);
      console.log(e);
    }
    this.dispatch("PARSE_ROUTE_API_DATA_CALL");
  },

  CALL_SEARCH_API({ commit }, data) {
    commit(data.paramName, data.param);
    this.dispatch(data.totalApiName, data.totalData);
    this.dispatch(data.searchApiName, data.searchData);
  },

  INIT_ALL_CHECKBOX({ commit }) {
    const routeName = router.app._route.name;
    const names = commitName[routeName];

    if (routeName === "claim" || routeName === "endClaim") {
      commit(names.ariseCheckboxCommitName, false);
      commit(names.passCheckboxCommitName, false);
    }
    if (names.checkboxCommitName) commit(names.checkboxCommitName, false);

    this.dispatch("SET_CHECKBOXLENGTH");
  },

  // 오더 검색 API 호출후 전달 받은 commit 함수 명을 통해 데이터 set
  async CALL_ORDER_SEARCH_API({ commit }, data) {
    if (data.isInfinite) this.dispatch("SET_API_LOADING", true);
    try {
      const result = await searchDocGrop({
        company: this.state.auth_company,
        params: data.param,
      });

      if (result.status !== 200) throw `error: ${result.status}`;

      commit(data.commitName, result.data);
    } catch (e) {
      console.log(e);
    }
    this.dispatch("SET_API_LOADING", false);
  },

  // 오더 검색 API 호출후 전달 받은 commit 함수 명을 통해 total set
  async CALL_ORDER_TOTAL_API({ commit }, data) {
    try {
      const result = await totalCount({
        company: this.state.auth_company,
        params: data.param,
      });

      if (result.status !== 200) throw `error: ${result.status}`;

      commit(data.commitName, result.data);
      this.dispatch("INIT_ALL_CHECKBOX");
    } catch (e) {
      console.log(e);
    }
  },

  // 클레임 검색 API 호출후 전달 받은 commit 함수 명을 통해 데이터 set
  async CALL_CLAIM_SEARCH_API({ commit }, data) {
    if (data.isInfinite) this.dispatch("SET_API_LOADING", true);
    try {
      const result = await claimSearch({
        company: this.state.auth_company,
        params: data.param,
      });

      if (result.status !== 200) throw `error: ${result.status}`;
      commit(data.commitName, result.data);
    } catch (e) {
      console.log(e);
    }
    this.dispatch("SET_API_LOADING", false);
  },

  // 클레임 검색 API 호출후 전달 받은 commit 함수 명을 통해 total set
  async CALL_CLAIM_TOTAL_API({ commit }, data) {
    try {
      const result = await claimTotalCount({
        company: this.state.auth_company,
        params: data.param,
      });

      if (result.status !== 200) throw `error: ${result.status}`;

      commit(data.commitName, result.data);
      this.dispatch("INIT_ALL_CHECKBOX");
    } catch (e) {
      console.log(e);
    }
  },

  // 외상금 검색 API 호출후 전달 받은 commit 함수 명을 통해 데이터 set
  async CALL_RECEIVABLE_SEARCH_API({ commit }, data) {
    if (data.isInfinite) this.dispatch("SET_API_LOADING", true);
    try {
      const result = await receivableSearch({
        company: this.state.auth_company,
        params: data.param,
      });
      if (result.status !== 200) throw `error: ${result.status}`;

      commit(data.commitName, result.data);
    } catch (e) {
      console.log(e);
    }
    this.dispatch("SET_API_LOADING", false);
  },

  //  검색 API 호출후 전달 받은 commit 함수 명을 통해 total set
  async CALL_RECEIVABLE_TOTAL_API({ commit }, data) {
    try {
      const result = await receivableCount({
        company: this.state.auth_company,
        params: data.param,
      });

      if (result.status !== 200) throw `error: ${result.status}`;

      commit(data.commitName, result.data);
    } catch (e) {
      console.log(e);
    }
  },

  // 외상금 검색 API 호출후 전달 받은 commit 함수 명을 통해 데이터 set
  async CALL_STOCK_SEARCH_API({ commit }, data) {
    if (data.isInfinite) this.dispatch("SET_API_LOADING", true);
    try {
      const result = await stockSearch({
        company: this.state.auth_company,
        params: data.param,
      });
      if (result.status !== 200) throw `error: ${result.status}`;

      commit(data.commitName, result.data);
    } catch (e) {
      console.log(e);
    }
    this.dispatch("SET_API_LOADING", false);
  },

  //  검색 API 호출후 전달 받은 commit 함수 명을 통해 total set
  async CALL_STOCK_TOTAL_API({ commit }, data) {
    try {
      const result = await stockCount({
        company: this.state.auth_company,
        params: data.param,
      });

      if (result.status !== 200) throw `error: ${result.status}`;

      commit(data.commitName, result.data);
      this.dispatch("INIT_ALL_CHECKBOX");
    } catch (e) {
      console.log(e);
    }
  },
  async TASK_CALCULATE_SAVE({ commit }, data) {
    try {
      this.dispatch("SET_API_LOADING", true);
      const type = data.type;
      const savePage = data.savePage;
      const saveData = this.state.selected;
      await calculateSave({
        saveData,
        type,
        savePage,
        grade: this.state.auth_grade,
        router: router.app._route.name,
        company: this.state.auth_company,
        liaison: this.state.auth_id,
      });

      this.dispatch("SET_API_LOADING", false);
      this._vm.$socket.emit("approval", { company: this.state.auth_company });
    } catch (e) {
      alert("저장에 실패하였습니다. 다시 시도해주세요.");
      this.dispatch("SET_API_LOADING", false);
    }
    this.dispatch("PARSE_ROUTE_API_DATA_CALL");
  },

  async TASK_ORDER_SAVE({ commit }) {
    this.dispatch("SET_API_LOADING", true);
    const form = this.state.orderForm;
    form.grade = this.state.auth_grade;
    form.liaison = this.state.auth_id;
    form.company = this.state.auth_company;
    try {
      await orderPageSave(form);
      this.dispatch("SAVE_INIT_PAGE");
      this.commit("setApprovalMemo");
      this._vm.$socket.emit("approval", { company: this.state.auth_company });
    } catch (e) {
      this.dispatch("ORDER_SAVE_ERROR");
      alert("저장에 실패하였습니다. 다시 시도해주세요.");
      this.dispatch("SET_API_LOADING", false);
    }
    this.dispatch("PARSE_ROUTE_API_DATA_CALL");
  },
  async TASK_RECEIVEABLE_SAVE({ commit }, data) {
    try {
      this.dispatch("SET_API_LOADING", true);

      const type = data.type;
      const savePage = data.savePage;
      const form = data.form;
      await saveReceivableAmount({
        form,
        type,
        savePage,
        grade: this.state.auth_grade,
        router: router.app._route.name,
        company: this.state.auth_company,
      });

      this.dispatch("SET_API_LOADING", false);
      this._vm.$socket.emit("approval", { company: this.state.auth_company });
    } catch (e) {
      alert("저장에 실패하였습니다. 다시 시도해주세요.");
      this.dispatch("SET_API_LOADING", false);
    }
    this.dispatch("PARSE_ROUTE_API_DATA_CALL");
  },
  async TASK_SAVE_STOCK(commit, data) {
    try {
      this.dispatch("SET_API_LOADING", true);
      data.company = this.state.auth_company;
      data.grade = this.state.auth_grade;
      data.router = router.app._route.name;
      data.liaison = this.state.auth_id;
      data.type === "end" || data.type === "cancel"
        ? await saveSelectStock({
            data,
            saveData: this.state.selected,
          })
        : await saveStock(data);
      if (data.type === "save") this.commit("setNewStock");
      if (data.type === "edit") this.commit("setEditStockForm");
      this.dispatch("SET_API_LOADING", false);
      this.dispatch("PARSE_ROUTE_API_DATA_CALL");
    } catch (e) {
      console.log(e);
    }
  },
  SAVE_PAGE_SEARCH_API({ commit }, data) {
    const names = commitName[router.app._route.name];
    const status =
      router.app._route.name === "order" && this.state.auth_grade >= 2
        ? names.managerStatus
        : names.status;

    const param = {
      multi: JSON.stringify(data.param),
      field: data.param.length ? data.param[data.param.length - 1].field : "",
      sort: data.sort,
      status: status,
      pageType: names.pageType,
      type: data.type ? data.type : names.type,
      isEndCase: names.isEndCase,
      kind: names.kind,
    };

    this.dispatch("CALL_SEARCH_API", {
      paramName: names.paramName,
      totalApiName: names.totalApiName,
      searchApiName: names.searchApiName,
      param: data.param,
      totalData: {
        param,
        commitName: names.totalCommitName,
      },
      searchData: {
        param,
        commitName: names.searchCommitName,
      },
    });
  },

  PARSE_ROUTE_API_DATA_CALL({ commit }) {
    const routeName = router.app._route.name;
    let param,
      type,
      sort = "";

    switch (routeName) {
      case "order":
        param = this.state.orderParams;
        sort = this.state.orderSort;
        break;
      case "purchasecalculate":
        param = this.state.purchaseCaculParam;
        sort = this.state.calculatesSort;
        break;
      case "purchasedeadline":
        param = this.state.purchaseEndParam;
        sort = this.state.calculateEndsSort;
        break;
      case "salescalculate":
        param = this.state.salesCaculParam;
        sort = this.state.salesCalculatesSort;
        break;
      case "salesdeadline":
        param = this.state.salesCaculParam;
        sort = this.state.salesCalculatesSort;
        break;
      case "production":
        param = this.state.productionParams;
        sort = this.state.productionSort;
        break;
      case "stock":
        param = this.state.stockParam;
        sort = this.state.stockSort;
        type = this.state.stockSelected;
        break;
      case "purchaseVat":
        param = this.state.purchaseVatParam;
        sort = this.state.vatSort;
        type = this.state.vatSelected;
        break;
      case "salesVat":
        param = this.state.salesVatParam;
        sort = this.state.vatSalesSort;
        type = this.state.vatSalesSelected;
        break;
      case "claim":
        param = this.state.claimParam;
        sort = this.state.claimSort;
        break;
      case "endClaim":
        param = this.state.endClaimParam;
        sort = this.state.endClaimSort;
        break;
      default:
        break;
    }
    this.dispatch("SAVE_PAGE_SEARCH_API", {
      param,
      sort,
      type,
    });
  },

  SET_CHECKBOXLENGTH({ commit }, list) {
    let checkboxLength;
    if (
      router.app._route.name === "claim" ||
      router.app._route.name === "endClaim"
    )
      checkboxLength = {
        oneCheckBoxLength: list
          ? list.filter((x) => x.arise_checked).length
          : 0,
        secondCheckBoxLength: list
          ? list.filter((x) => x.pass_checked).length
          : 0,
      };
    else
      checkboxLength = {
        oneCheckBoxLength: list ? list.filter((x) => x.checkBox).length : 0,
        secondCheckBoxLength: 0,
      };

    commit("setCheckBoxLength", checkboxLength);
  },
  async GET_MAIN_CHART_DATA({ commit }, data) {
    this.dispatch("SET_API_LOADING", true);
    try {
      const result = await mainChartDataFind({
        company: this.state.auth_company,
        year: data.year,
        season: data.season,
        client: data.client,
      });
      if (result.status !== 200) throw `error: ${result.status}`;

      commit("setMainChartData", result.data);
    } catch (e) {
      console.log(e);
    }
    this.dispatch("SET_API_LOADING", false);
  },

  //승인요청 창 생산페이지 승인 문서 set
  // SET_REUQEST_PRODUCTION_FORM({ commit }, docData) {
  //   console.log(docData);
  //   const doc = {
  //     name: docType,

  //   };
  // },

  async SET_REQUEST_FORM_DATA({ commit, state }, data) {
    try {
      const param = { ...data, ...{ company: state.auth_company } };
      const result = await getRequestFormData(param);

      if (result.status !== 200) throw `error: ${result.status}`;

      commit("setRequestForm", result.data);
    } catch (e) {
      console.log(e);
    }
  },
  async GET_REQUEST_TYPE_LIST({ commit }, type) {
    try {
      const result = await getRequesApprovalTypeList({
        company: this.state.auth_company,
        liaison: this.state.auth_id,
        grade: this.state.auth_grade,
        type,
      });

      if (result.status !== 200) throw `eror : ${result.status}`;

      this.commit("setRequestApprovalList", result.data);
    } catch (e) {
      console.log(e);
    }
  },
  async GET_APPROVAL_HISTORY({ commit }, data) {
    try {
      const result = await getApprovalHistory({
        company: this.state.auth_company,
        grade: this.state.auth_grade,
        order: data.order,
        name: data.name,
        id: data.id,
      });

      if (result.status !== 200) throw `eror : ${result.status}`;
      commit("setApprvoalHistory", result.data);
    } catch (e) {
      console.log(e);
    }
  },
  async PRODUCTION_DOC_CONTEXT_MENU_OPEN({ commit }, data) {
    const name = data.id ? data.id.split("_")[1] : "";

    await this.dispatch("GET_APPROVAL_HISTORY", {
      name: name,

      id: data.id,
    });
  },

  // * 백오피스페이지
  // 관리자 페이지 접속 시 admin 체크
  async CHECK_ADMIN({ commit }, data) {
    try {
      const result = await adminCheck({
        company: this.state.auth_company,
        grade: this.state.auth_grade,
      });

      // console.log(result);
      // if (!result.data)
      //   router.push({
      //     name: "main",
      //   });
    } catch (e) {
      throw e.toString();
    }
  },

  // 결제하기
  async PAY_SAVE(commit, data) {
    try {
      const result = await paySave({
        company: this.state.company._id,
        companyName: this.state.auth_company,
        liaison: this.state.auth_id,
        form: data,
      });
    } catch (e) {
      throw e.toString();
    }
  },
  // 입금확인 요청중이거나 아직 기간이 남았는지 체크
  async PAYMENT_STATUS_CHECK({ commit }, data) {
    try {
      const result = await paymentCheck({
        company: this.state.auth_company,
      });

      commit("setIsPayment", result.data);
    } catch (e) {
      throw e.toString();
    }
  },
  //결제내역 리스트
  async GET_PAYMENTLIST({ commit }, data) {
    try {
      const result = await getPaymentList({
        company: this.state.company._id,

        status: data.status,
        sort: data.sort,
      });

      commit("setPaymentList", result.data);
    } catch (e) {
      throw e.toString();
    }
  },
  //결제내역에서 환불 요청 호출
  async REQUEST_REFUND({ commit }, data) {
    try {
      this.dispatch("SET_API_LOADING", true);
      const result = await refundPay(data);

      this.dispatch("SET_API_LOADING", false);
    } catch (e) {
      throw e.toString();
    }
  },
  // 백오피스 관리자 결제정보 리스트 가져오기
  async CALL_ADMIN_PAYMENTLIST({ commit }, data) {
    try {
      const result = await getAdminPaymentList({
        type: data.type,
        sort: data.sort,
        status: data.status,
        company: data.company,
      });

      commit("setAdminPaymentList", result.data);
    } catch (e) {
      throw e.toString();
    }
  },
  // 활성화된 멤버십정보 가져오기
  async CALL_MEMBERSHIP_INFO({ commit }, data) {
    try {
      const result = await getMembershipInfo({
        membership_id: this.state.company.active_membership_id,
      });

      commit("setMembershipInfo", result.data && result.data[0]);
    } catch (e) {
      throw e.toString();
    }
  },
};
