import router from "@/router";

function dateFormat(date) {
  if (!date) {
    date = this.form.date_register;
  }
  const temp = new Date(date);
  const year = temp.getFullYear();
  const month = 1 + temp.getMonth();
  const day = temp.getDate();
  return `${year}-${month >= 10 ? month : "0" + month}-${
    day >= 10 ? day : "0" + day
  }`;
}
export default {
  isLogin(state) {
    return state.auth_uuid !== "";
  },
  isAdmin(state) {
    return +state.auth_grade >= 2;
  },
  isHistory(state) {
    return state.history_company_login !== "";
  },
  userGradeWithoutOwner(state) {
    const temp = JSON.parse(JSON.stringify(state.user_grade));
    temp.shift();
    return temp;
  },
  clientKindWithAll(state) {
    const temp = JSON.parse(JSON.stringify(state.client_kind));
    temp.unshift({
      value: "all",
      label: "전체",
    });
    return temp;
  },
  clientTypeWithAll(state) {
    const temp = JSON.parse(JSON.stringify(state.client_type));
    temp.unshift({
      value: "all",
      label: "전체",
    });

    return temp;
  },
  // 정산 페이지
  //22.02.10 정산 페이지 수정 될 출고의뢰서
  getChangeData(state) {
    return state.changeData;
  },
  //22.02.10 정산 페이지 출고의뢰서 수정 dialog boolean
  getChangeDialog(state) {
    return state.changeDialog;
  },

  // 생산페이지 (production page)
  getLoginUser(state) {
    const user = {
      name: state.auth_name,
      grade: state.auth_grade,
      _id: state.auth_id,
    };
    return user;
  },
  getItemList(state) {
    return state.itemList;
  },
  getItemClientList(state) {
    return state.clientList;
  },
  getClientList(state) {
    return state.clientList;
  },
  getUseClientList(state) {
    return state.useClientList;
  },
  getUseItemtList(state) {
    return state.useItemList;
  },
  getUseCtypeList(state) {
    return state.useCtypeList;
  },
  getUserList(state) {
    return state.userList;
  },

  getManufactureList(state) {
    return state.manufactureList;
  },

  getAppearanceList(state) {
    return state.requsestAppearance;
  },
  getReleaseColorList(state) {
    return state.releaseColorList;
  },
  getForm(state) {
    return state.form;
  },
  // 오더 페이지
  getOrderForm(state) {
    return state.orderForm;
  },
  getOrderList(state) {
    const exceptSatus = ["99", "60", "50", "20", "30", "90"];

    const orderList = JSON.parse(JSON.stringify(state.orderList));

    const result = orderList
      .filter((x) =>
        state.auth_grade >= 2
          ? !exceptSatus.includes(x.status)
          : x.status !== "99",
      )
      .reverse();

    state.orderFilterData = JSON.parse(JSON.stringify(result));
    return result;
  },

  getClaimOrderList(state) {
    const orderList = [...state.orderList];

    orderList.unshift({ order: "미선택" });
    return orderList;
  },
  getApproval(state) {
    return state.approval;
  },
  getCompany(state) {
    return state.company;
  },
  getSaveProductionData(state) {
    return state.saveProductionData;
  },
  getProductionData(state) {
    return state.productionData;
  },
  getBeforProductionData(state) {
    return state.beforProductionData;
  },
  //생산페이지 color List
  getProductionColor(state) {
    return state.productionColor;
  },
  //생산페이지 colorList total
  getProductionColorTot(state) {
    return state.colorTotal;
  },
  getBakData(state) {
    return state.bakData;
  },

  getCtypeList(state) {
    return state.ctypeList;
  },
  getApprovalList(state) {
    return state.approvalList;
  },

  getDefaultReleaseList(state) {
    return state.defaultReleaseList;
  },

  getEditedItem(state) {
    return state.editedItem;
  },
  getSelected(state) {
    return state.selected;
  },

  // 외상 매입,매출 페이지

  getRecivableSalesList(state) {
    return state.vatSalesList;
  },

  // 생산페이지 form
  getProductionForm(state) {
    return state.productionOrderForm;
  },

  // 가공의뢰서 form 리턴
  getManufactureForm(state) {
    return state.manufactureForm;
  },
  // 출고의뢰서 form 리턴
  getReleaseForm(state) {
    return state.releaseForm;
  },

  getFabricRelease(state) {
    return state.fabricRelease;
  },

  // 외관 검사서
  getAppearanceForm(state) {
    return state.appearanceForm;
  },
  getSaveOrder(state) {
    return state.saveData;
  },
  getProductionDoc(state) {
    let list = [];
    if (state.productionDocList.length && state.productionOrderForm.order) {
      state.productionDocList.forEach((x, i) => {
        if (!(x.kind === 12 && x.sales.isStock)) {
          const progress = state.productionOrderForm.progress.find(
            (y) => y.id === x.id,
          );
          if (progress) {
            x.placeName = `${i + 1}.${progress ? progress.log : ""}_${
              x.kind === 7 ? "가공" : x.kind === 12 ? "선매입" : "외관"
            }`;
            list.push(x);
          }
        }
      });
    }

    if (state.releaseForm.salesBtn && state.stockList.length)
      list.unshift({
        placeName: `* 재고 수량`,
        kind: 17,
      });

    if (state.releaseForm.stockBtn)
      list.unshift({
        placeName: `* 신규 재고 등록`,
        kind: 0,
      });

    return list;
  },

  getFabricRequestDoc(state) {
    const list = [];
    const order = JSON.parse(JSON.stringify(state.productionOrderForm));
    if (order.manufacture.length) {
      order.manufacture.forEach((x) => {
        if (x.status === "40") {
          const client = state.clientList.find(
            (client) => client._id === x.place_manufacture,
          );
          x.placeName = `${client.name} (${
            x.kind === 7 ? "가공" : "생지 가공"
          })`;
          list.push(x);
        }
      });
    }

    if (state.fabricRelease.kind === 19) {
      list.unshift({
        placeName: `* 생지 출고`,
        kind: 90,
      });
    }
    if (state.fabricRelease.kind === 9 && state.stockList.length)
      list.unshift({
        placeName: `* 재고 수량`,
        kind: 17,
      });

    return list;
  },

  getCancelDialog(state) {
    return state.cancelDialog;
  },

  //승인요청 dialog form 데이터
  getRequestForm(state) {
    return state.requestForm;
  },
  //승인요청 dialog list
  getRequestList(state) {
    const data = JSON.parse(JSON.stringify(state.requestSetList));
    if (data.list.length < 16) {
      const num = 15 - data.list.length;
      for (let i = 0; i < num; i++) {
        data.list.push({ id: "" });
      }
    }

    return data;
  },

  // 클레임페이지
  getClaimForm(state) {
    return state.claimForm;
  },
  getNewTypeClaimForm(state) {
    return state.newTypeClaimForm;
  },
  getModifyClaim(state) {
    return state.modifyClaim;
  },

  // 프린트 페이지
  getManufacturePrien(state) {
    return state.manufacturePrient;
  },
  getReleasePrien(state) {
    return state.releasePrient;
  },
  getPrientColor(state) {
    return state.prientColor;
  },

  //임시 필터용

  getPurchaseCacList(state) {
    return state.purchaseCacList ? state.purchaseCacList : [];
  },
  getClaimOriginalList(state) {
    return state.claimOriginalList ? state.claimOriginalList : [];
  },
  getClaimFilterList(state) {
    return state.claimFilterList ? state.claimFilterList : [];
  },
  getEndClaimOriginalList(state) {
    return state.endClaimOriginalList ? state.endClaimOriginalList : [];
  },
  getEndClaimFilterList(state) {
    return state.endClaimFilterList ? state.endClaimFilterList : [];
  },
  getClaimOriginFilter(state) {
    return state.claimOriginFilter ? state.claimOriginFilter : [];
  },
  getClaimOriginTotalList(state) {
    return state.claimOriginTotalList ? state.claimOriginTotalList : [];
  },
  getClaimFilteredOrginList(state) {
    return state.claimFilteredOrginList ? state.claimFilteredOrginList : [];
  },
  getEndClaimOriginFilter(state) {
    return state.endClaimOriginFilter ? state.endClaimOriginFilter : [];
  },
  getEndClaimOriginTotalList(state) {
    return state.endClaimOriginTotalList ? state.endClaimOriginTotalList : [];
  },
  getEndClaimFilteredOrginList(state) {
    return state.endClaimFilteredOrginList
      ? state.endClaimFilteredOrginList
      : [];
  },

  getMainOriginList(state) {
    return state.mainOriginList;
  },
  getMainFilterList(state) {
    return state.mainFilterList;
  },

  getOrderPageOrigin(state) {
    return state.orderPageOrigin;
  },
  getOrderPageFilter(state) {
    return state.orderPageFilter;
  },
  getOrderOriginFilter(state) {
    return state.orderOriginFilter;
  },
  getOrderOriginTotalList(state) {
    return state.orderOriginTotalList;
  },
  getOrderFilteredOrginList(state) {
    return state.orderFilteredOrginList;
  },

  // production page 필터 기능

  getProductionOriginList(state) {
    return state.productionOriginList;
  },
  getProductionFilterList(state) {
    return state.productionFilterList;
  },
  getProductionOriginFilter(state) {
    return state.productionOriginFilter;
  },
  getProductionOriginTotalList(state) {
    return state.productionOriginTotalList;
  },
  getProductionFilteredOrginList(state) {
    return state.productionFilteredOrginList;
  },

  getOriginVatList(state) {
    return state.originVatList;
  },
  getVatFilterList(state) {
    return state.vatFilterList;
  },
  getVatOriginFilter(state) {
    return state.vatOriginFilter;
  },
  getVatOriginTotalList(state) {
    return state.vatOriginTotalList;
  },
  getVatFilteredOrginList(state) {
    return state.vatFilteredOrginList;
  },

  getSalesOriginVatList(state) {
    return state.salesOriginVatList;
  },
  getSalesVatFilterList(state) {
    return state.salesVatFilterList;
  },
  getSalesVatOriginFilter(state) {
    return state.salesVatOriginFilter;
  },
  getSalesVatOriginTotalList(state) {
    return state.salesVatOriginTotalList;
  },
  getSalesVatFilteredOrginList(state) {
    return state.salesVatFilteredOrginList;
  },

  //매입 정산 페이지
  getPurchCalculList(state) {
    return state.purchCalculList;
  },
  getPurchCalculOrigin(state) {
    return state.purchCalculOrigin;
  },
  getPurchCalculFilter(state) {
    return state.purchCalculFilter;
  },
  getPurchaseCacOriginFilter(state) {
    return state.purchaseCacOriginFilter;
  },
  getPurchaseCacOriginTotalList(state) {
    return state.purchaseCacOriginTotalList;
  },
  getPurchaseCacFilteredOrginList(state) {
    return state.purchaseCacFilteredOrginList;
  },
  //매출 정산 페이지
  getsalesCacList(state) {
    return state.salesCacList;
  },
  getsalesCacOrigin(state) {
    return state.salesCacOrigin;
  },
  getsalesCacFilter(state) {
    return state.salesCacFilter;
  },
  getSalesCaccOriginFilter(state) {
    return state.salesCaccOriginFilter;
  },
  getSalesCacOriginTotalList(state) {
    return state.salesCacOriginTotalList;
  },
  getSalesCacFilteredOrginList(state) {
    return state.salesCacFilteredOrginList;
  },

  //매출 마감 페이지
  getsalesEndList(state) {
    return state.salesEndList;
  },
  getsalesEndOrigin(state) {
    return state.salesEndOrigin;
  },
  getsalesEndFilter(state) {
    return state.salesEndFilter;
  },
  getSalesDedOriginFilter(state) {
    return state.salesDedOriginFilter;
  },
  getSalesDedOriginTotalList(state) {
    return state.salesDedOriginTotalList;
  },
  getSalesDedFilteredOrginList(state) {
    return state.salesDedFilteredOrginList;
  },

  //매입 마감 페이지
  getPurchSalesCalculList(state) {
    return state.purchSalesCalculList;
  },
  getPurchSalesCalculOrigin(state) {
    return state.purchSalesCalculOrigin;
  },
  getPurchSalesCalculFilter(state) {
    return state.purchSalesCalculFilter;
  },
  getPurchaseDedcOriginFilter(state) {
    return state.purchaseDedcOriginFilter;
  },
  getPurchaseDedOriginTotalList(state) {
    return state.purchaseDedOriginTotalList;
  },
  getPurchaseDedFilteredOrginList(state) {
    return state.purchaseDedFilteredOrginList;
  },

  getNumFilterList(state) {
    return state.numFilterList;
  },
  //signType return
  getSignType(state) {
    return state.signType;
  },
  getClaimNewRows(state) {
    return state.claimNewRows;
  },

  getClaimUserList(state) {
    const userList = JSON.parse(JSON.stringify(state.userList)).reverse();
    userList.unshift({ name: "미선택" });
    return userList;
  },
  getEmailInput(state) {
    return state.emailInput;
  },
  getAriseCheckBox(state) {
    return state.ariseCheckBox;
  },
  getPassCheckBox(state) {
    return state.passCheckBox;
  },
  getEndAriseCheckBox(state) {
    return state.endAriseCheckBox;
  },
  getEndPassCheckBox(state) {
    return state.endPassCheckBox;
  },
  //생산페이지 매입 매출 계산 값
  getPurchasCacul(state) {
    return state.purchasCacul;
  },
  getSalesCacul(state) {
    return state.salesCacul;
  },
  getExcelFormList(state) {
    return state.excelFormList;
  },
  getItemExcelFormList(state) {
    return state.itemExcelFormList;
  },
  getCtypeExcelFormList(state) {
    return state.ctypeExcelFormList;
  },

  // 가공/출고 의뢰서 문서 열람 기능
  getDoctData(state) {
    return state.docData;
  },
  getSelectDocData(state) {
    return state.selectDocData;
  },
  getIsDocDataBtn(state) {
    return state.isDocDataBtn;
  },
  getDoctDataList(state) {
    return state.docDataList;
  },
  getContextData(state) {
    return state.contextData;
  },
  getNavSelected(state) {
    return state.navSelected;
  },

  getRequestDoc(state) {
    return state.manufactureForm.dialog
      ? state.manufactureForm
      : state.releaseForm.dialog
      ? state.releaseForm
      : state.appearanceForm.dialog
      ? state.appearanceForm
      : {
          kind: 99,
          dialog: false,
        };
  },
  getFabricDoc(state) {
    return state.isFabricRelease ? state.fabricRelease : state.manufactureForm;
  },

  getEditCompany(state) {
    return state.editCompany;
  },

  getEditRowData(state) {
    return state.editRowData;
  },
  getBakDocListDialog(state) {
    return state.bakDocListDialog;
  },

  getProductionPrint(state) {
    return state.releaseForm.dialog && state.releaseForm.id
      ? state.releaseForm
      : state.manufactureForm.dialog && state.manufactureForm.id
      ? state.manufactureForm
      : { dialog: false };
  },

  getProductionList(state) {
    return state.productionOrderForm._id
      ? JSON.parse(
          JSON.stringify([
            ...state.productionOrderForm.release,
            ...state.productionOrderForm.manufacture,
          ]),
        )
      : [];
  },
  getCommaColor(state) {
    return state.commaColor;
  },
  getMonthList(state) {
    return state.monthList;
  },
  getPrintForm(state) {
    return state.printForm;
  },

  getAttachmentList(state) {
    return state.attachmentList;
  },
  getAttachmentId(state) {
    return state.attachmentId;
  },

  getLogoImg(state) {
    return state.logoImg;
  },

  // getStock(state) {
  //   return state.saveStock;
  // },
  getSelectStock(state) {
    return state.selectStock;
  },
  getIsSalesSurcharge(state) {
    return state.isSalesSurcharge;
  },
  getRequestApprovalList(state) {
    return state.requestApprovalList;
  },
  getUserRequestApprovalList(state) {
    return state.requestList;
  },
  getRquestApprovalOrginalList(state) {
    return state.requestApprovalOriginalList;
  },
  getRquestApprovalFilterList(state) {
    return state.requestApprovalFilterList;
  },
  getStockList(state) {
    return state.stockList;
  },

  getStockColorList(state) {
    return state.stockColorList;
  },
  //재고 페이지
  getUseStockClientList(state) {
    return state.useStockClientList;
  },
  getStockTableOriginList(state) {
    return state.stockTableOriginList;
  },

  getStockOriginFilter(state) {
    return state.stockOriginFilter;
  },
  getStockOriginTotalList(state) {
    return state.stockOriginTotalList;
  },
  getStockFilteredOrginList(state) {
    return state.stockFilteredOrginList;
  },

  getNewStock(state) {
    return state.newStock;
  },
  getEditStockForm(state) {
    return state.editStockForm;
  },
  getIsStockDialog(state) {
    return state.isStockDialog;
  },
  getIsProgressDialog(state) {
    return state.isProgressDialog;
  },
  getProgressData(state) {
    return state.progressData;
  },
  getProgressFileList(state) {
    return state.prgoressFileList;
  },

  getPresetList(state) {
    return state.presetList;
  },

  getPageIndex(state) {
    return state.pageIndex;
  },
  getInputUsedStockList(state) {
    return state.usedStockList.filter((data) => data.input_type === 1);
  },
  getOutputUsedStockList(state) {
    return state.usedStockList.filter((data) => data.input_type === 2);
  },
  getOutputSetStockList(state) {
    return state.outputSetStockList;
  },
  getInputSetStockList(state) {
    return state.inputSetStockList;
  },
  getDocReferanceGroup(state) {
    return state.docReferanceGroup;
  },
  getSalesSurchargeColorRelease(state) {
    return state.isFabricRelease ? state.fabricRelease : state.releaseForm;
  },

  getFabricCaculateDialog(state) {
    return state.fabricCaculateDialog;
  },

  /**
   * 1. 오더 페이지 getters
   */
  // order 페이지 List
  getOrderPageList(state) {
    return state.orderPageList;
  },
  // 오더 페이지 params
  getOrderParams(state) {
    return state.orderParams;
  },
  //오더 페이지 total count
  getOrderTotalCount(state) {
    return state.orderTotalCount.totalCount;
  },

  //오더 페이지 sort
  getOrderSort(state) {
    return state.orderSort;
  },

  /**
   * 2. 생산 페이지 getters
   */
  // 생산 페이지 List
  getProductionOrderList(state) {
    return state.productionOrderList;
  },
  // 생산 페이지 params
  getProductionParams(state) {
    return state.productionParams;
  },
  //생산 페이지 total count
  getProductionTotalCount(state) {
    return state.productionTotalCount.totalCount;
  },
  //오더 페이지 sort
  getProductionSort(state) {
    return state.productionSort;
  },

  /**
   * 3 정산 페이지 getters
   */
  // 정산,마감 페이지 list
  getCalculates(state) {
    return state.calculates;
  },
  // 매입 마감 리스트 list
  getCalculateEnds(state) {
    return state.calculateEnds;
  },
  // 매출 정산 리스트 list
  getSalesCalculates(state) {
    return state.salesCalculates;
  },
  // 매출 마감 리스트 list
  getSalesCalculateEnds(state) {
    return state.salesCalculateEnds;
  },

  // 매입 정산 페이지 param
  getPurchaseCaculParam(state) {
    return state.purchaseCaculParam;
  },
  // 매입 마감 페이지 param
  getPurchaseEndParam(state) {
    return state.purchaseEndParam;
  },
  // 매출 정산 페이지 param
  getSalesCaculParam(state) {
    return state.salesCaculParam;
  },
  // 매출 마감 페이지 param
  getSalesEndParam(state) {
    return state.salesEndParam;
  },
  // 정산, 마감 페이지 total Count
  getCalculateTotalCount(state) {
    return state.calculateTotal.totalCount;
  },
  // 정산, 마감 페이지 calcualte total
  getCalculateTotal(state) {
    return state.calculateTotal;
  },

  getCalculatesSort(state) {
    return state.calculatesSort;
  },
  getCalculateEndsSort(state) {
    return state.calculateEndsSort;
  },
  getsalesCalculatesSort(state) {
    return state.salesCalculatesSort;
  },
  getsalesCalculateEndSort(state) {
    return state.salesCalculateEndSort;
  },

  getPurCheckBox(state) {
    return state.purchCheckBox;
  },
  getEndPurCheckBox(state) {
    return state.endPurchCheckBox;
  },
  getSalesCheckBox(state) {
    return state.salesCheckBox;
  },
  getEndSalesCheckBox(state) {
    return state.endSalesCheckBox;
  },

  /**
   * 4 클레임 페이지 getters
   */
  // 클레임 리스트
  getClaimList(state) {
    return state.claimList;
  },
  // end 클레임 리스트
  getEndClaimList(state) {
    return state.endClaimList ? state.endClaimList : [];
  },
  // 클레임 param
  getClaimParam(state) {
    return state.claimParam;
  },
  //end 클레임 param
  getEndClaimParam(state) {
    return state.endClaimParam;
  },
  //getClaimTotal
  // 정산, 마감 페이지 total Count
  getClaimTotalCount(state) {
    return state.claimTotal.totalCount;
  },
  // 정산, 마감 페이지 total
  getClaimTotal(state) {
    return state.claimTotal;
  },

  getClaimSort(state) {
    return state.claimSort;
  },
  getEndClaimSort(state) {
    return state.endClaimSort;
  },

  getAriseAllChecked(state) {
    return state.ariseAllChecked;
  },

  getPassAllChecked(state) {
    return state.passAllChecked;
  },

  getEndAriseAllChecked(state) {
    return state.endAriseAllChecked;
  },

  getEndPassAllChecked(state) {
    return state.endPassAllChecked;
  },

  /**
   * 5 main 페이지 getters
   */
  // 메인 페이지 리스트
  getMainOrderList(state) {
    return state.mainOrderList;
  },
  //메인 페이지 param
  getMainParam(state) {
    return state.mainParam;
  },
  // 메인 페이지 total
  getMainTotalCount(state) {
    return state.mainTotal.totalCount;
  },
  // 메인 페이지 total
  getMainTotal(state) {
    return state.mainTotal;
  },
  getMainSort(state) {
    return state.mainSort;
  },

  /**
   * 6 외상금 페이지 getters
   */
  //외상 매입금 페이지 리스트
  getRecivableList(state) {
    return state.vatList;
  },
  //외상 매출금 페이지 리스트
  getSalesRecivableList(state) {
    return state.vatSalesList;
  },
  //매입 외상금 param
  getRecivableParam(state) {
    return state.purchaseVatParam;
  },
  //매출 외상금 param
  getSalesRecivableParam(state) {
    return state.salesVatParam;
  },
  //외상금 total Count
  getRecivableTotalCount(state) {
    return state.vatTotal.totalCount;
  },
  //외상금 total
  getRecivableTotal(state) {
    return state.vatTotal;
  },

  //외상 매입금 구분
  getRecivableType(state) {
    return state.vatSelected;
  },

  //외상 매출금 구분
  getSalesRecivableType(state) {
    return state.vatSalesSelected;
  },

  getVatSort(state) {
    return state.vatSort;
  },
  getVatSalesSort(state) {
    return state.vatSalesSort;
  },

  /**
   * 7 재고 페이지 getters
   */
  //재고 페이지 리스트
  getStockTableList(state) {
    return state.stockTableList;
  },

  //재고 페이지 param
  getStockParam(state) {
    return state.stockParam;
  },

  //재고 페이지 count
  getStockTotalCount(state) {
    return state.stockTotal.totalCount;
  },

  //재고 페이지 total
  getStockTotal(state) {
    return state.stockTotal;
  },

  getStockSelected(state) {
    return state.stockSelected;
  },

  getstockSort(state) {
    return state.stockSort;
  },

  getStockCheckbox(state) {
    return state.stockCheckbox;
  },

  /**
   * 인디케이터
   */
  getPageTotalCount(state) {
    const route = router.app._route.name;
    let totalCount;
    let pageList;

    switch (route) {
      case "main":
        totalCount = state.mainTotal.totalCount;
        pageList = state.mainOrderList;
        break;
      case "order":
        totalCount = state.orderTotalCount.totalCount;
        pageList = state.orderPageList;
        break;
      case "production":
        totalCount = state.productionTotalCount.totalCount;
        pageList = state.productionOrderList;
        break;
      case "purchasecalculate":
        totalCount = state.calculateTotal.totalCount;
        pageList = state.calculates;
        break;
      case "purchasedeadline":
        totalCount = state.calculateTotal.totalCount;
        pageList = state.calculateEnds;
        break;
      case "salescalculate":
        totalCount = state.calculateTotal.totalCount;
        pageList = state.salesCalculates;
        break;
      case "salesdeadline":
        totalCount = state.calculateTotal.totalCount;
        pageList = state.salesCalculateEnds;
        break;
      case "purchaseVat":
        totalCount = state.vatTotal.totalCount;
        pageList = state.vatList;
        break;
      case "salesVat":
        totalCount = state.vatTotal.totalCount;
        pageList = state.vatSalesList;
        break;
      case "claim":
        totalCount = state.claimTotal.totalCount;
        pageList = state.claimList;
        break;
      case "endClaim":
        totalCount = state.claimTotal.totalCount;
        pageList = state.endClaimList;
        break;
      case "stock":
        totalCount = state.stockTotal.totalCount;
        pageList = state.stockTableList;
        break;
    }

    return {
      totalCount,
      pageCount: pageList.length,
      oneCheckBoxLength: state.checkBoxLength.oneCheckBoxLength,
      secondCheckBoxLength: state.checkBoxLength.secondCheckBoxLength,
    };
  },

  /**
   * numDialog
   */
  getNumDialogData(state) {
    return state.numDialogData;
  },
  getCurrentYearChartData(state) {
    return state.mainChartData.currentYearList ?? [];
  },
  getLastYearChartData(state) {
    return state.mainChartData.LastYearList ?? [];
  },
  getIsPreviewDialog(state) {
    return state.isPreviewDialog;
  },
  getPaymentList(state) {
    return state.paymentList;
  },
  getTrialAlramDialog(state) {
    return state.trialAlramDialog;
  },
  getAdminConfirmDialog(state) {
    return state.adminConfirmDialog;
  },
  getPayData(state) {
    return state.payData;
  },
  getAdminPaymentList(state) {
    return state.adminPaymentList;
  },
  getMembershipInfo(state) {
    return state.membershipInfo;
  },
};
