import { instance } from "./index";

function getClothestype() {
  return instance.get(`clothestype`);
}
function getClothestypeByCompany(company) {
  return instance.get(`clothestype/${company}/`);
}
function getClothestypeByCompanybyStatus(company, status) {
  return instance.get(`clothestype/${company}/?status=${status}`);
}
function postClothestype(data) {
  return instance.post(`clothestype`, data);
}
function putClothestype(data) {
  return instance.put(`clothestype`, data);
}
function deleteClothestype(name) {
  return instance.delete(`clothestype/${name}`);
}

export {
  getClothestype,
  getClothestypeByCompany,
  getClothestypeByCompanybyStatus,
  postClothestype,
  putClothestype,
  deleteClothestype,
};
